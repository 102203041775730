import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { listUserAccounts } from "../../graphql/queries";
import { updateUserAccount } from "../../graphql/mutations";
import ReactSingleSelect from "../tools/reactSingleSelect";
import UpdateActivity from "../tools/updateActivity";

export class addContent extends Component {
  state = {
    students: [],
    studentsSuggestion: [],
    addedSuggestion: null,
    loading: false,
    addingBatchID: this.props.batchID.id,
    addingBatchName: this.props.batchID.batchTitle,
    version: null,
    editStudentID: null,
    studentBatchInformation: null,
    studentNextToken: null,
    submitErrors: null,
  };

  getNewStudents() {
    API.graphql(
      graphqlOperation(listUserAccounts, {
        limit: 100,
        nextToken: this.state.studentNextToken,
      })
    ).then((result) => {
      var students = result.data.listUserAccounts.items.filter(
        (student) =>
          student.batchId === null &&
          student.typeOfAccount === "Student" &&
          student.Status !== "Disabled" &&
          student._deleted !== true
      );
      var studentsSuggestion = this.state.studentsSuggestion;
      var allStudents = [...this.state.students, ...students];
      if (students.length === 0) {
        this.setState({
          message: "No new students yet!",
        });
      } else {
        students.map((student) => {
          studentsSuggestion.push({
            key: student.id,
            label:
              student.firstName +
              " " +
              student.lastName +
              " (" +
              student.officeID +
              ")",
            value: student.id,
          });
          return null;
        });
      }
      this.setState({
        students: allStudents,
        studentsSuggestion,
        loading: false,
        studentNextToken: result.data.listUserAccounts.nextToken,
      });
      if (result.data.listUserAccounts.nextToken !== null) {
        this.getNewStudents();
      }
    });
  }

  componentDidMount() {
    this.getNewStudents();
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = () => {
    const {
      version,
      addingBatchID,
      editStudentID,
      studentBatchInformation,
      addingBatchName,
    } = this.state;
    var input = {};
    var today = new Date();
    var newBatchInfo = [];
    if (studentBatchInformation !== null) {
      newBatchInfo = [
        ...studentBatchInformation,
        {
          batchId: addingBatchID,
          start_date: today.toISOString(),
          end_date: "null",
          status: "active",
        },
      ];
    } else {
      newBatchInfo.push({
        batchId: addingBatchID,
        start_date: today.toISOString(),
        end_date: "null",
        status: "active",
      });
    }
    input = {
      id: editStudentID,
      batchId: addingBatchID,
      batchInformation: newBatchInfo,
      _version: version,
    };
    API.graphql(graphqlOperation(updateUserAccount, { input: input }))
      .then((updatedStudent) => {
        console.log("Updated Student", updatedStudent);
        this.setState({
          loading: false,
        });
        var activityTime = new Date();
        UpdateActivity.updateActivity({
          activityItem: `Student Added to Batch`,
          tableName: "UserAccount",
          referenceID: updatedStudent.id,
          remarks: `Student ${updatedStudent.firstName} Added to ${addingBatchName}`,
          activityDateTime: activityTime.toISOString(),
        });
        this.props.closeForm();
      })
      .catch((err) => {
        var errors = err.errors;
        errors.map((error) => {
          console.log(error.message);
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Error Adding to Batch`,
            tableName: "UserAccount",
            referenceID: editStudentID,
            remarks: error.message,
            activityDateTime: activityTime.toISOString(),
          });
          return null;
        });
        this.setState({
          loading: false,
          submitErrors: errors,
        });
      });
  };

  addStudentToBatch(value) {
    var selectedStudent = this.state.students.filter(
      (student) => student.id === value.key
    );
    this.setState({
      version: selectedStudent[0]._version,
      editStudentID: selectedStudent[0].id,
      studentBatchInformation: selectedStudent[0].batchInformation,
    });
  }

  render() {
    const { loading, studentsSuggestion, submitErrors } = this.state;
    return (
      <div>
        <Form>
          <ReactSingleSelect
            disableStatus={false}
            suggestions={studentsSuggestion}
            selectValue={"Students"}
            selectFunction={(value) => this.addStudentToBatch(value)}
            placeholder={"Select Student to add to Batch"}
          />
          <br />
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
        {submitErrors !== null
          ? submitErrors.map((error) => (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{error.message}</div>
              </div>
            ))
          : null}
      </div>
    );
  }
}

export default addContent;
