import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listNotifications,
  listUserAccounts,
} from "../../../../graphql/queries";
import { deleteNotification } from "../../../../graphql/mutations";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditNotification from "./addEditNotification";
import {
  onCreateNotification,
  onUpdateNotification,
} from "../../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class batchNotifications extends Component {
  state = {
    notifications: [],
    loading: false,
    message: null,
    openAddNotification: false,
    deleteNotificationName: null,
    deleteNotificationID: null,
    openDeleteNotification: false,
    editItem: null,
    studentBatch: this.props.notificationType,
    addEditStatus: true,
  };

  getNotifications(filterQuery) {
    API.graphql(graphqlOperation(listNotifications, filterQuery)).then(
      (result) => {
        var notifications = result.data.listNotifications.items;
        if (notifications.length === 0) {
          this.setState({
            message: "No Notifications Added yet!",
          });
        }
        this.setState({ notifications, loading: false });
      }
    );
  }

  componentDidMount() {
    const { studentBatch, notifications } = this.state;
    var filterQuery = {};
    if (studentBatch === "Batch") {
      filterQuery = {
        filter: {
          batchID: { eq: this.props.batchDetails.id },
        },
      };
      this.getNotifications(filterQuery);
    } else {
      API.graphql(
        graphqlOperation(listUserAccounts, {
          filter: {
            and: [
              {
                typeOfAccount: { eq: "Student" },
              },
              { batchId: { eq: this.props.batchDetails.id } },
            ],
          },
        })
      ).then((result) => {
        var users = result.data.listUserAccounts.items;
        if (users.length === 0) {
          this.setState({
            message: "No Users Added yet!",
          });
        } else {
          var and = [];
          users.map((user) => {
            and.push({ studentID: { eq: user.id } });
            return null;
          });
          filterQuery = { filter: { and: and } };
          this.getNotifications(filterQuery);
        }
      });
    }

    //Function to listen to post subject
    this.createNotificationListener = API.graphql(
      graphqlOperation(onCreateNotification)
    ).subscribe({
      next: (notificationData) => {
        const newNotification =
          notificationData.value.data.onCreateNotification;
        const prevNotification = notifications.filter(
          (notification) => notification.id !== newNotification.id
        );
        this.setState({
          notifications: [newNotification, ...prevNotification],
        });
      },
    });

    //Function to listen to update notifications
    this.updateNotificationListener = API.graphql(
      graphqlOperation(onUpdateNotification)
    ).subscribe({
      next: (notificationData) => {
        const updateNotification =
          notificationData.value.data.onUpdateNotification;
        const index = notifications.findIndex(
          (notification) => notification.id === updateNotification.id
        );
        const updateNotifications = [
          ...notifications.slice(0, index),
          updateNotification,
          ...notifications.slice(index + 1),
        ];
        this.setState({ notifications: updateNotifications });
      },
    });
  }

  deleteNotification(id) {
    API.graphql(graphqlOperation(deleteNotification, { id: id })).then(
      (deletedNotification) => {
        console.log("Deleted Notification:", deletedNotification);
      }
    );
  }

  componentWillUnmount() {
    if (this.createNotificationListener !== undefined) {
      this.createNotificationListener.unsubscribe();
    }
    if (this.updateNotificationListener !== undefined) {
      this.updateNotificationListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditNotification: false,
    });
  };

  render() {
    const {
      notifications,
      loading,
      openAddEditNotification,
      deleteNotificationName,
      openDeleteNotification,
      addEditStatus,
      editItem,
      message,
    } = this.state;
    const { batchDetails, notificationType } = this.props;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditNotification: true,
                addEditStatus: true,
              })
            }
            title={"Add Notification"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditNotification}
          onHide={() => this.setState({ openAddEditNotification: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? (
                <>{`Add ${notificationType} Notification`}</>
              ) : (
                <>{`Edit ${notificationType}  Notification`}</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditNotification
              studentBatch={notificationType}
              batchDetails={batchDetails}
              addEditStatus={addEditStatus}
              editingNotification={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteNotification}
          onHide={() => this.setState({ openDeleteNotification: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteNotificationName} Notification Material?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure u want to delete the Notification?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.deleteNotification}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteNotification: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader
            title={`${this.props.notificationType} Notification`}
            toolbar={toolbar}
          />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Topic </th>
                    <th>Message</th>
                    <th>Recipient</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : notifications.length === 0 ? (
                    <tr>
                      <td colSpan="7">{message}</td>
                    </tr>
                  ) : (
                    notifications.map((notification, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{notification.topic}</td>
                        <td>{notification.message}</td>
                        <td>
                          {" "}
                          <button className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                style={{ color: "red" }}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Cloud-download.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: notification,
                                openAddEditNotification: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            style={{ color: "red" }}
                            onClick={() => {
                              this.setState({
                                deleteNotificationName:
                                  notification.notificationTitle,
                                deleteNotificationID: notification.id,
                                openDeleteNotification: true,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default batchNotifications;
