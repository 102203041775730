import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { updateDissertation } from "../../../../graphql/mutations";

export class addEditRemarks extends Component {
  state = {
    remarks: "",
    userId: "",
    userName: "",
    loading: false,
    version: null,
  };

  componentDidMount() {
    const { addEditStatus, editingDissertation } = this.props;
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!addEditStatus) {
      this.setState({
        remarks: editingDissertation.remarks,
        editDissertationID: editingDissertation.id,
        version: editingDissertation._version,
      });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = () => {
    const { editDissertationID, version, remarks } = this.state;
    var input = {};
    if (this.props.addEditStatus) {
      console.log("Creating");
    } else {
      console.log("Updating");
      input = {
        id: editDissertationID,
        remarks: remarks,
        _version: version,
      };
      API.graphql(graphqlOperation(updateDissertation, { input: input })).then(
        (updatedDissertation) => {
          console.log("updated Dissertation:", updatedDissertation);
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        }
      );
    }
  };

  render() {
    const { remarks, loading } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              disabled={true}
              placeholder="Title"
              name="title"
              value={this.props.editingDissertation.title}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>SBS ID</Form.Label>
            <Form.Control
              type="text"
              disabled={true}
              placeholder="SBS ID"
              name="SBS ID"
              value={
                this.props.editingDissertation.student !== null &&
                this.props.editingDissertation.student !== undefined
                  ? this.props.editingDissertation.student.officeID
                  : ""
              }
              onChange={this.onChange}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              type="textArea"
              placeholder="Remarks"
              name="remarks"
              value={remarks}
              onChange={this.onChange}
            />
          </Form.Group>

          <br />

          <Button
            variant="primary"
            onClick={() => {
              this.setState({ loading: true });
              this.onSubmit();
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addEditRemarks;
