import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import SuperAdminBase from "../pages/superAdmin/SuperAdminBasePage";
import AdministratorBase from "../pages/administrator/AdminBasePage";
import ExaminationBase from "../pages/exam/ExaminationBasePage";
import DeanBase from "../pages/dean/DeanBasePage ";
import StudentBase from "../pages/student/StudentBasePage";

import { Logout, AuthPage } from "./account";
import ErrorsPage from "./errors/ErrorsPage";

export function Routes() {
  const { userType, isAuthenticated, user } = useSelector(
    (state) => state.reducerAuth,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      console.log("Loading Routes");
    };
  }, [isAuthenticated, userType, user]);

  return (
    <Router>
      <Switch>
        {!isAuthenticated ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to="/" />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        {!isAuthenticated ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            {userType === "Admin" ? (
              <SuperAdminBase />
            ) : userType === "Administrator" ? (
              <AdministratorBase />
            ) : userType === "Examiner" ? (
              <ExaminationBase />
            ) : userType === "Dean" ? (
              <DeanBase />
            ) : userType === "Student" ? (
              <StudentBase />
            ) : (
              "No Page Available"
            )}
          </Layout>
        )}
      </Switch>
    </Router>
  );
}
