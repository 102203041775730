/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import { useLocation } from "react-router";
import { checkIsActive } from "../../../../_helpers";
import SuperAdminMenu from "./UserBasedMenu/superAdminMenu";
import StudentMenu from "./UserBasedMenu/studentMenu";
import ExaminerMenu from "./UserBasedMenu/examinerMenu";
import DeanMenu from "./UserBasedMenu/deanMenu";
import AdministratorMenu from "./UserBasedMenu/adminMenu";

export default function Menu({ layoutProps, userType }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const users = [
    {
      name: "Student", //created
      icon: "/media/svg/icons/Communication/Group.svg",
    },
    {
      name: "Administrator", //created
      icon: "/media/svg/icons/Communication/Shield-user.svg",
    },
    {
      name: "Examiner", //created
      icon: "/media/svg/icons/Clothes/Shirt.svg",
    },
    {
      name: "Accounts",
      icon: "/media/svg/icons/Shopping/Money.svg",
    },
    {
      name: "Dean",
      icon: "/media/svg/icons/Shopping/Money.svg",
    },
  ];
  return (
    <>
      {userType === "Admin" ? (
        <SuperAdminMenu
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
          userType={userType}
          users={users}
        />
      ) : userType === "Student" ? (
        <StudentMenu
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
          userType={userType}
        />
      ) : userType === "Administrator" ? (
        <AdministratorMenu
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
          userType={userType}
        />
      ) : userType === "Examiner" ? (
        <ExaminerMenu
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
          userType={userType}
        />
      ) : userType === "Dean" ? (
        <DeanMenu
          layoutProps={layoutProps}
          getMenuItemActive={getMenuItemActive}
          userType={userType}
        />
      ) : null}
    </>
  );
}
