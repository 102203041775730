import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listLocations } from "../../../graphql/queries";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditLocation from "./addEditLocation";
import {
  onCreateLocation,
  onUpdateLocation,
} from "../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class listLocation extends Component {
  state = {
    Locations: [],
    loading: true,
    message: null,
    openAddLocation: false,
    deleteLocationName: null,
    deleteLocationID: null,
    openDeleteLocation: false,
    editItem: null,
    addEditStatus: true,
  };

  getLocations() {
    API.graphql(graphqlOperation(listLocations)).then((result) => {
      var Locations = result.data.listLocations.items;
      if (Locations.length === 0) {
        this.setState({
          message: "No Locations Added yet!",
          loading: false,
        });
      } else {
        Locations = Locations.filter((location) => location._deleted !== true);
        this.setState({ Locations, loading: false });
      }
    });
  }

  componentDidMount() {
    this.getLocations();

    //Function to listen to post Location
    this.createLocationListener = API.graphql(
      graphqlOperation(onCreateLocation)
    ).subscribe({
      next: (locationData) => {
        const newLocation = locationData.value.data.onCreateLocation;
        const prevLocations = this.state.Locations.filter(
          (Location) => Location.id !== newLocation.id
        );
        this.setState({ Locations: [newLocation, ...prevLocations] });
      },
    });

    //Function to listen to update Locations
    this.updateLocationListener = API.graphql(
      graphqlOperation(onUpdateLocation)
    ).subscribe({
      next: (locationData) => {
        const updateLocation = locationData.value.data.onUpdateLocation;
        const index = this.state.Locations.findIndex(
          (Location) => Location.id === updateLocation.id
        );
        const updateLocations = [
          ...this.state.Locations.slice(0, index),
          updateLocation,
          ...this.state.Locations.slice(index + 1),
        ];
        this.setState({ Locations: updateLocations });
      },
    });
  }

  componentWillUnmount() {
    if (this.createLocationListener !== undefined) {
      this.createLocationListener.unsubscribe();
    }
    if (this.updateLocationListener !== undefined) {
      this.updateLocationListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditLocation: false,
    });
  };

  render() {
    const {
      Locations,
      loading,
      openAddEditLocation,
      deleteLocationName,
      openDeleteLocation,
      addEditStatus,
      editItem,
    } = this.state;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditLocation: true,
                addEditStatus: true,
              })
            }
            title={"Add Location"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditLocation}
          onHide={() => this.setState({ openAddEditLocation: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Location" : "Edit Location"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditLocation
              addEditStatus={addEditStatus}
              editingLocation={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteLocation}
          onHide={() => this.setState({ openDeleteLocation: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteLocationName} Location
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure u want to delete the Location?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.deleteLocation}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteLocation: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="Locations" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Location </th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : Locations.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Locations added Yet!</td>
                    </tr>
                  ) : (
                    Locations.map((Location, i) => (
                      <tr key={Location.id}>
                        <td>{i + 1}</td>
                        <td>{Location.locationName}</td>
                        <td>{Location.locationDescription}</td>

                        <td>
                          <button className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                style={{ color: "red" }}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Settings-1.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: Location,
                                openAddEditLocation: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() => {
                              /*
                              this.setState({
                                deleteLocationName: Location.locationName,
                                deleteLocationID: Location.id,
                                openDeleteLocation: true,
                              });
                              */
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default listLocation;
