import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation } from "aws-amplify";
import {
  createDissertationCollection,
  updateDissertationCollection,
} from "../../../graphql/mutations";

export class addCollection extends Component {
  state = {
    title: "",
    description: "",
    editCollectionID: null,
    userId: "",
    userName: "",
    loading: false,
    version: null,
  };

  componentDidMount() {
    const { addEditStatus, editingCollection } = this.props;
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!addEditStatus) {
      this.setState({
        title: editingCollection.title,
        description: editingCollection.description,
        editCollectionID: editingCollection.id,
        version: editingCollection._version,
      });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = () => {
    const {
      title,
      description,
      userId,
      editCollectionID,
      version,
    } = this.state;
    console.log(title, description);
    var input = {};
    if (this.props.addEditStatus) {
      input = {
        title: title,
        description: description,
        createdByUserID: userId,
      };
      API.graphql(
        graphqlOperation(createDissertationCollection, { input: input })
      ).then((createdCollection) => {
        this.setState({
          loading: false,
        });
        this.props.closeForm();
      });
    } else {
      input = {
        id: editCollectionID,
        title: title,
        description: description,
        createdByUserID: userId,
        _version: version,
      };
      API.graphql(
        graphqlOperation(updateDissertationCollection, { input: input })
      ).then((updatedCollection) => {
        this.setState({
          loading: false,
        });
        this.props.closeForm();
      });
    }
  };

  render() {
    const { description, title, loading } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Collection Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="June 2022"
              name="title"
              value={title}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Collection Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              name="description"
              value={description}
              onChange={this.onChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addCollection;
