/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCourse = /* GraphQL */ `
  subscription OnCreateCourse {
    onCreateCourse {
      id
      courseName
      courseDescription
      qualifySubjects
      deniedUsers
      textBooks {
        items {
          id
          title
          courseID
          subject
          description
          deniedUsers
          downloadCount
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCourse = /* GraphQL */ `
  subscription OnUpdateCourse {
    onUpdateCourse {
      id
      courseName
      courseDescription
      qualifySubjects
      deniedUsers
      textBooks {
        items {
          id
          title
          courseID
          subject
          description
          deniedUsers
          downloadCount
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCourse = /* GraphQL */ `
  subscription OnDeleteCourse {
    onDeleteCourse {
      id
      courseName
      courseDescription
      qualifySubjects
      deniedUsers
      textBooks {
        items {
          id
          title
          courseID
          subject
          description
          deniedUsers
          downloadCount
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTextBook = /* GraphQL */ `
  subscription OnCreateTextBook {
    onCreateTextBook {
      id
      title
      courseID
      course {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subject
      description
      textBookMaterial {
        bucket
        region
        key
      }
      deniedUsers
      downloadCount
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTextBook = /* GraphQL */ `
  subscription OnUpdateTextBook {
    onUpdateTextBook {
      id
      title
      courseID
      course {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subject
      description
      textBookMaterial {
        bucket
        region
        key
      }
      deniedUsers
      downloadCount
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTextBook = /* GraphQL */ `
  subscription OnDeleteTextBook {
    onDeleteTextBook {
      id
      title
      courseID
      course {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subject
      description
      textBookMaterial {
        bucket
        region
        key
      }
      deniedUsers
      downloadCount
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation {
    onCreateLocation {
      id
      locationName
      locationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation {
    onUpdateLocation {
      id
      locationName
      locationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation {
    onDeleteLocation {
      id
      locationName
      locationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSpecialization = /* GraphQL */ `
  subscription OnCreateSpecialization {
    onCreateSpecialization {
      id
      specializationName
      specializationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSpecialization = /* GraphQL */ `
  subscription OnUpdateSpecialization {
    onUpdateSpecialization {
      id
      specializationName
      specializationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSpecialization = /* GraphQL */ `
  subscription OnDeleteSpecialization {
    onDeleteSpecialization {
      id
      specializationName
      specializationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSubject = /* GraphQL */ `
  subscription OnCreateSubject {
    onCreateSubject {
      id
      subjectName
      subjectDescription
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      subjectSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSubject = /* GraphQL */ `
  subscription OnUpdateSubject {
    onUpdateSubject {
      id
      subjectName
      subjectDescription
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      subjectSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSubject = /* GraphQL */ `
  subscription OnDeleteSubject {
    onDeleteSubject {
      id
      subjectName
      subjectDescription
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      subjectSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBatchGuidelines = /* GraphQL */ `
  subscription OnCreateBatchGuidelines {
    onCreateBatchGuidelines {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBatchGuidelines = /* GraphQL */ `
  subscription OnUpdateBatchGuidelines {
    onUpdateBatchGuidelines {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBatchGuidelines = /* GraphQL */ `
  subscription OnDeleteBatchGuidelines {
    onDeleteBatchGuidelines {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBatchDocuments = /* GraphQL */ `
  subscription OnCreateBatchDocuments {
    onCreateBatchDocuments {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBatchDocuments = /* GraphQL */ `
  subscription OnUpdateBatchDocuments {
    onUpdateBatchDocuments {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBatchDocuments = /* GraphQL */ `
  subscription OnDeleteBatchDocuments {
    onDeleteBatchDocuments {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBatch = /* GraphQL */ `
  subscription OnCreateBatch {
    onCreateBatch {
      id
      batchTitle
      batchIconName
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchType
      courseID
      scheduleDocument {
        bucket
        region
        key
      }
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchGuidelinesID
      attendances {
        items {
          id
          title
          batchID
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchGuidelines {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchDocuments
      studentCount
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBatch = /* GraphQL */ `
  subscription OnUpdateBatch {
    onUpdateBatch {
      id
      batchTitle
      batchIconName
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchType
      courseID
      scheduleDocument {
        bucket
        region
        key
      }
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchGuidelinesID
      attendances {
        items {
          id
          title
          batchID
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchGuidelines {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchDocuments
      studentCount
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBatch = /* GraphQL */ `
  subscription OnDeleteBatch {
    onDeleteBatch {
      id
      batchTitle
      batchIconName
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchType
      courseID
      scheduleDocument {
        bucket
        region
        key
      }
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchGuidelinesID
      attendances {
        items {
          id
          title
          batchID
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchGuidelines {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchDocuments
      studentCount
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAttendance = /* GraphQL */ `
  subscription OnCreateAttendance {
    onCreateAttendance {
      id
      title
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceFile {
        bucket
        region
        key
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAttendance = /* GraphQL */ `
  subscription OnUpdateAttendance {
    onUpdateAttendance {
      id
      title
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceFile {
        bucket
        region
        key
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAttendance = /* GraphQL */ `
  subscription OnDeleteAttendance {
    onDeleteAttendance {
      id
      title
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceFile {
        bucket
        region
        key
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAttendanceEntry = /* GraphQL */ `
  subscription OnCreateAttendanceEntry {
    onCreateAttendanceEntry {
      id
      attendanceID
      attendance {
        id
        title
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceFile {
          bucket
          region
          key
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceDate
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAttendanceEntry = /* GraphQL */ `
  subscription OnUpdateAttendanceEntry {
    onUpdateAttendanceEntry {
      id
      attendanceID
      attendance {
        id
        title
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceFile {
          bucket
          region
          key
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceDate
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAttendanceEntry = /* GraphQL */ `
  subscription OnDeleteAttendanceEntry {
    onDeleteAttendanceEntry {
      id
      attendanceID
      attendance {
        id
        title
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceFile {
          bucket
          region
          key
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceDate
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCourseSchedule = /* GraphQL */ `
  subscription OnCreateCourseSchedule {
    onCreateCourseSchedule {
      id
      title
      startDate
      endDate
      batchConnection
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCourseSchedule = /* GraphQL */ `
  subscription OnUpdateCourseSchedule {
    onUpdateCourseSchedule {
      id
      title
      startDate
      endDate
      batchConnection
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCourseSchedule = /* GraphQL */ `
  subscription OnDeleteCourseSchedule {
    onDeleteCourseSchedule {
      id
      title
      startDate
      endDate
      batchConnection
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateContentMaterials = /* GraphQL */ `
  subscription OnCreateContentMaterials {
    onCreateContentMaterials {
      id
      contentTitle
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      typeOfContent
      batchConnection
      Content_fileName
      Content_file {
        bucket
        region
        key
      }
      contentFiles {
        fileName
        bucket
        region
        key
        status
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateContentMaterials = /* GraphQL */ `
  subscription OnUpdateContentMaterials {
    onUpdateContentMaterials {
      id
      contentTitle
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      typeOfContent
      batchConnection
      Content_fileName
      Content_file {
        bucket
        region
        key
      }
      contentFiles {
        fileName
        bucket
        region
        key
        status
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteContentMaterials = /* GraphQL */ `
  subscription OnDeleteContentMaterials {
    onDeleteContentMaterials {
      id
      contentTitle
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      typeOfContent
      batchConnection
      Content_fileName
      Content_file {
        bucket
        region
        key
      }
      contentFiles {
        fileName
        bucket
        region
        key
        status
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateActivityLog = /* GraphQL */ `
  subscription OnCreateActivityLog {
    onCreateActivityLog {
      id
      activityItem
      tableName
      referenceID
      remarks
      activityDateTime
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateActivityLog = /* GraphQL */ `
  subscription OnUpdateActivityLog {
    onUpdateActivityLog {
      id
      activityItem
      tableName
      referenceID
      remarks
      activityDateTime
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteActivityLog = /* GraphQL */ `
  subscription OnDeleteActivityLog {
    onDeleteActivityLog {
      id
      activityItem
      tableName
      referenceID
      remarks
      activityDateTime
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUserAccount = /* GraphQL */ `
  subscription OnCreateUserAccount {
    onCreateUserAccount {
      id
      accountUserID
      officeID
      firstName
      lastName
      company
      qualification
      designation
      occupation
      language
      timeZone
      website
      DOJ
      birthCountry
      birthPlace
      email
      emailPersonal
      contactPhone
      contactPersonal
      DOR
      address {
        addressLine
        city
        state
        postCode
      }
      social {
        linkedIn
        facebook
        twitter
        instagram
      }
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      activeBatch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchInformation {
        batchId
        start_date
        end_date
        status
      }
      icon_image {
        bucket
        region
        key
      }
      communication {
        email
        sms
        phone
      }
      groupNotification
      personalNotification
      emailNotification
      Status
      sendInvitationMail
      accessExpiry
      typeOfAccount
      studentReport
      degreeID
      degree {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        certificateFile {
          bucket
          region
          key
        }
        applicationStatus
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUserAccount = /* GraphQL */ `
  subscription OnUpdateUserAccount {
    onUpdateUserAccount {
      id
      accountUserID
      officeID
      firstName
      lastName
      company
      qualification
      designation
      occupation
      language
      timeZone
      website
      DOJ
      birthCountry
      birthPlace
      email
      emailPersonal
      contactPhone
      contactPersonal
      DOR
      address {
        addressLine
        city
        state
        postCode
      }
      social {
        linkedIn
        facebook
        twitter
        instagram
      }
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      activeBatch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchInformation {
        batchId
        start_date
        end_date
        status
      }
      icon_image {
        bucket
        region
        key
      }
      communication {
        email
        sms
        phone
      }
      groupNotification
      personalNotification
      emailNotification
      Status
      sendInvitationMail
      accessExpiry
      typeOfAccount
      studentReport
      degreeID
      degree {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        certificateFile {
          bucket
          region
          key
        }
        applicationStatus
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUserAccount = /* GraphQL */ `
  subscription OnDeleteUserAccount {
    onDeleteUserAccount {
      id
      accountUserID
      officeID
      firstName
      lastName
      company
      qualification
      designation
      occupation
      language
      timeZone
      website
      DOJ
      birthCountry
      birthPlace
      email
      emailPersonal
      contactPhone
      contactPersonal
      DOR
      address {
        addressLine
        city
        state
        postCode
      }
      social {
        linkedIn
        facebook
        twitter
        instagram
      }
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      activeBatch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchInformation {
        batchId
        start_date
        end_date
        status
      }
      icon_image {
        bucket
        region
        key
      }
      communication {
        email
        sms
        phone
      }
      groupNotification
      personalNotification
      emailNotification
      Status
      sendInvitationMail
      accessExpiry
      typeOfAccount
      studentReport
      degreeID
      degree {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        certificateFile {
          bucket
          region
          key
        }
        applicationStatus
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAssignment = /* GraphQL */ `
  subscription OnCreateAssignment {
    onCreateAssignment {
      id
      assignmentTitle
      studentID
      userID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      courseID
      assignmentCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batchCode
      multipleSubmission
      finalSubmissionDate
      originalContent {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAssignment = /* GraphQL */ `
  subscription OnUpdateAssignment {
    onUpdateAssignment {
      id
      assignmentTitle
      studentID
      userID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      courseID
      assignmentCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batchCode
      multipleSubmission
      finalSubmissionDate
      originalContent {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAssignment = /* GraphQL */ `
  subscription OnDeleteAssignment {
    onDeleteAssignment {
      id
      assignmentTitle
      studentID
      userID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      courseID
      assignmentCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batchCode
      multipleSubmission
      finalSubmissionDate
      originalContent {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAssignmentSubmission = /* GraphQL */ `
  subscription OnCreateAssignmentSubmission {
    onCreateAssignmentSubmission {
      id
      assignmentID
      assignmentName {
        id
        assignmentTitle
        studentID
        userID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batchCode
        multipleSubmission
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      studentID
      studentName
      submissionDate
      assignmentFile {
        bucket
        region
        key
      }
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAssignmentSubmission = /* GraphQL */ `
  subscription OnUpdateAssignmentSubmission {
    onUpdateAssignmentSubmission {
      id
      assignmentID
      assignmentName {
        id
        assignmentTitle
        studentID
        userID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batchCode
        multipleSubmission
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      studentID
      studentName
      submissionDate
      assignmentFile {
        bucket
        region
        key
      }
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAssignmentSubmission = /* GraphQL */ `
  subscription OnDeleteAssignmentSubmission {
    onDeleteAssignmentSubmission {
      id
      assignmentID
      assignmentName {
        id
        assignmentTitle
        studentID
        userID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batchCode
        multipleSubmission
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      studentID
      studentName
      submissionDate
      assignmentFile {
        bucket
        region
        key
      }
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSelfStudentContent = /* GraphQL */ `
  subscription OnCreateSelfStudentContent {
    onCreateSelfStudentContent {
      id
      SelfContent_title
      batchID
      subjectID
      contentSubject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      studentDetails {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSelfStudentContent = /* GraphQL */ `
  subscription OnUpdateSelfStudentContent {
    onUpdateSelfStudentContent {
      id
      SelfContent_title
      batchID
      subjectID
      contentSubject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      studentDetails {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSelfStudentContent = /* GraphQL */ `
  subscription OnDeleteSelfStudentContent {
    onDeleteSelfStudentContent {
      id
      SelfContent_title
      batchID
      subjectID
      contentSubject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      studentDetails {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBulkMail = /* GraphQL */ `
  subscription OnCreateBulkMail {
    onCreateBulkMail {
      id
      mailTitle
      batchID
      receiverMail
      senderMail
      deniedUsers
      status
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBulkMail = /* GraphQL */ `
  subscription OnUpdateBulkMail {
    onUpdateBulkMail {
      id
      mailTitle
      batchID
      receiverMail
      senderMail
      deniedUsers
      status
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBulkMail = /* GraphQL */ `
  subscription OnDeleteBulkMail {
    onDeleteBulkMail {
      id
      mailTitle
      batchID
      receiverMail
      senderMail
      deniedUsers
      status
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSemester = /* GraphQL */ `
  subscription OnCreateSemester {
    onCreateSemester {
      id
      semesterTitle
      testString
      batchID
      batchName {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSemester = /* GraphQL */ `
  subscription OnUpdateSemester {
    onUpdateSemester {
      id
      semesterTitle
      testString
      batchID
      batchName {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSemester = /* GraphQL */ `
  subscription OnDeleteSemester {
    onDeleteSemester {
      id
      semesterTitle
      testString
      batchID
      batchName {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateReportCard = /* GraphQL */ `
  subscription OnCreateReportCard {
    onCreateReportCard {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentNameID
      gradesCount
      grades {
        items {
          id
          reportCardID
          searchString
          Mark
          Grade
          status
          subjectID
          Remarks
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateReportCard = /* GraphQL */ `
  subscription OnUpdateReportCard {
    onUpdateReportCard {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentNameID
      gradesCount
      grades {
        items {
          id
          reportCardID
          searchString
          Mark
          Grade
          status
          subjectID
          Remarks
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteReportCard = /* GraphQL */ `
  subscription OnDeleteReportCard {
    onDeleteReportCard {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentNameID
      gradesCount
      grades {
        items {
          id
          reportCardID
          searchString
          Mark
          Grade
          status
          subjectID
          Remarks
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGrades = /* GraphQL */ `
  subscription OnCreateGrades {
    onCreateGrades {
      id
      reportCardID
      reportCard {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      Mark
      Grade
      status
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGrades = /* GraphQL */ `
  subscription OnUpdateGrades {
    onUpdateGrades {
      id
      reportCardID
      reportCard {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      Mark
      Grade
      status
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGrades = /* GraphQL */ `
  subscription OnDeleteGrades {
    onDeleteGrades {
      id
      reportCardID
      reportCard {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      Mark
      Grade
      status
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDegree = /* GraphQL */ `
  subscription OnCreateDegree {
    onCreateDegree {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      certificateFile {
        bucket
        region
        key
      }
      applicationStatus
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDegree = /* GraphQL */ `
  subscription OnUpdateDegree {
    onUpdateDegree {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      certificateFile {
        bucket
        region
        key
      }
      applicationStatus
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDegree = /* GraphQL */ `
  subscription OnDeleteDegree {
    onDeleteDegree {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      certificateFile {
        bucket
        region
        key
      }
      applicationStatus
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      createdDate
      type
      topic
      message
      UserAccountID
      givenByUser {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      batchID
      status
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      createdDate
      type
      topic
      message
      UserAccountID
      givenByUser {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      batchID
      status
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      createdDate
      type
      topic
      message
      UserAccountID
      givenByUser {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      batchID
      status
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLecturer = /* GraphQL */ `
  subscription OnCreateLecturer {
    onCreateLecturer {
      id
      name
      image {
        bucket
        region
        key
      }
      profile {
        bucket
        region
        key
      }
      contactInfo {
        email
        phone
        availableTime
        preferredContact
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLecturer = /* GraphQL */ `
  subscription OnUpdateLecturer {
    onUpdateLecturer {
      id
      name
      image {
        bucket
        region
        key
      }
      profile {
        bucket
        region
        key
      }
      contactInfo {
        email
        phone
        availableTime
        preferredContact
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLecturer = /* GraphQL */ `
  subscription OnDeleteLecturer {
    onDeleteLecturer {
      id
      name
      image {
        bucket
        region
        key
      }
      profile {
        bucket
        region
        key
      }
      contactInfo {
        email
        phone
        availableTime
        preferredContact
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSchedule = /* GraphQL */ `
  subscription OnCreateSchedule {
    onCreateSchedule {
      id
      title
      createdDate
      type
      batchConnection
      location
      lecturerID
      lecturer {
        id
        name
        image {
          bucket
          region
          key
        }
        profile {
          bucket
          region
          key
        }
        contactInfo {
          email
          phone
          availableTime
          preferredContact
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDate
      endDate
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSchedule = /* GraphQL */ `
  subscription OnUpdateSchedule {
    onUpdateSchedule {
      id
      title
      createdDate
      type
      batchConnection
      location
      lecturerID
      lecturer {
        id
        name
        image {
          bucket
          region
          key
        }
        profile {
          bucket
          region
          key
        }
        contactInfo {
          email
          phone
          availableTime
          preferredContact
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDate
      endDate
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSchedule = /* GraphQL */ `
  subscription OnDeleteSchedule {
    onDeleteSchedule {
      id
      title
      createdDate
      type
      batchConnection
      location
      lecturerID
      lecturer {
        id
        name
        image {
          bucket
          region
          key
        }
        profile {
          bucket
          region
          key
        }
        contactInfo {
          email
          phone
          availableTime
          preferredContact
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDate
      endDate
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateStudyNote = /* GraphQL */ `
  subscription OnCreateStudyNote {
    onCreateStudyNote {
      id
      noteTitle
      status
      notes {
        items {
          id
          note
          status
          StudyNoteID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateStudyNote = /* GraphQL */ `
  subscription OnUpdateStudyNote {
    onUpdateStudyNote {
      id
      noteTitle
      status
      notes {
        items {
          id
          note
          status
          StudyNoteID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteStudyNote = /* GraphQL */ `
  subscription OnDeleteStudyNote {
    onDeleteStudyNote {
      id
      noteTitle
      status
      notes {
        items {
          id
          note
          status
          StudyNoteID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateNotes = /* GraphQL */ `
  subscription OnCreateNotes {
    onCreateNotes {
      id
      note
      status
      StudyNoteID
      studyNote {
        id
        noteTitle
        status
        notes {
          nextToken
          startedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateNotes = /* GraphQL */ `
  subscription OnUpdateNotes {
    onUpdateNotes {
      id
      note
      status
      StudyNoteID
      studyNote {
        id
        noteTitle
        status
        notes {
          nextToken
          startedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteNotes = /* GraphQL */ `
  subscription OnDeleteNotes {
    onDeleteNotes {
      id
      note
      status
      StudyNoteID
      studyNote {
        id
        noteTitle
        status
        notes {
          nextToken
          startedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFeePayment = /* GraphQL */ `
  subscription OnCreateFeePayment {
    onCreateFeePayment {
      id
      feeTitle
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      paymentMode
      paidSubjects
      amount
      dueDate
      dateOfPayment
      status
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateFeePayment = /* GraphQL */ `
  subscription OnUpdateFeePayment {
    onUpdateFeePayment {
      id
      feeTitle
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      paymentMode
      paidSubjects
      amount
      dueDate
      dateOfPayment
      status
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteFeePayment = /* GraphQL */ `
  subscription OnDeleteFeePayment {
    onDeleteFeePayment {
      id
      feeTitle
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      paymentMode
      paidSubjects
      amount
      dueDate
      dateOfPayment
      status
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      type
      sender
      senderDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      receiver
      receiverDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      text
      status
      parentMessage
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      type
      sender
      senderDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      receiver
      receiverDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      text
      status
      parentMessage
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      type
      sender
      senderDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      receiver
      receiverDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      text
      status
      parentMessage
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDissertationCollection = /* GraphQL */ `
  subscription OnCreateDissertationCollection {
    onCreateDissertationCollection {
      id
      title
      description
      dissertations {
        items {
          id
          title
          collectionID
          studentID
          remarks
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDissertationCollection = /* GraphQL */ `
  subscription OnUpdateDissertationCollection {
    onUpdateDissertationCollection {
      id
      title
      description
      dissertations {
        items {
          id
          title
          collectionID
          studentID
          remarks
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDissertationCollection = /* GraphQL */ `
  subscription OnDeleteDissertationCollection {
    onDeleteDissertationCollection {
      id
      title
      description
      dissertations {
        items {
          id
          title
          collectionID
          studentID
          remarks
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDissertation = /* GraphQL */ `
  subscription OnCreateDissertation {
    onCreateDissertation {
      id
      title
      collectionID
      collection {
        id
        title
        description
        dissertations {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDissertation = /* GraphQL */ `
  subscription OnUpdateDissertation {
    onUpdateDissertation {
      id
      title
      collectionID
      collection {
        id
        title
        description
        dissertations {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDissertation = /* GraphQL */ `
  subscription OnDeleteDissertation {
    onDeleteDissertation {
      id
      title
      collectionID
      collection {
        id
        title
        description
        dissertations {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
