import React, { Component } from "react";
import UserTable from "../../../userManagement/listUsers";

export class studentDashboard extends Component {
  render() {
    return (
      <UserTable
        userType="Student"
        batchID={this.props.batchDetails}
        inBatch={true}
      />
    );
  }
}
export default studentDashboard;
