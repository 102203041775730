import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listSelfStudentContents } from "../../graphql/queries";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";
import { Table } from "react-bootstrap";
import {
  onCreateSelfStudentContent,
  onUpdateSelfStudentContent,
} from "../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class studentContents extends Component {
  state = {
    contents: [],
    loading: true,
    message: null,
  };

  getSelfContents() {
    API.graphql(
      graphqlOperation(listSelfStudentContents, {
        filter: { studentID: { eq: this.props.studentID } },
      })
    ).then((result) => {
      var contents = result.data.listSelfStudentContents.items;
      if (contents.length !== 0) {
        contents = contents.filter((content) => content._deleted !== true);
      }
      this.setState({ contents, loading: false });
    });
  }

  componentDidMount() {
    this.getSelfContents();
    //Function to listen to post subject
    this.createContentListener = API.graphql(
      graphqlOperation(onCreateSelfStudentContent, {
        filter: {
          batchConnection: { contains: this.props.batchID },
        },
      })
    ).subscribe({
      next: (subjectData) => {
        const newContent = subjectData.value.data.onCreateSelfStudentContent;
        const prevContents = this.state.contents.filter(
          (subject) => subject.id !== newContent.id
        );
        this.setState({ contents: [newContent, ...prevContents] });
      },
    });

    //Function to listen to update contents
    this.updateContentListener = API.graphql(
      graphqlOperation(onUpdateSelfStudentContent)
    ).subscribe({
      next: (contentData) => {
        const updateContent = contentData.value.data.onUpdateSelfStudentContent;
        const index = this.state.contents.findIndex(
          (content) => content.id === updateContent.id
        );
        const updateContents = [
          ...this.state.contents.slice(0, index),
          updateContent,
          ...this.state.contents.slice(index + 1),
        ];
        this.setState({ contents: updateContents });
      },
    });
  }

  componentWillUnmount() {
    if (this.createContentListener !== undefined) {
      this.createContentListener.unsubscribe();
    }
    if (this.updateContentListener !== undefined) {
      this.updateContentListener.unsubscribe();
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditContent: false,
    });
  };

  render() {
    const { contents, loading, message } = this.state;
    return (
      <>
        <Card className="example example-compact">
          <CardHeader title="Course Material" />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Self Study Material </th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {message === null ? (
                    loading ? (
                      <tr>
                        <td colSpan="7" className="container">
                          {" "}
                          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                          <span className="sr-only">Loading...</span>{" "}
                        </td>
                      </tr>
                    ) : contents.length === 0 ? (
                      <tr>
                        <td colSpan="7">No Course Material added Yet!</td>
                      </tr>
                    ) : (
                      contents.map((courseMaterial, i) => (
                        <tr key={courseMaterial.id}>
                          <td>{i + 1}</td>
                          <td>{courseMaterial.SelfContent_title}</td>
                          <td>
                            {courseMaterial.contentSubject !== null
                              ? courseMaterial.contentSubject.subjectName
                              : "Invalid Subject"}
                          </td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-icon btn-light btn-sm"
                              onClick={() =>
                                Storage.get(courseMaterial.contentFile.key, {
                                  download: true,
                                }).then((res) => {
                                  this.downloadBlob(
                                    res.Body,
                                    courseMaterial.contentFile.key
                                  );
                                })
                              }
                            >
                              <span className="svg-icon svg-icon-md svg-icon-success">
                                <SVG
                                  style={{ color: "red" }}
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Files/Cloud-download.svg"
                                  )}
                                ></SVG>
                              </span>
                            </button>
                          </td>
                        </tr>
                      ))
                    )
                  ) : (
                    <tr>
                      <td colSpan="7">{message}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default studentContents;
