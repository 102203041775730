import AWS from "aws-sdk";
import awsExports from "../../aws-exports";
import { API, graphqlOperation } from "aws-amplify";
import { updateUserAccount } from "../../graphql/mutations";

export default function sendManualMail(
  Template,
  heading,
  subject,
  mailID,
  studentName,
  userID,
  officeID,
  message,
  welcomeMail
) {
  console.log("-----", mailID);
  AWS.config.update({
    region: awsExports.aws_cognito_region,
    accessKeyId: "AKIAQ23KTFUAM73OJJ35",
    secretAccessKey: "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",
  });

  /*
  Old 
    accessKeyId: "AKIAQ23KTFUAHAKFZ3FA",
    secretAccessKey: "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",
  */
  var values = {
    heading: heading,
    studentName: studentName,
    officeID: officeID,
    message: message,
  };

  var params = {
    Destination: {
      ToAddresses: [mailID],
    },
    Message: {
      Body: {
        Html: {
          Charset: "UTF-8",
          Data: `${Template(values)}`,
        },
        Text: {
          Charset: "UTF-8",
          Data: subject,
        },
      },
      Subject: {
        Charset: "UTF-8",
        Data: subject,
      },
    },
    Source: "notification@atmsstudentportal.com",
  };
  var status = new AWS.SES().sendEmail(params).promise();
  status
    .then((val) => {
      if (welcomeMail) {
        var input = {
          id: userID,
          sendInvitationMail: true,
        };
        API.graphql(graphqlOperation(updateUserAccount, { input: input })).then(
          (result) => {
            console.log(result.data.updateUserAccount);
          }
        );
      }
      return val;
    })
    .catch((e) => {
      console.log(e);
      return "failed";
    });
}
