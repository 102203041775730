import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import ReactSelect from "../tools/reactMultiSelect";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { listSubjects, listBatchs, listLecturers } from "../../graphql/queries";
import { createSchedule, updateSchedule } from "../../graphql/mutations";
import notificationActivityMail from "../tools/notificationMailActivity";

export default class createEvent extends Component {
  state = {
    lecturers: [],
    subjects: [],
    relatedBatches: [],
    addedBatches: [
      {
        key: this.props.batchDetails.id,
        label: this.props.batchDetails.batchTitle,
        value: this.props.batchDetails.id,
      },
    ],
    createdDate: "",
    type: "",
    title: "",
    location: "",
    subjectID: "",
    startDate: "",
    endDate: "",
    remarks: "",
    deniedUsers: "",
    createdByUserID: "",
    createdAt: "",
    updatedAt: "",
    userId: "",
    userName: "",
    errorMessage: "",
    relatedBatchNextToken: null,
    subjectNextToken: null,
  };

  getRelatedBatches() {
    const { relatedBatchNextToken, relatedBatches } = this.state;
    API.graphql(
      graphqlOperation(listBatchs, {
        limit: 100,
        nextToken: relatedBatchNextToken,
      })
    ).then((result) => {
      var batches = result.data.listBatchs.items;
      if (batches.length === 0) {
        this.setState({
          batchMessage: "No Similar Batches yet!",
        });
      } else {
        var batchSuggestions = [];
        batches.map((batch) => {
          batchSuggestions.push({
            key: batch.id,
            label: batch.batchTitle,
            value: batch.id,
          });
          return null;
        });
        this.setState({
          relatedBatches: [...relatedBatches, ...batchSuggestions],
          relatedBatchNextToken: result.data.listBatchs.nextToken,
          loading: false,
        });

        if (result.data.listBatchs.nextToken !== null) {
          this.getRelatedBatches();
        }
      }
    });
  }

  getLecturers() {
    API.graphql(graphqlOperation(listLecturers)).then((result) => {
      var lecturers = result.data.listLecturers.items;
      if (lecturers.length === 0) {
        this.setState({
          message: "No Lecturers Added yet!",
        });
      }
      this.setState({ lecturers, loading: false });
    });
  }

  getSubjects() {
    const { subjectNextToken } = this.state;
    API.graphql(
      graphqlOperation(listSubjects, {
        limit: 100,
        nextToken: subjectNextToken,
      })
    ).then((result) => {
      var subjects = result.data.listSubjects.items.filter(
        (subject) => subject.courseID === this.props.batchDetails.courseID
      );
      subjects = subjects.filter((subject) => subject._deleted !== true);
      if (subjects.length === 0) {
        this.setState({
          message: "No Subjects Added yet!",
        });
      }
      var allSubjects = [...this.state.subjects, ...subjects];
      this.setState({
        subjects: allSubjects,
        subjectNextToken: result.data.listSubjects.nextToken,
        loading: false,
      });
      if (result.data.listSubjects.nextToken !== null) {
        this.getSubjects();
      }
    });
  }

  componentDidMount() {
    const { addEditStatus, editingSchedule } = this.props;
    this.getSubjects();
    this.getLecturers();
    this.getRelatedBatches();

    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });

    if (!addEditStatus) {
      var editAddedBatches = [];
      this.state.relatedBatches.filter((batch) => {
        if (editingSchedule.batchConnection.includes(batch.id)) {
          editAddedBatches.push({
            key: batch.id,
            label: batch.batchTitle,
            value: batch.id,
          });
        }
      });
      this.setState({
        title: editingSchedule.title,
        type: editingSchedule.type,
        subjectID: editingSchedule.subjectID,
        lecturerID: editingSchedule.lecturerID,
        location: editingSchedule.location,
        addedBatches: editAddedBatches,
        remarks: editingSchedule.remarks,
        editScheduleID: editingSchedule.id,
        startDate: editingSchedule.start,
        endDate: editingSchedule.end,
        version: editingSchedule._version,
      });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSelectBatch(value) {
    this.setState({
      addedBatches: value,
    });
  }

  onSubmit() {
    var today = new Date();
    const {
      createdDate,
      type,
      title,
      location,
      lecturerID,
      subjectID,
      startDate,
      endDate,
      remarks,
      userId,
      editScheduleID,
      addedBatches,
      userName,
    } = this.state;
    var listOfBatches = [];
    if (addedBatches !== null) {
      addedBatches.map((batch) => {
        listOfBatches.push(batch.value);
        return null;
      });
    }
    var input = {};
    if (
      startDate !== "" &&
      endDate !== "" &&
      lecturerID !== "" &&
      subjectID !== ""
    ) {
      if (this.props.addEditStatus) {
        input = {
          title: title,
          createdDate: today.toISOString(),
          batchConnection: listOfBatches,
          location: location,
          lecturerID: lecturerID,
          subjectID: subjectID,
          startDate: startDate,
          endDate: endDate,
          remarks: remarks,
          createdByUserID: userId,
        };
        console.log(input);

        API.graphql(graphqlOperation(createSchedule, { input: input }))
          .then((createdSchedule) => {
            console.log("Created Schedule", createdSchedule);
            var activityTime = new Date();
            notificationActivityMail.notificationActivityMail({
              activityItem: `New Event Created`,
              tableName: "Schedule",
              referenceID: createdSchedule.data.createSchedule.id,
              remarks: `Event created for ${this.props.batchDetails.batchTitle} created by ${userName}`,
              activityDateTime: activityTime.toISOString(),
              notificationType: "Batch",
              type: "Notification",
              topic: "New Event",
              targetID: this.props.batchDetails.id,
              message: `A new Event has been uploaded for ${this.props.batchDetails.batchTitle}`,
              givenUserId: userId,
              mailHeading: "New Activity on ATMS Student Gateway",
              mailSubject: "New Activity on ATMS Student Gateway",
            });
            this.setState({
              loading: false,
            });
            this.props.closeForm();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        input = {
          id: editScheduleID,
          title: title,
          type: type,
          createdDate: createdDate,
          remarks: remarks,
          batchConnection: listOfBatches,
          location: location,
          startDate: startDate,
          endDate: endDate,
          lecturerID: lecturerID,
          createdByUserID: userId,
          _version: this.props.editingSchedule._version,
        };
        console.log("---", input);
        /*
        API.graphql(graphqlOperation(updateSchedule, { input: input })).then(
          (updatedSchedule) => {
            console.log("updated Schedule:", updatedSchedule);

            var activityTime = new Date();
            /*
            notificationActivityMail.notificationActivityMail({
              activityItem: `Event Updated`,
              tableName: "Schedule",
              referenceID: updatedSchedule.data.updateSchedule.id,
              remarks: `Event updated for ${this.props.batchDetails.batchTitle} created by ${userName}`,
              activityDateTime: activityTime.toISOString(),
              notificationType: "Batch",
              type: "Notification",
              topic: "Update Event",
              targetID: this.props.batchDetails.id,
              message: `An Event has been updated for ${this.props.batchDetails.batchTitle}`,
              givenUserId: userId,
              mailHeading: "New Activity on ATMS Student Gateway",
              mailSubject: "New Activity on ATMS Student Gateway",
            });
            
            this.setState({
              loading: false,
            });
            this.props.closeForm();
          }
        );
          */
      }
    } else {
      this.setState({
        errorMessage: "Missing Values",
        loading: false,
      });
      setTimeout(
        function() {
          this.setState({
            errorMessage: "",
            loading: false,
          });
        }.bind(this),
        3000
      );
    }
  }

  render() {
    const {
      title,
      titleError,
      loading,
      locationError,
      location,
      relatedBatches,
      addedBatches,
      batchError,
      startDate,
      endDate,
      remarks,
      lecturerID,
      subjectID,
      subjects,
      lecturers,
      subjectError,
      startDateError,
      errorMessage,
    } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Schedule Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Schedule Name"
              name="title"
              value={title}
              onChange={this.onChange}
            />
            {titleError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Title missing!</div>
              </div>
            ) : null}
          </Form.Group>
          <ReactSelect
            label="Batches"
            suggestions={relatedBatches}
            addedSuggestions={addedBatches}
            addBatch={(value) => this.onSelectBatch(value)}
          />
          {batchError ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">Select at least One batch</div>
            </div>
          ) : null}
          <br />
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              as="select"
              name="subjectID"
              disabled={!this.props.addEditStatus}
              value={subjectID}
              onChange={this.onChange}
            >
              <option value={null}>Select A Subject</option>
              {subjects !== [] ? (
                subjects.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject.subjectName}
                  </option>
                ))
              ) : (
                <option>No Subjects</option>
              )}
            </Form.Control>
            {subjectError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Please select a Subject</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Lecturer</Form.Label>
            <Form.Control
              as="select"
              name="lecturerID"
              value={lecturerID}
              onChange={this.onChange}
            >
              <option value={null}>Select A Lecturer</option>
              {lecturers.map((lecturer) => (
                <option key={lecturer.id} value={lecturer.id}>
                  {lecturer.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="datetime-local"
              placeholder="Start Date"
              name="startDate"
              value={startDate}
              onChange={this.onChange}
            />
            {startDateError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Start date missing!</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="datetime-local"
              placeholder="End Date"
              name="endDate"
              value={endDate}
              onChange={this.onChange}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              placeholder="Location"
              name="location"
              value={location}
              onChange={this.onChange}
            />
            {locationError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Location Missing!</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              type="text"
              placeholder="Remarks"
              name="remarks"
              value={remarks}
              onChange={this.onChange}
            />
          </Form.Group>
          {errorMessage !== "" ? (
            <p style={{ color: "red" }}>{errorMessage}</p>
          ) : (
            ""
          )}
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}
