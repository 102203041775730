import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";
import { Table, Modal, Button } from "react-bootstrap";
import { getCourse } from "../../graphql/queries";
import {
  onCreateTextBook,
  onDeleteTextBook,
  onUpdateTextBook,
} from "../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";

export class courseTextBook extends Component {
  state = {
    textBooks: [],
    courseInformation: null,
    loading: true,
    message: null,
  };

  componentDidMount() {
    API.graphql(
      graphqlOperation(getCourse, { id: this.props.user.activeBatch.courseID })
    ).then((result) => {
      var courseDetails = result.data.getCourse;
      this.setState({
        courseInformation: courseDetails,
        loading: false,
        textBooks: courseDetails.textBooks.items,
      });
    });

    //Function to listen to post subject
    this.createTextBookListener = API.graphql(
      graphqlOperation(onCreateTextBook)
    ).subscribe({
      next: (subjectData) => {
        const newTextBook = subjectData.value.data.onCreateTextBook;
        const prevTextBooks = this.state.textBooks;
        this.setState({ TextBooks: [newTextBook, ...prevTextBooks] });
      },
    });

    //Function to listen to update TextBooks
    this.updateTextBookListener = API.graphql(
      graphqlOperation(onUpdateTextBook)
    ).subscribe({
      next: (TextBookData) => {
        const updateTextBook = TextBookData.value.data.onUpdateTextBook;
        const index = this.state.TextBooks.findIndex(
          (TextBook) => TextBook.id === updateTextBook.id
        );
        const updateTextBooks = [
          ...this.state.TextBooks.slice(0, index),
          updateTextBook,
          ...this.state.TextBooks.slice(index + 1),
        ];
        this.setState({ TextBooks: updateTextBooks });
      },
    });

    //Function to listen to delete TextBooks
    this.deleteTextBookListener = API.graphql(
      graphqlOperation(onDeleteTextBook)
    ).subscribe({
      next: (TextBookData) => {
        const deleteTextBook = TextBookData.value.data.onDeleteTextBook;
        const TextBook = this.state.TextBooks.filter(
          (TextBook) => TextBook.id !== deleteTextBook.id
        );
        this.setState({ TextBooks: TextBook });
      },
    });
  }

  componentWillUnmount() {
    if (this.createTextBookListener !== undefined) {
      this.createTextBookListener.unsubscribe();
    }
    if (this.updateTextBookListener !== undefined) {
      this.updateTextBookListener.unsubscribe();
    }
    if (this.deleteTextBookListener !== undefined) {
      this.deleteTextBookListener.unsubscribe();
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  render() {
    const { textBooks, loading } = this.state;
    return (
      <>
        <Card className="example example-compact">
          <CardHeader title="E Library" />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title </th>
                    <th>Subject </th>
                    <th>Description</th>
                    <th>File</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : textBooks.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Text Books added Yet!</td>
                    </tr>
                  ) : (
                    textBooks.map((textBook, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{textBook.title}</td>
                        <td>{textBook.subject}</td>
                        <td>{textBook.description}</td>
                        <td>
                          <Button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() =>
                              Storage.get(textBook.textBookMaterial.key, {
                                download: true,
                              }).then((res) => {
                                console.log(res);
                                this.downloadBlob(
                                  res.Body,
                                  textBook.textBookMaterial.key
                                );
                              })
                            }
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                style={{ color: "red" }}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Cloud-download.svg"
                                )}
                              ></SVG>
                            </span>
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.reducerAuth.user,
});
export default connect(mapStateToProps, null)(courseTextBook);
