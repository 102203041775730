import React, { Component } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import StudentContent from "./studentContent";
import StudentSelfContent from "./studentSelfContent";
import { connect } from "react-redux";

export class contentDashboard extends Component {
  render() {
    return (
      <div className="card card-custom card-stretch pt-1 pl-1">
        <Tab.Container id="left-tabs-example" defaultActiveKey="course">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item className="pt-5 pl-2"></Nav.Item>
                <Nav.Item>
                  <Nav.Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="course">Course Material</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="selfStudy">Self Study Material</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9} className="pl-0">
              <Tab.Content>
                <Tab.Pane eventKey="course">
                  <StudentContent
                    batchID={this.props.user.batchId}
                    batchCollection={this.props.user.batchInformation}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="selfStudy">
                  <StudentSelfContent studentID={this.props.user.id} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.reducerAuth.user,
});
export default connect(mapStateToProps, null)(contentDashboard);
