import React, { Component } from "react";
import { connect } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import { listFeePayments } from "../../graphql/queries";
import { deleteFeePayment } from "../../graphql/mutations";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditPayment from "./addEditFeesPayment";
import {
  onCreateFeePayment,
  onUpdateFeePayment,
} from "../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class FeePaymentList extends Component {
  state = {
    payments: [],
    loading: true,
    message: null,
    openAddPayment: false,
    deletePaymentName: null,
    deletePaymentID: null,
    openDeletePayment: false,
    editItem: null,
    addEditStatus: true,
  };

  getFeePayments() {
    const { userType, user } = this.props;
    if (userType === "Student") {
      API.graphql(
        graphqlOperation(listFeePayments, {
          filter: { studentID: { eq: user.id } },
        })
      ).then((result) => {
        var payments = result.data.listFeePayments.items;
        console.log(payments);
        if (payments.length === 0) {
          this.setState({
            message: "No Payments Added yet!",
          });
        }
        this.setState({ payments, loading: false });
      });
    } else {
      API.graphql(graphqlOperation(listFeePayments)).then((result) => {
        var payments = result.data.listFeePayments.items;
        console.log(payments);
        if (payments.length === 0) {
          this.setState({
            message: "No Payments Added yet!",
          });
        }
        this.setState({ payments, loading: false });
      });
    }
  }

  componentDidMount() {
    this.getFeePayments();

    //Function to listen to post subject
    this.createPaymentListener = API.graphql(
      graphqlOperation(onCreateFeePayment)
    ).subscribe({
      next: (paymentData) => {
        const newPayment = paymentData.value.data.onCreateContent;
        const prevPayments = this.state.payments.filter(
          (payment) => payment.id !== newPayment.id
        );
        this.setState({ payments: [newPayment, ...prevPayments] });
      },
    });

    //Function to listen to update payments
    this.updatePaymentListener = API.graphql(
      graphqlOperation(onUpdateFeePayment)
    ).subscribe({
      next: (paymentData) => {
        const updatePayment = paymentData.value.data.onUpdateFeePayment;
        const index = this.state.payments.findIndex(
          (payment) => payment.id === updatePayment.id
        );
        const updatedPayments = [
          ...this.state.payments.slice(0, index),
          updatePayment,
          ...this.state.payments.slice(index + 1),
        ];
        this.setState({ payments: updatedPayments });
      },
    });
  }

  deleteContent(id) {
    API.graphql(graphqlOperation(deleteFeePayment, { id: id })).then(
      (deletedPayment) => {
        console.log("Deleted Payment:", deletedPayment);
      }
    );
  }

  componentWillUnmount() {
    if (this.createPaymentListener !== undefined) {
      this.createPaymentListener.unsubscribe();
    }
    if (this.updatePaymentListener !== undefined) {
      this.updatePaymentListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditPayment: false,
    });
  };

  render() {
    const {
      payments,
      loading,
      openAddEditPayment,
      deleteContentName,
      openDeletePayment,
      addEditStatus,
      editItem,
    } = this.state;
    const toolbar = (
      <div className="card-toolbar">
        {this.props.userType !== "Student" ? (
          <div className="example-tools">
            <CodeBlockToolbarATMS
              showViewCode={true}
              openAdd={() =>
                this.setState({
                  openAddEditPayment: true,
                  addEditStatus: true,
                })
              }
              title={"Add Payment"}
            />
          </div>
        ) : (
          " "
        )}
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditPayment}
          onHide={() => this.setState({ openAddEditPayment: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Payment" : "Edit Payment"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditPayment
              addEditStatus={addEditStatus}
              editingPayment={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeletePayment}
          onHide={() => this.setState({ openDeletePayment: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteContentName} Payment?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure u want to delete the Payment?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.deleteContent}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeletePayment: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="Fee Payments" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Student</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr className="container">
                      <td colSpan="7">
                        {" "}
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        <span className="sr-only">Loading...</span>{" "}
                      </td>
                    </tr>
                  ) : payments.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Payments added Yet!</td>
                    </tr>
                  ) : (
                    payments.map((payment, i) => (
                      <tr key={payment.id}>
                        <td>{i + 1}</td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {payment.student.firstName}{" "}
                            {payment.student.lastName}
                          </span>
                          <span className="text-muted font-weight-bold">
                            {payment.student.officeID}
                          </span>
                        </td>
                        <td>{payment.subjectName.subjectName}</td>
                        <td>
                          {" "}
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: payment,
                                openAddEditPayment: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            style={{ color: "red" }}
                            onClick={() => {
                              this.setState({
                                deleteContentName: `${payment.feeTitle} ${payment.student.firstName}`,
                                deleteContentID: payment.id,
                                openDeleteContent: true,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userType: state.reducerAuth.userType,
  user: state.reducerAuth.user,
});
export default connect(mapStateToProps, null)(FeePaymentList);
