import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { listUserAccounts } from "../../../../graphql/queries";
import {
  createNotification,
  updateNotification,
} from "../../../../graphql/mutations";
import SingleSelect from "../../../tools/reactSingleSelect";
import UpdateActivity from "../../../tools/updateActivity";

export class addNotification extends Component {
  state = {
    studentSuggestions: [],
    notificationTypes: {},
    selectedStudent: null,
    createdDate: "",
    type: "",
    topic: "",
    message: "",
    UserAccountID: "",
    studentID: "",
    batchID: "",
    status: "",
    createdByUserID: "",
  };

  getBatchStudents() {
    API.graphql(
      graphqlOperation(listUserAccounts, {
        filter: {
          and: [
            {
              typeOfAccount: { eq: "Student" },
            },
            { batchId: { eq: this.props.batchDetails.id } },
          ],
        },
      })
    ).then((result) => {
      var students = result.data.listUserAccounts.items;
      if (students.length === 0) {
        this.setState({
          message: "No Student Added yet!",
        });
      }
      var studentSuggestions = [];
      students.map((student) => {
        studentSuggestions.push({
          key: student.id,
          value: student.id,
          label: `${student.firstName} ${student.lastName}`,
        });
        return null;
      });
      this.setState({ studentSuggestions, loading: false });
    });
  }

  componentDidMount() {
    const { addEditStatus, editingNotification, studentBatch } = this.props;
    if (studentBatch === "Student") {
      console.log("loading Students");
      this.getBatchStudents();
    }
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });

    if (!addEditStatus) {
      this.setState({
        notificationTopic: editingNotification.notificationTitle,
        subjectID: editingNotification.subjectID,
        editNotificationID: editingNotification.id,
        version: editingNotification._version,
      });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  selectStudent(value) {
    this.setState({
      selectedStudent: value,
    });
  }

  onSubmit = () => {
    const {
      notificationName,
      addedBatches,
      selectedStudent,
      type,
      topic,
      message,
      batchID,
      userId,
      editNotificationID,
      version,
      userName,
    } = this.state;
    const { batchDetails } = this.props;
    var listOfBatches = [];
    if (addedBatches !== null) {
      addedBatches.map((batch) => {
        listOfBatches.push(batch.value);
        return null;
      });
    }
    var input = {};

    if (this.props.addEditStatus) {
      if (
        type !== "" &&
        topic !== "" &&
        message !== "" &&
        selectedStudent !== "" &&
        batchID !== ""
      ) {
        input = {
          createdDate: "",
          type: type,
          topic: topic,
          message: message,
          UserAccountID: this.props.user.id,
          status: "Created",
          createdByUserID: userId,
        };
        if (this.props.studentBatch === "Student") {
          input["studentID"] = selectedStudent.key;
        } else {
          input["batchID"] = this.props.batchDetails.id;
        }
        API.graphql(graphqlOperation(createNotification, { input: input }))
          .then((createdNotification) => {
            console.log("Created Notification", createdNotification);
            this.setState({
              loading: false,
            });
            var activityTime = new Date();
            UpdateActivity.updateActivity({
              activityItem: `Notification Created`,
              tableName: "Notification",
              referenceID: createdNotification.id,
              remarks: `Notification Created ${createdNotification.topic} created by ${userName}`,
              activityDateTime: activityTime.toISOString(),
            });
            this.props.closeForm();
          })
          .catch((err) => {
            console.log(err);
            var activityTime = new Date();
            UpdateActivity.updateActivity({
              activityItem: `Notification Failed`,
              tableName: "Notification",
              referenceID: null,
              remarks: err.message,
              activityDateTime: activityTime.toISOString(),
            });
          });
      } else {
        if (type === "") {
          this.setState({
            typeError: "Select a type!",
          });
        }
        if (topic === "") {
          this.setState({
            topicError: "Add a topic!",
          });
        }
        if (message === "") {
          this.setState({
            messageError: "Add a message!",
          });
        }
      }
    } else {
      input = {
        id: editNotificationID,
        notificationName: notificationName,
        specializationID: batchDetails.specializationID,
        createdByUserID: userId,
        _version: version,
      };
      API.graphql(graphqlOperation(updateNotification, { input: input })).then(
        (updatedNotification) => {
          console.log("updated notification:", updatedNotification);
          this.setState({
            loading: false,
          });
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Notification Updated`,
            tableName: "Notification",
            referenceID: updatedNotification.id,
            remarks: `Notification ${updatedNotification.topic} updated by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.props.closeForm();
        }
      );
    }
  };

  render() {
    const {
      topic,
      topicError,
      message,
      messageError,
      type,
      typeError,
      studentSuggestions,
      loading,
    } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              name="type"
              value={type}
              onChange={this.onChange}
            >
              <option value={null}>Select A Type</option>
              <option value="Announcement">Announcement</option>
              <option value="Notification">Notification</option>
              <option value="Information">Information</option>
            </Form.Control>
            {typeError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Please select a Type</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Notification {this.props.studentBatch}Topic</Form.Label>
            <Form.Control
              type="text"
              placeholder="Notification Topic"
              name="topic"
              value={topic}
              onChange={this.onChange}
            />
            {topicError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Topic missing!</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Message</Form.Label>
            <Form.Control
              type="textarea"
              placeholder="Notification Message"
              name="message"
              value={message}
              onChange={this.onChange}
            />
            {messageError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Message missing!</div>
              </div>
            ) : null}
          </Form.Group>
          {this.props.studentBatch === "Student" ? (
            <SingleSelect
              suggestions={studentSuggestions}
              disableStatus={!this.props.addEditStatus}
              selectValue={"Students"}
              selectFunction={(value) => this.selectStudent(value)}
              placeholder={"Select Student"}
            />
          ) : (
            " "
          )}
          <br />
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {"  "}
            {loading ? (
              <>
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.reducerAuth.user,
});
export default connect(mapStateToProps, null)(addNotification);
