import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { deleteDissertation } from "../../../../graphql/mutations";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/Card";
import { Table, Modal, Button } from "react-bootstrap";
import AddRemarks from "./addRemarks";
import { getDissertationCollection } from "../../../../customgraphql/queries";
import {
  onCreateDissertation,
  onDeleteDissertation,
  onUpdateDissertation,
} from "../../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class listDissertation extends Component {
  state = {
    dissertations: [],
    dissertationInformation: null,
    loading: true,
    message: null,
    editItem: null,
    addEditStatus: true,
  };

  componentDidMount() {
    API.graphql(
      graphqlOperation(getDissertationCollection, {
        id: this.props.match.params.collectionid,
      })
    ).then((result) => {
      var dissertationDetails = result.data.getDissertationCollection;
      console.log(dissertationDetails);
      this.setState({
        dissertationInformation: dissertationDetails,
        loading: false,
        dissertations: dissertationDetails.dissertations.items.filter(
          (dissertation) => dissertation._deleted !== true
        ),
      });
    });

    //Function to listen to post subject
    this.createDissertationListener = API.graphql(
      graphqlOperation(onCreateDissertation)
    ).subscribe({
      next: (subjectData) => {
        const newDissertation = subjectData.value.data.onCreateDissertation;
        const prevDissertations = this.state.dissertations;
        if (
          newDissertation.collectionID === this.props.match.params.collectionid
        )
          this.setState({
            dissertations: [newDissertation, ...prevDissertations],
          });
      },
    });

    //Function to listen to update Dissertations
    this.updateDissertationListener = API.graphql(
      graphqlOperation(onUpdateDissertation)
    ).subscribe({
      next: (DissertationData) => {
        const updateDissertation =
          DissertationData.value.data.onUpdateDissertation;
        const index = this.state.dissertations.findIndex(
          (Dissertation) => Dissertation.id === updateDissertation.id
        );
        const updateDissertations = [
          ...this.state.dissertations.slice(0, index),
          updateDissertation,
          ...this.state.dissertations.slice(index + 1),
        ];
        if (
          updateDissertation.collectionID ===
          this.props.match.params.collectionid
        )
          this.setState({ dissertations: updateDissertations });
      },
    });

    //Function to listen to delete Dissertations
    this.deleteDissertationListener = API.graphql(
      graphqlOperation(onDeleteDissertation)
    ).subscribe({
      next: (DissertationData) => {
        const deleteDissertation =
          DissertationData.value.data.onDeleteDissertation;
        const Dissertation = this.state.dissertations.filter(
          (Dissertation) => Dissertation.id !== deleteDissertation.id
        );
        this.setState({ dissertations: Dissertation });
      },
    });
  }

  componentWillUnmount() {
    if (this.createDissertationListener !== undefined) {
      this.createDissertationListener.unsubscribe();
    }
    if (this.updateDissertationListener !== undefined) {
      this.updateDissertationListener.unsubscribe();
    }
    if (this.deleteDissertationListener !== undefined) {
      this.deleteDissertationListener.unsubscribe();
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditDissertation: false,
    });
  };

  render() {
    const {
      dissertations,
      loading,
      openAddEditDissertation,
      addEditStatus,
      editItem,
    } = this.state;

    return (
      <>
        <Modal
          size="lg"
          show={openAddEditDissertation}
          onHide={() => this.setState({ openAddEditDissertation: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Dissertation" : "Edit Dissertation"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddRemarks
              addEditStatus={addEditStatus}
              editingDissertation={editItem}
              collectionID={this.props.match.params.collectionid}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="Dissertations" />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title </th>
                    <th>SBS ID </th>
                    <th>Student </th>
                    <th>File</th>
                    <th>Submission Date</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : dissertations.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Dissertations added Yet!</td>
                    </tr>
                  ) : (
                    dissertations.map((dissertation, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{dissertation.title}</td>
                        <td>
                          {dissertation.student !== null &&
                          dissertation.student !== undefined
                            ? dissertation.student.officeID
                            : ""}
                        </td>
                        <td>
                          {dissertation.student !== null &&
                          dissertation.student !== undefined
                            ? `${dissertation.student.firstName} ${dissertation.student.lastName}`
                            : ""}
                        </td>
                        <td>
                          <Button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() =>
                              Storage.get(dissertation.contentFile.key, {
                                download: true,
                              }).then((res) => {
                                console.log(res);
                                this.downloadBlob(
                                  res.Body,
                                  dissertation.contentFile.key
                                );
                              })
                            }
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                style={{ color: "red" }}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Cloud-download.svg"
                                )}
                              ></SVG>
                            </span>
                          </Button>
                        </td>
                        <td>{dissertation.createdAt}</td>
                        <td>{dissertation.remarks}</td>
                        <td>
                          {" "}
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: dissertation,
                                openAddEditDissertation: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default listDissertation;
