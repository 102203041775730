import React, { Component } from "react";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { S3Image } from "aws-amplify-react";
import { Grid, Typography, Paper } from "@material-ui/core";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";
import { API, graphqlOperation } from "aws-amplify";
import { listGradess } from "../../graphql/queries";
import { getReportCard } from "../../customgraphql/queries";
import {
  onCreateGrades,
  onUpdateReportCard,
  onUpdateGrades,
  onDeleteGrades,
} from "../../graphql/subscriptions";

export class viewReportCard extends Component {
  state = {
    student: this.props.user,
    grades: [],
    reportCardID: this.props.user.studentReport,
    reportCard: null,
    addGrades: false,
    selectedCard: null,
    editingGrade: null,
    addEditGrade: true,
    gradeNextToken: null,
    cards: [],
    cardsNextToken: null,
    loading: false,
  };

  getGrades() {
    const { gradeNextToken } = this.state;
    API.graphql(
      graphqlOperation(listGradess, { limit: 100, nextToken: gradeNextToken })
    ).then((result) => {
      var grades = result.data.listGradess.items.filter(
        (grade) => grade.reportCardID === this.props.user.studentReport
      );
      if (grades.length === 0) {
        this.setState({
          message: "No Grades Added yet!",
        });
      }
      var finalGrades = [...this.state.grades, ...grades];
      this.setState({
        grades: finalGrades,
        gradeNextToken: result.data.listGradess.nextToken,
      });
      if (result.data.listGradess.nextToken !== null) {
        this.getGrades();
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  getGradesAndCard() {
    this.setState({
      loading: true,
    });
    API.graphql(
      graphqlOperation(getReportCard, { id: this.props.user.studentReport })
    ).then((result) => {
      var reportCard = result.data.getReportCard;
      console.log(reportCard.grades.items);
      this.setState({
        reportCard: reportCard,
        grades: reportCard.grades.items,
        loading: false,
      });
    });
  }

  componentDidMount() {
    console.log(this.props.user);
    if (this.props.user.studentReport !== null) {
      this.getGradesAndCard();
    }
    //Function to listen to post subject
    this.createGradeLister = API.graphql(
      graphqlOperation(onCreateGrades)
    ).subscribe({
      next: (createdGradeData) => {
        const newGrade = createdGradeData.value.data.onCreateGrades;
        if (newGrade.reportCardID === this.state.reportCard.id) {
          const allGrades = this.state.grades;
          const prevGrades = allGrades.filter(
            (grade) => grade.id !== newGrade.id
          );
          this.setState({ contents: [newGrade, ...prevGrades] });
        }
      },
    });

    //Function to listen to update contents
    this.updateGradeListner = API.graphql(
      graphqlOperation(onUpdateGrades)
    ).subscribe({
      next: (updateGradeData) => {
        const updatedGrade = updateGradeData.value.data.onUpdateGrades;
        if (updatedGrade.reportCardID === this.state.reportCard.id) {
          const allGrades = this.state.grades;
          const index = allGrades.findIndex(
            (grade) => grade.id === updatedGrade.id
          );
          const updatedGrades = [
            ...this.state.grades.slice(0, index),
            updatedGrade,
            ...this.state.grades.slice(index + 1),
          ];
          this.setState({ grades: updatedGrades });
        }
      },
    });

    //Function to listen to post subject
    this.deleteGrade = API.graphql(graphqlOperation(onDeleteGrades)).subscribe({
      next: (deletedGradeData) => {
        const deletedGrade = deletedGradeData.value.data.onDeleteGrades;
        if (deletedGrade.reportCardID === this.state.reportCard.id) {
          const allGrades = this.state.grades;
          const prevGrades = allGrades.filter(
            (grade) => grade.id !== deletedGrade.id
          );
          this.setState({ grades: [...prevGrades] });
        }
      },
    });

    //Function to listen to update contents
    this.onUpdateReportCardListener = API.graphql(
      graphqlOperation(onUpdateReportCard)
    ).subscribe({
      next: (updatedReportData) => {
        const updatedReport = updatedReportData.value.data.onUpdateReportCard;
        if (updatedReport.id === this.state.reportCard.id) {
          this.setState({ reportCard: updatedReport });
        }
      },
    });
  }

  componentWillUnmount() {
    if (this.createGradeLister !== undefined) {
      this.createGradeLister.unsubscribe();
    }
    if (this.updateGradeListner !== undefined) {
      this.updateGradeListner.unsubscribe();
    }
    if (this.onUpdateReportCardListener !== undefined) {
      this.onUpdateReportCardListener.unsubscribe();
    }
  }
  render() {
    const { student, grades, reportCard, loading, cards } = this.state;
    return (
      <div>
        <Paper style={{ padding: "2px", margin: "auto", maxWidth: "100%" }}>
          <Grid container spacing={2}>
            <Grid item>
              <div style={{ width: "150px", height: "150px" }}>
                {student.icon_image !== null &&
                student.icon_image !== undefined ? (
                  <S3Image
                    theme={{ photoImg: { width: "150px", height: "150px" } }}
                    imgKey={student.icon_image.key}
                  />
                ) : (
                  <div
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        "/media/users/300_21.jpg"
                      )}`,
                      margin: "auto",
                      display: "block",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  ></div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h5">
                    {student.firstName} {student.lastName}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {reportCard !== null ? reportCard.batch.batchTitle : ""}{" "}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    {student.officeID}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {reportCard !== null ? (
          <Card className="example example-compact">
            <CardHeader title="Grades" />
            <CardBody>
              {/* begin::Table */}
              <div className="table-responsive">
                <table className="table table-borderless table-vertical-center">
                  <thead>
                    <tr>
                      <th>Subject </th>
                      <th>Grade</th>
                      <th>Marks</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="7">
                          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </td>
                      </tr>
                    ) : grades !== undefined ? (
                      grades.map((grade, i) => {
                        if (!grade._deleted)
                          return (
                            <tr key={grade.id}>
                              <td>
                                <span className="font-weight-bold">
                                  {grade.subjectName !== null
                                    ? grade.subjectName.subjectName
                                    : "Invalid Subject"}
                                </span>
                              </td>
                              <td>
                                <span className="font-weight-bold">
                                  {grade.Grade}
                                </span>
                              </td>
                              <td>
                                <span className="font-weight-bold">
                                  {grade.Mark}
                                </span>
                              </td>
                              <td>
                                <span className="font-weight-bold">
                                  {grade.Remarks}
                                </span>
                              </td>
                            </tr>
                          );
                        return null;
                      })
                    ) : (
                      <tr>
                        <td>
                          <span className="text-muted font-weight-bold">
                            "No grades added!"
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        ) : (
          <Card className="example example-compact">
            <CardHeader title="Grades" />
            <CardBody>Grade Card has not been generated yet!</CardBody>
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.reducerAuth.user,
});
export default connect(mapStateToProps, null)(viewReportCard);
