import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { createAssignmentSubmission } from "../../graphql/mutations";
import UpdateActivity from "../tools/updateActivity";
import awsExports from "../../aws-exports";

export default class addStudentSubmission extends Component {
  state = {
    assignmentID: this.props.sourceAssignment.id,
    studentID: this.props.student.id,
    batchID: this.props.student.batchId,
    studentName: `${this.props.student.firstName} ${this.props.student.officeID}`,
    submissionDate: "",
    assignmentFile: null,
    createdByUserID: this.props.student.accountUserID,
    completionMessage: "",
    loading: false,
  };

  componentDidMount() {
    var today = new Date();
    this.setState({
      submissionDate: today.toISOString(),
    });
  }

  onChangeFile = (e) => this.setState({ [e.target.name]: e.target.files[0] });

  submitFile() {
    this.setState({
      loading: true,
    });
    const {
      assignmentID,
      studentID,
      studentName,
      submissionDate,
      assignmentFile,
      createdByUserID,
      batchID,
    } = this.state;
    var input = {};
    var file_name = assignmentFile.name.split(".");
    var unique_filename =
      file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
    Storage.put(unique_filename, assignmentFile).then((fileDetails) => {
      console.log(fileDetails.key);

      input = {
        assignmentID: assignmentID,
        studentID: studentID,
        batchID: batchID,
        studentName: studentName,
        submissionDate: submissionDate,
        assignmentFile: {
          bucket: awsExports.aws_user_files_s3_bucket,
          region: awsExports.aws_user_files_s3_bucket_region,
          key: fileDetails.key,
        },
        createdByUserID: createdByUserID,
      };

      API.graphql(
        graphqlOperation(createAssignmentSubmission, { input: input })
      )
        .then((createSubmission) => {
          this.setState({
            loading: false,
          });
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Student Submission Uploaded`,
            tableName: "AssignmentSubmission",
            referenceID: createSubmission.data.createAssignmentSubmission.id,
            remarks: `Submission for ${createSubmission.data.createAssignmentSubmission.assignmentName.assignmentTitle} updated by ${studentName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.setState({
            loading: false,
            completionMessage: "Submission Successful!",
          });
          this.props.closeForm();
        })
        .catch((err) => {
          console.log(err);
          Storage.remove(unique_filename).then(() => {
            console.log("Upload removed due to failed DB insert!");
          });
          this.setState({
            loading: false,
            completionMessage: "Submission Failed, Please Try Again!",
          });
          setTimeout(() => {
            this.setState({
              completionMessage: "",
            });
          }, 3000);
        });
    });
  }
  render() {
    const { assignmentFile, loading, completionMessage } = this.state;
    return (
      <>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Assignment File</Form.Label>
              <Form.Control
                type="file"
                name="assignmentFile"
                onChange={this.onChangeFile}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          {completionMessage === "" ? (
            <>
              {assignmentFile !== null ? (
                loading ? (
                  <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                ) : (
                  <Button variant="primary" onClick={() => this.submitFile()}>
                    Upload
                  </Button>
                )
              ) : (
                ""
              )}
              <Button
                variant="danger"
                onClick={() => this.setState({ openSubmission: false })}
              >
                Cancel
              </Button>
            </>
          ) : (
            completionMessage
          )}
        </Modal.Footer>
      </>
    );
  }
}
