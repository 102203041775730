/* eslint-disable import/no-anonymous-default-export */
import { Auth, API, graphqlOperation } from "aws-amplify";
import { createActivityLog } from "../../graphql/mutations";

export default {
  updateActivity: (activityInfo) => {
    Auth.currentUserInfo().then((user) => {
      var input = {
        activityItem: activityInfo.activityItem,
        tableName: activityInfo.tableName,
        referenceID: activityInfo.referenceID,
        remarks: activityInfo.remarks,
        activityDateTime: activityInfo.activityDateTime,
        createdByUserID: user.attributes.sub,
      };
      API.graphql(graphqlOperation(createActivityLog, { input: input })).then(
        (createdMessage) => {
          console.log("Activity Added");
        }
      );
    });
  },
};

/* how to use
import UpdateActivity from "../tools/updateActivity";
 UpdateActivity.updateActivity({
                                activityItem: "activityInfo.activityItem",
                                tableName: "activityInfo.tableName",
                                referenceID: "activityInfo.referenceID",
                                remarks: "activityInfo.remarks",
                                activityDateTime:
                                  "activityInfo.activityDateTime",
                              });
      */
