import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../_metronic/layout";
import Course from "../../components/batch/course/listCourse";
import Location from "../../components/batch/location/listLocation";
import Specialization from "../../components/batch/specialization/listSpecialization";
import BatchGuidelines from "../../components/batch/batchGuidelines/listBatchGuidelines";
import Batch from "../../components/batch/batchCollection";
import Assignments from "../../components/Assignment/assignmentsDashboard";
import Content from "../../components/Content/contentDashboard";
import Grades from "../../components/Grades/gradesDashboard";
import Notifications from "../../components/Notifications/notificationsDashboard";
import Schedules from "../../components/Schedule/scheduleDashboard";
import FeePayments from "../../components/fees/listFeesPayment";
import BatchMain from "../../components/batch/batchDetails/batchMain";
import Messages from "../../components/messages/listMessages";
import Users from "../../components/userManagement/userDashboard";
import Library from "../../components/ELibrary/listTextBooks";

const UserProfilepage = lazy(() =>
  import("../../components/UserProfile/UserProfilePage")
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={UserProfilepage} />
        <ContentRoute path="/messages" component={Messages} />
        <ContentRoute path="/course" component={Course} />
        <ContentRoute path="/location" component={Location} />
        <ContentRoute path="/users/:userType" component={Users} />
        <ContentRoute path="/specialization" component={Specialization} />
        <ContentRoute path="/guidelines" component={BatchGuidelines} />
        <ContentRoute path="/batch" component={Batch} />
        <ContentRoute path="/assignments" component={Assignments} />
        <ContentRoute path="/content" component={Content} />
        <ContentRoute path="/grades" component={Grades} />
        <ContentRoute path="/notifications" component={Notifications} />
        <ContentRoute path="/schedules" component={Schedules} />
        <ContentRoute path="/fee-payments" component={FeePayments} />
        <ContentRoute path="/batchMain/:batchid" component={BatchMain} />
        <ContentRoute path="/library/:courseid" component={Library} />

        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
