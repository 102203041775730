export const template = (values) => {
  const templateString = `<!DOCTYPE html>
      <html
        lang="en"
        xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:v="urn:schemas-microsoft-com:vml"
      >
        <head>
          <title>ATMS Mail</title>
          <meta charset="utf-8" />
          <meta content="width=device-width, initial-scale=1.0" name="viewport" />
          <!--[if mso
            ]><xml
              ><o:OfficeDocumentSettings
                ><o:PixelsPerInch>96</o:PixelsPerInch
                ><o:AllowPNG /></o:OfficeDocumentSettings></xml
          ><![endif]-->
          <!--[if !mso]><!-->
          <script src="https://kit.fontawesome.com/bd553a934e.js" crossorigin="anonymous"></script>
          <link
            href="https://fonts.googleapis.com/css?family=Cormorant+Garamond"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Lato"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Droid+Serif"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Fira+Sans"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Lora"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Quattrocento"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Permanent+Marker"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Oswald"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Montserrat"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Merriweather"
            rel="stylesheet"
            type="text/css"
          />
          <!--<![endif]-->
          <style>
            * {
              box-sizing: border-box;
            }
      
            th.column {
              padding: 0;
            }
      
            a[x-apple-data-detectors] {
              color: inherit !important;
              text-decoration: inherit !important;
            }
      
            #MessageViewBody a {
              color: inherit;
              text-decoration: none;
            }
      
            p {
              line-height: inherit;
            }
      
            @media (max-width: 620px) {
              .icons-inner {
                text-align: center;
              }
      
              .icons-inner td {
                margin: 0 auto;
              }
      
              .fullMobileWidth,
              .row-content {
                width: 100% !important;
              }
      
              .image_block img.big {
                width: auto !important;
              }
      
              .stack .column {
                width: 100%;
                display: block;
              }
            }
          </style>
        </head>
        <body
          style="background-color: #FFFFFF;
                margin: 0; 
                padding: 0; 
                -webkit-text-size-adjust: none; 
                text-size-adjust: none;"
        >
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="nl-container"
            role="presentation"
            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF;"
            width="100%"
          >
            <tbody>
              <tr>
                <td>
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="row row-2"
                    role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f7f6f5;"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            align="center"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row-content stack"
                            role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #072b52;"
                            width="600"
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="column"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px;"
                                  width="100%"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="image_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td
                                        style="width:100%;padding-right:0px;padding-left:0px;padding-top:20px;padding-bottom:20px;"
                                      >
                                        <div align="left" style="line-height:10px">
                                          <img
                                            alt="your-logo"
                                            src="https://atmspublic-cdn.s3.ap-south-1.amazonaws.com/ATMS-Logo-cirlcle.png"
                                            style="display: block; height: auto; border: 21px; width: 120px; max-width: 100%;"
                                            title="your-logo"
                                            width="120"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style="width:100%;padding-right:0px;padding-left:0px;padding-top:20px;padding-bottom:20px;"
                                      >
                                        <div align="center" style="line-height:10px">
                                          <img
                                            alt="your-logo"
                                            src="https://atmspublic-cdn.s3.ap-south-1.amazonaws.com/ATMS-Logo-cirlcle.png"
                                            style="display: block; height: auto; border: 21px; width: 120px; max-width: 100%;"
                                            title="your-logo"
                                            width="120"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="row row-3"
                    role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f7f6f5;"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            align="center"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row-content stack"
                            role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #fff;"
                            width="600"
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="column"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px;"
                                  width="100%"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="image_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td
                                        style="width:100%;padding-right:0px;padding-left:0px;"
                                      >
                                        <div align="center" style="line-height:10px">
                                          <img
                                            alt="image-hotel-room"
                                            class="big"
                                            src="https://atmspublic-cdn.s3.ap-south-1.amazonaws.com/pexels-buro-millennial-1438072_2.jpg"
                                            style="display: block; height: auto; border: 0; width: 600px; max-width: 100%;"
                                            title="image-hotel-room"
                                            width="600"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
      
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="heading_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td
                                        style="text-align:center;width:100%;padding-top:35px;"
                                      >
                                        <h1
                                          style="margin: 0; color: #072b52; direction: ltr; font-family: 'Lora', Georgia, serif; font-size: 50px; font-weight: normal; letter-spacing: 1px; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0;"
                                        >
                                          <strong>${values.heading}</strong>
                                        </h1>
                                      </td>
                                    </tr>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="row row-4"
                    role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f7f6f5;"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            align="center"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row-content stack"
                            role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #fff;"
                            width="600"
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="column"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px;"
                                  width="100%"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="text_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td
                                        style="padding-bottom:40px;padding-left:15px;padding-right:15px;padding-top:40px;"
                                      >
                                        <div
                                          style="font-family: Tahoma, Verdana, sans-serif"
                                        >
                                          <div
                                            style="font-size: 12px; font-family: 'Lato', Tahoma, Verdana, Segoe, sans-serif; color: #222222; line-height: 1.5;"
                                          >
                                            <p
                                              style="margin: 0; font-size: 16px; text-align: center;"
                                            >
                                            <p>Dear ${values.studentName},&nbsp;<br />
                                            &nbsp;<br />
                                            Greetings for the day!<br />
                                            &nbsp;<br />
                                            ${values.message}
                                            
                                            
                                            
                                            <p>You can access the portal using the following link: <br />
                                            &nbsp;<br />
                                            <strong>Portal Link:</strong> https://www.atmsstudentgateway.com/<br />
                                            
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="row row-7"
                    role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f7f6f5;"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            align="center"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row-content stack"
                            role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f7f6f5;"
                            width="600"
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="column"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px;"
                                  width="100%"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="html_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          align="center"
                                          style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif;"
                                        >
                                          <div style="height:30px;"></div>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
      
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="image_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td
                                        style="width:100%;padding-right:0px;padding-left:0px;"
                                      >
                                        <div align="center" style="line-height:10px">
                                          <img
                                            alt="your-logo"
                                            src="https://atmspublic-cdn.s3.ap-south-1.amazonaws.com/ATMS-Logo-cirlcle.png"
                                            style="display: block; height: auto; border: 0; width: 60px; max-width: 100%;"
                                            title="your-logo"
                                            width="60"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
      
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="html_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          align="center"
                                          style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif;"
                                        >
                                          <div style="height:30px;"></div>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
      
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="social_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td
                                        style="text-align:center;padding-right:0px;padding-left:0px;"
                                      >
                                        <table
                                          align="center"
                                          border="0"
                                          cellpadding="0"
                                          cellspacing="0"
                                          class="social-table"
                                          role="presentation"
                                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                          width="168px"
                                        >
                                          <tr>
                                            <td style="padding:0 5px 0 5px;">
                                              <a
                                                href="https://www.facebook.com/"
                                                target="_blank"
                                                ><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                            </td>
                                            <td style="padding:0 5px 0 5px;">
                                              <a
                                                href="https://twitter.com/"
                                                target="_blank"
                                                ><i class="fa fa-twitter-square" aria-hidden="true"></i></a>
                                            </td>
                                            <td style="padding:0 5px 0 5px;">
                                              <a
                                                href="https://instagram.com/"
                                                target="_blank"
                                                ><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                            </td>
                                            <td style="padding:0 5px 0 5px;">
                                              <a
                                                href="https://www.youtube.com/"
                                                target="_blank"
                                                ><i class="fa fa-youtube" aria-hidden="true"></i></a>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
      
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="html_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          align="center"
                                          style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif;"
                                        >
                                          <div style="height:30px;"></div>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="row row-8"
                    role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f7f6f5;"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            align="center"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row-content stack"
                            role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #072b52;"
                            width="600"
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="column"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px;"
                                  width="100%"
                                >
                                  <table
                                    border="0"
                                    cellpadding="10"
                                    cellspacing="0"
                                    class="text_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td>
                                        <div
                                          style="font-family: Tahoma, Verdana, sans-serif"
                                        >
                                          <div
                                            style="font-size: 12px; font-family: 'Lato', Tahoma, Verdana, Segoe, sans-serif; color: #f7f6f5; line-height: 1.2;"
                                          >
                                            <p
                                              style="margin: 0; mso-line-height-alt: 14.399999999999999px;"
                                            >
                                              <br />
                                            </p>
                                            <p style="margin: 0; text-align: center;">
                                              <a
                                                href="http://www.atmsedu.org/"
                                                rel="noopener"
                                                style="text-decoration: underline; color: #f7f6f5;"
                                                target="_blank"
                                                title="http://www.atmsedu.org/"
                                                >Terms &amp; Conditions</a
                                              >
                                            </p>
                                            <p style="margin: 0; text-align: center;">
                                              This notification is an automated mail response send out as a 
                                              part of notification system of ATMS Student Gateway. You are receiving 
                                              this mail since you have subscribed to the service
                                            </p>
                                            <p
                                              style="margin: 0; font-size: 12px; text-align: center;"
                                            >
                                              <span style="color:#c0c0c0;"
                                                ><br /><br
                                              /></span>
                                            </p>
                                            <p style="margin: 0; text-align: center;">
                                              © Copyright 2021. ATMS Student Gateway
                                              All Rights Reserved.
                                            </p>
                                            <p style="margin: 0; text-align: center;">
                                              <a
                                                href="http://www.example.com/"
                                                rel="noopener"
                                                style="color: #f7f6f5;"
                                                target="_blank"
                                                title="http://www.example.com"
                                                >Manage Preferences</a
                                              >
                                              |
                                              <a
                                                href="http://www.example.com/"
                                                rel="noopener"
                                                style="color: #f7f6f5;"
                                                target="_blank"
                                                title="http://www.example.com"
                                                >Unsubscribe</a
                                              >
                                            </p>
                                            <p
                                              style="margin: 0; font-size: 12px; text-align: center;"
                                            >
                                              <span style="color:#c0c0c0;"> </span>
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    class="row row-9"
                    role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                    width="100%"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            align="center"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="row-content stack"
                            role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                            width="600"
                          >
                            <tbody>
                              <tr>
                                <th
                                  class="column"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px;"
                                  width="100%"
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    class="icons_block"
                                    role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                    width="100%"
                                  >
                                    <tr>
                                      <td
                                        style="color:#9d9d9d;font-family:inherit;font-size:15px;padding-bottom:5px;padding-top:5px;text-align:center;"
                                      >
                                        <table
                                          cellpadding="0"
                                          cellspacing="0"
                                          role="presentation"
                                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                          width="100%"
                                        >
                                          <tr>
                                            <td style="text-align:center;">
                                              <!--[if vml]><table align="left" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;"><![endif]-->
                                              <!--[if !vml]><!-->
                                              <table
                                                cellpadding="0"
                                                cellspacing="0"
                                                class="icons-inner"
                                                role="presentation"
                                                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block; margin-right: -4px; padding-left: 0px; padding-right: 0px;"
                                              >
                                                <!--<![endif]-->
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End -->
        </body>
      </html>`;
  return templateString;
};

export default template;
