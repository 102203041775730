export default function grader(marks, subject) {
  if (subject === "DBA_special") {
    switch (true) {
      case marks >= 60:
        return "Pass";
      case marks < 60:
        return "Fail";
      default:
        return "Invalid";
    }
  }

  if (subject === "graderBBA") {
    switch (true) {
      case marks >= 97:
        return "A+";
      case marks >= 93 && marks <= 96:
        return "A";
      case marks >= 90 && marks <= 92:
        return "A-";
      case marks >= 87 && marks <= 89:
        return "B+";
      case marks >= 83 && marks <= 86:
        return "B";
      case marks >= 80 && marks <= 82:
        return "B-";
      case marks >= 77 && marks <= 79:
        return "C+";
      case marks >= 73 && marks <= 76:
        return "C";
      case marks >= 70 && marks <= 72:
        return "C-";
      case marks >= 64 && marks <= 69:
        return "D+";
      case marks >= 56 && marks <= 63:
        return "D+";
      case marks >= 50 && marks <= 55:
        return "D+";
      case marks <= 50:
        return "Fail";
      default:
        return "Invalid";
    }
  }

  if (subject === "graderBBA") {
    switch (true) {
      case marks >= 97:
        return "A+";
      case marks >= 93 && marks <= 96:
        return "A";
      case marks >= 90 && marks <= 92:
        return "A-";
      case marks >= 87 && marks <= 89:
        return "B+";
      case marks >= 83 && marks <= 86:
        return "B";
      case marks >= 80 && marks <= 82:
        return "B-";
      case marks >= 77 && marks <= 79:
        return "C+";
      case marks >= 73 && marks <= 76:
        return "C";
      case marks >= 70 && marks <= 72:
        return "C-";
      case marks < 70:
        return "Fail";
      default:
        return "Invalid";
    }
  }
}
