import React from "react";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_helpers";

export default function SuperAdminMenu({
  layoutProps,
  getMenuItemActive,
  userType,
  users,
}) {
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* ---------------------------Academics----------------------------- */}
        <li className="menu-section ">
          <h4 className="menu-text">Academics</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {["Admin", "Administrator", "Examiner"].indexOf(userType) !== null ? (
          <>
            <li className={`menu-item ${getMenuItemActive("/batch", false)}`}>
              <NavLink className="menu-link" to="/batch">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                  />
                </span>
                <span className="menu-text">Batches</span>
              </NavLink>
            </li>
            <li className={`menu-item ${getMenuItemActive("/course", false)}`}>
              <NavLink className="menu-link" to="/course">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Bookmark.svg")}
                  />
                </span>
                <span className="menu-text">Programs</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/dissertations",
                false
              )}`}
            >
              <NavLink className="menu-link" to="/dissertations">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Shield-user.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Dissertations</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/specialization",
                false
              )}`}
            >
              <NavLink className="menu-link" to="/specialization">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Folder-star.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Specializations</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/location", false)}`}
            >
              <NavLink className="menu-link" to="/location">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")}
                  />
                </span>
                <span className="menu-text">Locations</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/guidelines", false)}`}
            >
              <NavLink className="menu-link" to="/guidelines">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Group-folders.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Guidelines Documents</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/documents", false)}`}
            >
              <NavLink className="menu-link" to="/documents">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
                </span>
                <span className="menu-text">Batch Documents</span>
              </NavLink>
            </li>
          </>
        ) : null}
        {/* --------------------------- End Academics----------------------------- */}

        {/* ---------------------------Users ----------------------------- */}
        <li className="menu-section ">
          <h4 className="menu-text">Users</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {users.map((user) => {
          return (
            <li
              key={user.name}
              className={`menu-item ${getMenuItemActive(
                `/users/${user.name}`,
                false
              )}`}
            >
              <NavLink className="menu-link" to={`/users/${user.name}`}>
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl(`${user.icon}`)} />
                </span>
                <span className="menu-text">{user.name}</span>
              </NavLink>
            </li>
          );
        })}
        {/*end::2 Level*/}

        {/* Academics */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Others</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/*begin::2 Level*/}

        {/*begin::2 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/notifications", false)}`}
        >
          <NavLink className="menu-link" to="/notifications">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Notifications</span>
          </NavLink>
        </li>
        {/*end::2 Level*/}

        {/*begin::2 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/fee-payments", false)}`}
        >
          <NavLink className="menu-link" to="/fee-payments">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Fee Payment</span>
          </NavLink>
        </li>
        {/*end::2 Level*/}

        {/* Custom */}
        {/* begin::section */}
        <>
          <li className="menu-section ">
            <h4 className="menu-text">Settings</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/* Error Pages */}
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/settings",
              true
            )}`}
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to="/settings">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")}
                />
              </span>
              <span className="menu-text">Settings</span>
              <i className="menu-arrow" />
            </NavLink>
          </li>

          {/*end::1 Level*/}
        </>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
