import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import { listSubjects, listUserAccounts } from "../../../../graphql/queries";
import {
  createAssignment,
  updateAssignment,
} from "../../../../graphql/mutations";
import Switch from "@material-ui/core/Switch";
import SingleSelect from "../../../tools/reactSingleSelect";
import awsExports from "../../../../aws-exports";
import UpdateActivity from "../../../tools/updateActivity";

export class addStudentAssignments extends Component {
  state = {
    subjects: [],
    assignmentFile: null,
    assignmentName: "",
    finalSubmissionDate: null,
    editAssignmentID: null,
    subjectID: null,
    multipleSubmission: false,
    userId: "",
    userName: "",
    loading: false,
    version: null,
    assignmentNameError: false,
    assignmentStudentError: false,
    assignmentSubjectError: false,
    assignmentFileError: false,
    studentMessage: "",
    students: [],
    studentID: "",
    subjectNextToken: null,
    studentNextToken: null,
  };

  getSubjects() {
    const { subjectNextToken } = this.state;
    API.graphql(
      graphqlOperation(listSubjects, {
        limit: 100,
        nextToken: subjectNextToken,
      })
    ).then((result) => {
      var subjects = result.data.listSubjects.items.filter(
        (subject) =>
          subject.courseID === this.props.batchDetails.courseID &&
          subject.specializationID === this.props.batchDetails.specializationID
      );
      subjects = subjects.filter((subject) => subject._deleted !== true);
      if (subjects.length === 0) {
        this.setState({
          message: "No Subjects Added yet!",
        });
      }
      var allSubjects = [...this.state.subjects, ...subjects];
      this.setState({
        subjects: allSubjects,
        subjectNextToken: result.data.listSubjects.nextToken,
        loading: false,
      });
      if (result.data.listSubjects.nextToken !== null) {
        this.getSubjects();
      }
    });
  }

  getStudentsOfBatch() {
    this.setState({
      loading: true,
    });
    API.graphql(
      graphqlOperation(listUserAccounts, {
        limit: 100,
        nextToken: this.state.studentNextToken,
      })
    ).then((result) => {
      var students = result.data.listUserAccounts.items.filter(
        (filterStudent) =>
          filterStudent.typeOfAccount === "Student" &&
          filterStudent.batchId === this.props.batchDetails.id &&
          filterStudent._deleted !== true
      );
      if (result.data.listUserAccounts.items.length === 0) {
        this.setState({
          studentMessage: "No Students In batch",
        });
      } else {
        var studentSuggestions = [...this.state.students];
        students.map((student) => {
          studentSuggestions.push({
            key: student.id,
            label: `${student.firstName} ${student.lastName} ${student.officeID}`,
            value: student.id,
          });
          return null;
        });
        this.setState({
          students: studentSuggestions,
          studentNextToken: result.data.listUserAccounts.nextToken,
        });
      }
      if (result.data.listUserAccounts.nextToken !== null) {
        this.getStudentsOfBatch();
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  componentDidMount() {
    const { addEditStatus, editingAssignment } = this.props;
    this.getSubjects();
    this.getStudentsOfBatch();
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });

    if (!addEditStatus) {
      this.setState({
        assignmentName: editingAssignment.assignmentTitle,
        subjectID: editingAssignment.subjectID,
        editAssignmentID: editingAssignment.id,
        multipleSubmission: editingAssignment.multipleSubmission,
        finalSubmissionDate: editingAssignment.finalSubmissionDate,
        version: editingAssignment._version,
      });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onChangeFile = (e) => this.setState({ [e.target.name]: e.target.files[0] });

  onSubmit = () => {
    const {
      assignmentName,
      studentID,
      finalSubmissionDate,
      assignmentFile,
      subjectID,
      userId,
      editAssignmentID,
      multipleSubmission,
      version,
      userName,
    } = this.state;
    var valueMissing = false;
    var input = {};
    var file_name = "",
      unique_filename = "";
    if (this.props.addEditStatus) {
      if (
        assignmentName !== "" &&
        assignmentFile !== null &&
        subjectID !== null &&
        studentID !== ""
      ) {
        file_name = assignmentFile.name.split(".");
        unique_filename =
          file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
        Storage.put(unique_filename, assignmentFile).then((fileDetails) => {
          input = {
            assignmentTitle: assignmentName,
            subjectID: subjectID,
            courseID: this.props.batchDetails.courseID,
            studentID: studentID,
            userID: studentID,
            multipleSubmission: multipleSubmission,
            finalSubmissionDate: finalSubmissionDate,
            originalContent: {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: fileDetails.key,
            },
            batchID: ["Student Assignment"],
            batchCode: this.props.batchDetails.id,
            createdByUserID: userId,
          };
          API.graphql(graphqlOperation(createAssignment, { input: input }))
            .then((createdAssignment) => {
              this.setState({
                loading: false,
              });
              var activityTime = new Date();
              UpdateActivity.updateActivity({
                activityItem: `Student Assignment Created`,
                tableName: "Assignment",
                referenceID: createdAssignment.data.createAssignment.id,
                remarks: `Assignment ${createdAssignment.data.createAssignment.assignmentTitle} updated by ${userName}`,
                activityDateTime: activityTime.toISOString(),
              });
              this.props.closeForm();
            })
            .catch((err) => {
              console.log(err);
              Storage.remove(unique_filename).then(() => {
                console.log("Upload removed due to failed DB insert!");
              });
            });
        });
      } else {
        valueMissing = true;
      }
    } else {
      input = {
        id: editAssignmentID,
        assignmentTitle: assignmentName,
        subjectID: subjectID,
        multipleSubmission: multipleSubmission,
        finalSubmissionDate: finalSubmissionDate,
        _version: version,
      };
      var uploadedFileKey = null;
      if (assignmentFile !== null) {
        file_name = assignmentFile.name.split(".");
        unique_filename =
          file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
        Storage.put(unique_filename, assignmentFile).then((uploadedContent) => {
          uploadedFileKey = uploadedContent.key;
        });
        input["originalContent"] = {
          bucket: awsExports.aws_user_files_s3_bucket,
          region: awsExports.aws_user_files_s3_bucket_region,
          key: uploadedFileKey,
        };
      }
      API.graphql(graphqlOperation(updateAssignment, { input: input })).then(
        (updatedAssignment) => {
          this.setState({
            loading: false,
          });
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Student Assignment Updated`,
            tableName: "Assignment",
            referenceID: updatedAssignment.data.updateAssignment.id,
            remarks: `Assignment ${updatedAssignment.data.updateAssignment.assignmentTitle} updated by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.props.closeForm();
        }
      );
    }
    if (valueMissing) {
      var nameMissing = false,
        studentMissing = false,
        subjectMissing = false,
        submissionDateMissing = false,
        fileMissing = false;
      if (assignmentName === "") {
        nameMissing = true;
      }
      if (assignmentFile === null) {
        fileMissing = true;
      }
      if (subjectID === null) {
        subjectMissing = true;
      }
      if (studentID === "") {
        studentMissing = true;
      }

      if (finalSubmissionDate === null) {
        submissionDateMissing = true;
      }
      this.setState({
        loading: false,
        assignmentNameError: nameMissing,
        assignmentStudentError: studentMissing,
        assignmentSubjectError: subjectMissing,
        assignmentFileError: fileMissing,
        finalSubmissionDateError: submissionDateMissing,
      });
    }
  };

  selectStudent(value) {
    console.log(value);
    this.setState({ studentID: value.key });
  }

  render() {
    const {
      subjects,
      assignmentName,
      finalSubmissionDate,
      loading,
      assignmentNameError,
      subjectID,
      assignmentSubjectError,
      assignmentFileError,
      finalSubmissionDateError,
      multipleSubmission,
      students,
    } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Assignment Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Assignment Name"
              name="assignmentName"
              value={assignmentName}
              onChange={this.onChange}
            />
            {assignmentNameError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">File name missing!</div>
              </div>
            ) : null}
          </Form.Group>
          <SingleSelect
            suggestions={students}
            disableStatus={!this.props.addEditStatus}
            selectValue={"Students"}
            selectFunction={(value) => this.selectStudent(value)}
            placeholder={"Select Student"}
          />
          <br />
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              as="select"
              name="subjectID"
              disabled={!this.props.addEditStatus}
              value={subjectID}
              onChange={this.onChange}
            >
              <option value={null}>Select A Subject</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.subjectName}
                </option>
              ))}
            </Form.Control>
            {assignmentSubjectError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Please select a Subject</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Assignment File</Form.Label>
            <Form.Control
              type="file"
              name="assignmentFile"
              onChange={this.onChangeFile}
            />
            {assignmentFileError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">File Missing</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Submission Date</Form.Label>
            <Form.Control
              type="date"
              name="finalSubmissionDate"
              value={finalSubmissionDate}
              onChange={this.onChange}
            />
            {finalSubmissionDateError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Submission Date missing!</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Allow Multiple Submission</Form.Label>

            <Switch
              checked={multipleSubmission}
              onChange={() =>
                this.setState({ multipleSubmission: !multipleSubmission })
              }
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addStudentAssignments;
