import React from "react";
import Form from "react-bootstrap/Form";

class SVGIconSelect extends React.Component {
  render() {
    return (
      <Form.Control as="select" {...this.props} custom>
        <option value="001-recycling">Recycle</option>
        <option value="002-eolic-energy">Energy</option>
        <option value="003-puzzle">Puzzle</option>
        <option value="004-retweet">Retweet</option>
        <option value="005-bebo">Bebo</option>
        <option value="006-plurk">Plurk</option>
        <option value="008-infography">infography</option>
        <option value="009-hot-air-balloon">Balloon</option>
        <option value="010-vimeo">Vimeo</option>
        <option value="011-swarm">Swarm</option>
        <option value="012-foursquare">FourseSquare</option>
        <option value="013-rgb">RGB</option>
        <option value="014-kickstarter">KickStarter</option>
        <option value="015-telegram">Telegram</option>
        <option value="016-Charger">Telegram</option>
      </Form.Control>
    );
  }
}
export default SVGIconSelect;
