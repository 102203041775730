import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Storage, Auth, API, graphqlOperation } from "aws-amplify";
import { listUserAccounts } from "../../../graphql/queries";
import {
  createDissertation,
  updateDissertation,
} from "../../../graphql/mutations";
import awsExports from "../../../aws-exports";

export class addEditDissertations extends Component {
  state = {
    students: [],
    studentNextToken: null,
    contentFile: null,
    title: "",
    collectionID: this.props.collectionID,
    studentID: "",
    userId: "",
    userName: "",
    loading: false,
    version: null,
    selectedStudent: null,
  };

  getStudents() {
    this.setState({
      loading: true,
    });
    API.graphql(
      graphqlOperation(listUserAccounts, {
        limit: 100,
        nextToken: this.state.studentNextToken,
      })
    ).then((result) => {
      var students = result.data.listUserAccounts.items.filter(
        (filterStudent) =>
          filterStudent.typeOfAccount === "Student" &&
          filterStudent._deleted !== true
      );
      if (result.data.listUserAccounts.items.length === 0) {
        this.setState({
          studentMessage: "No Students In batch",
        });
      } else {
        var studentSuggestions = [...this.state.students];
        students.map((student) => {
          studentSuggestions.push({
            key: student.id,
            label: `${student.firstName} ${student.lastName} ${student.officeID}`,
            officeID: student.officeID,
            value: student.id,
          });
          return null;
        });
        this.setState({
          students: studentSuggestions,
          studentNextToken: result.data.listUserAccounts.nextToken,
        });
      }
      if (result.data.listUserAccounts.nextToken !== null) {
        this.getStudents();
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  componentDidMount() {
    const { addEditStatus, editingDissertation } = this.props;
    this.getStudents();
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!addEditStatus) {
      this.setState({
        title: editingDissertation.title,
        subjectID: editingDissertation.subjectID,
        editDissertationID: editingDissertation.id,
        version: editingDissertation._version,
      });
    }
  }

  selectStudent(value) {
    console.log(value);
    this.setState({ studentID: value.key });
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onChangeStudent = (e) => {
    var selectedStudent = this.state.students.filter(
      (student) => student.officeID === e.target.value
    );
    if (selectedStudent.length !== 0) {
      this.setState({
        selectedStudent: selectedStudent[0],
        studentID: selectedStudent[0].key,
      });
    } else {
      this.setState({
        selectedStudent: null,
        studentID: "",
      });
    }
  };

  onChangeFile = (e) => this.setState({ [e.target.name]: e.target.files[0] });

  onSubmit = () => {
    const {
      title,
      studentID,
      userId,
      editDissertationID,
      version,
      contentFile,
    } = this.state;
    var input = {};
    if (this.props.addEditStatus) {
      console.log("Creating");
      if (contentFile !== null) {
        Storage.put(contentFile.name, contentFile).then((uploadedFile) => {
          input = {
            title: title,
            collectionID: this.props.collectionID,
            studentID: studentID,
            contentFile: {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: uploadedFile.key,
            },
            createdByUserID: userId,
          };
          console.log(input);
          API.graphql(graphqlOperation(createDissertation, { input: input }))
            .then((createdDissertation) => {
              console.log(createdDissertation);
              this.setState({
                loading: false,
              });
              this.props.closeForm();
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    } else {
      console.log("Updating");
      input = {
        id: editDissertationID,
        title: title,
        collectionID: this.props.collectionID,
        studentID: studentID,
        _version: version,
      };

      API.graphql(graphqlOperation(updateDissertation, { input: input })).then(
        (updatedDissertation) => {
          console.log("updated Dissertation:", updatedDissertation);
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        }
      );
    }
  };

  render() {
    const { title, loading, studentOfficeID, selectedStudent } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title"
              name="title"
              value={title}
              onChange={this.onChange}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>SBS ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="SBS ID"
              name="SBS ID"
              value={studentOfficeID}
              onChange={this.onChangeStudent}
            />
          </Form.Group>
          {selectedStudent !== null ? (
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>{selectedStudent.label}</Form.Label>
            </Form.Group>
          ) : (
            ""
          )}

          <br />

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Dissertation</Form.Label>
            <Form.Control
              type="file"
              name="contentFile"
              onChange={(e) => this.onChangeFile(e)}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.setState({ loading: true });
              this.onSubmit();
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addEditDissertations;
