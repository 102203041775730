import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listSelfStudentContents } from "../../../../graphql/queries";
import { deleteSelfStudentContent } from "../../../../graphql/mutations";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditContent from "./addEditStudyMaterial";
import {
  onCreateSelfStudentContent,
  onUpdateSelfStudentContent,
  onDeleteSelfStudentContent,
} from "../../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class listSelfStudyContent extends Component {
  state = {
    studyMaterials: [],
    loading: true,
    message: null,
    openAddMaterials: false,
    deleteMaterialName: null,
    deleteMaterialID: null,
    openDeleteMaterial: false,
    editItem: null,
    addEditStatus: true,
  };

  getStudyMaterials() {
    API.graphql(
      graphqlOperation(listSelfStudentContents, {
        filter: { batchID: { eq: this.props.batchDetails.id } },
      })
    ).then((result) => {
      var studyMaterials = result.data.listSelfStudentContents.items;
      if (studyMaterials.length === 0) {
        this.setState({
          message: "No Self Study Materials Added yet!",
          loading: false,
        });
      } else {
        studyMaterials = studyMaterials.filter(
          (material) => material._deleted !== true
        );
        this.setState({ studyMaterials, loading: false });
      }
    });
  }

  componentDidMount() {
    this.getStudyMaterials();

    //Function to listen to post subject
    this.createStudyMaterialListener = API.graphql(
      graphqlOperation(onCreateSelfStudentContent)
    ).subscribe({
      next: (subjectData) => {
        const newStudyMaterials =
          subjectData.value.data.onCreateSelfStudentContent;
        const prevStudyMaterials = this.state.studyMaterials.filter(
          (subject) => subject.id !== newStudyMaterials.id
        );
        this.setState({
          studyMaterials: [newStudyMaterials, ...prevStudyMaterials],
        });
      },
    });

    //Function to listen to update studyMaterials
    this.updateStudyMaterialListener = API.graphql(
      graphqlOperation(onUpdateSelfStudentContent)
    ).subscribe({
      next: (StudyMaterialData) => {
        const updateStudyMaterial =
          StudyMaterialData.value.data.onUpdateSelfStudentContent;
        const index = this.state.studyMaterials.findIndex(
          (StudyMaterial) => StudyMaterial.id === updateStudyMaterial.id
        );
        const updatedStudyMaterial = [
          ...this.state.StudyMaterials.slice(0, index),
          updateStudyMaterial,
          ...this.state.StudyMaterials.slice(index + 1),
        ];
        this.setState({ studyMaterials: updatedStudyMaterial });
      },
    });

    //Function to listen to delete studyMaterials
    this.deleteStudyMaterialListener = API.graphql(
      graphqlOperation(onDeleteSelfStudentContent)
    ).subscribe({
      next: (StudyMaterialData) => {
        const updateList = this.state.studyMaterials.filter(
          (studyMaterial) =>
            studyMaterial.id !==
            StudyMaterialData.value.data.onDeleteSelfStudentContent.id
        );
        this.setState({ studyMaterials: updateList });
      },
    });
  }

  deleteContent(id) {
    API.graphql(graphqlOperation(deleteSelfStudentContent, { id: id })).then(
      (deletedContent) => {
        console.log("Deleted Content:", deletedContent);
      }
    );
  }

  componentWillUnmount() {
    if (this.createStudyMaterialListener !== undefined) {
      this.createStudyMaterialListener.unsubscribe();
    }
    if (this.updateStudyMaterialListener !== undefined) {
      this.updateStudyMaterialListener.unsubscribe();
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditStudyMaterial: false,
    });
  };

  render() {
    const {
      studyMaterials,
      loading,
      openAddEditStudyMaterial,
      deleteStudyMaterialName,
      openDeleteStudyMaterial,
      addEditStatus,
      editItem,
    } = this.state;
    const { batchDetails } = this.props;

    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditStudyMaterial: true,
                addEditStatus: true,
              })
            }
            title={"Add Self Study Material"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditStudyMaterial}
          onHide={() => this.setState({ openAddEditStudyMaterial: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus
                ? "Add Self Study Material"
                : "Edit Self Study Material"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditContent
              batchDetails={batchDetails}
              addEditStatus={addEditStatus}
              editingStudyMaterial={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteStudyMaterial}
          onHide={() => this.setState({ openDeleteStudyMaterial: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteStudyMaterialName} Course Material?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure u want to delete the Self Study Material?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.deleteContent}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteStudyMaterial: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="Course Material" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Course Material </th>
                    <th>Subject</th>
                    <th>Student</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : studyMaterials.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Course Material added Yet!</td>
                    </tr>
                  ) : (
                    studyMaterials.map((studyMaterial, i) => (
                      <tr key={studyMaterial.id}>
                        <td>{i + 1}</td>
                        <td>{studyMaterial.SelfContent_title}</td>
                        <td>
                          {studyMaterial.contentSubject !== null
                            ? studyMaterial.contentSubject.subjectName
                            : "Invalid Subject"}
                        </td>
                        <td>
                          {studyMaterial.studentDetails.firstName}{" "}
                          {studyMaterial.studentDetails.lastName}
                        </td>
                        <td>
                          {" "}
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() =>
                              Storage.get(studyMaterial.contentFile.key, {
                                download: true,
                              }).then((res) => {
                                console.log(res);
                                this.downloadBlob(
                                  res.Body,
                                  studyMaterial.contentFile.key
                                );
                              })
                            }
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                style={{ color: "red" }}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Cloud-download.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: studyMaterial,
                                openAddEditStudyMaterial: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() => {
                              this.setState({
                                deleteStudyMaterialName:
                                  studyMaterial.SelfContent_title,
                                deleteContentID: studyMaterial.id,
                                openDeleteStudyMaterial: true,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default listSelfStudyContent;
