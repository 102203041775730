import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listAssignmentSubmissions } from "../../../../customgraphql/queries";
import { updateAssignmentSubmission } from "../../../../graphql/mutations";
import { Card, CardBody } from "../../../../_metronic/_partials/controls/Card";
import { Table, Modal, Button, Form, Badge } from "react-bootstrap";
import {
  onCreateAssignmentSubmission,
  onUpdateAssignmentSubmission,
} from "../../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class assignmentSubmissions extends Component {
  state = {
    assignmentSubmissions: [],
    loading: true,
    message: null,
    remarkingAssignmentID: null,
    remarkingAssignmentVersion: null,
    remarkingAssignmentName: null,
    openRemarkAssignment: false,
    assignmentRemarks: "",
    loadingRemarks: false,
  };

  getSelfAssignments() {
    const { submissionNextToken, assignmentSubmissions } = this.state;
    API.graphql(
      graphqlOperation(listAssignmentSubmissions, {
        limit: 100,
        nextToken: submissionNextToken,
      })
    ).then((result) => {
      var newAssignmentSubmissions = result.data.listAssignmentSubmissions.items.filter(
        (submission) =>
          submission.batchID === this.props.batchID &&
          submission.assignmentID === this.props.assignmentID
      );
      if (result.data.listAssignmentSubmissions.items.length !== 0) {
        newAssignmentSubmissions = newAssignmentSubmissions.filter(
          (submission) => submission._deleted !== true
        );
        this.setState({
          assignmentSubmissions: [
            ...assignmentSubmissions,
            ...newAssignmentSubmissions,
          ],
          submissionNextToken: result.data.listAssignmentSubmissions.nextToken,
        });
      } else {
        console.log("No submissions in this batch!");
        this.setState({ loading: false });
      }
      if (result.data.listAssignmentSubmissions.nextToken !== null) {
        this.getSelfAssignments();
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  componentDidMount() {
    this.getSelfAssignments();
    //Function to listen to post subject
    this.createAssignmentListener = API.graphql(
      graphqlOperation(onCreateAssignmentSubmission, {
        filter: {
          batchConnection: { contains: this.props.batchID },
        },
      })
    ).subscribe({
      next: (submissionData) => {
        const newSubmission =
          submissionData.value.data.onCreateAssignmentSubmission;
        const prevSubmissions = this.state.assignmentSubmissions.filter(
          (submission) => submission.id !== newSubmission.id
        );
        this.setState({
          assignmentSubmissions: [newSubmission, ...prevSubmissions],
        });
      },
    });

    //Function to listen to update assignmentSubmissions
    this.updateAssignmentListener = API.graphql(
      graphqlOperation(onUpdateAssignmentSubmission)
    ).subscribe({
      next: (submissionData) => {
        const updateSubmission =
          submissionData.value.data.onUpdateAssignmentSubmission;
        const index = this.state.assignmentSubmissions.findIndex(
          (submission) => submission.id === updateSubmission.id
        );
        const submissions = [
          ...this.state.assignmentSubmissions.slice(0, index),
          updateSubmission,
          ...this.state.assignmentSubmissions.slice(index + 1),
        ];
        this.setState({ assignmentSubmissions: submissions });
      },
    });
  }

  componentWillUnmount() {
    if (this.createAssignmentListener !== undefined) {
      this.createAssignmentListener.unsubscribe();
    }
    if (this.updateAssignmentListener !== undefined) {
      this.updateAssignmentListener.unsubscribe();
    }
  }

  addRemarksSubmission = () => {
    this.setState({ loadingRemarks: true });
    const {
      remarkingAssignmentID,
      remarkingAssignmentVersion,
      assignmentRemarks,
    } = this.state;
    var input = {
      id: remarkingAssignmentID,
      remarks: assignmentRemarks,
      _version: remarkingAssignmentVersion,
    };
    API.graphql(
      graphqlOperation(updateAssignmentSubmission, {
        input: input,
      })
    ).then((result) => {
      console.log(
        "assignment updated:",
        result.data.updateAssignmentSubmission
      );
      this.setState({
        loadingRemarks: false,
        openRemarkAssignment: false,
      });
    });
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  render() {
    const {
      assignmentSubmissions,
      loading,
      message,
      assignmentRemarks,
      remarkingAssignmentName,
      openRemarkAssignment,
    } = this.state;
    return (
      <>
        <Modal
          size="lg"
          show={openRemarkAssignment}
          onHide={() => this.setState({ openRemarkAssignment: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Add Remarks for {remarkingAssignmentName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="assignmentRemarks"
                  value={assignmentRemarks}
                  onChange={(e) =>
                    this.setState({ assignmentRemarks: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => this.addRemarksSubmission()}
            >
              Add{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openRemarkAssignment: false })}
            >
              Close{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card>
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Assignment Submission </th>
                    <th>Student</th>
                    <th>Subject</th>
                    <th>Date/Time</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {message === null ? (
                    loading ? (
                      <tr>
                        <td colSpan="7">
                          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </td>
                      </tr>
                    ) : assignmentSubmissions.length === 0 ? (
                      <tr>
                        <td colSpan="7">No Submissions Yet!</td>
                      </tr>
                    ) : (
                      assignmentSubmissions.map((submission, i) => {
                        var createdDateTime = new Date(submission.createdAt);
                        var submissionDateTime = null;
                        if (submission.assignmentName !== null) {
                          submissionDateTime = new Date(
                            submission.assignmentName.finalSubmissionDate
                          );
                          return (
                            <tr key={submission.id}>
                              <td>{i + 1}</td>
                              <td>
                                {submission.assignmentName.assignmentTitle}{" "}
                                <br />
                                {createdDateTime > submissionDateTime ? (
                                  <Badge pill variant="danger">
                                    Late / Extended
                                  </Badge>
                                ) : (
                                  <Badge pill variant="success">
                                    On Time
                                  </Badge>
                                )}
                              </td>
                              <td>{submission.studentName}</td>
                              <td>
                                {
                                  submission.assignmentName.subjectName
                                    .subjectName
                                }
                              </td>
                              <td>
                                {createdDateTime.toLocaleDateString()} <br />{" "}
                                {createdDateTime.toLocaleTimeString()}
                              </td>
                              <td>
                                {" "}
                                <button
                                  className="btn btn-icon btn-light btn-sm"
                                  onClick={() =>
                                    Storage.get(submission.assignmentFile.key, {
                                      download: true,
                                    }).then((res) => {
                                      console.log(res);
                                      this.downloadBlob(
                                        res.Body,
                                        submission.assignmentFile.key
                                      );
                                    })
                                  }
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-success">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Files/Cloud-download.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </button>{" "}
                                <button
                                  className="btn btn-icon btn-light btn-sm"
                                  onClick={() =>
                                    this.setState({
                                      remarkingAssignmentID: submission.id,
                                      remarkingAssignmentVersion:
                                        submission._version,
                                      remarkingAssignmentName:
                                        submission.assignmentName
                                          .assignmentTitle,
                                      assignmentRemarks: submission.remarks,
                                      openRemarkAssignment: true,
                                    })
                                  }
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Write.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </button>
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={submission.id}>
                              <td>{i + 1}</td>
                              <td>
                                {submission.assignmentName !== null
                                  ? submission.assignmentName.assignmentTitle
                                  : "Invalid"}{" "}
                                <br />
                                {submissionDateTime !== null ? (
                                  createdDateTime > submissionDateTime ? (
                                    <Badge pill variant="danger">
                                      Late / Extended
                                    </Badge>
                                  ) : (
                                    <Badge pill variant="success">
                                      On Time
                                    </Badge>
                                  )
                                ) : (
                                  "Invalid"
                                )}
                              </td>
                              <td>{submission.studentName}</td>
                              <td>
                                {submission.assignmentName !== null
                                  ? submission.assignmentName.subjectName
                                      .subjectName
                                  : "Invalid"}
                              </td>
                              <td>
                                {createdDateTime.toLocaleDateString()} <br />{" "}
                                {createdDateTime.toLocaleTimeString()}
                              </td>
                              <td>
                                {" "}
                                <button
                                  className="btn btn-icon btn-light btn-sm"
                                  onClick={() =>
                                    Storage.get(submission.assignmentFile.key, {
                                      download: true,
                                    }).then((res) => {
                                      console.log(res);
                                      this.downloadBlob(
                                        res.Body,
                                        submission.assignmentFile.key
                                      );
                                    })
                                  }
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-success">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Files/Cloud-download.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </button>{" "}
                                {submission.assignmentName !== null ? (
                                  <button
                                    className="btn btn-icon btn-light btn-sm"
                                    onClick={() =>
                                      this.setState({
                                        remarkingAssignmentID: submission.id,
                                        remarkingAssignmentVersion:
                                          submission._version,
                                        remarkingAssignmentName:
                                          submission.assignmentName
                                            .assignmentTitle,
                                        assignmentRemarks: submission.remarks,
                                        openRemarkAssignment: true,
                                      })
                                    }
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Communication/Write.svg"
                                        )}
                                      ></SVG>
                                    </span>
                                  </button>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          );
                        }
                      })
                    )
                  ) : (
                    <tr>
                      <td colSpan="7">{message}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default assignmentSubmissions;
