import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { LOGIN_SUCCESS } from "../../actions/types";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { listUserAccounts } from "../../customgraphql/queries";
import UpdateActivity from "../tools/updateActivity";
import { getUserAccount } from "../../graphql/queries";

const initialValues = {
  email: "",
  password: "",
  username: "",
};

function Login(props) {
  const dispatch = useDispatch();

  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [newPasswordRedirect, setNewPasswordRedirect] = useState(false);
  const [newPasswordUser, setNewPasswordUser] = useState(null);
  const [userNextToken, setUserNextToken] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  useEffect(() => {
    if (
      (userNextToken === null && usersList.length === 0) ||
      (userNextToken !== null && usersList.length !== 0) //&& false
    ) {
      setLoading(true);
      API.graphql(
        graphqlOperation(listUserAccounts, {
          limit: 100,
          nextToken: userNextToken,
        })
      ).then((result) => {
        var users = result.data.listUserAccounts.items;
        var newNextToken = result.data.listUserAccounts.nextToken;
        setUsersList([...usersList, ...users]);
        setUserNextToken(newNextToken);
      });
    } else {
      setLoading(false);
    }
  }, [userNextToken]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();

      var activeUser = usersList.filter(
        (user) => user.officeID === values.username
      );
      console.log(activeUser);
      if (activeUser.length === 0) {
        var userTime = new Date();
        UpdateActivity.updateActivity({
          activityItem: `User Account not Found : ${values.username}`,
          tableName: "UserAccount",
          referenceID: "null",
          remarks: "User Cognito found without UserAccount DB",
          activityDateTime: userTime.toISOString(),
        });
        dispatch({
          type: "AUTH_ERROR",
        });
        setStatus("Error accessing User Information! Contact Administration!");
        disableLoading();
        setSubmitting(false);
      } else {
        API.graphql(
          graphqlOperation(getUserAccount, {
            id: activeUser[0].id,
          })
        ).then((currentUser) => {
          var loggedInUser = currentUser.data.getUserAccount;
          Auth.signIn(loggedInUser.email, values.password)
            .then((user) => {
              if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                setNewPasswordUser(user);
                setNewPasswordRedirect(true);
                var timeNow = new Date();
                UpdateActivity.updateActivity({
                  activityItem: `New User Password reset`,
                  tableName: "UserAccount",
                  referenceID: "null",
                  remarks: "User Logged In and Password reset started",
                  activityDateTime: timeNow.toISOString(),
                });
              } else {
                var userFoundTime = new Date();
                UpdateActivity.updateActivity({
                  activityItem: `Login Success`,
                  tableName: "UserAccount",
                  referenceID: `${loggedInUser.firstName} (${values.username}) successfully login`,
                  remarks: "User Successfully logged in!",
                  activityDateTime: userFoundTime.toISOString(),
                });
                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: loggedInUser,
                });
                setSubmitting(false);
                setLoading(false);
              }
            })
            .catch((error) => {
              setStatus(error.message);
              var userfailedTime = new Date();
              UpdateActivity.updateActivity({
                activityItem: `Login Failed: ${values.username}`,
                tableName: "UserAccount",
                referenceID: `null`,
                remarks: "User Cognito failed",
                activityDateTime: userfailedTime.toISOString(),
              });
              setSubmitting(false);
              setLoading(false);
            });
        });
      }
    },
  });

  return (
    <>
      {newPasswordRedirect && (
        <Redirect
          to={{
            pathname: "/auth/new-user",
            state: { user: newPasswordUser },
          }}
        />
      )}
      {!newPasswordRedirect && (
        <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
            <p className="text-muted font-weight-bold">
              Enter your User Name and password
            </p>
          </div>
          {/* end::Head */}
          {/*begin::Form*/}
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            ) : (
              " "
            )}

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="User Name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "username"
                )}`}
                name="username"
                {...formik.getFieldProps("username")}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.username}</div>
                </div>
              ) : null}
            </div>

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <Link
                to="/auth/forgot-password"
                className="text-dark-50 text-hover-primary my-3 mr-2"
                id="kt_login_forgot"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting || loading}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span>Sign In</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </form>
          {/*end::Form*/}
        </div>
      )}
    </>
  );
}

export default injectIntl(Login);
