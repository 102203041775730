import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listUserAccounts } from "../../graphql/queries";

export default class addEditFeesPayment extends Component {
  state = {
    students: [],
  };

  getStudents() {
    API.graphql(
      graphqlOperation(listUserAccounts, {
        filter: {
          typeOfAccount: { eq: "Student" },
        },
      })
    ).then((result) => {
      var students = result.data.listUserAccounts.items;
      console.log(students);
      if (students.length === 0) {
        this.setState({
          studentMessage: "No Students Added yet!",
        });
      }
      this.setState({ students, loading: false });
    });
  }

  componentDidMount() {
    this.getStudents();
  }
  render() {
    return <div>add fees form</div>;
  }
}
