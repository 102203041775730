import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ScheduleLayout from "./eventLayout";

export class scheduleDashboard extends Component {
  static propTypes = {
    userType: PropTypes.string.isRequired,
  };

  render() {
    const { userType, batchDetails, user } = this.props;
    if (userType === "Student") {
      return (
        <ScheduleLayout batchDetails={user.activeBatch} userType={"Student"} />
      );
    } else {
      return <ScheduleLayout batchDetails={batchDetails} />;
    }
  }
}

const mapStateToProps = (state) => ({
  userType: state.reducerAuth.userType,
  user: state.reducerAuth.user,
});
export default connect(mapStateToProps, null)(scheduleDashboard);
