import React, { Component } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Auth, API, graphqlOperation } from "aws-amplify";
import {
  listCourses,
  listLocations,
  listBatchGuideliness,
  listSpecializations,
} from "../../graphql/queries";
import { createBatch, updateBatch } from "../../graphql/mutations";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVGIconSelect from "../tools/svgIconSelect";
import UpdateActivity from "../tools/updateActivity";

export class addBatch extends Component {
  state = {
    locations: [],
    courses: [],
    specializations: [],
    guidelines: [],
    batchTitle: "",
    batchIconName: "",
    locationID: "",
    batchType: "Monthly",
    courseID: "",
    specializationID: "",
    batchGuidelinesID: "",
    createdByUserID: "",
    editBatchID: null,
    userId: "",
    userName: "",
    loading: false,
    version: null,
  };

  getCourses() {
    API.graphql(graphqlOperation(listCourses)).then((result) => {
      var courses = result.data.listCourses.items;
      if (courses.length === 0) {
        this.setState({
          message: "No Batch Guidelines Added yet!",
        });
      }
      this.setState({ courses, loading: false });
    });
  }

  getLocations() {
    API.graphql(graphqlOperation(listLocations)).then((result) => {
      var locations = result.data.listLocations.items;
      if (locations.length === 0) {
        this.setState({
          message: "No Batch Locations Added yet!",
        });
      }
      this.setState({ locations, loading: false });
    });
  }

  getSpecializations() {
    API.graphql(graphqlOperation(listSpecializations)).then((result) => {
      var specializations = result.data.listSpecializations.items;
      if (specializations.length === 0) {
        this.setState({
          message: "No Specializations Added yet!",
        });
      }
      this.setState({ specializations, loading: false });
    });
  }

  getBatchGuidelines() {
    API.graphql(graphqlOperation(listBatchGuideliness)).then((result) => {
      var guidelines = result.data.listBatchGuideliness.items;
      if (guidelines.length === 0) {
        this.setState({
          message: "No Batch Guidelines Added yet!",
        });
      }
      this.setState({ guidelines, loading: false });
    });
  }

  componentDidMount() {
    this.getCourses();
    this.getLocations();
    this.getBatchGuidelines();
    this.getSpecializations();

    const { addEditStatus, editingBatch } = this.props;
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!addEditStatus) {
      this.setState({
        batchTitle: editingBatch.batchTitle,
        batchIconName: editingBatch.batchIconName,
        locationID: editingBatch.locationID,
        batchType: editingBatch.batchType,
        courseID: editingBatch.courseID,
        specializationID: editingBatch.specializationID,
        batchGuidelinesID: editingBatch.batchGuidelinesID,
        createdByUserID: editingBatch.createdByUserID,
        editBatchID: editingBatch.id,
        version: editingBatch._version,
      });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = () => {
    const {
      batchTitle,
      userId,
      editBatchID,
      version,
      batchGuidelinesID,
      locationID,
      courseID,
      batchIconName,
      specializationID,
      batchType,
      userName,
    } = this.state;
    var input = {};
    if (courseID !== "" && locationID !== "") {
      if (this.props.addEditStatus) {
        input = {
          batchTitle: batchTitle,
          locationID: locationID,
          batchType: batchType,
          batchIconName: batchIconName,
          courseID: courseID,
          specializationID: specializationID,
          batchGuidelinesID: batchGuidelinesID,
          createdByUserID: userId,
        };
        console.log(input);
        API.graphql(graphqlOperation(createBatch, { input: input })).then(
          (createdBatch) => {
            var activityTime = new Date();
            UpdateActivity.updateActivity({
              activityItem: `Batch created`,
              tableName: "Batch",
              referenceID: createdBatch.data.createBatch.id,
              remarks: `Batch created ${createdBatch.data.createBatch.batchTitle} created by ${userName}`,
              activityDateTime: activityTime.toISOString(),
            });
            this.setState({
              loading: false,
            });
            this.props.closeForm();
          }
        );
      } else {
        input = {
          id: editBatchID,
          batchTitle: batchTitle,
          locationID: locationID,
          batchType: batchType,
          courseID: courseID,
          batchIconName: batchIconName,
          specializationID: specializationID,
          batchGuidelinesID: batchGuidelinesID,
          _version: version,
        };
        API.graphql(graphqlOperation(updateBatch, { input: input })).then(
          (updatedBatch) => {
            var activityTime = new Date();
            UpdateActivity.updateActivity({
              activityItem: `Batch updated`,
              tableName: "Batch",
              referenceID: updatedBatch.data.createBatch.id,
              remarks: `Batch updated ${updatedBatch.data.createBatch.batchTitle} created by ${userName}`,
              activityDateTime: activityTime.toISOString(),
            });
            this.setState({
              loading: false,
            });
            this.props.closeForm();
          }
        );
      }
    }
  };

  render() {
    const {
      batchTitle,
      loading,
      courses,
      locations,
      specializations,
      guidelines,
      batchIconName,
      batchGuidelinesID,
      courseID,
      locationID,
      specializationID,
      batchType,
    } = this.state;
    return (
      <div>
        <div className="symbol symbol-50 symbol-light mr-1">
          <span className="symbol-label">
            <SVG
              src={toAbsoluteUrl(`/media/svg/misc/${batchIconName}.svg`)}
            ></SVG>
          </span>
        </div>
        <Form>
          <Form.Row>
            <Col>
              <Form.Label>Select Icon</Form.Label>
              <SVGIconSelect
                value={batchIconName}
                onChange={(e) => {
                  this.setState({ batchIconName: e.target.value });
                }}
              />
            </Col>
            <Col>
              <Form.Label>Batch Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Dubai BBA"
                name="batchTitle"
                value={batchTitle}
                onChange={this.onChange}
              />
            </Col>
          </Form.Row>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Batch Type</Form.Label>
            <Row>
              <Col>
                <Form.Check
                  type="radio"
                  label="Monthly"
                  name="batchType"
                  onClick={() =>
                    this.setState({
                      batchType: "Monthly",
                    })
                  }
                  checked={batchType === "Monthly"}
                />
              </Col>
              <Col>
                <Form.Check
                  type="radio"
                  label="Weekly"
                  name="batchType"
                  onClick={() =>
                    this.setState({
                      batchType: "Weekly",
                    })
                  }
                  checked={batchType === "Weekly"}
                />
              </Col>
            </Row>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Courses</Form.Label>
                <Form.Control
                  as="select"
                  value={courseID}
                  name="courseID"
                  onChange={this.onChange}
                >
                  <option value="">Select Course</option>
                  {courses.map((course) => (
                    <option value={course.id}>{course.courseName}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Locations</Form.Label>
                <Form.Control
                  as="select"
                  value={locationID}
                  name="locationID"
                  onChange={this.onChange}
                >
                  <option value="">Select Location</option>
                  {locations.map((location) => (
                    <option value={location.id}>{location.locationName}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Specializations</Form.Label>
                <Form.Control
                  as="select"
                  value={specializationID}
                  name="specializationID"
                  onChange={this.onChange}
                >
                  <option value="">Select Specialization</option>
                  {specializations.map((specialization) => (
                    <option value={specialization.id}>
                      {specialization.specializationName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Batch Guidelines</Form.Label>
                <Form.Control
                  as="select"
                  onChange={this.onChange}
                  name="batchGuidelinesID"
                  value={batchGuidelinesID}
                >
                  <option value="">Select Guideline </option>
                  {guidelines.map((guideline) => (
                    <option value={guideline.id}>{guideline.filename}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addBatch;
