import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import {
  createAttendance,
  updateAttendance,
} from "../../../../graphql/mutations";
import { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import awsExports from "../../../../aws-exports";
import UpdateActivity from "../../../tools/updateActivity";

export default class createEditAttendance extends Component {
  state = {
    loading: false,
    title: "",
    batchID: this.props.batchDetails.id,
    attendanceFile: null,
    userId: "",
    userName: "",
    message: "",
  };

  componentDidMount() {
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!this.props.addEditStatus) {
      this.setState({
        title: this.props.editingContent.title,
        batchID: this.props.batchDetails.id,
      });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeFile = (e) => this.setState({ [e.target.name]: e.target.files[0] });

  onSubmit = () => {
    const { title, attendanceFile, userName, userId } = this.state;
    var input = {};
    var unique_filename = "";
    var uploadedFileKey = "";
    var file_name = "";
    if (this.props.addEditStatus) {
      console.log("creating attendance");
      if (title !== "" && attendanceFile !== null) {
        input = {
          title: title,
          batchID: this.props.batchDetails.id,
          createdByUserID: userId,
        };
        file_name = attendanceFile.name.split(".");
        unique_filename =
          file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
        Storage.put(unique_filename, attendanceFile).then((uploadedContent) => {
          console.log(uploadedContent);
          uploadedFileKey = uploadedContent.key;
          input["attendanceFile"] = {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: uploadedFileKey,
          };
          API.graphql(graphqlOperation(createAttendance, { input: input }))
            .then((createdAttendance) => {
              console.log("Created Attendance");
              var activityTime = new Date();
              UpdateActivity.updateActivity({
                activityItem: `Batch Attendance Created`,
                tableName: "Attendance",
                referenceID: createdAttendance.data.createAttendance.id,
                remarks: `Attendance ${createdAttendance.data.createAttendance.title} added by ${userName}`,
                activityDateTime: activityTime.toISOString(),
              });
              this.props.closeForm();
            })
            .catch((e) => {
              console.log(e);
            });
        });
      } else {
        setTimeout(
          () =>
            this.setState({
              message: "Invalid Attendance Sheet or title!",
            }),
          3000
        );
        this.setState({
          message: "",
        });
      }
    } else {
      console.log("updating attendance");
      input = {
        title: title,
        _version: this.props.editingContent._version,
      };
      if (attendanceFile !== null) {
        file_name = attendanceFile.name.split(".");
        unique_filename =
          file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
        Storage.put(unique_filename, attendanceFile).then((uploadedContent) => {
          uploadedFileKey = uploadedContent.key;
          input["attendanceFile"] = {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: uploadedFileKey,
          };
        });
        API.graphql(graphqlOperation(updateAttendance, { input: input }))
          .then((updatedAttendance) => {
            console.log("Created Attendance");
            var activityTime = new Date();
            UpdateActivity.updateActivity({
              activityItem: `Batch Attendance Updated`,
              tableName: "Attendance",
              referenceID: updatedAttendance.data.updateAttendance.id,
              remarks: `Attendance ${updatedAttendance.data.updateAttendance.title} updated by ${userName}`,
              activityDateTime: activityTime.toISOString(),
            });
            this.props.closeForm();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  render() {
    const { title, loading, message } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Assignment Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Attendance Name"
              name="title"
              value={title}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Assignment File</Form.Label>
            <Form.Control
              type="file"
              name="attendanceFile"
              onChange={this.onChangeFile}
            />
          </Form.Group>
          <br />
          <p style={{ color: "red" }}>{message}</p>
          <br />
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}
