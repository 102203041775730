import React, { Component } from "react";
import { getBatch } from "../../graphql/queries";
import { updateUserAccount } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { Table, Button } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
} from "../../_metronic/_partials/controls/Card";

export default class userBatchHistory extends Component {
  state = {
    user: this.props.userInformation,
    previousBatch: [],
  };

  onChangeStart = (e) => {
    const { previousBatch } = this.state;
    var newBatchList = [];
    var changeSet = previousBatch[e.target.name];
    changeSet["startDate"] = e.target.value;
    if (previousBatch.length > 1) {
      previousBatch.map((batch, i) => {
        if (i.toString() !== e.target.name) {
          newBatchList.push(batch);
          console.log(i, batch);
        }
      });
      newBatchList.push(changeSet);
    } else newBatchList = [changeSet];
    this.setState({
      previousBatch: newBatchList,
    });
  };

  onChangeEnd = (e) => {
    const { previousBatch } = this.state;
    var newBatchList = [];
    var changeSet = previousBatch[e.target.name];
    changeSet["endDate"] = e.target.value;
    if (previousBatch.length > 1) {
      previousBatch.map((batch, i) => {
        if (i.toString() !== e.target.name) {
          newBatchList.push(batch);
          console.log(i, batch);
        }
      });
      newBatchList.push(changeSet);
    } else newBatchList = [changeSet];
    this.setState({
      previousBatch: newBatchList,
    });
  };

  getBatchDetails() {
    var batchInformation = this.props.userInformation.batchInformation;

    batchInformation.map((batch) => {
      API.graphql(graphqlOperation(getBatch, { id: batch.batchId })).then(
        (result) => {
          console.log(result);
          var oldBatch = {
            id: result.data.getBatch.id,
            batchTitle: result.data.getBatch.batchTitle,
            startDate: batch.start_date.substring(0, 10),
            endDate: null,
            status: batch.status,
          };
          if (batch.end_date !== null) {
            oldBatch["endDate"] = batch.end_date.substring(0, 10);
          }

          this.setState({
            previousBatch: [...this.state.previousBatch, oldBatch],
          });
        }
      );
    });
  }
  componentDidMount() {
    this.getBatchDetails();
  }

  applyChanges() {
    var requiredBatchInfo = [];
    this.state.previousBatch.map((batch) => {
      requiredBatchInfo.push({
        batchId: batch.id,
        start_date: batch.startDate,
        end_date: batch.endDate,
        status: batch.status,
      });
    });
    var input = {
      id: this.props.userInformation.id,
      batchInformation: requiredBatchInfo,
      _version: this.props.userInformation._version,
    };
    console.log(input);
    API.graphql(graphqlOperation(updateUserAccount, { input: input })).then(
      (result) => {
        console.log(result.data.updateUserAccount);
        this.props.closeForm();
      }
    );
  }
  render() {
    const { previousBatch, loading } = this.state;
    return (
      <div>
        <Card>
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Batch</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : previousBatch.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Batch History</td>
                    </tr>
                  ) : (
                    previousBatch.map((batch, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{batch.batchTitle}</td>
                          <td>
                            {" "}
                            <input
                              className={`form-control form-control-solid h-auto py-5 px-6`}
                              value={batch.startDate}
                              name={i}
                              type="date"
                              onChange={this.onChangeStart}
                            />
                          </td>
                          <td>
                            <input
                              className={`form-control form-control-solid h-auto py-5 px-6`}
                              value={batch.endDate}
                              name={i}
                              type="date"
                              onChange={this.onChangeEnd}
                            />
                          </td>
                          <td>{batch.status}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardFooter>
            <Button
              variant="success"
              style={{ margin: "2px" }}
              onClick={() => this.applyChanges()}
            >
              Save
            </Button>
            <Button
              variant="danger"
              style={{ margin: "2px" }}
              onClick={() => this.props.closeForm()}
            >
              Close
            </Button>
          </CardFooter>
        </Card>
      </div>
    );
  }
}
