import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Storage, Auth, API, graphqlOperation } from "aws-amplify";
import { listSubjects } from "../../graphql/queries";
import { createTextBook, updateTextBook } from "../../graphql/mutations";
import awsExports from "../../aws-exports";
import UpdateActivity from "../tools/updateActivity";

export class addEditTextBook extends Component {
  state = {
    subjects: [],
    subjectNextToken: null,
    contentFile: null,
    title: "",
    description: "",
    courseID: this.props.courseID,
    subject: "",
    userId: "",
    userName: "",
    loading: false,
    version: null,
  };

  getSubjects() {
    const { subjectNextToken } = this.state;
    API.graphql(
      graphqlOperation(listSubjects, {
        limit: 100,
        nextToken: subjectNextToken,
      })
    ).then((result) => {
      var subjects = result.data.listSubjects.items.filter(
        (subject) => subject.courseID === this.props.courseID
      );
      subjects = subjects.filter((subject) => subject._deleted !== true);
      if (subjects.length === 0) {
        this.setState({
          message: "No Subjects Added yet!",
        });
      }
      var allSubjects = [...this.state.subjects, ...subjects];
      this.setState({
        subjects: allSubjects,
        subjectNextToken: result.data.listSubjects.nextToken,
        loading: false,
      });
      if (result.data.listSubjects.nextToken !== null) {
        this.getSubjects();
      }
    });
  }

  componentDidMount() {
    const { addEditStatus, editingContent } = this.props;
    this.getSubjects();
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!addEditStatus) {
      this.setState({
        title: editingContent.title,
        subjectID: editingContent.subjectID,
        editContentID: editingContent.id,
        version: editingContent._version,
      });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeFile = (e) => this.setState({ [e.target.name]: e.target.files[0] });

  onSubmit = () => {
    const {
      title,
      description,
      subject,
      userId,
      editContentID,
      version,
      contentFile,
      userName,
    } = this.state;
    var input = {};
    if (this.props.addEditStatus) {
      if (contentFile !== null) {
        Storage.put(contentFile.name, contentFile).then((uploadedFile) => {
          if (contentFile !== null && subject !== "") {
            input = {
              title: title,
              courseID: this.props.courseID,
              subject: subject,
              description: description,
              textBookMaterial: {
                bucket: awsExports.aws_user_files_s3_bucket,
                region: awsExports.aws_user_files_s3_bucket_region,
                key: uploadedFile.key,
              },
              CreatedByUserID: userId,
            };
            API.graphql(graphqlOperation(createTextBook, { input: input }))
              .then((createdContent) => {
                console.log(createdContent);
                this.setState({
                  loading: false,
                });
                var activityTime = new Date();
                UpdateActivity.updateActivity({
                  activityItem: `Text Book Created`,
                  tableName: "TextBook",
                  referenceID: createdContent.data.createTextBook.id,
                  remarks: `Text Book ${createdContent.data.createTextBook.title} created by ${userName}`,
                  activityDateTime: activityTime.toISOString(),
                });

                this.props.closeForm();
              })
              .catch((err) => {
                console.log(err);
                var activityTime = new Date();
                UpdateActivity.updateActivity({
                  activityItem: `Text Book Failed`,
                  tableName: "TextBook",
                  referenceID: null,
                  remarks: err.message,
                  activityDateTime: activityTime.toISOString(),
                });
              });
          }
        });
      }
    } else {
      input = {
        id: editContentID,
        title: title,
        courseID: this.props.courseID,
        subject: subject,
        description: description,
        _version: version,
      };

      API.graphql(graphqlOperation(updateTextBook, { input: input })).then(
        (updatedContent) => {
          console.log("updated Content:", updatedContent);
          this.setState({
            loading: false,
          });
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Text Book Updated`,
            tableName: "TextBook",
            referenceID: updatedContent.data.createTextBook.id,
            remarks: `Text Book ${updatedContent.data.createTextBook.title} updated by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.props.closeForm();
        }
      );
    }
  };

  render() {
    const { subjects, title, loading, description, subject } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title"
              name="title"
              value={title}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="textArea"
              placeholder="Description"
              name="description"
              value={description}
              onChange={this.onChange}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              as="select"
              name="subject"
              disabled={!this.props.addEditStatus}
              value={subject}
              onChange={this.onChange}
            >
              <option value={null}>Select A Subject</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.subjectName}>
                  {subject.subjectName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Content File</Form.Label>
            <Form.Control
              type="file"
              name="contentFile"
              multiple
              onChange={(e) => this.onChangeFile(e)}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.setState({ loading: true });
              this.onSubmit();
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addEditTextBook;
