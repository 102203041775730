import React, { Component } from "react";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { listBatchDocumentss } from "../../../graphql/queries";
import { deleteBatchDocuments } from "../../../graphql/mutations";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditBatchDocuments from "./addEditBatchDocuments";
import {
  onCreateBatchDocuments,
  onDeleteBatchDocuments,
  onUpdateBatchDocuments,
} from "../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class listBatchDocuments extends Component {
  state = {
    guidelines: [],
    message: null,
    openAddDocuments: false,
    deleteDocumentsFilename: null,
    deleteDocumentsID: null,
    openDeleteDocuments: false,
    editItem: null,
    loading: true,
    addEditStatus: true,
  };

  getBatchDocuments() {
    API.graphql(graphqlOperation(listBatchDocumentss)).then((result) => {
      var guidelines = result.data.listBatchDocumentss.items;
      console.log(guidelines);
      if (guidelines.length === 0) {
        this.setState({
          message: "No Batch Documents Added yet!",
        });
      }
      this.setState({ guidelines, loading: false });
    });
  }

  componentDidMount() {
    this.getBatchDocuments();

    //Function to listen to post course
    this.createBatchDocumentsListener = API.graphql(
      graphqlOperation(onCreateBatchDocuments)
    ).subscribe({
      next: (guidelineData) => {
        const newBatchDocuments =
          guidelineData.value.data.onCreateBatchDocuments;
        const prevBatchDocuments = this.state.guidelines.filter(
          (guideline) => guideline.id !== newBatchDocuments.id
        );
        this.setState({
          guidelines: [newBatchDocuments, ...prevBatchDocuments],
        });
      },
    });

    //Function to listen to delete courses
    this.deleteBatchDocumentsListener = API.graphql(
      graphqlOperation(onDeleteBatchDocuments)
    ).subscribe({
      next: (guidelineData) => {
        var updateBatchDocumentss = this.state.guidelines.filter(
          (guideline) =>
            guideline.id !== guidelineData.value.data.onDeleteBatchDocuments.id
        );
        this.setState({ guidelines: updateBatchDocumentss });
      },
    });

    //Function to listen to delete guidelines
    this.updateBatchDocumentsListener = API.graphql(
      graphqlOperation(onUpdateBatchDocuments)
    ).subscribe({
      next: (guidelineData) => {
        const updateBatchDocuments =
          guidelineData.value.data.onUpdateBatchDocuments;
        const index = this.state.guidelines.findIndex(
          (guideline) => guideline.id === updateBatchDocuments.id
        );
        const updateBatchDocumentss = [
          ...this.state.guidelines.slice(0, index),
          updateBatchDocuments,
          ...this.state.guidelines.slice(index + 1),
        ];
        this.setState({ guidelines: updateBatchDocumentss });
      },
    });
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  deleteBatchDocuments(id) {
    API.graphql(graphqlOperation(deleteBatchDocuments, { id: id })).then(
      (deletedDocuments) => {
        console.log("Deleted Documents:", deletedDocuments);
      }
    );
  }

  componentWillUnmount() {
    if (this.createBatchDocumentsListener !== undefined) {
      this.createBatchDocumentsListener.unsubscribe();
    }
    if (this.updateBatchDocumentsListener !== undefined) {
      this.updateBatchDocumentsListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditBatchDocuments: false,
    });
  };

  render() {
    const {
      guidelines,
      loading,
      openAddEditBatchDocuments,
      deleteBatchDocumentsName,
      openDeleteBatchDocuments,
      addEditStatus,
      editItem,
    } = this.state;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditBatchDocuments: true,
                addEditStatus: true,
              })
            }
            title={"Add Documents"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditBatchDocuments}
          onHide={() => this.setState({ openAddEditBatchDocuments: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add BatchDocuments" : "Edit BatchDocuments"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditBatchDocuments
              addEditStatus={addEditStatus}
              editingBatchDocuments={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteBatchDocuments}
          onHide={() => this.setState({ openDeleteBatchDocuments: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteBatchDocumentsName} BatchDocuments
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure u want to delete the Guideline Documents?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.deleteBatchDocuments}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteBatchDocuments: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="BatchDocumentss" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>File Name </th>
                    <th>File </th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : guidelines.length === 0 ? (
                    <tr>
                      <td colSpan="7">No BatchDocumentss added Yet!</td>
                    </tr>
                  ) : (
                    guidelines.map((guideline, i) => (
                      <tr key={guideline.id}>
                        <td>{i + 1}</td>
                        <td>{guideline.filename}</td>
                        <td>
                          <Button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() =>
                              Storage.get(guideline.guidelinesFile.key, {
                                download: true,
                              }).then((res) => {
                                console.log(res);
                                this.downloadBlob(
                                  res.Body,
                                  guideline.guidelinesFile.key
                                );
                              })
                            }
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                style={{ color: "red" }}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Cloud-download.svg"
                                )}
                              ></SVG>
                            </span>
                          </Button>
                        </td>
                        <td>
                          {" "}
                          <button className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-warning">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Settings-1.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: guideline,
                                openAddEditBatchDocuments: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() => {
                              this.setState({
                                deleteBatchDocumentsName: guideline.filename,
                                deleteBatchDocumentsID: guideline.id,
                                openDeleteBatchDocuments: true,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default listBatchDocuments;
