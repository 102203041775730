import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ListNotifications from "./listNotifications";

export default class assignments extends Component {
  render() {
    return (
      <Tabs defaultActiveKey="batch" id="uncontrolled-tab-example">
        <Tab eventKey="batch" title="Batch">
          <ListNotifications
            notificationType={"Batch"}
            batchDetails={this.props.batchDetails}
          />
        </Tab>
        <Tab eventKey="student" title="Student">
          <ListNotifications
            notificationType={"Student"}
            batchDetails={this.props.batchDetails}
          />
        </Tab>
      </Tabs>
    );
  }
}
