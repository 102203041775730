import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Button, Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import CreateUser from "../userManagement/createUser";
import AddStudentToBatch from "../userManagement/addStudentToBatch";
import { listUserAccounts } from "../../graphql/queries";
import {
  onCreateUserAccount,
  onUpdateUserAccount,
} from "../../graphql/subscriptions";
import UserProfile from "../userManagement/userProfile";
import BatchHistory from "./userBatchHistroy";
import StudentRow from "./studentRow";
import EditBatch from "./editBatch";
import sendManualMail from "../sendMail/sendMailFn";
import Template from "../sendMail/newStudentTemplate";
import SVG from "react-inlinesvg";
import clsx from "clsx";
import generatePDF from "../tools/pdfGenerator";
import { CSVDownload } from "react-csv";

export default class listUsers extends Component {
  state = {
    createUser: false,
    openBatchHistory: false,
    batchInformation: null,
    userType: this.props.userType,
    batchID: this.props.batchID,
    inBatch: this.props.inBatch,
    loading: false,
    usersNextToken: null,
    message: null,
    originalUserList: [],
    userList: [],
    addToBatch: false,
    selectedUser: null,
    editBatch: false,
    searchValue: "",
    csvData: null,
  };

  getAllUsers = () => {
    this.setState({ loading: true });
    const { usersNextToken, userType, batchID, originalUserList } = this.state;
    API.graphql(
      graphqlOperation(listUserAccounts, {
        limit: 100,
        nextToken: usersNextToken,
      })
    ).then((result) => {
      var users = result.data.listUserAccounts.items.filter(
        (user) => user._deleted !== true
      );
      var newNextToken = result.data.listUserAccounts.nextToken;
      if (users.length === 0) {
        this.setState({ message: "No Users Added yet!" });
      } else {
        if (batchID) {
          users = users.filter(
            (user) =>
              user.batchId === batchID.id && user.typeOfAccount === userType
          );
        } else {
          users = users.filter((user) => user.typeOfAccount === userType);
        }
      }
      this.setState({
        originalUserList: [...originalUserList, ...users],
        usersNextToken: newNextToken,
      });
      if (newNextToken !== null) {
        this.getAllUsers();
      } else {
        this.setState({ loading: false, userList: originalUserList });
      }
    });
  };

  searchUserList = (searchText) => {
    const { originalUserList } = this.state;
    searchText = searchText.toLowerCase();
    var users = [];
    if (searchText.toLowerCase() === "no batch") {
      users = originalUserList.filter((user) => user.batchId === null);
    } else {
      users = originalUserList.filter(
        (user) =>
          user.officeID.toLowerCase().search(searchText) !== -1 ||
          user.firstName.toLowerCase().search(searchText) !== -1 ||
          user.lastName.toLowerCase().search(searchText) !== -1 ||
          user.emailPersonal.toLowerCase().search(searchText) !== -1 ||
          user.email.toLowerCase().search(searchText) !== -1 ||
          user.contactPersonal.search(searchText) !== -1 ||
          user.contactPhone.search(searchText) !== -1
      );
    }
    this.setState({ userList: users, loading: false });
  };

  handleSearchChange = (e) => {
    var searchText = e.target.value;
    this.setState({ searchValue: searchText });
    if (searchText.length > 3) {
      this.setState({ loading: true });
      this.searchUserList(searchText);
    } else {
      this.setState({ userList: this.state.originalUserList });
    }
  };

  clear = () => {
    this.setState({ searchValue: "", userList: this.state.originalUserList });
  };

  generatePdf() {
    var tableColumn = ["Student ID", "Name", "EMail", "Phone", "Batch"];
    var tableRow = [];
    this.state.userList.forEach((user) => {
      const batchRow = [
        user.officeID,
        `${user.firstName} ${user.lastName}`,
        user.email,
        user.contactPhone,
      ];
      if (user.batchId !== null) {
        batchRow.push(user.activeBatch.batchTitle);
      } else {
        batchRow.push("No Batch");
      }
      tableRow.push(batchRow);
    });
    this.setState({
      csvData: [["Student ID", "Name", "EMail", "Phone", "Batch"], ...tableRow],
    });
    generatePDF(
      tableRow,
      tableColumn,
      ["Student List"],
      "ATMS Student-Gateway Students",
      "Autogenerated Report"
    );
  }

  componentDidMount() {
    this.getAllUsers();
    //Function to listen to post batch
    this.createUserListener = API.graphql(
      graphqlOperation(onCreateUserAccount)
    ).subscribe({
      next: (userData) => {
        const newUser = userData.value.data.onCreateUserAccount;
        if (newUser.typeOfAccount === this.props.userType) {
          this.setState({
            userList: [newUser, ...this.state.userList],
            originalUserList: [newUser, ...this.state.originalUserList],
          });
        }
      },
    });

    //Function to listen to update batches
    this.updateReportListener = API.graphql(
      graphqlOperation(onUpdateUserAccount)
    ).subscribe({
      next: (report) => {
        const updatedUser = report.value.data.onUpdateUserAccount;
        if (updatedUser.typeOfAccount === this.props.userType) {
          if (this.state.batchID && updatedUser.batchId !== null) {
            if (updatedUser.batchId === this.state.batchID.id) {
              const index = this.state.originalUserList.findIndex(
                (user) => user.id === updatedUser.id
              );
              const updatedUserList = [
                ...this.state.originalUserList.slice(0, index),
                updatedUser,
                ...this.state.originalUserList.slice(index + 1),
              ];
              this.setState({
                userList: updatedUserList,
                originalUserList: updatedUserList,
              });
            }
          } else {
            const index = this.state.originalUserList.findIndex(
              (user) => user.id === updatedUser.id
            );
            const updatedUserList = [
              ...this.state.originalUserList.slice(0, index),
              updatedUser,
              ...this.state.originalUserList.slice(index + 1),
            ];
            this.setState({
              userList: updatedUserList,
              originalUserList: updatedUserList,
            });
          }
        }
      },
    });
  }

  componentWillUnmount() {
    if (this.createUserListener !== undefined) {
      this.createUserListener.unsubscribe();
    }
    if (this.updateUserListener !== undefined) {
      this.updateUserListener.unsubscribe();
    }
  }

  showUserProfile = (user) => {
    this.setState({
      selectedUser: user,
      userProfile: true,
    });
  };

  editStudentBatch = (user) => {
    this.setState({
      selectedUser: user,
      editBatch: true,
    });
  };

  sendWelcomeMail = async (user) => {
    var studentName = user.firstName + " " + user.lastName;
    console.log(user.email, studentName);
    sendManualMail(
      Template,
      "Welcome Mail",
      "Welcome to ATMS Student Gateway",
      user.email,
      studentName,
      user.id,
      user.officeID,
      "",
      true
    );
  };

  render() {
    const {
      createUser,
      openBatchHistory,
      userType,
      batchID,
      addToBatch,
      userProfile,
      selectedUser,
      editBatch,
      searchValue,
      loading,
      inBatch,
      message,
      userList,
      csvData,
    } = this.state;
    return (
      <>
        <Modal
          size="lg"
          show={createUser}
          onHide={() => this.setState({ createUser: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Create {userType}
            </Modal.Title>
          </Modal.Header>
          <CreateUser
            userType={userType}
            closeForm={() => this.setState({ createUser: false })}
          />
        </Modal>

        <Modal
          size="lg"
          show={addToBatch}
          onHide={() => this.setState({ addToBatch: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Add {userType}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddStudentToBatch
              userType={userType}
              batchID={batchID}
              closeForm={() => this.setState({ addToBatch: false })}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={userProfile}
          onHide={() => this.setState({ userProfile: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <UserProfile
            userType={userType}
            userInformation={selectedUser}
            closeForm={() => this.setState({ userProfile: false })}
          />
        </Modal>

        <Modal
          size="lg"
          show={openBatchHistory}
          onHide={() => this.setState({ openBatchHistory: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <BatchHistory
            userType={userType}
            userInformation={selectedUser}
            closeForm={() => this.setState({ openBatchHistory: false })}
          />
        </Modal>

        <Modal
          size="lg"
          show={userProfile}
          onHide={() => this.setState({ userProfile: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <UserProfile
            userType={userType}
            openBatchHistory={() =>
              this.setState({ userProfile: false, openBatchHistory: true })
            }
            userInformation={selectedUser}
            closeForm={() => this.setState({ userProfile: false })}
          />
        </Modal>

        <Modal
          size="lg"
          show={editBatch}
          onHide={() => this.setState({ editBatch: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Edit Batch for{" "}
              {selectedUser !== undefined && selectedUser !== null
                ? `${selectedUser.firstName} ${selectedUser.lastName}`
                : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditBatch
              userType={userType}
              userInformation={selectedUser}
              closeForm={() => this.setState({ editBatch: false })}
            />
          </Modal.Body>
        </Modal>

        {/* begin::Advance Table Widget 9 */}
        <div className={`card card-custom`}>
          {/* begin::Header */}
          <div className="card-header border-0 py-1">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                {userType}
              </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
                {userList.length !== 0
                  ? `More than ${Math.floor(userList.length / 10) *
                      10}+ new ${userType}`
                  : ""}
              </span>
            </h3>

            <div className="card-toolbar">
              <div
                id="kt_quick_search_dropdown"
                className={clsx("quick-search quick-search-dropdown", {
                  "quick-search-has-result": null && 0,
                })}
              >
                <form className="quick-search-form">
                  <div className="input-group">
                    <div className={`input-group-prepend`}>
                      <span className="input-group-text">
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Search.svg"
                            )}
                          />
                        </span>
                      </span>
                    </div>
                    <input
                      type="text"
                      autoFocus={true}
                      placeholder="Search..."
                      value={searchValue}
                      onChange={(e) => this.handleSearchChange(e)}
                      className="form-control"
                    />

                    <div
                      className={`input-group-append ${
                        loading ? "spinner spinner-sm spinner-primary" : ""
                      }")}`}
                    >
                      <span className="input-group-text">
                        <i
                          style={{
                            display:
                              loading && searchValue && searchValue.length > 0
                                ? "none"
                                : "flex",
                          }}
                          onClick={() => this.clear()}
                          className="quick-search-close ki ki-close icon-sm text-muted"
                        />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              {inBatch ? (
                <Button
                  onClick={() => this.setState({ addToBatch: true })}
                  className="btn btn-primary font-weight-bolder font-size-sm"
                >
                  {" "}
                  Add Student
                </Button>
              ) : (
                <Button
                  onClick={() => this.setState({ createUser: true })}
                  className="btn btn-danger font-weight-bolder font-size-sm"
                >
                  Create
                </Button>
              )}
              {"    "}
              <Button
                onClick={() => this.generatePdf()}
                style={{ margin: "5px" }}
                className="btn btn-info font-weight-bolder font-size-sm"
              >
                Export
              </Button>
              {csvData !== null ? (
                <CSVDownload data={csvData} target="_blank" />
              ) : (
                ""
              )}
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              {/* begin::Table */}
              <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center table-head-bg table-borderless">
                  <thead>
                    <tr className="text-left">
                      {userType === "Student" ? (
                        <>
                          <th style={{ minWidth: "150px" }} className="pl-7">
                            <span className="text-dark-75">Student ID</span>
                          </th>
                          <th style={{ minWidth: "100px" }}>Name</th>
                          <th style={{ minWidth: "100px" }}>Email</th>
                          <th style={{ minWidth: "100px" }}>Phone</th>
                          <th style={{ minWidth: "100px" }}>Batch</th>
                          <th style={{ minWidth: "100px" }}>Actions</th>
                        </>
                      ) : null}
                      {userType === "Administrator" ||
                      userType === "Examiner" ||
                      userType === "Accounts" ? (
                        <>
                          <th style={{ minWidth: "150px" }} className="pl-7">
                            <span className="text-dark-75">Name</span>
                          </th>
                          <th style={{ minWidth: "120px" }}>Email</th>
                          <th style={{ minWidth: "100px" }}>Designation</th>
                          <th style={{ minWidth: "100px" }}>Phone</th>
                          <th style={{ minWidth: "100px" }}>Actions</th>
                        </>
                      ) : null}
                      {userType === "Dean" ? (
                        <>
                          <th style={{ minWidth: "150px" }} className="pl-7">
                            <span className="text-dark-75">Name</span>
                          </th>
                          <th style={{ minWidth: "120px" }}>Email</th>
                          <th style={{ minWidth: "100px" }}>Designation</th>
                          <th style={{ minWidth: "100px" }}>Phone</th>
                          <th style={{ minWidth: "100px" }}>Institution</th>
                          <th style={{ minWidth: "100px" }} />
                        </>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="7">
                          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </td>
                      </tr>
                    ) : userList.length === 0 ? (
                      <tr>
                        <td>{message}</td>
                      </tr>
                    ) : (
                      userList
                        .slice(0, 100)
                        .map((user) => (
                          <StudentRow
                            key={user.id}
                            user={user}
                            editBatch={() => this.editStudentBatch(user)}
                            showUserProfile={() => this.showUserProfile(user)}
                            sendMail={() => this.sendWelcomeMail(user)}
                          />
                        ))
                    )}
                  </tbody>
                </table>
              </div>
              {/* end::Table */}
            </div>
          </div>
          {/* end::Body */}
        </div>
        {/* end::Advance Table Widget 9 */}
      </>
    );
  }
}
