import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  UPDATE_USER,
} from "../actions/types";

const initialState = {
  userType: localStorage.getItem("userType"), //typeOfAccount
  failedAuthentication: false,
  isAuthenticated: localStorage.getItem("userAuthenticated"),
  isLoading: false,
  user: JSON.parse(localStorage.getItem("currentUser")),
  users: [],
  groups: [],
  status: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case AUTH_ERROR:
    case REGISTER_FAIL:
    case LOGOUT_SUCCESS:
      console.log("logout good");
      localStorage.removeItem("userAuthenticated");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("userType");
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: null,
        isLoading: false,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("userAuthenticated", true);
      localStorage.setItem("currentUser", JSON.stringify(action.payload));
      localStorage.setItem("userType", action.payload.typeOfAccount);
      return {
        ...state,
        userType: action.payload.typeOfAccount, //typeOfAccount
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case UPDATE_USER:
      localStorage.removeItem("currentUser");
      localStorage.setItem("currentUser", JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
      };
    default:
      return state;
  }
}
