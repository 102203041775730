import React, { Component } from "react";
import { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import { createLecturer } from "../../graphql/mutations";
import awsExports from "../../aws-exports";
import { Form, Button } from "react-bootstrap";

export default class addNewLecturer extends Component {
  state = {
    name: "",
    image: null,
    profile: null,
    email: "",
    phone: "",
    availableTime: "",
    preferredContact: "",
    userId: "",
  };

  componentDidMount() {
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
      });
    });
    if (!this.props.addEditStatus) {
      var editingLecturer = this.props.editingLecturer;
      this.setState({
        name: editingLecturer.name,
        image: editingLecturer.image,
        profile: editingLecturer.profile,
        email: editingLecturer.email,
        phone: editingLecturer.phone,
        availableTime: editingLecturer.availableTime,
        preferredContact: editingLecturer.preferredContact,
      });
    }
  }

  onChangeFile = (e) => this.setState({ [e.target.name]: e.target.files[0] });
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  uploadImageProfile = async (image, profile) => {
    var imageKey = null;
    var profileKey = null;
    if (image !== null) {
      var image_name = image.name.split(".");
      var unique_imageName =
        image_name[0] + "_" + Date.now().toString() + "." + image_name[1];
      await Storage.put(unique_imageName, image).then((uploadedImage) => {
        imageKey = uploadedImage.key;
      });
    }

    if (profile !== null) {
      var profile_name = profile.name.split(".");
      var unique_profileName =
        profile_name[0] + "_" + Date.now().toString() + "." + profile_name[1];
      await Storage.put(unique_profileName, profile).then((uploadedProfile) => {
        profileKey = uploadedProfile.key;
      });
    }
    return { imageKey, profileKey };
  };
  onSubmit() {
    const {
      userId,
      name,
      image,
      profile,
      email,
      phone,
      availableTime,
      preferredContact,
    } = this.state;
    var input = {
      name: name,
      contactInfo: {
        email: email,
        phone: phone,
        availableTime: availableTime,
        preferredContact: preferredContact,
      },
      createdByUserID: userId,
    };

    this.uploadImageProfile(image, profile).then((upload) => {
      if (upload.imageKey !== null) {
        input["image"] = {
          bucket: awsExports.aws_user_files_s3_bucket,
          region: awsExports.aws_user_files_s3_bucket_region,
          key: upload.imageKey,
        };
      }
      if (upload.profileKey !== null) {
        input["profile"] = {
          bucket: awsExports.aws_user_files_s3_bucket,
          region: awsExports.aws_user_files_s3_bucket_region,
          key: upload.profileKey,
        };
      }

      if (this.props.addEditStatus) {
        API.graphql(graphqlOperation(createLecturer, { input: input }))
          .then((createdLecturer) => {
            console.log("Created Lecturer", createdLecturer);
            this.setState({
              loading: false,
            });
            this.props.closeForm();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        input["id"] = this.props.editingLecturer.id;
        console.log(input);
        /*
      API.graphql(graphqlOperation(updateLecturer, { input: input }))
        .then((updatedLecturer) => {
          console.log("Updated Lecturer", updatedLecturer);
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        })
        .catch((err) => {
          console.log(err);
        });
        */
      }
    });
  }

  render() {
    const {
      loading,
      name,
      nameError,
      email,
      phone,
      availableTime,
      preferredContact,
    } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Content Name"
              name="name"
              value={name}
              onChange={this.onChange}
            />
            {nameError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Name Missing</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email ID"
              name="email"
              value={email}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone"
              name="phone"
              value={phone}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="file"
              name="image"
              onChange={this.onChangeFile}
            />
            <Form.Label>Profile</Form.Label>
            <Form.Control
              type="file"
              name="profile"
              onChange={this.onChangeFile}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Available Time</Form.Label>
            <Form.Control
              type="text"
              placeholder="Best Time to Contact the Lecturer"
              name="availableTime"
              value={availableTime}
              onChange={this.onChange}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Preferred Contact (Mail, Call, SMS)"
              name="preferredContact"
              value={preferredContact}
              onChange={this.onChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}
