import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { createLocation, updateLocation } from "../../../graphql/mutations";
import { searchLocations } from "../../../customgraphql/queries";
import UpdateActivity from "../../tools/updateActivity";

export class addLocation extends Component {
  state = {
    locationName: "",
    locationDescription: "",
    editLocationID: null,
    userId: "",
    userName: "",
    loading: false,
    version: null,
    message: "",
    duplicateValue: false,
  };

  componentDidMount() {
    const { addEditStatus, editingLocation } = this.props;
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!addEditStatus) {
      this.setState({
        locationName: editingLocation.locationName,
        locationDescription: editingLocation.locationDescription,
        editLocationID: editingLocation.id,
        version: editingLocation._version,
      });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  validation = async () => {
    API.graphql(
      graphqlOperation(searchLocations, {
        filter: { locationName: { eq: this.state.locationName } },
      })
    ).then((result) => {
      if (result.data.searchLocations.items.length !== 0) {
        this.setState({
          duplicateValue: true,
          message: "Location Name Already Exists",
        });
      } else {
        this.setState({
          duplicateValue: false,
          message: "",
        });
      }
    });
  };

  onSubmit = () => {
    const {
      locationName,
      locationDescription,
      userId,
      editLocationID,
      version,
      userName,
    } = this.state;

    var input = {};
    if (this.props.addEditStatus) {
      input = {
        locationName: locationName,
        locationDescription: locationDescription,
        CreatedByUserID: userId,
      };
      API.graphql(graphqlOperation(createLocation, { input: input })).then(
        (createdLocation) => {
          console.log(createdLocation);
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Location created`,
            tableName: "Location",
            referenceID: createdLocation.data.createLocation.id,
            remarks: `Location created ${locationName} created by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        }
      );
    } else {
      input = {
        id: editLocationID,
        locationName: locationName,
        locationDescription: locationDescription,
        CreatedByUserID: userId,
        _version: version,
      };
      API.graphql(graphqlOperation(updateLocation, { input: input })).then(
        (updatedLocation) => {
          console.log(updatedLocation);
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Location updated`,
            tableName: "Location",
            referenceID: updatedLocation.data.updateLocation.id,
            remarks: `Location  ${locationName} created by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        }
      );
    }
  };

  render() {
    const {
      locationDescription,
      locationName,
      loading,
      message,
      duplicateValue,
    } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Location Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Location"
              name="locationName"
              value={locationName}
              onChange={this.onChange}
              onBlur={() => this.validation()}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Location Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              name="locationDescription"
              value={locationDescription}
              onChange={this.onChange}
            />
          </Form.Group>
          {duplicateValue ? (
            ""
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                this.onSubmit();
                this.setState({ loading: true });
              }}
            >
              {loading ? (
                <>
                  <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                  <span class="sr-only">Loading...</span>
                </>
              ) : this.props.addEditStatus ? (
                "Add"
              ) : (
                "Update"
              )}
            </Button>
          )}
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
          {message !== "" ? <p style={{ color: "red" }}> {message}</p> : ""}
        </Form>
      </div>
    );
  }
}

export default addLocation;
