import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/Card";
import Switch from "@material-ui/core/Switch";
import { CodeBlockToolbarATMS } from "../../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Table, Modal, Button } from "react-bootstrap";
import AddBatchAssignment from "./addEditBatchAssignment";
import AddStudentAssignment from "./addEditStudentAssignment";
import {
  onCreateAssignment,
  onDeleteAssignment,
  onUpdateAssignment,
} from "../../../../graphql/subscriptions";
import { listAssignments } from "../../../../graphql/queries";
import {
  deleteAssignment,
  updateAssignment,
} from "../../../../graphql/mutations";
import AssignmentSubmissions from "./listSubmissions";
import SVG from "react-inlinesvg";

export class listBatchAssignments extends Component {
  state = {
    assignments: [],
    loading: true,
    message: null,
    openAddAssignment: false,
    deleteAssignmentName: null,
    deleteAssignmentID: null,
    deleteAssignmentVersion: null,
    openDeleteAssignment: false,
    editItem: null,
    addEditStatus: true,
    studentAssignment: false,
    assignmentNextToken: null,
  };

  getAssignments(assignmentType) {
    const { assignmentNextToken, assignments } = this.state;
    if (assignmentType === "Batch") {
      API.graphql(
        graphqlOperation(listAssignments, {
          limit: 100,
          nextToken: assignmentNextToken,
        })
      ).then((result) => {
        var newAssignments = result.data.listAssignments.items.filter(
          (assignment) =>
            assignment.batchID.includes(this.props.batchDetails.id)
        );
        if (result.data.listAssignments.items.length === 0) {
          this.setState({
            message: "No Assignments Added yet!",
          });
        }
        newAssignments = newAssignments.filter(
          (assignment) => assignment._deleted !== true
        );
        this.setState({
          assignments: [...assignments, ...newAssignments],
          assignmentNextToken: result.data.listAssignments.nextToken,
        });
        if (result.data.listAssignments.nextToken !== null) {
          this.getAssignments(assignmentType);
        } else {
          this.setState({
            loading: false,
          });
        }
      });
    } else {
      API.graphql(
        graphqlOperation(listAssignments, {
          limit: 100,
          nextToken: assignmentNextToken,
        })
      ).then((result) => {
        var newAssignments = result.data.listAssignments.items.filter(
          (assignment) =>
            assignment.batchID.includes("Student Assignment") &&
            assignment.courseID === this.props.batchDetails.courseID &&
            assignment.batchCode === this.props.batchDetails.id
        );
        if (result.data.listAssignments.items.length === 0) {
          this.setState({
            message: "No Assignments Added yet!",
            loading: false,
          });
        }
        newAssignments = newAssignments.filter(
          (assignment) => assignment._deleted !== true
        );
        this.setState({
          assignments: [...assignments, ...newAssignments],
          assignmentNextToken: result.data.listAssignments.nextToken,
        });
        if (result.data.listAssignments.nextToken !== null) {
          this.getAssignments(assignmentType);
        } else {
          this.setState({
            loading: false,
          });
        }
      });
    }
  }

  componentDidMount() {
    this.getAssignments(this.props.assignmentType);

    //Function to listen to post subject
    this.createAssignmentListener = API.graphql(
      graphqlOperation(onCreateAssignment)
    ).subscribe({
      next: (assignmentData) => {
        var allAssignments = [];
        const newAssignment = assignmentData.value.data.onCreateAssignment;
        const prevAssignments = this.state.assignments.filter(
          (assignment) => assignment.id !== newAssignment.id
        );
        if (this.props.assignmentType === "Batch") {
          if (newAssignment.batchID.includes(this.props.batchDetails.id)) {
            allAssignments = [newAssignment, ...prevAssignments];
          } else {
            allAssignments = prevAssignments;
          }
        } else {
          if (
            newAssignment.batchID.includes("Student Assignment") &&
            newAssignment.batchCode === this.props.batchDetails.id
          ) {
            allAssignments = [newAssignment, ...prevAssignments];
          } else {
            allAssignments = prevAssignments;
          }
        }
        this.setState({ assignments: allAssignments });
      },
    });

    //Function to listen to update assignments
    this.updateAssignmentListener = API.graphql(
      graphqlOperation(onUpdateAssignment)
    ).subscribe({
      next: (assignmentData) => {
        var updateAssignments = [];
        const updatedAssignment = assignmentData.value.data.onUpdateAssignment;
        const index = this.state.assignments.findIndex(
          (assignment) => assignment.id === updatedAssignment.id
        );

        if (this.props.assignmentType === "Batch") {
          if (updatedAssignment.batchID.includes(this.props.batchDetails.id)) {
            updateAssignments = [
              ...this.state.assignments.slice(0, index),
              updatedAssignment,
              ...this.state.assignments.slice(index + 1),
            ];
          } else {
            updateAssignments = this.state.assignments;
          }
        } else {
          if (
            updatedAssignment.batchID.includes("Student Assignment") &&
            updatedAssignment.batchCode === this.props.batchDetails.id
          ) {
            updateAssignments = [
              ...this.state.assignments.slice(0, index),
              updatedAssignment,
              ...this.state.assignments.slice(index + 1),
            ];
          } else {
            updateAssignments = this.state.assignments;
          }
        }
        this.setState({ assignments: updateAssignments });
      },
    });

    //Function to listen to update assignments
    this.deleteAssignmentListener = API.graphql(
      graphqlOperation(onDeleteAssignment)
    ).subscribe({
      next: (assignmentData) => {
        var updateAssignments = [];
        const deletedAssignment = assignmentData.value.data.onDeleteAssignment;
        updateAssignments = this.state.assignments.filter(
          (assignment) => assignment.id !== deletedAssignment.id
        );
        this.setState({ assignments: updateAssignments });
      },
    });
  }

  componentWillUnmount() {
    if (this.createAssignmentListener !== undefined) {
      this.createAssignmentListener.unsubscribe();
    }
    if (this.updateAssignmentListener !== undefined) {
      this.updateAssignmentListener.unsubscribe();
    }
    if (this.deleteAssignmentListener !== undefined) {
      this.deleteAssignmentListener.unsubscribe();
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  deleteAssignmentAction() {
    API.graphql(
      graphqlOperation(deleteAssignment, {
        input: {
          id: this.state.deleteAssignmentID,
          _version: this.state.deleteAssignmentVersion,
        },
      })
    ).then((result) => {
      console.log("deleted assignment");
    });
    this.setState({
      openDeleteAssignment: false,
    });
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditAssignment: false,
    });
  };

  render() {
    const {
      assignments,
      loading,
      openAddEditAssignment,
      deleteAssignmentName,
      openDeleteAssignment,
      addEditStatus,
      editItem,
      studentAssignment,
      selectedAssignmentName,
      selectedAssignmentID,
      openAssignmentSubmissions,
    } = this.state;
    const { batchDetails } = this.props;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditAssignment: true,
                addEditStatus: true,
              })
            }
            title={"Add Assignment"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditAssignment}
          onHide={() => this.setState({ openAddEditAssignment: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            {studentAssignment ? (
              <Modal.Title id="example-modal-sizes-title-lg">
                {addEditStatus
                  ? "Add Student Assignment"
                  : "Edit Student Assignment"}
              </Modal.Title>
            ) : (
              <Modal.Title id="example-modal-sizes-title-lg">
                {addEditStatus
                  ? "Add Batch Assignment"
                  : "Edit Batch Assignment"}
              </Modal.Title>
            )}
            {addEditStatus ? (
              <Switch
                checked={studentAssignment}
                onChange={() =>
                  this.setState({ studentAssignment: !studentAssignment })
                }
              />
            ) : (
              ""
            )}
          </Modal.Header>
          <Modal.Body>
            {studentAssignment ? (
              <AddStudentAssignment
                batchDetails={batchDetails}
                addEditStatus={addEditStatus}
                editingAssignment={editItem}
                closeForm={this.closeAddEditForm}
              />
            ) : (
              <AddBatchAssignment
                batchDetails={batchDetails}
                addEditStatus={addEditStatus}
                editingAssignment={editItem}
                closeForm={this.closeAddEditForm}
              />
            )}
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteAssignment}
          onHide={() => this.setState({ openDeleteAssignment: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteAssignmentName} Assignment?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure u want to delete the Assignment?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => this.deleteAssignmentAction()}
            >
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteAssignment: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={openAssignmentSubmissions}
          onHide={() => this.setState({ openAssignmentSubmissions: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Submissions for {selectedAssignmentName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AssignmentSubmissions
              assignmentID={selectedAssignmentID}
              batchID={this.props.batchDetails.id}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() =>
                this.setState({ openAssignmentSubmissions: false })
              }
            >
              Close{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader
            title={`${this.props.assignmentType} Assignment`}
            toolbar={toolbar}
          />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Assignment</th>
                    <th>Subject</th>
                    {this.props.assignmentType === "Student" ? (
                      <th>Student</th>
                    ) : (
                      <th>-</th>
                    )}
                    <th>Submission Date</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : assignments.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Assignment added Yet!</td>
                    </tr>
                  ) : (
                    assignments.map((assignment, i) => {
                      if (assignment._deleted !== true) {
                        return (
                          <tr key={assignment.id}>
                            <td>{i + 1}</td>
                            <td>{assignment.assignmentTitle}</td>
                            <td>
                              {assignment.subjectName !== null
                                ? assignment.subjectName.subjectName
                                : "Invalid Subject"}
                            </td>
                            {assignment.batchID.includes(
                              "Student Assignment"
                            ) && assignment.student !== null ? (
                              <td>
                                {assignment.student.firstName}{" "}
                                {assignment.student.lastName} -{" "}
                                {assignment.student.officeID}
                              </td>
                            ) : (
                              <td>-</td>
                            )}
                            <td>{assignment.finalSubmissionDate}</td>
                            <td>
                              {" "}
                              <button
                                className="btn btn-icon btn-light btn-sm mx-1"
                                onClick={() => {
                                  this.setState({
                                    selectedAssignmentName:
                                      assignment.assignmentTitle,
                                    selectedAssignmentID: assignment.id,
                                    openAssignmentSubmissions: true,
                                  });
                                }}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Visible.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </button>{" "}
                              <button
                                className="btn btn-icon btn-light btn-sm mx-1"
                                onClick={() =>
                                  Storage.get(assignment.originalContent.key, {
                                    download: true,
                                  }).then((res) => {
                                    this.downloadBlob(
                                      res.Body,
                                      assignment.originalContent.key
                                    );
                                  })
                                }
                              >
                                <span className="svg-icon svg-icon-md svg-icon-success">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Files/Cloud-download.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </button>
                              <button
                                className="btn btn-icon btn-light btn-sm mx-1"
                                onClick={() => {
                                  var currentAssignmentIsStudent = false;
                                  if (assignment.studentID !== null) {
                                    currentAssignmentIsStudent = true;
                                  }
                                  this.setState({
                                    editItem: assignment,
                                    openAddEditAssignment: true,
                                    addEditStatus: false,
                                    studentAssignment: currentAssignmentIsStudent,
                                  });
                                }}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Communication/Write.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </button>
                              <button
                                className="btn btn-icon btn-light btn-sm mx-1"
                                onClick={() => {
                                  this.setState({
                                    deleteAssignmentName:
                                      assignment.assignmentTitle,
                                    deleteAssignmentID: assignment.id,
                                    deleteAssignmentVersion:
                                      assignment._version,
                                    openDeleteAssignment: true,
                                  });
                                }}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Trash.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </button>
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default listBatchAssignments;
