import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { deleteTextBook } from "../../graphql/mutations";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditTextBook from "./addEditTextBook.js";
import { getCourse } from "../../graphql/queries";
import {
  onCreateTextBook,
  onDeleteTextBook,
  onUpdateTextBook,
} from "../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class courseTextBook extends Component {
  state = {
    textBooks: [],
    courseInformation: null,
    loading: true,
    message: null,
    openAddTextBook: false,
    deleteTextBookName: null,
    deleteTextBookID: null,
    openDeleteTextBook: false,
    editItem: null,
    addEditStatus: true,
  };

  componentDidMount() {
    API.graphql(
      graphqlOperation(getCourse, { id: this.props.match.params.courseid })
    ).then((result) => {
      var courseDetails = result.data.getCourse;
      this.setState({
        courseInformation: courseDetails,
        loading: false,
        textBooks: courseDetails.textBooks.items,
      });
    });

    //Function to listen to post subject
    this.createTextBookListener = API.graphql(
      graphqlOperation(onCreateTextBook)
    ).subscribe({
      next: (subjectData) => {
        const newTextBook = subjectData.value.data.onCreateTextBook;
        const prevTextBooks = this.state.textBooks;
        this.setState({ TextBooks: [newTextBook, ...prevTextBooks] });
      },
    });

    //Function to listen to update TextBooks
    this.updateTextBookListener = API.graphql(
      graphqlOperation(onUpdateTextBook)
    ).subscribe({
      next: (TextBookData) => {
        const updateTextBook = TextBookData.value.data.onUpdateTextBook;
        const index = this.state.TextBooks.findIndex(
          (TextBook) => TextBook.id === updateTextBook.id
        );
        const updateTextBooks = [
          ...this.state.TextBooks.slice(0, index),
          updateTextBook,
          ...this.state.TextBooks.slice(index + 1),
        ];
        this.setState({ TextBooks: updateTextBooks });
      },
    });

    //Function to listen to delete TextBooks
    this.deleteTextBookListener = API.graphql(
      graphqlOperation(onDeleteTextBook)
    ).subscribe({
      next: (TextBookData) => {
        const deleteTextBook = TextBookData.value.data.onDeleteTextBook;
        const TextBook = this.state.TextBooks.filter(
          (TextBook) => TextBook.id !== deleteTextBook.id
        );
        this.setState({ TextBooks: TextBook });
      },
    });
  }

  deleteTextBook(id, version) {
    API.graphql(
      graphqlOperation(deleteTextBook, {
        input: { id: id, _version: version },
      })
    ).then((deletedTextBook) => {
      this.setState({ openDeleteTextBook: false });
    });
  }

  componentWillUnmount() {
    if (this.createTextBookListener !== undefined) {
      this.createTextBookListener.unsubscribe();
    }
    if (this.updateTextBookListener !== undefined) {
      this.updateTextBookListener.unsubscribe();
    }
    if (this.deleteTextBookListener !== undefined) {
      this.deleteTextBookListener.unsubscribe();
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditTextBook: false,
    });
  };

  render() {
    const {
      textBooks,
      loading,
      openAddEditTextBook,
      deleteTextBookID,
      deleteTextBookName,
      deletedVersion,
      openDeleteTextBook,
      addEditStatus,
      editItem,
    } = this.state;
    const { batchDetails } = this.props;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditTextBook: true,
                addEditStatus: true,
              })
            }
            title={"Add Text Book"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditTextBook}
          onHide={() => this.setState({ openAddEditTextBook: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add TextBook" : "Edit TextBook"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditTextBook
              batchDetails={batchDetails}
              addEditStatus={addEditStatus}
              editingTextBook={editItem}
              courseID={this.props.match.params.courseid}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteTextBook}
          onHide={() => this.setState({ openDeleteTextBook: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete "{deleteTextBookName}"?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure u want to delete the Text Book?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.deleteTextBook(deleteTextBookID, deletedVersion)
              }
            >
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteTextBook: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="E Library" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title </th>
                    <th>Subject </th>
                    <th>Description</th>
                    <th>File</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : textBooks.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Text Books added Yet!</td>
                    </tr>
                  ) : (
                    textBooks.map((textBook, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{textBook.title}</td>
                        <td>{textBook.subject}</td>
                        <td>{textBook.description}</td>
                        <td>
                          <Button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() =>
                              Storage.get(textBook.textBookMaterial.key, {
                                download: true,
                              }).then((res) => {
                                console.log(res);
                                this.downloadBlob(
                                  res.Body,
                                  textBook.textBookMaterial.key
                                );
                              })
                            }
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                style={{ color: "red" }}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Cloud-download.svg"
                                )}
                              ></SVG>
                            </span>
                          </Button>
                        </td>
                        <td>
                          {" "}
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: textBook,
                                openAddEditTextBook: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            style={{ color: "red" }}
                            onClick={() => {
                              this.setState({
                                deleteTextBookName: textBook.TextBookTitle,
                                deleteTextBookID: textBook.id,
                                deletedVersion: textBook._version,
                                openDeleteTextBook: true,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default courseTextBook;
