/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:9f4e9612-2b88-4161-88c0-194e21530299",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_fwVjfe8jP",
    "aws_user_pools_web_client_id": "1boej6p5n21kvimjg7h9f9ldoa",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://7xbwcw6cjvavdkgzjemavd37nu.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-i2oixdv565alhajsiqf4e24xiq",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://5kbnc69ji6.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        },
        {
            "name": "createUserTrigger",
            "endpoint": "https://km1k21fb86.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        },
        {
            "name": "sendMailTrigger",
            "endpoint": "https://fcgqemfapb.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        }
    ],
    "aws_user_files_s3_bucket": "studentportal2e92a4419d6c44cfae544e77087de52b154515-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
