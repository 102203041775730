/* eslint-disable no-loop-func */
import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "react-router-dom";
import { CodeBlockToolbarATMS } from "../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Modal, Button } from "react-bootstrap";
import { listBatchs, searchUserAccounts } from "../../customgraphql/queries";
import { onCreateBatch, onUpdateBatch } from "../../graphql/subscriptions";
import { updateBatch } from "../../graphql/mutations";
import AddEditBatch from "./addEditBatch";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import clsx from "clsx";
import generatePDF from "../tools/pdfGenerator";
import { CSVDownload } from "react-csv";

export default class batchCollection extends Component {
  state = {
    batches: [],
    originalBatches: [],
    loading: true,
    message: null,
    openAddBatch: false,
    deleteBatchName: null,
    deleteBatchID: null,
    openDeleteBatch: false,
    editItem: null,
    addEditStatus: true,
    refreshingBatch: false,
    refreshingBatchid: null,
    selectedIconName: "006-plurk",
    searchValue: "",
    searchLoading: false,
    nextToken: null,
    csvData: null,
  };

  getBatches() {
    if (this.state.searchValue === "") {
      this.setState({
        batches: this.state.originalBatches,
      });
    }
    API.graphql(
      graphqlOperation(listBatchs, {
        limit: 100,
        nextToken: this.state.nextToken,
      })
    ).then((result) => {
      var batches = result.data.listBatchs.items;
      if (batches.length === 0) {
        this.setState({
          message: "No batches Added yet!",
        });
      }
      this.setState({
        originalBatches: [...this.state.originalBatches, ...batches],
        nextToken: result.data.listBatchs.nextToken,
      });
      if (result.data.listBatchs.nextToken !== null) {
        this.getBatches();
      }
    });
  }

  handleSearchChange = (e) => {
    const { originalBatches } = this.state;
    var newBatches = [];
    var searchValue = e.target.value;
    this.setState({
      searchValue: searchValue,
    });
    if (searchValue.length > 3) {
      newBatches = originalBatches.filter(
        (batch) =>
          batch.batchTitle.toLowerCase().search(searchValue.toLowerCase()) !==
          -1
      );
      this.setState({
        batches: newBatches,
      });
    } else {
      this.setState({
        batches: originalBatches,
      });
    }
  };

  clear = () => {
    this.setState({ searchValue: "", batches: this.state.originalBatches });
  };

  componentDidMount() {
    this.getBatches(null);
    if (this.state.searchValue === "") {
      this.setState({
        batches: this.state.originalBatches,
      });
    }
    //Function to listen to post batch
    this.createBatchListener = API.graphql(
      graphqlOperation(onCreateBatch)
    ).subscribe({
      next: (batchData) => {
        const newBatch = batchData.value.data.onCreateBatch;
        const prevBatches = this.state.batches.filter(
          (batch) => batch.id !== newBatch.id
        );
        this.setState({ batches: [newBatch, ...prevBatches] });
      },
    });

    //Function to listen to update batches
    this.updateBatchListener = API.graphql(
      graphqlOperation(onUpdateBatch)
    ).subscribe({
      next: (batchData) => {
        const updateBatch = batchData.value.data.onUpdateBatch;
        const index = this.state.batches.findIndex(
          (batch) => batch.id === updateBatch.id
        );
        const updateBatches = [
          ...this.state.batches.slice(0, index),
          updateBatch,
          ...this.state.batches.slice(index + 1),
        ];
        this.setState({ batches: updateBatches });
      },
    });
  }

  toggleRaised(value) {
    this.setState({
      selectedObject: value,
    });
  }

  selectRandomType(n) {
    var typesOfVariations = [
      "primary",
      "secondary",
      "danger",
      "success",
      "warning",
      "info",
    ];
    return typesOfVariations[n];
  }
  componentWillUnmount() {
    if (this.createBatchListener !== undefined) {
      this.createBatchListener.unsubscribe();
    }
    if (this.updateBatchListener !== undefined) {
      this.updateBatchListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditBatch: false,
    });
  };

  refreshBatch(batchId, batchVersion) {
    this.setState({
      refreshingBatch: true,
      refreshingBatchid: batchId,
    });
    API.graphql(
      graphqlOperation(searchUserAccounts, {
        filter: {
          batchId: { eq: batchId },
        },
      })
    ).then((result) => {
      var studentsInBatch = result.data.searchUserAccounts.items;
      var input = {
        id: batchId,
        studentCount: studentsInBatch.length,
        _version: batchVersion,
      };
      API.graphql(graphqlOperation(updateBatch, { input: input })).then(
        (result) => {
          console.log("Batch refreshed!");
        }
      );
      this.setState({ refreshingBatch: false });
    });
  }

  generatePdf() {
    var tableColumn = [
      "Title",
      "Type",
      "Location",
      "Program",
      "Students",
      "Specialization",
    ];
    var tableRow = [];
    this.state.batches.forEach((batch) => {
      const batchRow = [
        batch.batchTitle,
        batch.batchType,
        batch.batchLocation.locationName,
        batch.subjectCourse.courseName,
        batch.studentCount,
      ];
      if (batch.batchSpecialization !== null) {
        batchRow.push(batch.batchSpecialization.specializationName);
      }
      tableRow.push(batchRow);
    });
    generatePDF(
      tableRow,
      tableColumn,
      [],
      "ATMS Student-Gateway Batches",
      "Auto Generated Document"
    );
    /*
    this.setState({
      csvData: [tableColumn, ...tableRow],
    });
    */
  }

  render() {
    const {
      batches,
      openAddEditBatch,
      deleteBatchName,
      openDeleteBatch,
      addEditStatus,
      editItem,
      refreshingBatch,
      refreshingBatchid,
      searchValue,
      searchLoading,
      nextToken,
      csvData,
    } = this.state;
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditBatch}
          onHide={() => this.setState({ openAddEditBatch: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Create batch" : "Edit batch"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditBatch
              addEditStatus={addEditStatus}
              editingBatch={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteBatch}
          onHide={() => this.setState({ openDeleteBatch: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteBatchName} batch
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure u want to delete the batch?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.deleteBatch}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteBatch: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <div
          className="card card-custom card-stretch gutter-b"
          style={{ overflow: "scroll", height: "600px" }}
        >
          {/* Stat */}

          <div className="card-header border-0 pt-2">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Batches
              </span>
            </h3>
            <div className="card-toolbar">
              <div
                id="kt_quick_search_dropdown"
                className={clsx("quick-search quick-search-dropdown", {
                  "quick-search-has-result": null && 0,
                })}
              >
                <form className="quick-search-form">
                  <div className="input-group">
                    <div className={`input-group-prepend`}>
                      <span className="input-group-text">
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Search.svg"
                            )}
                          />
                        </span>
                      </span>
                    </div>

                    <input
                      type="text"
                      autoFocus={true}
                      placeholder="Search..."
                      value={searchValue}
                      onChange={(e) => {
                        this.setState({ loading: true });
                        this.handleSearchChange(e);
                      }}
                      className="form-control"
                    />
                    <div
                      className={`input-group-append ${
                        searchLoading
                          ? "spinner spinner-sm spinner-primary"
                          : ""
                      }")}`}
                    >
                      <span className="input-group-text">
                        <i
                          style={{
                            display:
                              searchLoading &&
                              searchValue &&
                              searchValue.length > 0
                                ? "none"
                                : "flex",
                          }}
                          onClick={() => this.clear()}
                          className="quick-search-close ki ki-close icon-sm text-muted"
                        />
                      </span>
                    </div>
                  </div>
                </form>
              </div>

              <Button onClick={() => this.generatePdf()}>Export</Button>
              {csvData !== null ? (
                <CSVDownload data={csvData} target="_blank" />
              ) : (
                ""
              )}

              <div className="example-tools">
                <CodeBlockToolbarATMS
                  showViewCode={true}
                  openAdd={() =>
                    this.setState({
                      openAddEditBatch: true,
                      addEditStatus: true,
                    })
                  }
                  title={"Add batch"}
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="card-body pt-2 pb-0">
              {/* begin::Table */}
              <div className="table-responsive">
                <table className="table table-borderless table-vertical-center">
                  <thead>
                    <tr>
                      <th className="p-0" style={{ width: "50px" }}></th>
                      <th className="p-0" style={{ minWidth: "150px" }}></th>
                      <th className="p-0" style={{ minWidth: "140px" }}></th>
                      <th className="p-0" style={{ minWidth: "120px" }}></th>
                      <th className="p-0" style={{ minWidth: "120px" }}></th>
                      <th className="p-0" style={{ minWidth: "40px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {batches.map((batch, i) => (
                      <tr key={batch.id}>
                        <td className="pl-0 py-5">
                          <div className="symbol symbol-50 symbol-light mr-2">
                            <span className="symbol-label">
                              <SVG
                                className="h-50 align-self-center"
                                src={toAbsoluteUrl(
                                  `/media/svg/misc/${batch.batchIconName}.svg`
                                )}
                              ></SVG>
                            </span>
                          </div>
                        </td>
                        <td className="pl-0">
                          <Link
                            to={"/batchMain/" + batch.id}
                            className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          >
                            {batch.batchTitle}
                          </Link>
                          <span className="text-muted font-weight-bold d-block">
                            <span
                              className={
                                batch.batchType === "Monthly"
                                  ? "label label-lg label-light-danger"
                                  : "label label-lg label-light-primary"
                              }
                            >
                              {batch.batchType}
                            </span>
                          </span>
                        </td>
                        <td className="text-right">
                          <span className="text-muted font-weight-bold">
                            {batch.subjectCourse !== null
                              ? batch.subjectCourse.courseName
                              : ""}
                          </span>
                        </td>
                        <td className="text-right">
                          <span className="text-muted font-weight-bold">
                            {batch.batchSpecialization === null
                              ? "-"
                              : batch.batchSpecialization.specializationName}
                          </span>
                        </td>
                        <td className="text-right">
                          <span className="text-muted font-weight-bold">
                            {batch.studentCount === null
                              ? "0"
                              : batch.studentCount}{" "}
                            Students
                          </span>
                          {"    "}

                          <span
                            onClick={() =>
                              this.refreshBatch(batch.id, batch._version)
                            }
                            className="btn btn-icon btn-light btn-sm"
                          >
                            {refreshingBatch &&
                            refreshingBatchid === batch.id ? (
                              <i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                            ) : (
                              <i
                                className="fa fa-refresh"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                        </td>

                        <td className="text-right pr-0">
                          <Link
                            to={"/batchMain/" + batch.id}
                            className="btn btn-icon btn-light btn-sm"
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Navigation/Arrow-right.svg"
                                )}
                              ></SVG>
                            </span>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {nextToken === null && batches.length !== 0 ? (
                  ""
                ) : (
                  <Button onClick={() => this.getBatches(null)}>Next</Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
