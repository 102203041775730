import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listReportCards } from "../../../../customgraphql/queries";
import { Modal, Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  onCreateReportCard,
  onDeleteReportCard,
} from "../../../../graphql/subscriptions";
import { onUpdateReportCard } from "../../../../customgraphql/subscriptions";
import {
  deleteReportCard,
  updateUserAccount,
} from "../../../../graphql/mutations";
import AddEditReport from "./addEditReportCard";
import ViewReportCard from "./viewReportCard";
import EnhancedTable from "./sampleTable";
import clsx from "clsx";
import SVG from "react-inlinesvg";
import generatePDF from "../../../tools/pdfGenerator";

export default class BatchReportCards extends Component {
  state = {
    selectedObject: null,
    reportCards: [],
    displayedCards: [],
    searchValue: "",
    searchLoading: false,
    loading: true,
    message: null,
    openAddReport: false,
    deleteReportName: null,
    deleteReportID: null,
    openDeleteReport: false,
    editItem: null,
    addEditStatus: true,
    openGrades: false,
    viewGrade: null,
    selectedIconName: "006-plurk",
    activeStudentCards: [],
    nextToken: null,
    deleteReportStudentID: null,
    deleteReportStudentVersion: null,
  };

  onIconChange = (e) => this.setState({ selectedIconName: e.target.value });

  getReports() {
    API.graphql(
      graphqlOperation(listReportCards, {
        limit: 100,
        nextToken: this.state.nextToken,
      })
    ).then((result) => {
      var reportCards = result.data.listReportCards.items.filter(
        (reportCard) => reportCard.batchID === this.props.batchDetails.id
      );
      if (result.data.listReportCards.items.length === 0) {
        this.setState({
          message: "No Report Cards Added yet!",
          loading: false,
        });
      } else {
        var notDeletedReportCards = reportCards.filter(
          (card) => card._deleted !== true
        );

        this.setState({
          reportCards: [...this.state.reportCards, ...notDeletedReportCards],
          displayedCards: [...this.state.reportCards, ...notDeletedReportCards],
          nextToken: result.data.listReportCards.nextToken,
        });
        if (result.data.listReportCards.nextToken !== null) {
          this.getReports();
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    });
  }

  onSearch = (e) => {
    const { reportCards } = this.state;
    var newCards = [];
    var searchValue = e.target.value;
    this.setState({
      searchValue: searchValue,
    });
    if (searchValue.length > 3) {
      newCards = reportCards.filter(
        (card) =>
          card.studentNameID.toLowerCase().search(searchValue.toLowerCase()) !==
          -1
      );
      this.setState({
        displayedCards: newCards,
        loading: false,
      });
    } else {
      this.setState({
        displayedCards: reportCards,
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.getReports();

    //Function to listen to post batch
    this.createReportListener = API.graphql(
      graphqlOperation(onCreateReportCard)
    ).subscribe({
      next: (cardData) => {
        const newReport = cardData.value.data.onCreateReportCard;
        const prevReports = this.state.reportCards.filter(
          (report) => report.id !== newReport.id
        );
        this.setState({
          reportCards: [newReport, ...prevReports],
          displayedCards: [newReport, ...prevReports],
        });
      },
    });

    //Function to listen to update batches
    this.updateReportListener = API.graphql(
      graphqlOperation(onUpdateReportCard)
    ).subscribe({
      next: (report) => {
        const updateReport = report.value.data.onUpdateReportCard;
        const index = this.state.reportCards.findIndex(
          (card) => card.id === updateReport.id
        );
        var updateReports = [];
        if (index !== -1) {
          updateReports = [
            ...this.state.reportCards.slice(0, index),
            updateReport,
            ...this.state.reportCards.slice(index + 1),
          ];
        } else {
          updateReports = [...this.state.reportCards, updateReport];
        }
        this.setState({
          reportCards: updateReports.filter((card) => card._deleted !== true),
          displayedCards: updateReports.filter(
            (card) => card._deleted !== true
          ),
        });
      },
    });

    //Function to listen to delete reportCards
    this.deleteContentListener = API.graphql(
      graphqlOperation(onDeleteReportCard)
    ).subscribe({
      next: (reportCard) => {
        const deletedReportCard = reportCard.value.data.onDeleteReportCard;
        const cards = this.state.reportCards.filter(
          (card) => card.id !== deletedReportCard.id
        );
        this.setState({ reportCards: cards, displayedCards: cards });
      },
    });
  }

  toggleRaised(value) {
    this.setState({
      selectedObject: value,
    });
  }

  selectRandomType(n) {
    var typesOfVariations = [
      "primary",
      "secondary",
      "danger",
      "success",
      "warning",
      "info",
    ];
    return typesOfVariations[n];
  }
  componentWillUnmount() {
    if (this.createReportListener !== undefined) {
      this.createReportListener.unsubscribe();
    }
    if (this.updateReportListener !== undefined) {
      this.updateReportListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditReport: false,
    });
  };

  viewReportCard = (report) => {
    this.setState({
      viewGrade: report,
      openGrades: true,
    });
  };

  deleteReport() {
    const {
      deleteReportID,
      deleteReportVersion,
      deleteReportStudentID,
      deleteReportStudentVersion,
    } = this.state;
    API.graphql(
      graphqlOperation(deleteReportCard, {
        input: { id: deleteReportID, _version: deleteReportVersion },
      })
    ).then((result) => {
      API.graphql(
        graphqlOperation(updateUserAccount, {
          input: {
            id: deleteReportStudentID,
            _version: deleteReportStudentVersion,
            studentReport: null,
          },
        })
      ).then((result) => {
        console.log(result);
        this.setState({
          openDeleteReport: false,
        });
      });
    });
  }

  updateCard(card) {
    console.log(card);
    API.graphql(
      graphqlOperation(updateUserAccount, {
        input: {
          id: card.student.id,
          _version: card.student._version,
          studentReport: card.id,
        },
      })
    ).then((result) => {
      console.log("Successfull:", result);
    });
  }

  exportCard(card) {
    console.log(card);
    var tableColumn = ["Subject", "Grade", "Marks", "Remarks"];
    var textContents = [
      `${card.student.firstName} ${card.student.lastName}`,
      `Student ID: ${card.student.officeID}`,
      `Email: ${card.student.email}`,
      `Batch: ${card.batch.batchTitle}`,
    ];
    var tableRow = [];
    card.grades.items.map((grade) => {
      if (grade._deleted !== true) {
        const gradeRow = [
          grade.subjectName.subjectName,
          grade.Grade,
          grade.Mark,
          grade.Remarks,
        ];
        tableRow.push(gradeRow);
      }
    });
    generatePDF(
      tableRow,
      tableColumn,
      textContents,
      "ATMS Student-Gateway Report Card",
      "Auto Generated Document"
    );
  }
  render() {
    const {
      openAddEditReport,
      deleteReportCardName,
      openDeleteReport,
      addEditStatus,
      editItem,
      openGrades,
      viewGrade,
      displayedCards,
      searchLoading,
      searchValue,
      loading,
    } = this.state;
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditReport}
          onHide={() => this.setState({ openAddEditReport: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Create Report Card" : "Edit Report Card"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditReport
              addEditStatus={addEditStatus}
              editingReport={editItem}
              batchDetails={this.props.batchDetails}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="xl"
          show={openGrades}
          onHide={() => this.setState({ openGrades: false })}
          aria-labelledby="example-modal-sizes-title-xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-xl">
              Report Card
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ViewReportCard
              viewGrade={viewGrade}
              batchDetails={this.props.batchDetails}
              closeForm={() => this.setState({ openGrades: false })}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="xl"
          show={openDeleteReport}
          onHide={() => this.setState({ openDeleteReport: false })}
          aria-labelledby="example-modal-sizes-title-xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-xl">
              Delete Report Card
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure u want to delete the {deleteReportCardName}'s Report
            Card?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.deleteReport()}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteReport: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}

          <div className="card-toolbar">
            <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", {
                "quick-search-has-result": null && 0,
              })}
            >
              <form className="quick-search-form">
                <div className="input-group">
                  <div className={`input-group-prepend`}>
                    <span className="input-group-text">
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Search.svg"
                          )}
                        />
                      </span>
                    </span>
                  </div>

                  <input
                    type="text"
                    autoFocus={true}
                    placeholder="Search..."
                    value={searchValue}
                    onChange={(e) => {
                      this.setState({ loading: true });
                      this.onSearch(e);
                    }}
                    className="form-control"
                  />
                  <div
                    className={`input-group-append ${
                      searchLoading ? "spinner spinner-sm spinner-primary" : ""
                    }")}`}
                  >
                    <span className="input-group-text">
                      <i
                        style={{
                          display:
                            searchLoading &&
                            searchValue &&
                            searchValue.length > 0
                              ? "none"
                              : "flex",
                        }}
                        onClick={() => this.clear()}
                        className="quick-search-close ki ki-close icon-sm text-muted"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {loading ? (
            <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          ) : (
            ""
          )}
          <EnhancedTable
            data={displayedCards}
            title="Report Cards"
            columnNames={["Student", "Grades", "Status"]}
            viewCard={(card) => this.viewReportCard(card)}
            deleteCard={(card) =>
              this.setState({
                openDeleteReport: true,
                deleteReportID: card.id,
                deleteReportCardName: card.studentNameID,
                deleteReportStudentID: card.StudentID,
                deleteReportStudentVersion: card.student._version,
                deleteReportVersion: card._version,
              })
            }
            exportCard={(card) => this.exportCard(card)}
            addCard={() =>
              this.setState({
                openAddEditReport: true,
                addEditStatus: true,
              })
            }
            message="No Report Cards Yet!"
            updateCard={(card) => this.updateCard(card)}
          />
        </div>
      </>
    );
  }
}
