import React, { Component } from "react";
import SingleSelect from "../../../tools/reactSingleSelect";
import {
  createReportCard,
  updateUserAccount,
} from "../../../../graphql/mutations";
import { listUserAccounts } from "../../../../graphql/queries";
import { Auth, graphqlOperation, API } from "aws-amplify";
import { Form, Button } from "react-bootstrap";
import SendMailProgress from "../../../sendMail/sendMail";
import UpdateActivity from "../../../tools/updateActivity";
import UpdateNotification from "../../../tools/createNotification";

export default class addEditReportCard extends Component {
  state = {
    StudentID: "",
    batchID: "",
    studentNameID: "",
    grades: [],
    createdByUserID: "",
    createdByUserName: "",
    studentSuggestions: [],
    students: [],
    loading: false,
    studentMessage: "",
    values: {},
    textData: "",
    subjectData: "",
    mailIDs: [],
    studentNextToken: null,
  };

  getBatchStudents() {
    this.setState({ loading: true });
    API.graphql(
      graphqlOperation(listUserAccounts, {
        limit: 100,
        nextToken: this.state.studentNextToken,
      })
    ).then((result) => {
      var students = result.data.listUserAccounts.items.filter(
        (student) =>
          student.typeOfAccount === "Student" &&
          student.batchId === this.props.batchDetails.id &&
          student.studentReport === null
      );
      if (result.data.listUserAccounts.items.length === 0) {
        this.setState({
          studentMessage: "No Students In batch",
        });
      } else {
        var studentSuggestions = [];
        students.map((student) => {
          studentSuggestions.push({
            key: student.id,
            label: `${student.firstName} ${student.lastName} (${student.officeID})`,
            value: student.officeID,
          });
          return null;
        });
        var newStudents = [...this.state.students, ...students];
        var newStudentSuggestions = [
          ...this.state.studentSuggestions,
          ...studentSuggestions,
        ];
        this.setState({
          studentSuggestions: newStudentSuggestions,
          studentNextToken: result.data.listUserAccounts.nextToken,
          students: newStudents,
          loading: false,
        });
        if (result.data.listUserAccounts.nextToken !== null) {
          this.getBatchStudents();
        } else {
          this.setState({ loading: false });
        }
      }
    });
  }

  componentDidMount() {
    this.getBatchStudents();
    Auth.currentUserInfo().then((user) => {
      this.setState({
        createdByUserID: user.attributes.sub,
        createdByUserName: user.username,
      });
    });
  }

  closeProgressBar() {
    this.setState({ openProgressBar: false });
  }

  selectStudent(value) {
    var selectedStudent = this.state.students.filter(
      (student) => student.id === value.key
    );
    this.setState({
      selectedStudentID: value.key,
      selectedStudent: selectedStudent[0],
      selectedStudentString: `${selectedStudent[0].firstName} ${selectedStudent[0].lastName}`,
    });
  }

  onSubmit() {
    const {
      createdByUserID,
      selectedStudentString,
      selectedStudentID,
      selectedStudent,
      createdByUserName,
    } = this.state;
    var input = {
      StudentID: selectedStudentID,
      batchID: this.props.batchDetails.id,
      studentNameID: selectedStudentString,
      createdByUserID: createdByUserID,
    };
    API.graphql(graphqlOperation(createReportCard, { input: input })).then(
      (createdReportCard) => {
        console.log("reportCard Created", createdReportCard);

        //Update User
        API.graphql(
          graphqlOperation(updateUserAccount, {
            input: {
              id: selectedStudent.id,
              studentReport: createdReportCard.data.createReportCard.id,
              _version: selectedStudent._version,
            },
          })
        ).then((updatedUser) => {
          console.log(updatedUser);

          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Report Card Created`,
            tableName: "ReportCard",
            referenceID: createdReportCard.data.createReportCard.id,
            remarks: `Grade created for ${selectedStudent.firstName} created by ${createdByUserName}`,
            activityDateTime: activityTime.toISOString(),
          });
          UpdateNotification.updateNotification({
            notificationType: "Student",
            type: "Notification",
            topic: "Grade Card Created",
            message: `Your Grade card has been created!`,
            givenUserId: selectedStudent.id,
          });
          this.setState({
            loading: false,
          });
        });
        this.props.closeForm();
      }
    );
  }
  render() {
    const {
      values,
      textData,
      subjectData,
      mailIDs,
      studentSuggestions,
      loading,
      openProgressBar,
    } = this.state;
    return (
      <div>
        <Form>
          <SingleSelect
            suggestions={studentSuggestions}
            disableStatus={!this.props.addEditStatus}
            selectValue={"Students"}
            selectFunction={(value) => this.selectStudent(value)}
            placeholder={"Select Student"}
          />
          <br />
          {openProgressBar ? (
            <SendMailProgress
              values={values}
              closeProgressBar={() => this.closeProgressBar()}
              textData={textData}
              subjectData={subjectData}
              mailIDs={mailIDs}
            />
          ) : (
            ""
          )}
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}
