export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const LOGIN_FAILED = "LOGIN_FAILED";

export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";

export const AUTH_ERROR = "AUTH_ERROR";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const GET_ERRORS = "GET_ERRORS";

export const USER_CREATED = "USER_CREATED";
export const USER_FAILED = "USER_FAILED";
export const UPDATE_USER = "UPDATE_USER";
