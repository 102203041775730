import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../_metronic/layout";
import Dissertations from "../../components/batch/dissertations/deanView/listDissertations";
import DissertationCollections from "../../components/batch/dissertations/deanView/listCollection";

const UserProfilepage = lazy(() =>
  import("../../components/UserProfile/UserProfilePage")
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DissertationCollections} />
        <ContentRoute
          path="/dissertation/:collectionid"
          component={Dissertations}
        />

        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
