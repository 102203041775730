import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listSubjects } from "../../../../graphql/queries";
import { deleteSubject } from "../../../../graphql/mutations";
import {
  onCreateSubject,
  onDeleteSubject,
  onUpdateSubject,
} from "../../../../graphql/subscriptions";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditSubject from "./addEditSubject";

export class batchSubjects extends Component {
  state = {
    subjects: [],
    loading: true,
    message: null,
    openAddSubject: false,
    deleteSubjectName: null,
    deleteSubjectID: null,
    openDeleteSubject: false,
    subjectNextToken: null,
    editItem: null,
    deleteSubjectVersion: null,
    addEditStatus: true,
  };

  getSubjects() {
    const { subjectNextToken, subjects } = this.state;
    var tempToken = subjectNextToken;
    API.graphql(
      graphqlOperation(listSubjects, {
        limit: 100,
        nextToken: subjectNextToken,
      })
    ).then((result) => {
      var newSubjects = result.data.listSubjects.items.filter(
        (subject) =>
          subject.courseID === this.props.batchDetails.courseID &&
          subject.specializationID === this.props.batchDetails.specializationID
      );
      if (newSubjects.length === 0) {
        this.setState({
          message: "No Subjects Added yet!",
          loading: false,
        });
      } else {
        newSubjects = newSubjects.filter(
          (subject) => subject._deleted !== true
        );
      }
      this.setState({
        subjects: [...subjects, ...newSubjects],
        subjectNextToken: result.data.listSubjects.nextToken,
        loading: false,
      });
      if (
        result.data.listSubjects.nextToken !== null &&
        result.data.listSubjects.nextToken !== tempToken
      ) {
        this.getSubjects();
      }
    });
  }

  componentDidMount() {
    this.getSubjects();

    //Function to listen to post subject
    this.createSubjectListener = API.graphql(
      graphqlOperation(onCreateSubject)
    ).subscribe({
      next: (subjectData) => {
        const newSubject = subjectData.value.data.onCreateSubject;
        const prevSubjects = this.state.subjects.filter(
          (subject) => subject.id !== newSubject.id
        );
        this.setState({ subjects: [newSubject, ...prevSubjects] });
      },
    });

    //Function to listen to update subjects
    this.updateSubjectListener = API.graphql(
      graphqlOperation(onUpdateSubject)
    ).subscribe({
      next: (subjectData) => {
        const updateSubject = subjectData.value.data.onUpdateSubject;
        const index = this.state.subjects.findIndex(
          (subject) => subject.id === updateSubject.id
        );
        const updateSubjects = [
          ...this.state.subjects.slice(0, index),
          updateSubject,
          ...this.state.subjects.slice(index + 1),
        ];
        this.setState({ subjects: updateSubjects });
      },
    });

    //Function to listen to delete subjects
    this.deleteSubjectListener = API.graphql(
      graphqlOperation(onDeleteSubject)
    ).subscribe({
      next: (subjectData) => {
        const deleteSubject = subjectData.value.data.onDeleteSubject;
        const deleteSubjects = this.state.subjects.filter(
          (subject) => subject.id !== deleteSubject.id
        );
        this.setState({ subjects: deleteSubjects });
      },
    });
  }

  componentWillUnmount() {
    if (this.createSubjectListener !== undefined) {
      this.createSubjectListener.unsubscribe();
    }
    if (this.updateSubjectListener !== undefined) {
      this.updateSubjectListener.unsubscribe();
    }
    if (this.deleteSubjectListener !== undefined) {
      this.deleteSubjectListener.unsubscribe();
    }
  }

  deleteSubjectAction() {
    const { deleteSubjectID, deleteSubjectVersion } = this.state;
    API.graphql(
      graphqlOperation(deleteSubject, {
        input: {
          id: deleteSubjectID,
          _version: deleteSubjectVersion,
        },
      })
    ).then((result) => {
      console.log("deleted Subject");
    });
    this.setState({
      openDeleteSubject: false,
    });
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditSubject: false,
    });
  };

  render() {
    const {
      subjects,
      loading,
      openAddEditSubject,
      deleteSubjectName,
      openDeleteSubject,
      addEditStatus,
      editItem,
    } = this.state;
    const { batchDetails } = this.props;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditSubject: true,
                addEditStatus: true,
              })
            }
            title={"Add Subject"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditSubject}
          onHide={() => this.setState({ openAddEditSubject: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Subject" : "Edit Subject"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditSubject
              batchDetails={batchDetails}
              addEditStatus={addEditStatus}
              editingSubject={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteSubject}
          onHide={() => this.setState({ openDeleteSubject: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteSubjectName} Subject
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure u want to delete the subject?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => this.deleteSubjectAction()}
            >
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteSubject: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="Subjects" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Subject </th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : subjects.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Subjects added Yet!</td>
                    </tr>
                  ) : (
                    subjects.map((subject, i) => (
                      <tr key={subject.id}>
                        <td>{i + 1}</td>
                        <td>{subject.subjectName}</td>
                        <td>{subject.subjectDescription}</td>
                        <td>
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: subject,
                                openAddEditSubject: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-info">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() => {
                              this.setState({
                                deleteSubjectName: subject.subjectName,
                                deleteSubjectVersion: subject._version,
                                deleteSubjectID: subject.id,
                                openDeleteSubject: true,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default batchSubjects;
