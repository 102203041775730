/* eslint-disable import/no-anonymous-default-export */

import { Storage } from "aws-amplify";

export default {
  uploadFile: async (fileList, setUploads) => {
    var uploadedFile = [];
    const uploadStatus = fileList.map((file) => {
      var file_name = file.file.name.split(".");
      var unique_filename =
        file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
      return Storage.put(unique_filename, file.file).then((uploadedContent) => {
        uploadedFile.push({ type: file.fileType, key: uploadedContent.key });
      });
    });

    await Promise.all(uploadStatus).then(() => {
      setUploads(uploadedFile);
      return uploadedFile;
    });
  },
};
