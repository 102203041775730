import React, { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Template from "./mailTemplate_1";
import AWS from "aws-sdk";
import awsExports from "../../aws-exports";

export default class sendMail extends Component {
  state = {
    currentValue: 0,
    totalMails: 0,
  };
  componentDidMount() {
    AWS.config.update({
      region: awsExports.aws_cognito_region,
      ///accessKeyId: "AKIAQ23KTFUAM73OJJ35",
      //secretAccessKey: "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",
      accessKeyId: "AKIAQ23KTFUACGLDVFNF",
      secretAccessKey: "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",
    });

    /*
    Old 
      accessKeyId: "AKIAQ23KTFUAHAKFZ3FA",
      secretAccessKey: "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",
    */

    const { values, textData, subjectData, mailIDs } = this.props;
    var params = {};
    this.setState({
      totalMails: mailIDs.length,
    });
    mailIDs.map((address) => {
      params = {
        Destination: {
          ToAddresses: [address],
        },
        Message: {
          Body: {
            Html: {
              Charset: "UTF-8",
              Data: `${Template(values)}`,
            },
            Text: {
              Charset: "UTF-8",
              Data: textData,
            },
          },
          Subject: {
            Charset: "UTF-8",
            Data: subjectData,
          },
        },
        Source: "notification@atmsstudentportal.com",
      };
      new AWS.SES().sendEmail(params).promise((resolve, reject) => {
        resolve((status) => {
          this.setState({
            currentValue: this.state.currentValue + 1,
          });
        });
      });
      return null;
    });
  }

  componentDidUpdate() {
    const { totalMails, currentValue } = this.state;
    console.log("total:", totalMails, "-->current:", currentValue);
    if (totalMails === currentValue) {
      this.props.closeProgressBar();
    }
  }

  render() {
    const { currentValue } = this.state;
    return (
      <div>
        <LinearProgress variant="determinate" value={currentValue} />
      </div>
    );
  }
}
