import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Storage, Auth, API, graphqlOperation } from "aws-amplify";
import {
  createBatchGuidelines,
  updateBatchGuidelines,
} from "../../../graphql/mutations";
import awsExports from "../../../aws-exports";
import UpdateActivity from "../../tools/updateActivity";
Storage.configure(awsExports);
export class addBatchGuidelines extends Component {
  state = {
    filename: "",
    guidelinesFile: null,
    editBatchGuidelinesID: null,
    userId: "",
    userName: "",
    loading: false,
    version: null,
  };

  componentDidMount() {
    const { addEditStatus, editingBatchGuidelines } = this.props;
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!addEditStatus) {
      this.setState({
        guidelineName: editingBatchGuidelines.guidelineName,
        guidelineDescription: editingBatchGuidelines.guidelineDescription,
        editBatchGuidelinesID: editingBatchGuidelines.id,
        version: editingBatchGuidelines._version,
      });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onChangeFile = (e) => this.setState({ [e.target.name]: e.target.files[0] });

  onSubmit = () => {
    const {
      filename,
      guidelinesFile,
      editBatchGuidelinesID,
      version,
      userName,
    } = this.state;
    var input = {};
    Storage.put(guidelinesFile.name, guidelinesFile).then(() => {
      if (this.props.addEditStatus) {
        input = {
          filename: filename,
          guidelinesFile: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: guidelinesFile.name,
          },
        };
        API.graphql(
          graphqlOperation(createBatchGuidelines, { input: input })
        ).then((createdBatchGuidelines) => {
          console.log(createdBatchGuidelines);
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Guidelines created`,
            tableName: "Guidelines",
            referenceID: createdBatchGuidelines.data.createBatchGuidelines.id,
            remarks: `Guidelines created by ${createdBatchGuidelines.data.createBatchGuidelines.filename} created by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        });
      } else {
        input = {
          id: editBatchGuidelinesID,
          filename: filename,
          guidelinesFile: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: guidelinesFile.name,
          },
          _version: version,
        };
        API.graphql(
          graphqlOperation(updateBatchGuidelines, { input: input })
        ).then((updatedBatchGuidelines) => {
          console.log(updatedBatchGuidelines);
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Guidelines updated`,
            tableName: "Guidelines",
            referenceID: updateBatchGuidelines.data.updateBatchGuidelines.id,
            remarks: `Guidelines updated by ${updateBatchGuidelines.data.updateBatchGuideline.filename} created by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        });
      }
    });
  };

  render() {
    const { filename, loading } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>File Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="File Name"
              name="filename"
              value={filename}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Batch Guidelines File</Form.Label>
            <Form.Control
              type="file"
              name="guidelinesFile"
              onChange={this.onChangeFile}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addBatchGuidelines;
