import { Auth, API, graphqlOperation } from "aws-amplify";
import { listUserAccounts } from "../graphql/queries";
import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from "./types";

export const logoutUser = () => (dispatch) => {
  console.log("Logging out User!!");
  dispatch({
    type: LOGOUT_SUCCESS,
    payload: false,
  });
};

export const loginUser = (email, password) => {
  return (dispatch) => {
    console.log("Logging our User!!");
    try {
      Auth.signIn(email, password).then((user) => {
        console.log(user);
        /*
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.props.requiredResetPassword(user);
      }
      if (user !== null) {
        Auth.signOut({ global: true }).then((singOutUser) => {
          console.log("GlobalSignOut:", singOutUser);
        });

        Auth.signIn(email, password).then((ReUser) => {
          console.log("Re-signIn:", ReUser);
      */
        API.graphql(
          graphqlOperation(listUserAccounts, {
            filter: {
              accountUserID: { eq: user.attributes.sub },
            },
          })
        ).then((result) => {
          var userAccount = result.data.listUserAccounts.items;
          console.log(userAccount);
          if (userAccount.length === 0) {
            console.log("User Not Available");
          }
          dispatch({
            type: LOGIN_SUCCESS,
            payload: userAccount,
          });
        });

        /*
        });
      } else {
        console.log("User Null:", user);
      }*/
      });
    } catch (err) {
      console.log("Not logged In:", err.message, " ", err);
      setTimeout(() => this.setState({ errorMessage: "" }), 5000);
    }

    dispatch({
      type: LOGOUT_SUCCESS,
      payload: false,
    });
  };
};
