import React, { Component } from "react";
import SingleSelect from "../tools/reactSingleSelect";
import { Form, Button } from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { listBatchs, getReportCard } from "../../graphql/queries";
import { updateUserAccount, updateReportCard } from "../../graphql/mutations";
import UpdateActivity from "../tools/updateActivity";

export default class editBatch extends Component {
  state = {
    batchSuggestions: [],
    selectedBatch: null,
    startDate: null,
    batches: [],
    nextToken: null,
    submitErrors: null,
  };

  getSuggestedBatches() {
    API.graphql(
      graphqlOperation(listBatchs, {
        limit: 100,
        nextToken: this.state.nextToken,
      })
    ).then((result) => {
      var batches = result.data.listBatchs.items;
      var batchSuggestions = this.state.batchSuggestions;
      batches.map((batch) => {
        if (batch.id !== this.props.userInformation.batchId) {
          batchSuggestions.push({
            key: batch.id,
            label:
              batch.batchTitle +
              " (" +
              batch.batchLocation.locationName +
              " - " +
              batch.batchType +
              ")",
            value: batch.id,
          });
        }
        return null;
      });
      this.setState({
        batches: [...this.state.batches, ...batches],
        batchSuggestions,
        nextToken: result.data.listBatchs.nextToken,
      });
      if (result.data.listBatchs.nextToken !== null) {
        this.getSuggestedBatches();
      }
    });
  }
  componentDidMount() {
    this.getSuggestedBatches();
    var today = new Date();
    this.setState({
      startDate: today,
    });
  }

  updateReportCardBatch(reportCard, batchID) {
    var input = {
      id: reportCard.id,
      batchID: batchID,
      _version: reportCard._version,
    };
    API.graphql(graphqlOperation(updateReportCard, { input: input })).then(
      (updatedReportCard) => {
        console.log("updated reportCard");
      }
    );
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  selectBatch(value) {
    var selectedBatch = this.state.batches.filter(
      (batch) => batch.id === value.key
    );
    console.log(selectedBatch);
    this.setState({
      selectedBatch: selectedBatch[0],
    });
  }

  onSubmit = () => {
    const { selectedBatch, startDate } = this.state;
    var today = new Date();
    if (startDate !== null) {
      var newBatchInfo = [];
      if (this.props.userInformation.batchInformation !== null) {
        this.props.userInformation.batchInformation.map((batchInfo) => {
          if (batchInfo.batchId !== null) {
            newBatchInfo.push({
              batchId: batchInfo.batchId,
              start_date: batchInfo.start_date,
              end_date: today.toISOString(),
              status: "deactivated",
            });
          }
          return null;
        });
      }
      newBatchInfo.push({
        batchId: selectedBatch.id,
        start_date: startDate,
        end_date: null,
        status: "active",
      });
      var input = {
        id: this.props.userInformation.id,
        batchId: selectedBatch.id,
        batchInformation: newBatchInfo,
        _version: this.props.userInformation._version,
      };
      API.graphql(graphqlOperation(updateUserAccount, { input: input }))
        .then((updatedUser) => {
          var updatedStudent = updatedUser.data.updateUserAccount;
          this.setState({
            loading: false,
          });
          if (updatedStudent.studentReport !== null) {
            API.graphql(
              graphqlOperation(getReportCard, {
                id: updatedStudent.studentReport,
              })
            ).then((currentReportCard) => {
              this.updateReportCardBatch(
                currentReportCard.data.getReportCard,
                selectedBatch.id
              );
            });
          }
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Student Batch Updated`,
            tableName: "UserAccount",
            referenceID: updatedStudent.id,
            remarks: `Student ${updatedStudent.firstName} Added to ${selectedBatch.batchTitle}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.props.closeForm();
        })
        .catch((err) => {
          var errors = err.errors;
          errors.map((error) => {
            console.log(error.message);
            var activityTime = new Date();
            UpdateActivity.updateActivity({
              activityItem: `Error Adding to Batch`,
              tableName: "UserAccount",
              referenceID: this.props.userInformation.id,
              remarks: error.message,
              activityDateTime: activityTime.toISOString(),
            });
            return null;
          });
          this.setState({
            loading: false,
            submitErrors: errors,
          });
        });
    } else {
      this.setState({
        loading: false,
        submitErrors: "Invalid Dates!",
      });
    }
  };

  render() {
    const {
      batchSuggestions,
      loading,
      startDate,

      submitErrors,
    } = this.state;
    return (
      <div>
        <Form>
          <SingleSelect
            suggestions={batchSuggestions}
            disableStatus={false}
            selectValue={"Batches"}
            selectFunction={(value) => this.selectBatch(value)}
            placeholder={"Select Batch"}
          />
          <br />
          <div className="form-group row">
            <Form.Group className="col-lg-6">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                className="form-control form-control-solid h-auto py-5 px-6"
                onChange={this.onChange}
                type="date"
                name="startDate"
                value={startDate}
              />
            </Form.Group>
          </div>
          <br />
          {submitErrors !== null ? (
            <p style={{ color: "red" }}>{submitErrors}</p>
          ) : (
            ""
          )}
          <br />
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
              </>
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}
