import React, { useState } from "react";
import { Tooltip, Button } from "@material-ui/core";

export default function MessageTooBar({ openAdd, title, iClass }) {
  const [hoverStatus, setHoverStatus] = useState(false);
  return (
    <>
      <Tooltip title={title}>
        <Button
          onMouseEnter={() => setHoverStatus(true)}
          onMouseLeave={() => setHoverStatus(false)}
          onClick={openAdd}
        >
          <i
            style={{ color: hoverStatus ? "#389aff" : "" }}
            className={iClass}
            aria-hidden="true"
          ></i>
        </Button>
      </Tooltip>
    </>
  );
}
