import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation } from "aws-amplify";
import {
  createSpecialization,
  updateSpecialization,
} from "../../../graphql/mutations";
import UpdateActivity from "../../tools/updateActivity";

export class addSpecialization extends Component {
  state = {
    specializationName: "",
    specializationDescription: "",
    editSpecializationID: null,
    userId: "",
    userName: "",
    loading: false,
    version: null,
  };

  componentDidMount() {
    const { addEditStatus, editingSpecialization } = this.props;
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!addEditStatus) {
      this.setState({
        specializationName: editingSpecialization.specializationName,
        specializationDescription:
          editingSpecialization.specializationDescription,
        editSpecializationID: editingSpecialization.id,
        version: editingSpecialization._version,
      });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = () => {
    const {
      specializationName,
      specializationDescription,
      userId,
      userName,
      editSpecializationID,
      version,
    } = this.state;
    console.log(specializationName, specializationDescription);
    var input = {};
    if (this.props.addEditStatus) {
      input = {
        specializationName: specializationName,
        specializationDescription: specializationDescription,
        CreatedByUserID: userId,
      };
      API.graphql(
        graphqlOperation(createSpecialization, { input: input })
      ).then((createdSpecialization) => {
        console.log(createdSpecialization);
        var activityTime = new Date();
        UpdateActivity.updateActivity({
          activityItem: `Specialization created`,
          tableName: "Specialization",
          referenceID: createdSpecialization.data.createSpecialization.id,
          remarks: `Specialization created ${createdSpecialization.data.createSpecialization.specializationName} created by ${userName}`,
          activityDateTime: activityTime.toISOString(),
        });
        this.setState({
          loading: false,
        });
        this.props.closeForm();
      });
    } else {
      input = {
        id: editSpecializationID,
        specializationName: specializationName,
        specializationDescription: specializationDescription,
        CreatedByUserID: userId,
        _version: version,
      };
      API.graphql(
        graphqlOperation(updateSpecialization, { input: input })
      ).then((updatedSpecialization) => {
        console.log(updatedSpecialization);
        var activityTime = new Date();
        UpdateActivity.updateActivity({
          activityItem: `Specialization updated`,
          tableName: "Specialization",
          referenceID: updatedSpecialization.data.updateSpecialization.id,
          remarks: `Specialization updated ${updatedSpecialization.data.updateSpecialization.specializationName} created by ${userName}`,
          activityDateTime: activityTime.toISOString(),
        });
        this.setState({
          loading: false,
        });
        this.props.closeForm();
      });
    }
  };

  render() {
    const {
      specializationDescription,
      specializationName,
      loading,
    } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Specialization Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="BBA"
              name="specializationName"
              value={specializationName}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Specialization Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              name="specializationDescription"
              value={specializationDescription}
              onChange={this.onChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addSpecialization;
