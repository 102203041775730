import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listSpecializations } from "../../../graphql/queries";
import { deleteSpecialization } from "../../../graphql/mutations";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditSpecialization from "./addEditSpecilization";
import {
  onCreateSpecialization,
  onDeleteSpecialization,
  onUpdateSpecialization,
} from "../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class listSpecialization extends Component {
  state = {
    Specializations: [],
    loading: true,
    message: null,
    openAddSpecialization: false,
    deleteSpecializationName: null,
    deleteSpecializationID: null,
    openDeleteSpecialization: false,
    editItem: null,
    addEditStatus: true,
  };

  getSpecializations() {
    API.graphql(graphqlOperation(listSpecializations)).then((result) => {
      var Specializations = result.data.listSpecializations.items;
      if (Specializations.length === 0) {
        this.setState({
          message: "No Specializations Added yet!",
        });
      } else {
        Specializations = Specializations.filter(
          (specialization) => specialization._deleted !== true
        );
        this.setState({ Specializations, loading: false });
      }
    });
  }

  componentDidMount() {
    this.getSpecializations();

    //Function to listen to post Specialization
    this.createSpecializationListener = API.graphql(
      graphqlOperation(onCreateSpecialization)
    ).subscribe({
      next: (specializationData) => {
        const newSpecialization =
          specializationData.value.data.onCreateSpecialization;
        const prevSpecializations = this.state.Specializations.filter(
          (Specialization) => Specialization.id !== newSpecialization.id
        );
        this.setState({
          Specializations: [newSpecialization, ...prevSpecializations],
        });
      },
    });

    //Function to listen to update Specializations
    this.updateSpecializationListener = API.graphql(
      graphqlOperation(onUpdateSpecialization)
    ).subscribe({
      next: (specializationData) => {
        const updateSpecialization =
          specializationData.value.data.onUpdateSpecialization;
        const index = this.state.Specializations.findIndex(
          (Specialization) => Specialization.id === updateSpecialization.id
        );
        const updateSpecializations = [
          ...this.state.Specializations.slice(0, index),
          updateSpecialization,
          ...this.state.Specializations.slice(index + 1),
        ];
        this.setState({ Specializations: updateSpecializations });
      },
    });

    //Function to listen to update Specializations
    this.deleteSpecializationListener = API.graphql(
      graphqlOperation(onDeleteSpecialization)
    ).subscribe({
      next: (specializationData) => {
        const deleteSpecialization =
          specializationData.value.data.onDeleteSpecialization;
        var deleteSpecializations = this.state.Specializations.filter(
          (Specialization) => Specialization.id !== deleteSpecialization.id
        );
        this.setState({ Specializations: deleteSpecializations });
      },
    });
  }

  componentWillUnmount() {
    if (this.createSpecializationListener !== undefined) {
      this.createSpecializationListener.unsubscribe();
    }
    if (this.updateSpecializationListener !== undefined) {
      this.updateSpecializationListener.unsubscribe();
    }
    if (this.deleteSpecializationListener !== undefined) {
      this.deleteSpecializationListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditSpecialization: false,
    });
  };

  deleteSpecialization(id, version) {
    API.graphql(
      graphqlOperation(deleteSpecialization, {
        input: { id: id, _version: version },
      })
    ).then((deletedSpecialization) => {
      this.setState({ openDeleteSpecialization: false });
    });
  }

  render() {
    const {
      Specializations,
      loading,
      openAddEditSpecialization,
      deleteSpecializationName,
      deleteSpecializationID,
      deleteSpecializationVersion,
      openDeleteSpecialization,
      addEditStatus,
      editItem,
    } = this.state;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditSpecialization: true,
                addEditStatus: true,
              })
            }
            title={"Add Specialization"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditSpecialization}
          onHide={() => this.setState({ openAddEditSpecialization: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Specialization" : "Edit Specialization"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditSpecialization
              addEditStatus={addEditStatus}
              editingSpecialization={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteSpecialization}
          onHide={() => this.setState({ openDeleteSpecialization: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteSpecializationName} Specialization
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure u want to delete the Specialization?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.deleteSpecialization(
                  deleteSpecializationID,
                  deleteSpecializationVersion
                )
              }
            >
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteSpecialization: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="Specializations" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Specialization </th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : Specializations.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Specializations added Yet!</td>
                    </tr>
                  ) : (
                    Specializations.map((Specialization, i) => (
                      <tr key={Specialization.id}>
                        <td>{i + 1}</td>
                        <td>{Specialization.specializationName}</td>
                        <td>{Specialization.specializationDescription}</td>

                        <td>
                          <button className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                style={{ color: "red" }}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Settings-1.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: Specialization,
                                openAddEditSpecialization: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() => {
                              this.setState({
                                deleteSpecializationName:
                                  Specialization.specializationName,
                                deleteSpecializationID: Specialization.id,
                                deleteSpecializationVersion:
                                  Specialization._version,
                                openDeleteSpecialization: true,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default listSpecialization;
