import React, { Component } from "react";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { listLecturers } from "../../graphql/queries";
import { deleteLecturer } from "../../graphql/mutations";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import {
  onCreateLecturer,
  onDeleteLecturer,
  onUpdateLecturer,
} from "../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import AddLecturer from "./addNewLecturer";

export class ShowLecturers extends Component {
  state = {
    lecturers: [],
    message: null,
    openAddLecturer: false,
    deleteLecturerName: null,
    deleteLecturerID: null,
    openDeleteLecturer: false,
    editItem: null,
    loading: true,
    addEditStatus: true,
  };

  getLecturers() {
    API.graphql(graphqlOperation(listLecturers)).then((result) => {
      var lecturers = result.data.listLecturers.items;
      if (lecturers.length === 0) {
        this.setState({
          message: "No Lecturers Added yet!",
        });
      }
      this.setState({ lecturers, loading: false });
    });
  }

  componentDidMount() {
    this.getLecturers();

    //Function to listen to post course
    this.createLecturerListener = API.graphql(
      graphqlOperation(onCreateLecturer)
    ).subscribe({
      next: (lecturerData) => {
        const newLecturer = lecturerData.value.data.onCreateLecturer;
        const prevLecturer = this.state.lecturers.filter(
          (lecturer) => lecturer.id !== newLecturer.id
        );
        this.setState({
          lecturers: [newLecturer, ...prevLecturer],
        });
      },
    });

    //Function to listen to delete lecturers
    this.updateBatchGuidelinesListener = API.graphql(
      graphqlOperation(onUpdateLecturer)
    ).subscribe({
      next: (guidelineData) => {
        const updateLecturer = guidelineData.value.data.onUpdateLecturer;
        const index = this.state.lecturers.findIndex(
          (lecturer) => lecturer.id === updateLecturer.id
        );
        const updateLecturers = [
          ...this.state.lecturers.slice(0, index),
          updateLecturer,
          ...this.state.lecturers.slice(index + 1),
        ];
        this.setState({ lecturers: updateLecturers });
      },
    });

    //Function to listen to delete lecturers
    this.deleteBatchGuidelinesListener = API.graphql(
      graphqlOperation(onDeleteLecturer)
    ).subscribe({
      next: (guidelineData) => {
        const deleteLecturer = guidelineData.value.data.onDeleteLecturer;
        const deleteLecturers = this.state.lecturers.filter(
          (lecturer) => lecturer.id !== deleteLecturer.id
        );

        this.setState({ lecturers: deleteLecturers });
      },
    });
  }

  deleteLecturer(id, version) {
    API.graphql(
      graphqlOperation(deleteLecturer, { input: { id: id, _version: version } })
    ).then((deletedLecturer) => {
      console.log("Deleted Lecturer:", deletedLecturer);
    });
  }

  componentWillUnmount() {
    if (this.createLecturerListener !== undefined) {
      this.createLecturerListener.unsubscribe();
    }
    if (this.updateBatchGuidelinesListener !== undefined) {
      this.updateBatchGuidelinesListener.unsubscribe();
    }
    if (this.deleteBatchGuidelinesListener !== undefined) {
      this.deleteBatchGuidelinesListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditLecturer: false,
    });
  };

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  render() {
    const {
      lecturers,
      loading,
      openAddEditLecturer,
      openDeleteLecturer,
      deleteLecturerName,
      deleteLecturerID,
      deleteLectureVersion,
      addEditStatus,
      editItem,
    } = this.state;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditLecturer: true,
                addEditStatus: true,
              })
            }
            title={"Add Lecturer"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditLecturer}
          onHide={() => this.setState({ openAddEditLecturer: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Lecturer" : "Edit Lecturer"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddLecturer
              addEditStatus={addEditStatus}
              editingLecturer={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteLecturer}
          onHide={() => this.setState({ openDeleteLecturer: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteLecturerName} Profile
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure u want to delete the Lecturer?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.deleteLecturer(deleteLecturerID, deleteLectureVersion)
              }
            >
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteLecturer: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="Lecturers" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th></th>
                    <th>Name </th>
                    <th>Contact Info</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : lecturers.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Lecturers added Yet!</td>
                    </tr>
                  ) : (
                    lecturers.map((lecturer, i) => (
                      <tr key={lecturer.id}>
                        <td>{i + 1}</td>
                        <td>{lecturer.name}</td>
                        <td>
                          {lecturer.profile !== null ? (
                            <Button
                              className="btn btn-icon btn-light btn-sm"
                              onClick={() =>
                                Storage.get(lecturer.profile.key, {
                                  download: true,
                                }).then((res) => {
                                  console.log(res);
                                  this.downloadBlob(
                                    res.Body,
                                    lecturer.profile.key
                                  );
                                })
                              }
                            >
                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG
                                  style={{ color: "red" }}
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Files/Cloud-download.svg"
                                  )}
                                ></SVG>
                              </span>
                            </Button>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {" "}
                          <button className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-warning">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Settings-1.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: lecturer,
                                openAddEditLecturer: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() => {
                              this.setState({
                                deleteLecturerName: lecturer.name,
                                deleteLecturerID: lecturer.id,
                                deleteLectureVersion: lecturer._version,
                                openDeleteLecturer: true,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default ShowLecturers;
