import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { logoutUser } from "../../actions/auth";
import UpdateActivity from "../tools/updateActivity";

class Logout extends Component {
  componentDidMount() {
    this.props.logoutUser();
    var activityTime = new Date();
    UpdateActivity.updateActivity({
      activityItem: `Logout success`,
      tableName: "UserAccount",
      referenceID: `null`,
      remarks: "Logout Success",
      activityDateTime: activityTime.toISOString(),
    });
  }

  render() {
    const { isAuthenticated } = this.props;
    return isAuthenticated ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.reducerAuth.isAuthenticated,
});

export default connect(mapStateToProps, { logoutUser })(Logout);
