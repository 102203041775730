import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ExpansionPanel,
  Avatar,
  Typography,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { Modal } from "react-bootstrap";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";
import MessageTooBar from "./toolBar";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";
import AddMessage from "./addMessage";
import { listMessages } from "../../graphql/queries";
import { updateMessage } from "../../graphql/mutations";
import { onCreateMessage, onUpdateMessage } from "../../graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";
import UpdateActivity from "../tools/updateActivity";

export class MessageList extends Component {
  state = {
    openAddMessage: false,
    messages: [],
    currentMessages: "Received",
    newMessages: "Received",
    errorMessage: "",
  };

  updateMessageStatus(id, version) {
    var input = {
      id: id,
      status: "Read",
      _version: version,
    };
    API.graphql(graphqlOperation(updateMessage, { input: input })).then(
      (updating) => {
        var updatedMessage = updating.data.updateMessage;
        var activityTime = new Date();
        UpdateActivity.updateActivity({
          activityItem: `Message Read`,
          tableName: "Message",
          referenceID: updatedMessage.id,
          remarks: `Message Read send from ${updatedMessage.senderDetail.firstName} 
             to ${updatedMessage.receiverDetail.firstName}`,
          activityDateTime: activityTime.toISOString(),
        });
        this.setState({
          loading: false,
        });
      }
    );
  }

  getMessages(currentMessages, userId) {
    var messageFilter = {};
    if (currentMessages === "Received") {
      messageFilter = { receiver: { eq: userId } };
    } else {
      messageFilter = { sender: { eq: userId } };
    }
    API.graphql(
      graphqlOperation(listMessages, {
        filter: messageFilter,
      })
    ).then((result) => {
      var messages = result.data.listMessages.items;
      if (messages.length === 0) {
        this.setState({
          errorMessage: `No ${currentMessages} Messages Added yet!`,
        });
      }
      this.setState({
        messages,
        loading: false,
        newMessages: currentMessages,
        currentMessages: currentMessages,
      });
    });
  }

  componentDidMount() {
    const { user } = this.props;
    const { currentMessages } = this.state;
    this.getMessages("Received", user.id);

    var messageFilter = {};
    if (currentMessages === "Received") {
      messageFilter = { receiver: { eq: user.id } };
    } else {
      messageFilter = { sender: { eq: user.id } };
    }
    //Function to listen to create message
    this.createMessageListener = API.graphql(
      graphqlOperation(onCreateMessage, { filter: messageFilter })
    ).subscribe({
      next: (messageData) => {
        const newMessage = messageData.value.data.onCreateMessage;
        const prevMessages = this.state.messages.filter(
          (message) => message.id !== newMessage.id
        );
        this.setState({ messages: [newMessage, ...prevMessages] });
      },
    });

    //Function to listen to update messages
    this.updateMessageListener = API.graphql(
      graphqlOperation(onUpdateMessage, { filter: messageFilter })
    ).subscribe({
      next: (messageData) => {
        const updatedMessage = messageData.value.data.onUpdateMessage;
        const index = this.state.messages.findIndex(
          (message) => message.id === updatedMessage.id
        );
        const updatedMessages = [
          ...this.state.messages.slice(0, index),
          updatedMessage,
          ...this.state.messages.slice(index + 1),
        ];
        this.setState({ messages: updatedMessages });
      },
    });
  }

  componentDidUpdate() {
    const { newMessages, currentMessages } = this.state;
    if (newMessages !== currentMessages) {
      this.getMessages(currentMessages, this.props.user.id);
    }
  }

  onClickMessage(id, messageStatus, version) {
    if (
      this.state.currentMessages === "Received" &&
      messageStatus === "Created"
    ) {
      this.updateMessageStatus(id, version);
    }
  }

  componentWillUnmount() {
    if (this.createMessageListener !== undefined) {
      this.createMessageListener.unsubscribe();
    }
    if (this.updateMessageListener !== undefined) {
      this.updateMessageListener.unsubscribe();
    }
  }

  render() {
    const {
      openAddMessage,
      messages,
      errorMessage,
      currentMessages,
    } = this.state;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          {/* 
          <MessageTooBar
            iClass="fa fa-plus-circle"
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddMessage: true,
              })
            }
            title={"Send Message"}
          />*/}
          {currentMessages === "Received" ? (
            <MessageTooBar
              iClass="fa fa-toggle-off"
              showViewCode={true}
              openAdd={() =>
                this.setState({
                  currentMessages: "Send",
                })
              }
              title={"Send Messages"}
            />
          ) : (
            <MessageTooBar
              iClass="fa fa-toggle-on"
              showViewCode={true}
              openAdd={() =>
                this.setState({
                  currentMessages: "Received",
                })
              }
              title={"Received Messages"}
            />
          )}
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddMessage}
          onHide={() => this.setState({ openAddMessage: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Send Message
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddMessage
              closeForm={() => this.setState({ openAddMessage: false })}
            />
          </Modal.Body>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title={`${currentMessages} Messages`} toolbar={toolbar} />
          <CardBody>
            <div>
              <List>
                {messages.length !== 0
                  ? messages.map((message) => (
                      <>
                        <ListItem alignItems="flex-start" key={message.id}>
                          <ExpansionPanel
                            onClick={() => {
                              this.onClickMessage(
                                message.id,
                                message.status,
                                message._version
                              );
                            }}
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <ListItemAvatar>
                                <Badge
                                  color="secondary"
                                  variant="dot"
                                  invisible={message.status !== "Created"}
                                >
                                  <Avatar
                                    style={{
                                      backgroundColor: "#c9f7f5",
                                      color: "#21c7bf",
                                    }}
                                  >
                                    <i
                                      style={{
                                        color: "#21c7bf",
                                      }}
                                      className={
                                        message.status === "Created"
                                          ? "fa fa-envelope"
                                          : "fa fa-envelope-open-o"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </Avatar>
                                </Badge>
                              </ListItemAvatar>
                              <ListItemText
                                className="font-weight-bold"
                                primary={message.text.substring(0, 15)}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {" "}
                                      {currentMessages === "Send" ? (
                                        <>
                                          To: {message.receiverDetail.firstName}
                                          {message.receiverDetail.lastName}{" "}
                                        </>
                                      ) : (
                                        <>
                                          {message.senderDetail.firstName}
                                          {message.senderDetail.lastName}
                                        </>
                                      )}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              {message.text}
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </>
                    ))
                  : errorMessage}
              </List>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.reducerAuth.user,
  userType: state.reducerAuth.userType,
});

export default connect(mapStateToProps, null)(MessageList);
