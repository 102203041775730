import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listContentMaterialss } from "../../../../graphql/queries";
import { deleteContentMaterials } from "../../../../graphql/mutations";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditContent from "./addEditContent";
import {
  onCreateContentMaterials,
  onDeleteContentMaterials,
  onUpdateContentMaterials,
} from "../../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class batchContents extends Component {
  state = {
    contents: [],
    contentNextToken: null,
    loading: true,
    message: null,
    openAddContent: false,
    deleteContentName: null,
    deleteContentID: null,
    openDeleteContent: false,
    editItem: null,
    addEditStatus: true,
  };

  getContents() {
    const { contents, contentNextToken } = this.state;
    API.graphql(
      graphqlOperation(listContentMaterialss, {
        limit: 100,
        nextToken: contentNextToken,
      })
    ).then((result) => {
      var newContents = result.data.listContentMaterialss.items.filter(
        (content) =>
          content.batchConnection.includes(this.props.batchDetails.id)
      );
      if (newContents.length === 0) {
        this.setState({
          message: "No Contents Added yet!",
        });
      }
      this.setState({
        contents: [...contents, ...newContents],
        contentNextToken: result.data.listContentMaterialss.nextToken,
      });

      if (result.data.listContentMaterialss.nextToken !== null) {
        this.getContents();
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  componentDidMount() {
    this.getContents();

    //Function to listen to post subject
    this.createContentListener = API.graphql(
      graphqlOperation(onCreateContentMaterials)
    ).subscribe({
      next: (subjectData) => {
        const newContent = subjectData.value.data.onCreateContentMaterials;
        const prevContents = this.state.contents.filter(
          (subject) => subject.id !== newContent.id
        );
        this.setState({ contents: [newContent, ...prevContents] });
      },
    });

    //Function to listen to update contents
    this.updateContentListener = API.graphql(
      graphqlOperation(onUpdateContentMaterials)
    ).subscribe({
      next: (contentData) => {
        const updateContent = contentData.value.data.onUpdateContentMaterials;
        const index = this.state.contents.findIndex(
          (content) => content.id === updateContent.id
        );
        const updateContents = [
          ...this.state.contents.slice(0, index),
          updateContent,
          ...this.state.contents.slice(index + 1),
        ];
        this.setState({ contents: updateContents });
      },
    });

    //Function to listen to delete contents
    this.deleteContentListener = API.graphql(
      graphqlOperation(onDeleteContentMaterials)
    ).subscribe({
      next: (contentData) => {
        const deleteContent = contentData.value.data.onDeleteContentMaterials;
        const content = this.state.contents.filter(
          (content) => content.id !== deleteContent.id
        );
        this.setState({ contents: content });
      },
    });
  }

  deleteContent(id, version) {
    API.graphql(
      graphqlOperation(deleteContentMaterials, {
        input: { id: id, _version: version },
      })
    ).then((deletedContent) => {
      this.setState({ openDeleteContent: false });
    });
  }

  componentWillUnmount() {
    if (this.createContentListener !== undefined) {
      this.createContentListener.unsubscribe();
    }
    if (this.updateContentListener !== undefined) {
      this.updateContentListener.unsubscribe();
    }
    if (this.deleteContentListener !== undefined) {
      this.deleteContentListener.unsubscribe();
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditContent: false,
    });
  };

  render() {
    const {
      contents,
      loading,
      openAddEditContent,
      deleteContentID,
      deleteContentName,
      deletedVersion,
      openDeleteContent,
      addEditStatus,
      editItem,
    } = this.state;
    const { batchDetails } = this.props;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditContent: true,
                addEditStatus: true,
              })
            }
            title={"Add Course Material"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditContent}
          onHide={() => this.setState({ openAddEditContent: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Content" : "Edit Content"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditContent
              batchDetails={batchDetails}
              addEditStatus={addEditStatus}
              editingContent={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteContent}
          onHide={() => this.setState({ openDeleteContent: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete "{deleteContentName}"?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure u want to delete the Course Material?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.deleteContent(deleteContentID, deletedVersion)
              }
            >
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteContent: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="Course Material" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Course Material </th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : contents.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Course Material added Yet!</td>
                    </tr>
                  ) : (
                    contents.map((courseMaterial, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td
                          style={{
                            textDecoration: courseMaterial._deleted
                              ? "line-through"
                              : "",
                          }}
                        >
                          {courseMaterial.contentFiles !== null
                            ? courseMaterial.contentFiles.map((file) => (
                                <p key={file.key}>
                                  {file.fileName}{" "}
                                  <button
                                    className="btn btn-icon btn-light btn-sm"
                                    onClick={() =>
                                      Storage.get(file.key, {
                                        download: true,
                                      }).then((res) => {
                                        this.downloadBlob(res.Body, file.key);
                                      })
                                    }
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                      <SVG
                                        style={{ color: "red" }}
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Files/Cloud-download.svg"
                                        )}
                                      ></SVG>
                                    </span>
                                  </button>
                                </p>
                              ))
                            : " "}
                        </td>
                        <td
                          style={{
                            textDecoration: courseMaterial._deleted
                              ? "line-through"
                              : "",
                          }}
                        >
                          {courseMaterial.subjectName !== null
                            ? courseMaterial.subjectName.subjectName
                            : "Invalid Subject"}
                        </td>
                        <td>
                          {" "}
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            disabled={courseMaterial._deleted}
                            onClick={() => {
                              this.setState({
                                editItem: courseMaterial,
                                openAddEditContent: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            style={{ color: "red" }}
                            disabled={courseMaterial._deleted}
                            onClick={() => {
                              this.setState({
                                deleteContentName: courseMaterial.contentTitle,
                                deleteContentID: courseMaterial.id,
                                deletedVersion: courseMaterial._version,
                                openDeleteContent: true,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default batchContents;
