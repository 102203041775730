import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listContentMaterialss } from "../../graphql/queries";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";
import { Table } from "react-bootstrap";
import {
  onCreateContentMaterials,
  onUpdateContentMaterials,
} from "../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class studentContents extends Component {
  state = {
    contents: [],
    contentNextToken: null,
    loading: true,
    message: null,
  };

  getContents() {
    var requiredContents = [...this.state.contents];
    API.graphql(
      graphqlOperation(listContentMaterialss, {
        limit: 100,
        nextToken: this.state.contentNextToken,
      })
    ).then((result) => {
      var contents = result.data.listContentMaterialss.items;
      if (contents.length !== 0) {
        contents = contents.filter((content) => content._deleted !== true);
      }
      var collectedBatches = this.props.batchCollection;
      var today = new Date();
      collectedBatches.map((batch) => {
        var end_date = today;
        var start_date = new Date(batch.start_date);
        if (batch.end_date !== null && batch.end_date !== "null") {
          end_date = new Date(batch.end_date);
        }
        end_date.setDate(end_date.getDate() + 1);
        contents.map((content) => {
          if (
            content.createdAt >= start_date.toISOString() &&
            content.createdAt <= end_date.toISOString() &&
            content.batchConnection.includes(batch.batchId) &&
            requiredContents.filter(
              (testingContent) => testingContent.id === content.id
            ).length === 0
          ) {
            requiredContents.push(content);
          }
        });
        this.setState({
          contents: requiredContents,
          contentNextToken: result.data.listContentMaterialss.nextToken,
          loading: false,
        });
      });
      if (result.data.listContentMaterialss.nextToken !== null) {
        this.getContents();
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  componentDidMount() {
    var today = new Date();
    today.setDate(today.getDate() + 1);
    if (this.props.batchID !== null) {
      //required date format "2019-01-27T00:00:00"
      if (this.props.batchCollection !== null) {
        var filterQuery = {};
        var collectedBatches = this.props.batchCollection;
        var orQuery = [];
        collectedBatches.map((batch) => {
          var andQuery = [];
          var endDate = "";
          if (batch.end_date === "null") {
            endDate = today.toISOString();
          } else {
            endDate = batch.end_date;
          }
          andQuery.push({
            createdAt: {
              between: [batch.start_date, endDate],
            },
          });

          andQuery.push({
            batchConnection: {
              contains: batch.batchId,
            },
          });
          orQuery.push({ and: andQuery });
          return null;
        });
      }
      filterQuery["or"] = orQuery;
      this.getContents();
    } else {
      this.setState({
        message: null,
        loading: false,
      });
    }

    //Function to listen to post subject
    this.createContentListener = API.graphql(
      graphqlOperation(onCreateContentMaterials, {
        filter: {
          batchConnection: { contains: this.props.batchID },
        },
      })
    ).subscribe({
      next: (subjectData) => {
        const newContent = subjectData.value.data.onCreateContentMaterials;
        const prevContents = this.state.contents.filter(
          (subject) => subject.id !== newContent.id
        );
        this.setState({ contents: [newContent, ...prevContents] });
      },
    });

    //Function to listen to update contents
    this.updateContentListener = API.graphql(
      graphqlOperation(onUpdateContentMaterials)
    ).subscribe({
      next: (contentData) => {
        const updateContent = contentData.value.data.onUpdateContentMaterials;
        const index = this.state.contents.findIndex(
          (content) => content.id === updateContent.id
        );
        const updateContents = [
          ...this.state.contents.slice(0, index),
          updateContent,
          ...this.state.contents.slice(index + 1),
        ];
        this.setState({ contents: updateContents });
      },
    });
  }

  componentWillUnmount() {
    if (this.createContentListener !== undefined) {
      this.createContentListener.unsubscribe();
    }
    if (this.updateContentListener !== undefined) {
      this.updateContentListener.unsubscribe();
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditContent: false,
    });
  };

  render() {
    const { contents, loading, message } = this.state;
    return (
      <>
        <Card className="example example-compact">
          <CardHeader title="Course Material" />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Course Material </th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {message === null ? (
                    loading ? (
                      <tr>
                        <td colSpan="7" className="container">
                          {" "}
                          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                          <span className="sr-only">Loading...</span>{" "}
                        </td>
                      </tr>
                    ) : contents.length === 0 ? (
                      <tr>
                        <td colSpan="7">No Course Material added Yet!</td>
                      </tr>
                    ) : (
                      contents.map((courseMaterial, i) => (
                        <tr key={courseMaterial.id}>
                          <td>{i + 1}</td>
                          <td>{courseMaterial.contentTitle}</td>
                          <td>
                            {courseMaterial.subjectName !== null
                              ? courseMaterial.subjectName.subjectName
                              : "Invalid Subject"}
                          </td>
                          <td>
                            {courseMaterial.contentFiles !== null
                              ? courseMaterial.contentFiles.map((file, i) => (
                                  <p key={i}>
                                    {file.fileName}{" "}
                                    <button
                                      className="btn btn-icon btn-light btn-sm"
                                      onClick={() =>
                                        Storage.get(file.key, {
                                          download: true,
                                        }).then((res) => {
                                          this.downloadBlob(res.Body, file.key);
                                        })
                                      }
                                    >
                                      <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG
                                          style={{ color: "red" }}
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/Files/Cloud-download.svg"
                                          )}
                                        ></SVG>
                                      </span>
                                    </button>
                                  </p>
                                ))
                              : " "}
                          </td>
                        </tr>
                      ))
                    )
                  ) : (
                    <tr>
                      <td colSpan="7">{message}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default studentContents;
