import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { LOGIN_SUCCESS } from "../../actions/types";
import { listUserAccounts } from "../../graphql/queries";
import UpdateActivity from "../tools/updateActivity";

function NewPassword(props) {
  const dispatch = useDispatch();
  var initialValues = {
    newPassword: "",
    phone_number: "",
  };
  var userInfo = null;

  if (props.location.state.user) {
    userInfo = props.location.state.user;
  }
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [isRested, setIsRested] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  const ResetCodeSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetCodeSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //Reset Password function
      enableLoading();
      Auth.completeNewPassword(userInfo, values.newPassword, {
        phone_number: values.phone_number,
      })
        .then((user) => {
          console.log("-->>>", user);
          var userfailedTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `New Password: ${values.email}`,
            tableName: "UserAccount",
            referenceID: `null`,
            remarks: "User Password updated failed",
            activityDateTime: userfailedTime.toISOString(),
          });
          API.graphql(
            graphqlOperation(listUserAccounts, {
              filter: {
                accountUserID: { eq: user.username },
              },
            })
          )
            .then((result) => {
              var userAccount = result.data.listUserAccounts.items;
              console.log(userAccount.length);
              if (userAccount.length === 0) {
                console.log("User Not Available");
                dispatch({
                  type: "AUTH_ERROR",
                });
                var userfailedTime = new Date();
                UpdateActivity.updateActivity({
                  activityItem: `User DB not found: ${values.email}`,
                  tableName: "UserAccount",
                  referenceID: `null`,
                  remarks: "User not found in DB",
                  activityDateTime: userfailedTime.toISOString(),
                });
                props.login();
                disableLoading();
                setSubmitting(true);
              } else {
                console.log("User Found");
                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: userAccount[0],
                });
              }
            })
            .catch(() => {
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
              var userfailedTime = new Date();
              UpdateActivity.updateActivity({
                activityItem: `Invalid Login: ${values.email}`,
                tableName: "UserAccount",
                referenceID: `null`,
                remarks: "User Password updated failed",
                activityDateTime: userfailedTime.toISOString(),
              });
            });

          setIsRested(true);
        })
        .catch((err) => {
          console.log(err);
          setStatus(err.message);
          var userfailedTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Invalid Login: ${values.email}`,
            tableName: "UserAccount",
            referenceID: `null`,
            remarks: err.message,
            activityDateTime: userfailedTime.toISOString(),
          });
          disableLoading();
        });
    },
  });

  return (
    <>
      {!isRested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Update your Password</h3>
            <div className="text-muted font-weight-bold">
              Change the default password here!
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="+9XXXXXXXXXXX"
                type="string"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "phone_number"
                )}`}
                name="phone_number"
                {...formik.getFieldProps("phone_number")}
              />
              {formik.touched.phone_number && formik.errors.phone_number ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.phone_number}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                onMouseEnter={() => setViewPassword(true)}
                onMouseLeave={() => setViewPassword(false)}
                placeholder="Password"
                type={viewPassword ? "string" : "password"}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "newPassword"
                )}`}
                name="newPassword"
                {...formik.getFieldProps("newPassword")}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.newPassword}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <span>Login</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, null)(NewPassword));
