import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import { listSubjects, listBatchs } from "../../../../graphql/queries";
import {
  createAssignment,
  updateAssignment,
} from "../../../../graphql/mutations";
import Switch from "@material-ui/core/Switch";
import awsExports from "../../../../aws-exports";
import ReactSelect from "../../../tools/reactMultiSelect";
import UpdateActivity from "../../../tools/updateActivity";

export class addBatchAssignments extends Component {
  state = {
    subjects: [],
    assignmentFile: null,
    relatedBatches: [],
    addedBatches: [
      {
        key: this.props.batchDetails.id,
        label: this.props.batchDetails.batchTitle,
        value: this.props.batchDetails.id,
      },
    ],
    assignmentName: "",
    finalSubmissionDate: null,
    editAssignmentID: null,
    subjectID: null,
    multipleSubmission: false,
    userId: "",
    userName: "",
    loading: false,
    version: null,
    assignmentNameError: false,
    assignmentBatchError: false,
    assignmentSubjectError: false,
    assignmentFileError: false,
    relatedBatchNextToken: null,
    subjectNextToken: null,
  };

  getSubjects() {
    const { subjectNextToken } = this.state;
    API.graphql(
      graphqlOperation(listSubjects, {
        limit: 100,
        nextToken: subjectNextToken,
      })
    ).then((result) => {
      var subjects = result.data.listSubjects.items.filter(
        (subject) =>
          subject.courseID === this.props.batchDetails.courseID &&
          subject.specializationID === this.props.batchDetails.specializationID
      );
      subjects = subjects.filter((subject) => subject._deleted !== true);
      if (subjects.length === 0) {
        this.setState({
          message: "No Subjects Added yet!",
        });
      }
      var allSubjects = [...this.state.subjects, ...subjects];
      this.setState({
        subjects: allSubjects,
        subjectNextToken: result.data.listSubjects.nextToken,
        loading: false,
      });
      if (result.data.listSubjects.nextToken !== null) {
        this.getSubjects();
      }
    });
  }

  getRelatedBatches() {
    const { relatedBatchNextToken, relatedBatches } = this.state;
    API.graphql(
      graphqlOperation(listBatchs, {
        limit: 100,
        nextToken: relatedBatchNextToken,
      })
    ).then((result) => {
      var batches = result.data.listBatchs.items;
      if (batches.length === 0) {
        this.setState({
          batchMessage: "No Similar Batches yet!",
        });
      } else {
        var batchSuggestions = [];
        batches.map((batch) => {
          batchSuggestions.push({
            key: batch.id,
            label: batch.batchTitle,
            value: batch.id,
          });
          return null;
        });
        this.setState({
          relatedBatches: [...relatedBatches, ...batchSuggestions],
          relatedBatchNextToken: result.data.listBatchs.nextToken,
          loading: false,
        });

        if (result.data.listBatchs.nextToken !== null) {
          this.getRelatedBatches();
        }
      }
    });
  }

  componentDidMount() {
    const { addEditStatus, editingAssignment } = this.props;
    this.getSubjects();
    this.getRelatedBatches();
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });

    if (!addEditStatus) {
      this.setState({
        assignmentName: editingAssignment.assignmentTitle,
        subjectID: editingAssignment.subjectID,
        editAssignmentID: editingAssignment.id,
        multipleSubmission: editingAssignment.multipleSubmission,
        finalSubmissionDate: editingAssignment.finalSubmissionDate,
        version: editingAssignment._version,
      });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onChangeFile = (e) => this.setState({ [e.target.name]: e.target.files[0] });
  onSelectBatch(value) {
    this.setState({
      addedBatches: value,
    });
  }

  onSubmit = () => {
    const {
      assignmentName,
      addedBatches,
      finalSubmissionDate,
      assignmentFile,
      subjectID,
      multipleSubmission,
      userId,
      editAssignmentID,
      version,
      userName,
    } = this.state;
    var valueMissing = false;
    var listOfBatches = [];
    if (addedBatches !== null) {
      addedBatches.map((batch) => {
        listOfBatches.push(batch.value);
        return null;
      });
    }
    var input = {};
    var file_name = "",
      unique_filename = "";

    if (this.props.addEditStatus) {
      if (
        assignmentName !== "" &&
        assignmentFile !== null &&
        subjectID !== null &&
        listOfBatches.length !== 0
      ) {
        file_name = assignmentFile.name.split(".");
        unique_filename =
          file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
        Storage.put(unique_filename, assignmentFile).then((fileDetails) => {
          input = {
            assignmentTitle: assignmentName,
            subjectID: subjectID,
            courseID: this.props.batchDetails.courseID,
            batchID: listOfBatches,
            multipleSubmission: multipleSubmission,
            batchCode: this.props.batchDetails.id,
            finalSubmissionDate: finalSubmissionDate,
            originalContent: {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: fileDetails.key,
            },
            createdByUserID: userId,
          };
          API.graphql(graphqlOperation(createAssignment, { input: input }))
            .then((createdAssignment) => {
              console.log(createdAssignment);
              this.setState({
                loading: false,
              });
              var activityTime = new Date();
              UpdateActivity.updateActivity({
                activityItem: `Batch Assignment Created`,
                tableName: "Assignment",
                referenceID: createdAssignment.data.createAssignment.id,
                remarks: `Assignment ${createdAssignment.data.createAssignment.assignmentTitle} updated by ${userName}`,
                activityDateTime: activityTime.toISOString(),
              });
              this.props.closeForm();
            })
            .catch((err) => {
              console.log(err);
              Storage.remove(unique_filename).then(() => {
                console.log("Upload removed due to failed DB insert!");
              });
            });
        });
      } else {
        valueMissing = true;
      }
    } else {
      input = {
        id: editAssignmentID,
        assignmentTitle: assignmentName,
        finalSubmissionDate: finalSubmissionDate,
        multipleSubmission: multipleSubmission,
        _version: version,
      };
      var uploadedFileKey = null;
      if (assignmentFile !== null) {
        file_name = assignmentFile.name.split(".");
        unique_filename =
          file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
        Storage.put(unique_filename, assignmentFile).then((uploadedContent) => {
          uploadedFileKey = uploadedContent.key;
        });
        input["originalContent"] = {
          bucket: awsExports.aws_user_files_s3_bucket,
          region: awsExports.aws_user_files_s3_bucket_region,
          key: uploadedFileKey,
        };
      }
      API.graphql(graphqlOperation(updateAssignment, { input: input })).then(
        (updatedAssignment) => {
          this.setState({
            loading: false,
          });

          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Assignment Updated`,
            tableName: "Assignment",
            referenceID: updatedAssignment.data.updateAssignment.id,
            remarks: `Assignment ${updatedAssignment.data.updateAssignment.assignmentTitle} updated by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.props.closeForm();
        }
      );
    }
    if (valueMissing) {
      var nameMissing = false,
        batchMissing = false,
        subjectMissing = false,
        submissionDateMissing = false,
        fileMissing = false;
      if (assignmentName === "") {
        nameMissing = true;
      }
      if (assignmentFile === null) {
        fileMissing = true;
      }
      if (subjectID === null) {
        subjectMissing = true;
      }
      if (listOfBatches.length === 0) {
        batchMissing = true;
      }

      if (finalSubmissionDate === null) {
        submissionDateMissing = true;
      }
      this.setState({
        loading: false,
        assignmentNameError: nameMissing,
        assignmentBatchError: batchMissing,
        assignmentSubjectError: subjectMissing,
        assignmentFileError: fileMissing,
        finalSubmissionDateError: submissionDateMissing,
      });
    }
  };

  selectStudent(value) {
    this.setState({ studentID: value.key });
  }

  render() {
    const {
      subjects,
      assignmentName,
      finalSubmissionDate,
      loading,
      relatedBatches,
      addedBatches,
      assignmentNameError,
      assignmentBatchError,
      subjectID,
      multipleSubmission,
      assignmentSubjectError,
      assignmentFileError,
      finalSubmissionDateError,
    } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Assignment Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Assignment Name"
              name="assignmentName"
              value={assignmentName}
              onChange={this.onChange}
            />
            {assignmentNameError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">File name missing!</div>
              </div>
            ) : null}
          </Form.Group>
          <ReactSelect
            label="Batches"
            disableStatus={!this.props.addEditStatus}
            suggestions={relatedBatches}
            addedSuggestions={addedBatches}
            addBatch={(value) => this.onSelectBatch(value)}
          />
          {assignmentBatchError ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">Select at least One batch</div>
            </div>
          ) : null}{" "}
          <br />
          <br />
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              as="select"
              name="subjectID"
              disabled={!this.props.addEditStatus}
              value={subjectID}
              onChange={this.onChange}
            >
              <option value={null}>Select A Subject</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.subjectName}
                </option>
              ))}
            </Form.Control>
            {assignmentSubjectError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Please select a Subject</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Assignment File</Form.Label>
            <Form.Control
              type="file"
              name="assignmentFile"
              onChange={this.onChangeFile}
            />
            {assignmentFileError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">File Missing</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Submission Date</Form.Label>
            <Form.Control
              type="date"
              name="finalSubmissionDate"
              value={finalSubmissionDate}
              onChange={this.onChange}
            />
            {finalSubmissionDateError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Submission Date missing!</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Allow Multiple Submission</Form.Label>

            <Switch
              checked={multipleSubmission}
              onChange={() =>
                this.setState({ multipleSubmission: !multipleSubmission })
              }
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addBatchAssignments;
