import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

import SVG from "react-inlinesvg";

export default function studentRow({
  user,
  showUserProfile,
  editBatch,
  sendMail,
}) {
  if (user.typeOfAccount === "Student") {
    return (
      <tr>
        <td className="pl-0 py-8">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50 symbol-light mr-4">
              <span className="symbol-label">
                <SVG
                  className="h-75 align-self-end"
                  src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                ></SVG>
              </span>
            </div>
            <div>
              <p
                className={
                  user.Status !== "Disabled"
                    ? "text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    : "text-muted font-weight-bold d-block text-hover-danger mb-1 font-size-lg"
                }
              >
                {user.officeID}
                {user.Status === "Disabled" ? (
                  <i
                    style={{ color: "red" }}
                    className="fa fa-times"
                    aria-hidden="true"
                  ></i>
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {user.firstName} {user.lastName}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {user.email}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {user.contactPhone}
          </span>
        </td>

        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {user.batchId !== null ? (
              <Link to={"/batchMain/" + user.activeBatch.id}>
                {user.activeBatch.batchTitle}
              </Link>
            ) : (
              "No Active Batch"
            )}
            {"    "}
            {user.typeOfAccount === "Student" ? (
              <span
                style={{ margin: "2px" }}
                onClick={() => editBatch()}
                className="btn btn-icon btn-primary btn-sm"
                aria-hidden="true"
              >
                <i className="btn fa fa-pencil" aria-hidden="true"></i>
              </span>
            ) : (
              ""
            )}
          </span>
        </td>
        <td className="pr-0 text-right">
          <span
            style={{ margin: "2px" }}
            onClick={() => {
              showUserProfile(user);
              console.log(user);
            }}
            className="btn btn-icon btn-primary btn-sm"
          >
            <i className="fa fa-eye" aria-hidden="true"></i>
          </span>
          {user.sendInvitationMail !== true ? (
            <span
              style={{ margin: "2px" }}
              onClick={() => sendMail(user)}
              className="btn btn-icon btn-success btn-sm"
            >
              <i className="fa fa-envelope-o" aria-hidden="true"></i>
            </span>
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  } else if (
    user.typeOfAccount === "Administrator" ||
    user.typeOfAccount === "Examiner" ||
    user.typeOfAccount === "Accounts" ||
    user.typeOfAccount === "Dean"
  ) {
    return (
      <tr>
        <td className="pl-0 py-8">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50 symbol-light mr-4">
              <span className="symbol-label">
                <SVG
                  className="h-75 align-self-end"
                  src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                ></SVG>
              </span>
            </div>
            <div>
              <p className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {user.firstName} {user.lastName}
              </p>
              <span className="text-muted font-weight-bold d-block">
                {user.officeID}
              </span>
            </div>
          </div>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {user.email}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {user.designation}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {user.contactPhone}
          </span>
        </td>
        <td className="pr-0 text-center">
          <span
            style={{ margin: "2px" }}
            onClick={() => {
              showUserProfile(user);
              console.log(user);
            }}
            className="btn btn-icon btn-primary btn-sm"
          >
            <i className="fa fa-eye" aria-hidden="true"></i>
          </span>
        </td>
      </tr>
    );
  }
}
