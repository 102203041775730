import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { createSubject, updateSubject } from "../../../../graphql/mutations";
import UpdateActivity from "../../../tools/updateActivity";

export class addSubject extends Component {
  state = {
    subjectName: "",
    subjectDescription: "",
    editSubjectID: null,
    userId: "",
    userName: "",
    loading: false,
    version: null,
  };

  componentDidMount() {
    const { addEditStatus, editingSubject } = this.props;
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!addEditStatus) {
      this.setState({
        subjectName: editingSubject.subjectName,
        subjectDescription: editingSubject.subjectDescription,
        editSubjectID: editingSubject.id,
        version: editingSubject._version,
      });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = () => {
    const {
      subjectName,
      subjectDescription,
      userId,
      editSubjectID,
      version,
      userName,
    } = this.state;
    const { batchDetails } = this.props;
    console.log(subjectName, subjectDescription, batchDetails);
    var input = {};
    if (this.props.addEditStatus) {
      input = {
        subjectName: subjectName,
        courseID: batchDetails.courseID,
        specializationID: batchDetails.specializationID,
        subjectDescription: subjectDescription,
        createdByUserID: userId,
      };
      API.graphql(graphqlOperation(createSubject, { input: input })).then(
        (createdSubject) => {
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Subject Created`,
            tableName: "Subject",
            referenceID: createdSubject.data.createSubject.id,
            remarks: `Subject created by${createdSubject.data.createSubject.subjectName} created by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        }
      );
    } else {
      input = {
        id: editSubjectID,
        subjectName: subjectName,
        courseID: batchDetails.courseID,
        specializationID: batchDetails.specializationID,
        subjectDescription: subjectDescription,
        createdByUserID: userId,
        _version: version,
      };
      API.graphql(graphqlOperation(updateSubject, { input: input })).then(
        (updatedSubject) => {
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Subject Updated`,
            tableName: "Subject",
            referenceID: updatedSubject.data.updateSubject.id,
            remarks: `Subject Updated by ${updatedSubject.data.updateSubject.subjectName} created by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        }
      );
    }
  };

  render() {
    const { subjectDescription, subjectName, loading } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Subject Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="BBA"
              name="subjectName"
              value={subjectName}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Subject Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              name="subjectDescription"
              value={subjectDescription}
              onChange={this.onChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addSubject;
