/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncCourses = /* GraphQL */ `
  query SyncCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCourses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      courseName
      courseDescription
      qualifySubjects
      deniedUsers
      textBooks {
        items {
          id
          title
          courseID
          subject
          description
          deniedUsers
          downloadCount
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTextBooks = /* GraphQL */ `
  query SyncTextBooks(
    $filter: ModelTextBookFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTextBooks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        courseID
        course {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subject
        description
        textBookMaterial {
          bucket
          region
          key
        }
        deniedUsers
        downloadCount
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTextBook = /* GraphQL */ `
  query GetTextBook($id: ID!) {
    getTextBook(id: $id) {
      id
      title
      courseID
      course {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subject
      description
      textBookMaterial {
        bucket
        region
        key
      }
      deniedUsers
      downloadCount
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTextBooks = /* GraphQL */ `
  query ListTextBooks(
    $filter: ModelTextBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTextBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        courseID
        course {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subject
        description
        textBookMaterial {
          bucket
          region
          key
        }
        deniedUsers
        downloadCount
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      locationName
      locationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSpecializations = /* GraphQL */ `
  query SyncSpecializations(
    $filter: ModelSpecializationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSpecializations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSpecialization = /* GraphQL */ `
  query GetSpecialization($id: ID!) {
    getSpecialization(id: $id) {
      id
      specializationName
      specializationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSpecializations = /* GraphQL */ `
  query ListSpecializations(
    $filter: ModelSpecializationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecializations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubjects = /* GraphQL */ `
  query SyncSubjects(
    $filter: ModelSubjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubject = /* GraphQL */ `
  query GetSubject($id: ID!) {
    getSubject(id: $id) {
      id
      subjectName
      subjectDescription
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      subjectSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSubjects = /* GraphQL */ `
  query ListSubjects(
    $filter: ModelSubjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBatchGuidelines = /* GraphQL */ `
  query SyncBatchGuidelines(
    $filter: ModelBatchGuidelinesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBatchGuidelines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBatchGuidelines = /* GraphQL */ `
  query GetBatchGuidelines($id: ID!) {
    getBatchGuidelines(id: $id) {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBatchGuideliness = /* GraphQL */ `
  query ListBatchGuideliness(
    $filter: ModelBatchGuidelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatchGuideliness(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBatchDocuments = /* GraphQL */ `
  query SyncBatchDocuments(
    $filter: ModelBatchDocumentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBatchDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBatchDocuments = /* GraphQL */ `
  query GetBatchDocuments($id: ID!) {
    getBatchDocuments(id: $id) {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBatchDocumentss = /* GraphQL */ `
  query ListBatchDocumentss(
    $filter: ModelBatchDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatchDocumentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBatches = /* GraphQL */ `
  query SyncBatches(
    $filter: ModelBatchFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBatches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBatch = /* GraphQL */ `
  query GetBatch($id: ID!) {
    getBatch(id: $id) {
      id
      batchTitle
      batchIconName
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchType
      courseID
      scheduleDocument {
        bucket
        region
        key
      }
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchGuidelinesID
      attendances {
        items {
          id
          title
          batchID
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchGuidelines {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchDocuments
      studentCount
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBatchs = /* GraphQL */ `
  query ListBatchs(
    $filter: ModelBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttendances = /* GraphQL */ `
  query SyncAttendances(
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttendances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceFile {
          bucket
          region
          key
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttendance = /* GraphQL */ `
  query GetAttendance($id: ID!) {
    getAttendance(id: $id) {
      id
      title
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceFile {
        bucket
        region
        key
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAttendances = /* GraphQL */ `
  query ListAttendances(
    $filter: ModelAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceFile {
          bucket
          region
          key
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttendanceEntries = /* GraphQL */ `
  query SyncAttendanceEntries(
    $filter: ModelAttendanceEntryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttendanceEntries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        attendanceID
        attendance {
          id
          title
          batchID
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceDate
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttendanceEntry = /* GraphQL */ `
  query GetAttendanceEntry($id: ID!) {
    getAttendanceEntry(id: $id) {
      id
      attendanceID
      attendance {
        id
        title
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceFile {
          bucket
          region
          key
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceDate
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAttendanceEntrys = /* GraphQL */ `
  query ListAttendanceEntrys(
    $filter: ModelAttendanceEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendanceEntrys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attendanceID
        attendance {
          id
          title
          batchID
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceDate
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCourseSchedules = /* GraphQL */ `
  query SyncCourseSchedules(
    $filter: ModelCourseScheduleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCourseSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        startDate
        endDate
        batchConnection
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCourseSchedule = /* GraphQL */ `
  query GetCourseSchedule($id: ID!) {
    getCourseSchedule(id: $id) {
      id
      title
      startDate
      endDate
      batchConnection
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCourseSchedules = /* GraphQL */ `
  query ListCourseSchedules(
    $filter: ModelCourseScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        startDate
        endDate
        batchConnection
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContentMaterials = /* GraphQL */ `
  query SyncContentMaterials(
    $filter: ModelContentMaterialsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContentMaterials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        contentTitle
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        typeOfContent
        batchConnection
        Content_fileName
        Content_file {
          bucket
          region
          key
        }
        contentFiles {
          fileName
          bucket
          region
          key
          status
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContentMaterials = /* GraphQL */ `
  query GetContentMaterials($id: ID!) {
    getContentMaterials(id: $id) {
      id
      contentTitle
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      typeOfContent
      batchConnection
      Content_fileName
      Content_file {
        bucket
        region
        key
      }
      contentFiles {
        fileName
        bucket
        region
        key
        status
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContentMaterialss = /* GraphQL */ `
  query ListContentMaterialss(
    $filter: ModelContentMaterialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentMaterialss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentTitle
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        typeOfContent
        batchConnection
        Content_fileName
        Content_file {
          bucket
          region
          key
        }
        contentFiles {
          fileName
          bucket
          region
          key
          status
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncActivityLogs = /* GraphQL */ `
  query SyncActivityLogs(
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActivityLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        activityItem
        tableName
        referenceID
        remarks
        activityDateTime
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getActivityLog = /* GraphQL */ `
  query GetActivityLog($id: ID!) {
    getActivityLog(id: $id) {
      id
      activityItem
      tableName
      referenceID
      remarks
      activityDateTime
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listActivityLogs = /* GraphQL */ `
  query ListActivityLogs(
    $filter: ModelActivityLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activityItem
        tableName
        referenceID
        remarks
        activityDateTime
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserAccounts = /* GraphQL */ `
  query SyncUserAccounts(
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserAccount = /* GraphQL */ `
  query GetUserAccount($id: ID!) {
    getUserAccount(id: $id) {
      id
      accountUserID
      officeID
      firstName
      lastName
      company
      qualification
      designation
      occupation
      language
      timeZone
      website
      DOJ
      birthCountry
      birthPlace
      email
      emailPersonal
      contactPhone
      contactPersonal
      DOR
      address {
        addressLine
        city
        state
        postCode
      }
      social {
        linkedIn
        facebook
        twitter
        instagram
      }
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      activeBatch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchInformation {
        batchId
        start_date
        end_date
        status
      }
      icon_image {
        bucket
        region
        key
      }
      communication {
        email
        sms
        phone
      }
      groupNotification
      personalNotification
      emailNotification
      Status
      sendInvitationMail
      accessExpiry
      typeOfAccount
      studentReport
      degreeID
      degree {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        certificateFile {
          bucket
          region
          key
        }
        applicationStatus
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserAccounts = /* GraphQL */ `
  query ListUserAccounts(
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAssignments = /* GraphQL */ `
  query SyncAssignments(
    $filter: ModelAssignmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAssignments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        assignmentTitle
        studentID
        userID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batchCode
        multipleSubmission
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAssignment = /* GraphQL */ `
  query GetAssignment($id: ID!) {
    getAssignment(id: $id) {
      id
      assignmentTitle
      studentID
      userID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      courseID
      assignmentCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batchCode
      multipleSubmission
      finalSubmissionDate
      originalContent {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAssignments = /* GraphQL */ `
  query ListAssignments(
    $filter: ModelAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assignmentTitle
        studentID
        userID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batchCode
        multipleSubmission
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAssignmentSubmissions = /* GraphQL */ `
  query SyncAssignmentSubmissions(
    $filter: ModelAssignmentSubmissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAssignmentSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        assignmentID
        assignmentName {
          id
          assignmentTitle
          studentID
          userID
          courseID
          subjectID
          batchID
          batchCode
          multipleSubmission
          finalSubmissionDate
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        studentID
        studentName
        submissionDate
        assignmentFile {
          bucket
          region
          key
        }
        remarks
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAssignmentSubmission = /* GraphQL */ `
  query GetAssignmentSubmission($id: ID!) {
    getAssignmentSubmission(id: $id) {
      id
      assignmentID
      assignmentName {
        id
        assignmentTitle
        studentID
        userID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batchCode
        multipleSubmission
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      studentID
      studentName
      submissionDate
      assignmentFile {
        bucket
        region
        key
      }
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAssignmentSubmissions = /* GraphQL */ `
  query ListAssignmentSubmissions(
    $filter: ModelAssignmentSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssignmentSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignmentID
        assignmentName {
          id
          assignmentTitle
          studentID
          userID
          courseID
          subjectID
          batchID
          batchCode
          multipleSubmission
          finalSubmissionDate
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        studentID
        studentName
        submissionDate
        assignmentFile {
          bucket
          region
          key
        }
        remarks
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSelfStudentContents = /* GraphQL */ `
  query SyncSelfStudentContents(
    $filter: ModelSelfStudentContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSelfStudentContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        SelfContent_title
        batchID
        subjectID
        contentSubject {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentID
        studentDetails {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFile {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSelfStudentContent = /* GraphQL */ `
  query GetSelfStudentContent($id: ID!) {
    getSelfStudentContent(id: $id) {
      id
      SelfContent_title
      batchID
      subjectID
      contentSubject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      studentDetails {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSelfStudentContents = /* GraphQL */ `
  query ListSelfStudentContents(
    $filter: ModelSelfStudentContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSelfStudentContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        SelfContent_title
        batchID
        subjectID
        contentSubject {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentID
        studentDetails {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFile {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBulkMails = /* GraphQL */ `
  query SyncBulkMails(
    $filter: ModelBulkMailFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBulkMails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mailTitle
        batchID
        receiverMail
        senderMail
        deniedUsers
        status
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBulkMail = /* GraphQL */ `
  query GetBulkMail($id: ID!) {
    getBulkMail(id: $id) {
      id
      mailTitle
      batchID
      receiverMail
      senderMail
      deniedUsers
      status
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBulkMails = /* GraphQL */ `
  query ListBulkMails(
    $filter: ModelBulkMailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBulkMails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mailTitle
        batchID
        receiverMail
        senderMail
        deniedUsers
        status
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSemesters = /* GraphQL */ `
  query SyncSemesters(
    $filter: ModelSemesterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSemesters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        semesterTitle
        testString
        batchID
        batchName {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSemester = /* GraphQL */ `
  query GetSemester($id: ID!) {
    getSemester(id: $id) {
      id
      semesterTitle
      testString
      batchID
      batchName {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSemesters = /* GraphQL */ `
  query ListSemesters(
    $filter: ModelSemesterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSemesters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        semesterTitle
        testString
        batchID
        batchName {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReportCards = /* GraphQL */ `
  query SyncReportCards(
    $filter: ModelReportCardFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReportCards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReportCard = /* GraphQL */ `
  query GetReportCard($id: ID!) {
    getReportCard(id: $id) {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentNameID
      gradesCount
      grades {
        items {
          id
          reportCardID
          searchString
          Mark
          Grade
          status
          subjectID
          Remarks
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReportCards = /* GraphQL */ `
  query ListReportCards(
    $filter: ModelReportCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGrades = /* GraphQL */ `
  query SyncGrades(
    $filter: ModelGradesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGrades(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        reportCardID
        reportCard {
          id
          StudentID
          batchID
          studentNameID
          gradesCount
          status
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        searchString
        Mark
        Grade
        status
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Remarks
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGrades = /* GraphQL */ `
  query GetGrades($id: ID!) {
    getGrades(id: $id) {
      id
      reportCardID
      reportCard {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      Mark
      Grade
      status
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGradess = /* GraphQL */ `
  query ListGradess(
    $filter: ModelGradesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGradess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reportCardID
        reportCard {
          id
          StudentID
          batchID
          studentNameID
          gradesCount
          status
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        searchString
        Mark
        Grade
        status
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Remarks
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDegrees = /* GraphQL */ `
  query SyncDegrees(
    $filter: ModelDegreeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDegrees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        certificateFile {
          bucket
          region
          key
        }
        applicationStatus
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDegree = /* GraphQL */ `
  query GetDegree($id: ID!) {
    getDegree(id: $id) {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      certificateFile {
        bucket
        region
        key
      }
      applicationStatus
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDegrees = /* GraphQL */ `
  query ListDegrees(
    $filter: ModelDegreeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDegrees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        certificateFile {
          bucket
          region
          key
        }
        applicationStatus
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdDate
        type
        topic
        message
        UserAccountID
        givenByUser {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentID
        batchID
        status
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      createdDate
      type
      topic
      message
      UserAccountID
      givenByUser {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      batchID
      status
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdDate
        type
        topic
        message
        UserAccountID
        givenByUser {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentID
        batchID
        status
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLecturers = /* GraphQL */ `
  query SyncLecturers(
    $filter: ModelLecturerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLecturers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        image {
          bucket
          region
          key
        }
        profile {
          bucket
          region
          key
        }
        contactInfo {
          email
          phone
          availableTime
          preferredContact
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLecturer = /* GraphQL */ `
  query GetLecturer($id: ID!) {
    getLecturer(id: $id) {
      id
      name
      image {
        bucket
        region
        key
      }
      profile {
        bucket
        region
        key
      }
      contactInfo {
        email
        phone
        availableTime
        preferredContact
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLecturers = /* GraphQL */ `
  query ListLecturers(
    $filter: ModelLecturerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLecturers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image {
          bucket
          region
          key
        }
        profile {
          bucket
          region
          key
        }
        contactInfo {
          email
          phone
          availableTime
          preferredContact
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSchedules = /* GraphQL */ `
  query SyncSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        createdDate
        type
        batchConnection
        location
        lecturerID
        lecturer {
          id
          name
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subject {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDate
        endDate
        remarks
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($id: ID!) {
    getSchedule(id: $id) {
      id
      title
      createdDate
      type
      batchConnection
      location
      lecturerID
      lecturer {
        id
        name
        image {
          bucket
          region
          key
        }
        profile {
          bucket
          region
          key
        }
        contactInfo {
          email
          phone
          availableTime
          preferredContact
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDate
      endDate
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        createdDate
        type
        batchConnection
        location
        lecturerID
        lecturer {
          id
          name
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subject {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDate
        endDate
        remarks
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudyNotes = /* GraphQL */ `
  query SyncStudyNotes(
    $filter: ModelStudyNoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudyNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        noteTitle
        status
        notes {
          nextToken
          startedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudyNote = /* GraphQL */ `
  query GetStudyNote($id: ID!) {
    getStudyNote(id: $id) {
      id
      noteTitle
      status
      notes {
        items {
          id
          note
          status
          StudyNoteID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStudyNotes = /* GraphQL */ `
  query ListStudyNotes(
    $filter: ModelStudyNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudyNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        noteTitle
        status
        notes {
          nextToken
          startedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotes = /* GraphQL */ `
  query SyncNotes(
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        note
        status
        StudyNoteID
        studyNote {
          id
          noteTitle
          status
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotes = /* GraphQL */ `
  query GetNotes($id: ID!) {
    getNotes(id: $id) {
      id
      note
      status
      StudyNoteID
      studyNote {
        id
        noteTitle
        status
        notes {
          nextToken
          startedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotess = /* GraphQL */ `
  query ListNotess(
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        note
        status
        StudyNoteID
        studyNote {
          id
          noteTitle
          status
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFeePayments = /* GraphQL */ `
  query SyncFeePayments(
    $filter: ModelFeePaymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeePayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        feeTitle
        studentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        searchString
        paymentMode
        paidSubjects
        amount
        dueDate
        dateOfPayment
        status
        remarks
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFeePayment = /* GraphQL */ `
  query GetFeePayment($id: ID!) {
    getFeePayment(id: $id) {
      id
      feeTitle
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      paymentMode
      paidSubjects
      amount
      dueDate
      dateOfPayment
      status
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFeePayments = /* GraphQL */ `
  query ListFeePayments(
    $filter: ModelFeePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeePayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        feeTitle
        studentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        searchString
        paymentMode
        paidSubjects
        amount
        dueDate
        dateOfPayment
        status
        remarks
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        sender
        senderDetail {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        receiver
        receiverDetail {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        text
        status
        parentMessage
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      type
      sender
      senderDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      receiver
      receiverDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      text
      status
      parentMessage
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        sender
        senderDetail {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        receiver
        receiverDetail {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        text
        status
        parentMessage
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDissertationCollections = /* GraphQL */ `
  query SyncDissertationCollections(
    $filter: ModelDissertationCollectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDissertationCollections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        dissertations {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDissertationCollection = /* GraphQL */ `
  query GetDissertationCollection($id: ID!) {
    getDissertationCollection(id: $id) {
      id
      title
      description
      dissertations {
        items {
          id
          title
          collectionID
          studentID
          remarks
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDissertationCollections = /* GraphQL */ `
  query ListDissertationCollections(
    $filter: ModelDissertationCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDissertationCollections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        dissertations {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDissertations = /* GraphQL */ `
  query SyncDissertations(
    $filter: ModelDissertationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDissertations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        collectionID
        collection {
          id
          title
          description
          status
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFile {
          bucket
          region
          key
        }
        remarks
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDissertation = /* GraphQL */ `
  query GetDissertation($id: ID!) {
    getDissertation(id: $id) {
      id
      title
      collectionID
      collection {
        id
        title
        description
        dissertations {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDissertations = /* GraphQL */ `
  query ListDissertations(
    $filter: ModelDissertationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDissertations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        collectionID
        collection {
          id
          title
          description
          status
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        contentFile {
          bucket
          region
          key
        }
        remarks
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
