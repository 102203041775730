import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../_metronic/layout";
import Assignments from "../../components/Assignment/assignmentsDashboard";
import Content from "../../components/Content/contentDashboard";
import Grades from "../../components/Grades/gradesDashboard";
import Notifications from "../../components/Notifications/notificationsDashboard";
import Schedules from "../../components/Schedule/scheduleDashboard";
import Degree from "../../components/Degree/degreeDashboard";
import Messages from "../../components/messages/listMessages";
import FeePayment from "../../components/fees/listFeesPayment";
import Library from "../../components/ELibrary/studentLibrary";

const UserProfilepage = lazy(() =>
  import("../../components/UserProfile/UserProfilePage")
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={UserProfilepage} />
        <ContentRoute path="/messages" component={Messages} />
        <ContentRoute path="/assignments" component={Assignments} />
        <ContentRoute path="/coursematerial" component={Content} />
        <ContentRoute path="/grades" component={Grades} />
        <ContentRoute path="/degree" component={Degree} />
        <ContentRoute path="/notifications" component={Notifications} />
        <ContentRoute path="/schedules" component={Schedules} />
        <ContentRoute path="/fee-payments" component={FeePayment} />
        <ContentRoute path="/library" component={Library} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
