import React, { Component } from "react";
import { Tab, Row, Col, Nav, Modal } from "react-bootstrap";
import SourceAssignments from "./assignmentFile";
import AssignmentSubmissions from "./assignmentSubmission";
import { connect } from "react-redux";
import AddSubmission from "./addStudentSubmission";

export class assignmentDashboard extends Component {
  state = {
    openSubmission: false,
    sourceAssignment: null,
  };
  closeForm() {
    this.setState({
      openSubmission: false,
    });
  }
  render() {
    const { openSubmission, sourceAssignment } = this.state;
    return (
      <div className="card card-custom card-stretch pt-1 pl-1">
        <Modal
          size="lg"
          show={openSubmission}
          onHide={() => this.setState({ openSubmission: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Upload Assignment Submission
            </Modal.Title>
          </Modal.Header>
          <AddSubmission
            sourceAssignment={sourceAssignment}
            student={this.props.user}
            closeForm={() => this.setState({ openSubmission: false })}
          />
        </Modal>

        <Tab.Container id="left-tabs-example" defaultActiveKey="batch">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item className="pt-5 pl-2"></Nav.Item>
                <Nav.Item>
                  <Nav.Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="batch">Batch Assignments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="student">Student Assignments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="submissions">Your Submissions</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9} className="pl-0">
              <Tab.Content>
                <Tab.Pane eventKey="batch">
                  <SourceAssignments
                    key={"Batch Assignment"}
                    user={this.props.user}
                    batchID={this.props.user.batchId}
                    makeSubmission={(sourceAssignment) =>
                      this.setState({
                        sourceAssignment: sourceAssignment,
                        openSubmission: true,
                      })
                    }
                    batchCollection={this.props.user.batchInformation}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="student">
                  <SourceAssignments
                    key={"Student Assignment"}
                    user={this.props.user}
                    batchID={"Student Assignment"}
                    makeSubmission={(sourceAssignment) =>
                      this.setState({
                        sourceAssignment: sourceAssignment,
                        openSubmission: true,
                      })
                    }
                    batchCollection={this.props.user.batchInformation}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="submissions">
                  <AssignmentSubmissions studentID={this.props.user.id} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.reducerAuth.user,
});
export default connect(mapStateToProps, null)(assignmentDashboard);
