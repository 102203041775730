import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  listAssignments,
  listAssignmentSubmissions,
} from "../../graphql/queries";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";
import { Table, Modal, Button } from "react-bootstrap";
import {
  onCreateAssignment,
  onUpdateAssignment,
} from "../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class assignmentFile extends Component {
  state = {
    assignments: [],
    assignmentSubmissions: [],
    loading: true,
    message: null,
    openLateSubmission: false,
  };

  getAssignments() {
    const { assignmentNextToken, assignments } = this.state;
    API.graphql(
      graphqlOperation(listAssignments, {
        limit: 100,
        nextToken: assignmentNextToken,
      })
    ).then((result) => {
      var assignmentsNew = result.data.listAssignments.items;
      var requiredAssignments = [];
      if (this.props.batchID === "Student Assignment") {
        requiredAssignments = assignmentsNew.filter(
          (assignment) =>
            assignment.batchID.includes("Student Assignment") &&
            assignment.studentID === this.props.user.id
        );
      } else {
        var collectedBatches = this.props.batchCollection;
        var today = new Date();
        collectedBatches.map((batch) => {
          var end_date = today;
          var start_date = new Date(batch.start_date);
          if (batch.end_date !== null && batch.end_date !== "null") {
            end_date = new Date(batch.end_date);
          }
          end_date.setDate(end_date.getDate() + 1);
          assignmentsNew.map((assignment) => {
            if (
              assignment.createdAt >= start_date.toISOString() &&
              assignment._deleted !== true &&
              assignment.createdAt <= end_date.toISOString() &&
              assignment.batchID.includes(batch.batchId) &&
              requiredAssignments.filter(
                (testingAssignment) => testingAssignment.id === assignment.id
              ).length === 0
            ) {
              requiredAssignments.push(assignment);
            }
          });
        });
      }
      this.setState({
        assignments: [...requiredAssignments, ...assignments],
        assignmentNextToken: result.data.listAssignments.nextToken,
      });
      if (result.data.listAssignments.nextToken !== null) {
        this.getAssignments();
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  getSelfAssignments() {
    const { submissionNextToken, assignmentSubmissions } = this.state;
    API.graphql(
      graphqlOperation(listAssignmentSubmissions, {
        limit: 100,
        nextToke: submissionNextToken,
      })
    ).then((result) => {
      var assignmentSubmissionsNew = result.data.listAssignmentSubmissions.items.filter(
        (submission) => submission.studentID === this.props.user.id
      );
      var submittedAssignments = [];
      assignmentSubmissionsNew.map((submission) => {
        submittedAssignments.push(submission.assignmentID);
      });
      this.setState({
        assignmentSubmissions: [
          ...submittedAssignments,
          ...assignmentSubmissions,
        ],
        loading: false,
      });
    });
  }

  componentDidMount() {
    if (this.props.batchID !== null) {
      //required date format "2019-01-27T00:00:00"
      this.getAssignments();
      this.getSelfAssignments();
    } else {
      this.setState({
        message: null,
        loading: false,
      });
    }

    //Function to listen to post subject
    this.createAssignmentListener = API.graphql(
      graphqlOperation(onCreateAssignment, {
        filter: {
          batchConnection: { contains: this.props.batchID },
        },
      })
    ).subscribe({
      next: (subjectData) => {
        const newAssignment = subjectData.value.data.onCreateAssignment;
        const prevContents = this.state.assignments.filter(
          (subject) =>
            subject.id !== newAssignment.id &&
            subject.studentID === this.props.user.id
        );
        this.setState({ assignments: [newAssignment, ...prevContents] });
      },
    });

    //Function to listen to update assignments
    this.updateAssignmentListener = API.graphql(
      graphqlOperation(onUpdateAssignment)
    ).subscribe({
      next: (contentData) => {
        const updateAssignment = contentData.value.data.onUpdateAssignment;
        const index = this.state.assignments.findIndex(
          (content) => content.id === updateAssignment.id
        );
        const updateContents = [
          ...this.state.assignments.slice(0, index),
          updateAssignment,
          ...this.state.assignments.slice(index + 1),
        ];
        this.setState({ assignments: updateContents });
      },
    });
  }

  componentWillUnmount() {
    if (this.createAssignmentListener !== undefined) {
      this.createAssignmentListener.unsubscribe();
    }
    if (this.updateAssignmentListener !== undefined) {
      this.updateAssignmentListener.unsubscribe();
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditContent: false,
    });
  };

  render() {
    var today = new Date();

    const {
      assignments,
      assignmentSubmissions,
      loading,
      message,
      openLateSubmission,
      submissionAssignment,
    } = this.state;
    return (
      <>
        <Modal
          size="lg"
          show={openLateSubmission}
          onHide={() => this.setState({ openLateSubmission: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Late Submission
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            Your Submission is delayed! Your submission will be marked as
            extended or late!{" "}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ openLateSubmission: false });
                this.props.makeSubmission(submissionAssignment);
              }}
            >
              Submit
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openLateSubmission: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>
        <Card className="example example-compact">
          <CardHeader title="Assignments" />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Assignment </th>
                    <th>Subject</th>
                    <th>Submission Date</th>
                    <th>Created Date</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {message === null ? (
                    loading ? (
                      <tr>
                        <td colSpan="7" className="container">
                          {" "}
                          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                          <span className="sr-only">Loading...</span>{" "}
                        </td>
                      </tr>
                    ) : assignments.length === 0 ? (
                      <tr>
                        <td colSpan="7">No Assignments Added!</td>
                      </tr>
                    ) : (
                      assignments.map((assignment, i) => {
                        var submissionDate = new Date(
                          assignment.finalSubmissionDate
                        );
                        var isoDateTime = new Date(assignment.createdAt);

                        return (
                          <tr key={assignment.id}>
                            <td>{i + 1}</td>
                            <td>{assignment.assignmentTitle}</td>
                            <td>
                              {assignment.subjectName !== null
                                ? assignment.subjectName.subjectName
                                : "Invalid Subject"}
                            </td>
                            <td>{assignment.finalSubmissionDate}</td>
                            <td>
                              <span className=" font-weight-bold">
                                {isoDateTime.toLocaleDateString()} <br />{" "}
                                {isoDateTime.toLocaleTimeString()}
                              </span>
                            </td>
                            <td>
                              {" "}
                              <button
                                className="btn btn-icon btn-light btn-sm"
                                onClick={() =>
                                  Storage.get(assignment.originalContent.key, {
                                    download: true,
                                  }).then((res) => {
                                    this.downloadBlob(
                                      res.Body,
                                      assignment.originalContent.key
                                    );
                                  })
                                }
                              >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <SVG
                                    style={{ color: "red" }}
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Files/Cloud-download.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </button>{" "}
                              {assignment.multipleSubmission === true ? (
                                <button
                                  className="btn btn-icon btn-light btn-sm"
                                  onClick={() =>
                                    this.props.makeSubmission(assignment)
                                  }
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Files/Cloud-upload.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </button>
                              ) : today + 1 < submissionDate ? (
                                assignmentSubmissions.includes(
                                  assignment.id
                                ) ? (
                                  ""
                                ) : (
                                  <button
                                    className="btn btn-icon btn-light btn-sm"
                                    onClick={() =>
                                      this.props.makeSubmission(assignment)
                                    }
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Files/Cloud-upload.svg"
                                        )}
                                      ></SVG>
                                    </span>
                                  </button>
                                )
                              ) : (
                                <button
                                  className="btn btn-icon btn-light btn-sm"
                                  onClick={() =>
                                    this.props.makeSubmission(assignment)
                                  }
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Files/Cloud-upload.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </button>
                                /*
                                <button
                                  className="btn btn-icon btn-light btn-sm"
                                  onClick={() =>
                                    this.setState({
                                      submissionAssignment: assignment,
                                      openLateSubmission: true,
                                    })
                                  }
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Files/Cloud-upload.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </button>
                                */
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )
                  ) : (
                    <tr>
                      <td colSpan="7">{message}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default assignmentFile;
