import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { S3Image } from "aws-amplify-react";
import { Grid, Typography, Paper, ButtonBase } from "@material-ui/core";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/Card";
import AddEditGrade from "./addEditGrades";
import { API, graphqlOperation } from "aws-amplify";
import { listGradess } from "../../../../graphql/queries";
import { deleteGrades, updateReportCard } from "../../../../graphql/mutations";
import {
  onCreateGrades,
  onUpdateReportCard,
  onUpdateGrades,
  onDeleteGrades,
} from "../../../../graphql/subscriptions";
import { CodeBlockToolbarATMS } from "../../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";

export default class viewReportCard extends Component {
  state = {
    student: this.props.viewGrade.student,
    grades: this.props.viewGrade.grades.items,
    reportCard: this.props.viewGrade,
    addGrades: false,
    selectedCard: null,
    editingGrade: null,
    addEditGrade: true,
    gradeNextToken: null,
    loading: false,
  };

  getGrades() {
    this.setState({
      loading: true,
    });
    API.graphql(
      graphqlOperation(listGradess, {
        limit: 100,
        nextToken: this.state.gradeNextToken,
      })
    ).then((result) => {
      var grades = result.data.listGradess.items.filter(
        (grade) => grade.reportCardID === this.props.viewGrade.id
      );
      if (grades.length === 0) {
        this.setState({
          message: "No Grades Added yet!",
        });
      }
      var newGrades = [...this.state.grades, ...grades];
      this.setState({
        grades: newGrades,
        gradeNextToken: result.data.listGradess.nextToken,
      });

      if (result.data.listGradess.nextToken !== null) {
        this.getGrades();
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  componentDidMount() {
    //Function to listen to post subject
    this.createGradeLister = API.graphql(
      graphqlOperation(onCreateGrades)
    ).subscribe({
      next: (createdGradeData) => {
        const newGrade = createdGradeData.value.data.onCreateGrades;
        if (newGrade.reportCardID === this.state.reportCard.id) {
          const allGrades = this.state.grades;
          const prevGrades = allGrades.filter(
            (grade) => grade.id !== newGrade.id
          );
          this.setState({ grades: [newGrade, ...prevGrades] });
        }
      },
    });

    //Function to listen to update grades
    this.updateGradeListner = API.graphql(
      graphqlOperation(onUpdateGrades)
    ).subscribe({
      next: (updateGradeData) => {
        const updatedGrade = updateGradeData.value.data.onUpdateGrades;
        if (updatedGrade.reportCardID === this.state.reportCard.id) {
          const allGrades = this.state.grades;
          const index = allGrades.findIndex(
            (grade) => grade.id === updatedGrade.id
          );
          const updatedGrades = [
            ...this.state.grades.slice(0, index),
            updatedGrade,
            ...this.state.grades.slice(index + 1),
          ];
          this.setState({ grades: updatedGrades });
        }
      },
    });

    //Function to listen to post subject
    this.deleteGrade = API.graphql(graphqlOperation(onDeleteGrades)).subscribe({
      next: (deletedGradeData) => {
        console.log(deletedGradeData);
        const deletedGrade = deletedGradeData.value.data.onDeleteGrades;
        if (deletedGrade.reportCardID === this.state.reportCard.id) {
          const allGrades = this.state.grades;
          const prevGrades = allGrades.filter(
            (grade) => grade.id !== deletedGrade.id
          );
          this.setState({ grades: [...prevGrades] });
        }
      },
    });

    //Function to listen to update grades
    this.onUpdateReportCardListener = API.graphql(
      graphqlOperation(onUpdateReportCard)
    ).subscribe({
      next: (updatedReportData) => {
        console.log(updatedReportData);
        const updatedReport = updatedReportData.value.data.onUpdateReportCard;
        if (updatedReport.id === this.state.reportCard.id) {
          this.setState({ reportCard: updatedReport });
        }
      },
    });
  }

  deleteCurrentGrade(id, version) {
    API.graphql(
      graphqlOperation(deleteGrades, { input: { id: id, _version: version } })
    ).then((deletedContent) => {
      var gradeCount = 0;
      if (this.props.viewGrade.gradesCount !== null) {
        gradeCount = this.props.viewGrade.gradesCount - 1;
      }

      API.graphql(
        graphqlOperation(updateReportCard, {
          input: {
            id: this.props.viewGrade.id,
            gradesCount: gradeCount,
            _version: this.props.viewGrade._version,
          },
        })
      ).then((updatedCard) => {
        console.log("Card updaed", updatedCard);
      });
      console.log("Deleted Grade:", deletedContent);
    });
  }

  componentWillUnmount() {
    if (this.createGradeLister !== undefined) {
      this.createGradeLister.unsubscribe();
    }
    if (this.updateGradeListner !== undefined) {
      this.updateGradeListner.unsubscribe();
    }
    if (this.onUpdateReportCardListener !== undefined) {
      this.onUpdateReportCardListener.unsubscribe();
    }
  }
  render() {
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                addGrades: true,
                addEditGrade: true,
                selectedCard: this.props.viewGrade,
              })
            }
            title={"Add Grade"}
          />
        </div>
      </div>
    );
    const {
      student,
      grades,
      addGrades,
      selectedCard,
      reportCard,
      editingGrade,
      addEditGrade,
      loading,
    } = this.state;
    return (
      <div>
        <Modal
          size="lg"
          show={addGrades}
          onHide={() => this.setState({ addGrades: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditGrade ? "New Grade" : "Edit Grade"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditGrade
              viewGrade={selectedCard}
              studentInfo={student}
              addEditGrade={addEditGrade}
              batchDetails={this.props.batchDetails}
              editingGrade={editingGrade}
              closeForm={() => this.setState({ addGrades: false })}
            />
          </Modal.Body>
        </Modal>

        <Paper style={{ padding: "2px", margin: "auto", maxWidth: "100%" }}>
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase style={{ width: 128, height: 128 }}>
                {student.icon_image !== null &&
                student.icon_image !== undefined ? (
                  <S3Image
                    imgKey={student.icon_image.key}
                    style={{
                      margin: "auto",
                      display: "block",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        "/media/users/300_21.jpg"
                      )}`,
                      margin: "auto",
                      display: "block",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  ></div>
                )}
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h5">
                    {student.firstName} {student.lastName}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {reportCard.batch.batchTitle}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    {student.officeID}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Card className="example example-compact">
          <CardHeader title="Grades" toolbar={toolbar} />
          <CardBody>
            {/* begin::Table */}
            <div className="table-responsive">
              <table className="table table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Subject </th>
                    <th style={{ textAlign: "center" }}>Grade</th>
                    <th style={{ textAlign: "center" }}>Marks</th>
                    <th style={{ textAlign: "center" }}>Remarks</th>
                    <th style={{ textAlign: "center" }}>Date/Time</th>
                    <th style={{ textAlign: "center" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : grades !== undefined ? (
                    grades.map((grade, i) => {
                      var isoDateTime = new Date(grade.createdAt);
                      if (!grade._deleted)
                        return (
                          <tr key={grade.id}>
                            <td style={{ textAlign: "center" }}>
                              <span className=" font-weight-bold">
                                {grade.subjectName !== null
                                  ? grade.subjectName.subjectName
                                  : "Invalid Subject"}
                              </span>
                            </td>
                            <td>
                              <span className=" font-weight-bold">
                                {grade.Grade}
                              </span>
                            </td>
                            <td>
                              <span className=" font-weight-bold">
                                {grade.Mark}
                              </span>
                            </td>
                            <td>
                              <span className=" font-weight-bold">
                                {grade.Remarks}
                              </span>
                            </td>
                            <td>
                              <span className=" font-weight-bold">
                                {isoDateTime.toLocaleDateString()} <br />{" "}
                                {isoDateTime.toLocaleTimeString()}
                              </span>
                            </td>

                            <td>
                              {" "}
                              <button
                                onClick={() => {
                                  this.setState({
                                    editingGrade: grade,
                                    selectedCard: this.props.viewGrade,
                                    addGrades: true,
                                    addEditGrade: false,
                                  });
                                }}
                                className="btn btn-icon btn-light btn-sm mx-3"
                              >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Communication/Write.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </button>
                              <button
                                className="btn btn-icon btn-light btn-sm mx-3"
                                onClick={() => {
                                  this.deleteCurrentGrade(
                                    grade.id,
                                    grade._version
                                  );
                                }}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Trash.svg"
                                    )}
                                  ></SVG>
                                </span>
                              </button>
                            </td>
                          </tr>
                        );
                      return null;
                    })
                  ) : (
                    <tr>
                      <td>
                        <span className="text-muted font-weight-bold">
                          "No grades added!"
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}
