import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listDegrees } from "../../../../graphql/queries";
import { Modal, Button } from "react-bootstrap";
import {
  onCreateDegree,
  onUpdateDegree,
} from "../../../../graphql/subscriptions";
import { deleteDegree, updateUserAccount } from "../../../../graphql/mutations";
import EnhancedTable from "../batchGrades/sampleTable";

export default class BatchDegree extends Component {
  state = {
    selectedObject: null,
    degrees: [],
    loading: true,
    message: null,
    openAddDegree: false,
    deleteDegreeName: null,
    deleteDegreeID: null,
    openDeleteDegree: false,
    editItem: null,
    addEditStatus: true,
    openGrades: false,
    viewGrade: null,
    selectedIconName: "006-plurk",
  };

  onIconChange = (e) => this.setState({ selectedIconName: e.target.value });

  getDegrees() {
    API.graphql(
      graphqlOperation(listDegrees, {
        filter: {
          and: [{ batchID: { eq: this.props.batchDetails.id } }],
        },
      })
    ).then((result) => {
      var degrees = result.data.listDegrees.items;
      if (degrees.length === 0) {
        this.setState({
          message: "No Degree Added yet!",
          loading: false,
        });
      } else {
        var notDeletedDegreeCards = degrees.filter(
          (degree) => degree._deleted !== true
        );
        this.setState({ degrees: notDeletedDegreeCards, loading: false });
      }
    });
  }

  componentDidMount() {
    this.getDegrees();

    //Function to listen to post batch
    this.createDegreeListener = API.graphql(
      graphqlOperation(onCreateDegree)
    ).subscribe({
      next: (cardData) => {
        const newDegree = cardData.value.data.onCreateDegree;
        const prevDegrees = this.state.degrees.filter(
          (report) => report.id !== newDegree.id
        );
        this.setState({ degrees: [newDegree, ...prevDegrees] });
      },
    });

    //Function to listen to update batches
    this.updateDegreeListener = API.graphql(
      graphqlOperation(onUpdateDegree)
    ).subscribe({
      next: (report) => {
        const updateDegree = report.value.data.onUpdateDegree;
        const index = this.state.degrees.findIndex(
          (batch) => batch.id === updateDegree.id
        );
        const updateDegrees = [
          ...this.state.degrees.slice(0, index),
          updateDegree,
          ...this.state.degrees.slice(index + 1),
        ];
        this.setState({ degrees: updateDegrees });
      },
    });
  }

  toggleRaised(value) {
    this.setState({
      selectedObject: value,
    });
  }

  selectRandomType(n) {
    var typesOfVariations = [
      "primary",
      "secondary",
      "danger",
      "success",
      "warning",
      "info",
    ];
    return typesOfVariations[n];
  }
  componentWillUnmount() {
    if (this.createDegreeListener !== undefined) {
      this.createDegreeListener.unsubscribe();
    }
    if (this.updateDegreeListener !== undefined) {
      this.updateDegreeListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditDegree: false,
    });
  };

  viewDegreeCard = (report) => {
    this.setState({
      viewGrade: report,
      openGrades: true,
    });
  };

  deleteDegree() {
    var id = this.state.deleteDegreeID;
    var version = this.state.deleteDegreeVersion;
    API.graphql(
      graphqlOperation(deleteDegree, {
        input: { id: id, _version: version },
      })
    ).then((result) => {
      console.log(result);
      this.setState({
        openDeleteDegree: false,
      });
    });
  }

  updateCard(degree) {
    console.log(degree);
    API.graphql(
      graphqlOperation(updateUserAccount, {
        input: {
          id: degree.student.id,
          _version: degree.student._version,
          studentDegree: degree.id,
        },
      })
    ).then((result) => {
      console.log("Successfull:", result);
    });
  }

  render() {
    const { degrees, deleteDegreeCardName, openDeleteDegree } = this.state;
    return (
      <>
        <Modal
          size="lg"
          show={openDeleteDegree}
          onHide={() => this.setState({ openDeleteDegree: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete Degree
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure u want to delete the {deleteDegreeCardName}'s Degree
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.deleteDegree()}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteDegree: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <EnhancedTable
              data={degrees}
              title="Degree"
              columnNames={["Student", "Degree", "Status"]}
              viewCard={(degree) => this.viewDegreeCard(degree)}
              deleteCard={(degree) =>
                this.setState({
                  openDeleteDegree: true,
                  deleteDegreeID: degree.id,
                  deleteDegreeCardName: degree.studentNameID,
                  deleteDegreeVersion: degree._version,
                })
              }
              addCard={() =>
                this.setState({
                  openAddEditDegree: true,
                  addEditStatus: true,
                })
              }
              message="No Degree  Yet!"
              updateCard={(degree) => this.updateCard(degree)}
            />
          </div>
        </div>
      </>
    );
  }
}
