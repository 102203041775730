import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { getBatch } from "../../../customgraphql/queries";
import BatchSubjects from "./batchSubjects/batchSubjects";
import BatchContents from "./batchContent/batchContent";
import Assignments from "./batchAssignments/assignments";
import BatchInformation from "./batchInfo";
import StudentsList from "./batchStudents/batchStudents";
import Schedule from "../../Schedule/scheduleDashboard";
import SelfStudy from "./batchSelfStudy/listStudyMaterial";
import Grades from "./batchGrades/listReportCards";
import Notifications from "./batchNotifications/dashboard";
import Degree from "./batchDegree/listDegree";
import Attendance from "./batchAttendance/listAttendance";

export class batchMain extends Component {
  state = {
    batchInformation: null,
    loading: false,
  };

  componentDidMount() {
    API.graphql(
      graphqlOperation(getBatch, { id: this.props.match.params.batchid })
    ).then((result) => {
      var batchDetails = result.data.getBatch;
      this.setState({ batchInformation: batchDetails, loading: false });
    });
  }
  render() {
    const { batchInformation } = this.state;
    return (
      <div className="card card-custom card-stretch pt-1 pl-1">
        {batchInformation === null ? (
          "Loading.."
        ) : (
          <Tab.Container id="left-tabs-example" defaultActiveKey="info">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item className="pt-5 pl-2">
                    {batchInformation.batchTitle}
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="info">Program Schedule</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="students">Students</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="announcements">Announcements</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="subjects">Subjects</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="content">Course Material</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="selfStudy">Self Study</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="assignment">Assignments</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="schedule">Schedule</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="attendance">Attendance</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="degree">Degree</Nav.Link>
                  </Nav.Item>
                  {["Administrator", "Dean"].indexOf(this.props.userType) ===
                  -1 ? (
                    <>
                      <Nav.Item>
                        <Nav.Link eventKey="grades">Grades</Nav.Link>
                      </Nav.Item>
                    </>
                  ) : null}
                </Nav>
              </Col>
              <Col sm={9} className="pl-0">
                <Tab.Content>
                  <Tab.Pane eventKey="info">
                    <BatchInformation
                      batchDetails={batchInformation}
                      className="card-stretch gutter-b"
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="students">
                    <StudentsList
                      batchDetails={batchInformation}
                      className="card-stretch gutter-b"
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="announcements">
                    <Notifications
                      batchDetails={batchInformation}
                      className="card-stretch gutter-b"
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="subjects">
                    <BatchSubjects batchDetails={batchInformation} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="content">
                    <BatchContents batchDetails={batchInformation} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="selfStudy">
                    <SelfStudy batchDetails={batchInformation} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="attendance">
                    <Attendance batchDetails={batchInformation} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="assignment">
                    <Assignments batchDetails={batchInformation} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="schedule">
                    <Schedule batchDetails={batchInformation} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="grades">
                    <Grades batchDetails={batchInformation} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="degree">
                    <Degree batchDetails={batchInformation} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userType: state.reducerAuth.userType,
});

export default connect(mapStateToProps, null)(batchMain);
