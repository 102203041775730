/* eslint-disable import/no-anonymous-default-export */
import { Auth, API, graphqlOperation } from "aws-amplify";
import { createNotification } from "../../graphql/mutations";

export default {
  updateNotification: (notificationInfo) => {
    var today = new Date();

    Auth.currentUserInfo().then((user) => {
      var input = {
        createdDate: today.toISOString(),
        type: notificationInfo.type,
        topic: notificationInfo.topic,
        message: notificationInfo.message,
        UserAccountID: notificationInfo.givenUserId,
        status: "New",
        createdByUserID: user.attributes.sub,
      };
      if (notificationInfo.notificationType === "Batch") {
        input["batchID"] = notificationInfo.targetID;
      } else {
        input["studentID"] = notificationInfo.targetID;
      }
      API.graphql(graphqlOperation(createNotification, { input: input })).then(
        (createdNotification) => {
          console.log("Notification Added");
        }
      );
    });
  },
};

/* how to use
import UpdateActivity from "../tools/updateNotification";
 
          UpdateNotification.updateNotification({
            notificationType: "Student",
            type: "Notification",
            topic: "New Grade",
            message: `A new Grade has been uploaded for ${subjectName}`,
            givenUserId: studentInfo.id,
          });
      */
