import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { API, graphqlOperation } from "aws-amplify";
import { listNotifications } from "../../graphql/queries";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Table, Modal, Button } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";

export class notificationDashboard extends Component {
  static propTypes = {
    userType: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
  };

  state = {
    notifications: [],
    notificationToken: null,
    loading: false,
    message: "",
    openMessage: false,
    selectedNotification: null,
  };

  getNotifications() {
    API.graphql(
      graphqlOperation(listNotifications, {
        limit: 100,
        nextToken: this.state.notificationToken,
      })
    ).then((result) => {
      console.log(result);
      var notifications = result.data.listNotifications.items.filter(
        (notification) => notification.UserAccountID === this.props.user.id
      );
      console.log(notifications);
      this.setState({
        notifications,
        loading: false,
        notificationToken: result.data.listNotifications.nextToken,
      });
      if (result.data.listNotifications.nextToken !== null) {
        this.getNotifications();
      }
    });
  }

  componentDidMount() {
    this.getNotifications();
  }

  render() {
    const {
      openMessage,
      selectedNotification,
      loading,
      notifications,
    } = this.state;
    return (
      <>
        <div>
          <Modal
            size="lg"
            show={openMessage}
            onHide={() => this.setState({ openMessage: false })}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            {selectedNotification !== null ? (
              <>
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    {selectedNotification.topic}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>{selectedNotification.message}</Modal.Body>
              </>
            ) : (
              ""
            )}
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => this.setState({ openMessage: false })}
              >
                Close{" "}
              </Button>
            </Modal.Footer>
          </Modal>

          <Card className="example example-compact">
            <CardHeader title="Notifications" />
            <CardBody>
              <div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title </th>
                      <th>Message</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        <span className="sr-only">Loading...</span>{" "}
                      </tr>
                    ) : notifications.length === 0 ? (
                      <tr>
                        <td colSpan="7">No Notifications added Yet!</td>
                      </tr>
                    ) : (
                      notifications.map((notification, i) => (
                        <tr key={notification.id}>
                          <td>
                            {notification.status === "New" ? (
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Notification1.svg"
                                  )}
                                ></SVG>
                              </span>
                            ) : (
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Notification2.svg"
                                  )}
                                ></SVG>
                              </span>
                            )}{" "}
                            {i + 1}
                          </td>
                          <td>{notification.topic}</td>
                          <td>{notification.message}</td>
                          <td>
                            <button
                              className="btn btn-icon btn-light btn-sm mx-3"
                              onClick={() => {
                                this.setState({
                                  selectedNotification: notification,
                                  openMessage: true,
                                });
                              }}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Communication/Write.svg"
                                  )}
                                ></SVG>
                              </span>
                              status
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userType: state.reducerAuth.userType,
  user: state.reducerAuth.user,
});
export default connect(mapStateToProps, null)(notificationDashboard);
