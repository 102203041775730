/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      courseName
      courseDescription
      qualifySubjects
      deniedUsers
      textBooks {
        items {
          id
          title
          courseID
          subject
          description
          deniedUsers
          downloadCount
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      courseName
      courseDescription
      qualifySubjects
      deniedUsers
      textBooks {
        items {
          id
          title
          courseID
          subject
          description
          deniedUsers
          downloadCount
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      courseName
      courseDescription
      qualifySubjects
      deniedUsers
      textBooks {
        items {
          id
          title
          courseID
          subject
          description
          deniedUsers
          downloadCount
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTextBook = /* GraphQL */ `
  mutation CreateTextBook(
    $input: CreateTextBookInput!
    $condition: ModelTextBookConditionInput
  ) {
    createTextBook(input: $input, condition: $condition) {
      id
      title
      courseID
      course {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subject
      description
      textBookMaterial {
        bucket
        region
        key
      }
      deniedUsers
      downloadCount
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTextBook = /* GraphQL */ `
  mutation UpdateTextBook(
    $input: UpdateTextBookInput!
    $condition: ModelTextBookConditionInput
  ) {
    updateTextBook(input: $input, condition: $condition) {
      id
      title
      courseID
      course {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subject
      description
      textBookMaterial {
        bucket
        region
        key
      }
      deniedUsers
      downloadCount
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTextBook = /* GraphQL */ `
  mutation DeleteTextBook(
    $input: DeleteTextBookInput!
    $condition: ModelTextBookConditionInput
  ) {
    deleteTextBook(input: $input, condition: $condition) {
      id
      title
      courseID
      course {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subject
      description
      textBookMaterial {
        bucket
        region
        key
      }
      deniedUsers
      downloadCount
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      locationName
      locationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      locationName
      locationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      locationName
      locationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSpecialization = /* GraphQL */ `
  mutation CreateSpecialization(
    $input: CreateSpecializationInput!
    $condition: ModelSpecializationConditionInput
  ) {
    createSpecialization(input: $input, condition: $condition) {
      id
      specializationName
      specializationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSpecialization = /* GraphQL */ `
  mutation UpdateSpecialization(
    $input: UpdateSpecializationInput!
    $condition: ModelSpecializationConditionInput
  ) {
    updateSpecialization(input: $input, condition: $condition) {
      id
      specializationName
      specializationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSpecialization = /* GraphQL */ `
  mutation DeleteSpecialization(
    $input: DeleteSpecializationInput!
    $condition: ModelSpecializationConditionInput
  ) {
    deleteSpecialization(input: $input, condition: $condition) {
      id
      specializationName
      specializationDescription
      deniedUsers
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSubject = /* GraphQL */ `
  mutation CreateSubject(
    $input: CreateSubjectInput!
    $condition: ModelSubjectConditionInput
  ) {
    createSubject(input: $input, condition: $condition) {
      id
      subjectName
      subjectDescription
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      subjectSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSubject = /* GraphQL */ `
  mutation UpdateSubject(
    $input: UpdateSubjectInput!
    $condition: ModelSubjectConditionInput
  ) {
    updateSubject(input: $input, condition: $condition) {
      id
      subjectName
      subjectDescription
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      subjectSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSubject = /* GraphQL */ `
  mutation DeleteSubject(
    $input: DeleteSubjectInput!
    $condition: ModelSubjectConditionInput
  ) {
    deleteSubject(input: $input, condition: $condition) {
      id
      subjectName
      subjectDescription
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      subjectSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBatchGuidelines = /* GraphQL */ `
  mutation CreateBatchGuidelines(
    $input: CreateBatchGuidelinesInput!
    $condition: ModelBatchGuidelinesConditionInput
  ) {
    createBatchGuidelines(input: $input, condition: $condition) {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBatchGuidelines = /* GraphQL */ `
  mutation UpdateBatchGuidelines(
    $input: UpdateBatchGuidelinesInput!
    $condition: ModelBatchGuidelinesConditionInput
  ) {
    updateBatchGuidelines(input: $input, condition: $condition) {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBatchGuidelines = /* GraphQL */ `
  mutation DeleteBatchGuidelines(
    $input: DeleteBatchGuidelinesInput!
    $condition: ModelBatchGuidelinesConditionInput
  ) {
    deleteBatchGuidelines(input: $input, condition: $condition) {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBatchDocuments = /* GraphQL */ `
  mutation CreateBatchDocuments(
    $input: CreateBatchDocumentsInput!
    $condition: ModelBatchDocumentsConditionInput
  ) {
    createBatchDocuments(input: $input, condition: $condition) {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBatchDocuments = /* GraphQL */ `
  mutation UpdateBatchDocuments(
    $input: UpdateBatchDocumentsInput!
    $condition: ModelBatchDocumentsConditionInput
  ) {
    updateBatchDocuments(input: $input, condition: $condition) {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBatchDocuments = /* GraphQL */ `
  mutation DeleteBatchDocuments(
    $input: DeleteBatchDocumentsInput!
    $condition: ModelBatchDocumentsConditionInput
  ) {
    deleteBatchDocuments(input: $input, condition: $condition) {
      id
      filename
      deniedUsers
      guidelinesFile {
        bucket
        region
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBatch = /* GraphQL */ `
  mutation CreateBatch(
    $input: CreateBatchInput!
    $condition: ModelBatchConditionInput
  ) {
    createBatch(input: $input, condition: $condition) {
      id
      batchTitle
      batchIconName
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchType
      courseID
      scheduleDocument {
        bucket
        region
        key
      }
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchGuidelinesID
      attendances {
        items {
          id
          title
          batchID
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchGuidelines {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchDocuments
      studentCount
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBatch = /* GraphQL */ `
  mutation UpdateBatch(
    $input: UpdateBatchInput!
    $condition: ModelBatchConditionInput
  ) {
    updateBatch(input: $input, condition: $condition) {
      id
      batchTitle
      batchIconName
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchType
      courseID
      scheduleDocument {
        bucket
        region
        key
      }
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchGuidelinesID
      attendances {
        items {
          id
          title
          batchID
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchGuidelines {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchDocuments
      studentCount
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBatch = /* GraphQL */ `
  mutation DeleteBatch(
    $input: DeleteBatchInput!
    $condition: ModelBatchConditionInput
  ) {
    deleteBatch(input: $input, condition: $condition) {
      id
      batchTitle
      batchIconName
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchType
      courseID
      scheduleDocument {
        bucket
        region
        key
      }
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchGuidelinesID
      attendances {
        items {
          id
          title
          batchID
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchGuidelines {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchDocuments
      studentCount
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAttendance = /* GraphQL */ `
  mutation CreateAttendance(
    $input: CreateAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    createAttendance(input: $input, condition: $condition) {
      id
      title
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceFile {
        bucket
        region
        key
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAttendance = /* GraphQL */ `
  mutation UpdateAttendance(
    $input: UpdateAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    updateAttendance(input: $input, condition: $condition) {
      id
      title
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceFile {
        bucket
        region
        key
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAttendance = /* GraphQL */ `
  mutation DeleteAttendance(
    $input: DeleteAttendanceInput!
    $condition: ModelAttendanceConditionInput
  ) {
    deleteAttendance(input: $input, condition: $condition) {
      id
      title
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceFile {
        bucket
        region
        key
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAttendanceEntry = /* GraphQL */ `
  mutation CreateAttendanceEntry(
    $input: CreateAttendanceEntryInput!
    $condition: ModelAttendanceEntryConditionInput
  ) {
    createAttendanceEntry(input: $input, condition: $condition) {
      id
      attendanceID
      attendance {
        id
        title
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceFile {
          bucket
          region
          key
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceDate
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAttendanceEntry = /* GraphQL */ `
  mutation UpdateAttendanceEntry(
    $input: UpdateAttendanceEntryInput!
    $condition: ModelAttendanceEntryConditionInput
  ) {
    updateAttendanceEntry(input: $input, condition: $condition) {
      id
      attendanceID
      attendance {
        id
        title
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceFile {
          bucket
          region
          key
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceDate
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAttendanceEntry = /* GraphQL */ `
  mutation DeleteAttendanceEntry(
    $input: DeleteAttendanceEntryInput!
    $condition: ModelAttendanceEntryConditionInput
  ) {
    deleteAttendanceEntry(input: $input, condition: $condition) {
      id
      attendanceID
      attendance {
        id
        title
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        attendanceFile {
          bucket
          region
          key
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      attendanceDate
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCourseSchedule = /* GraphQL */ `
  mutation CreateCourseSchedule(
    $input: CreateCourseScheduleInput!
    $condition: ModelCourseScheduleConditionInput
  ) {
    createCourseSchedule(input: $input, condition: $condition) {
      id
      title
      startDate
      endDate
      batchConnection
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCourseSchedule = /* GraphQL */ `
  mutation UpdateCourseSchedule(
    $input: UpdateCourseScheduleInput!
    $condition: ModelCourseScheduleConditionInput
  ) {
    updateCourseSchedule(input: $input, condition: $condition) {
      id
      title
      startDate
      endDate
      batchConnection
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCourseSchedule = /* GraphQL */ `
  mutation DeleteCourseSchedule(
    $input: DeleteCourseScheduleInput!
    $condition: ModelCourseScheduleConditionInput
  ) {
    deleteCourseSchedule(input: $input, condition: $condition) {
      id
      title
      startDate
      endDate
      batchConnection
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createContentMaterials = /* GraphQL */ `
  mutation CreateContentMaterials(
    $input: CreateContentMaterialsInput!
    $condition: ModelContentMaterialsConditionInput
  ) {
    createContentMaterials(input: $input, condition: $condition) {
      id
      contentTitle
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      typeOfContent
      batchConnection
      Content_fileName
      Content_file {
        bucket
        region
        key
      }
      contentFiles {
        fileName
        bucket
        region
        key
        status
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateContentMaterials = /* GraphQL */ `
  mutation UpdateContentMaterials(
    $input: UpdateContentMaterialsInput!
    $condition: ModelContentMaterialsConditionInput
  ) {
    updateContentMaterials(input: $input, condition: $condition) {
      id
      contentTitle
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      typeOfContent
      batchConnection
      Content_fileName
      Content_file {
        bucket
        region
        key
      }
      contentFiles {
        fileName
        bucket
        region
        key
        status
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteContentMaterials = /* GraphQL */ `
  mutation DeleteContentMaterials(
    $input: DeleteContentMaterialsInput!
    $condition: ModelContentMaterialsConditionInput
  ) {
    deleteContentMaterials(input: $input, condition: $condition) {
      id
      contentTitle
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      typeOfContent
      batchConnection
      Content_fileName
      Content_file {
        bucket
        region
        key
      }
      contentFiles {
        fileName
        bucket
        region
        key
        status
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createActivityLog = /* GraphQL */ `
  mutation CreateActivityLog(
    $input: CreateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    createActivityLog(input: $input, condition: $condition) {
      id
      activityItem
      tableName
      referenceID
      remarks
      activityDateTime
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateActivityLog = /* GraphQL */ `
  mutation UpdateActivityLog(
    $input: UpdateActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    updateActivityLog(input: $input, condition: $condition) {
      id
      activityItem
      tableName
      referenceID
      remarks
      activityDateTime
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteActivityLog = /* GraphQL */ `
  mutation DeleteActivityLog(
    $input: DeleteActivityLogInput!
    $condition: ModelActivityLogConditionInput
  ) {
    deleteActivityLog(input: $input, condition: $condition) {
      id
      activityItem
      tableName
      referenceID
      remarks
      activityDateTime
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserAccount = /* GraphQL */ `
  mutation CreateUserAccount(
    $input: CreateUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    createUserAccount(input: $input, condition: $condition) {
      id
      accountUserID
      officeID
      firstName
      lastName
      company
      qualification
      designation
      occupation
      language
      timeZone
      website
      DOJ
      birthCountry
      birthPlace
      email
      emailPersonal
      contactPhone
      contactPersonal
      DOR
      address {
        addressLine
        city
        state
        postCode
      }
      social {
        linkedIn
        facebook
        twitter
        instagram
      }
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      activeBatch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchInformation {
        batchId
        start_date
        end_date
        status
      }
      icon_image {
        bucket
        region
        key
      }
      communication {
        email
        sms
        phone
      }
      groupNotification
      personalNotification
      emailNotification
      Status
      sendInvitationMail
      accessExpiry
      typeOfAccount
      studentReport
      degreeID
      degree {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        certificateFile {
          bucket
          region
          key
        }
        applicationStatus
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserAccount = /* GraphQL */ `
  mutation UpdateUserAccount(
    $input: UpdateUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    updateUserAccount(input: $input, condition: $condition) {
      id
      accountUserID
      officeID
      firstName
      lastName
      company
      qualification
      designation
      occupation
      language
      timeZone
      website
      DOJ
      birthCountry
      birthPlace
      email
      emailPersonal
      contactPhone
      contactPersonal
      DOR
      address {
        addressLine
        city
        state
        postCode
      }
      social {
        linkedIn
        facebook
        twitter
        instagram
      }
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      activeBatch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchInformation {
        batchId
        start_date
        end_date
        status
      }
      icon_image {
        bucket
        region
        key
      }
      communication {
        email
        sms
        phone
      }
      groupNotification
      personalNotification
      emailNotification
      Status
      sendInvitationMail
      accessExpiry
      typeOfAccount
      studentReport
      degreeID
      degree {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        certificateFile {
          bucket
          region
          key
        }
        applicationStatus
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserAccount = /* GraphQL */ `
  mutation DeleteUserAccount(
    $input: DeleteUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    deleteUserAccount(input: $input, condition: $condition) {
      id
      accountUserID
      officeID
      firstName
      lastName
      company
      qualification
      designation
      occupation
      language
      timeZone
      website
      DOJ
      birthCountry
      birthPlace
      email
      emailPersonal
      contactPhone
      contactPersonal
      DOR
      address {
        addressLine
        city
        state
        postCode
      }
      social {
        linkedIn
        facebook
        twitter
        instagram
      }
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      activeBatch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchInformation {
        batchId
        start_date
        end_date
        status
      }
      icon_image {
        bucket
        region
        key
      }
      communication {
        email
        sms
        phone
      }
      groupNotification
      personalNotification
      emailNotification
      Status
      sendInvitationMail
      accessExpiry
      typeOfAccount
      studentReport
      degreeID
      degree {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        certificateFile {
          bucket
          region
          key
        }
        applicationStatus
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAssignment = /* GraphQL */ `
  mutation CreateAssignment(
    $input: CreateAssignmentInput!
    $condition: ModelAssignmentConditionInput
  ) {
    createAssignment(input: $input, condition: $condition) {
      id
      assignmentTitle
      studentID
      userID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      courseID
      assignmentCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batchCode
      multipleSubmission
      finalSubmissionDate
      originalContent {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAssignment = /* GraphQL */ `
  mutation UpdateAssignment(
    $input: UpdateAssignmentInput!
    $condition: ModelAssignmentConditionInput
  ) {
    updateAssignment(input: $input, condition: $condition) {
      id
      assignmentTitle
      studentID
      userID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      courseID
      assignmentCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batchCode
      multipleSubmission
      finalSubmissionDate
      originalContent {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAssignment = /* GraphQL */ `
  mutation DeleteAssignment(
    $input: DeleteAssignmentInput!
    $condition: ModelAssignmentConditionInput
  ) {
    deleteAssignment(input: $input, condition: $condition) {
      id
      assignmentTitle
      studentID
      userID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      courseID
      assignmentCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batchCode
      multipleSubmission
      finalSubmissionDate
      originalContent {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAssignmentSubmission = /* GraphQL */ `
  mutation CreateAssignmentSubmission(
    $input: CreateAssignmentSubmissionInput!
    $condition: ModelAssignmentSubmissionConditionInput
  ) {
    createAssignmentSubmission(input: $input, condition: $condition) {
      id
      assignmentID
      assignmentName {
        id
        assignmentTitle
        studentID
        userID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batchCode
        multipleSubmission
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      studentID
      studentName
      submissionDate
      assignmentFile {
        bucket
        region
        key
      }
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAssignmentSubmission = /* GraphQL */ `
  mutation UpdateAssignmentSubmission(
    $input: UpdateAssignmentSubmissionInput!
    $condition: ModelAssignmentSubmissionConditionInput
  ) {
    updateAssignmentSubmission(input: $input, condition: $condition) {
      id
      assignmentID
      assignmentName {
        id
        assignmentTitle
        studentID
        userID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batchCode
        multipleSubmission
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      studentID
      studentName
      submissionDate
      assignmentFile {
        bucket
        region
        key
      }
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAssignmentSubmission = /* GraphQL */ `
  mutation DeleteAssignmentSubmission(
    $input: DeleteAssignmentSubmissionInput!
    $condition: ModelAssignmentSubmissionConditionInput
  ) {
    deleteAssignmentSubmission(input: $input, condition: $condition) {
      id
      assignmentID
      assignmentName {
        id
        assignmentTitle
        studentID
        userID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batchCode
        multipleSubmission
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      studentID
      studentName
      submissionDate
      assignmentFile {
        bucket
        region
        key
      }
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSelfStudentContent = /* GraphQL */ `
  mutation CreateSelfStudentContent(
    $input: CreateSelfStudentContentInput!
    $condition: ModelSelfStudentContentConditionInput
  ) {
    createSelfStudentContent(input: $input, condition: $condition) {
      id
      SelfContent_title
      batchID
      subjectID
      contentSubject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      studentDetails {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSelfStudentContent = /* GraphQL */ `
  mutation UpdateSelfStudentContent(
    $input: UpdateSelfStudentContentInput!
    $condition: ModelSelfStudentContentConditionInput
  ) {
    updateSelfStudentContent(input: $input, condition: $condition) {
      id
      SelfContent_title
      batchID
      subjectID
      contentSubject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      studentDetails {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSelfStudentContent = /* GraphQL */ `
  mutation DeleteSelfStudentContent(
    $input: DeleteSelfStudentContentInput!
    $condition: ModelSelfStudentContentConditionInput
  ) {
    deleteSelfStudentContent(input: $input, condition: $condition) {
      id
      SelfContent_title
      batchID
      subjectID
      contentSubject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      studentDetails {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBulkMail = /* GraphQL */ `
  mutation CreateBulkMail(
    $input: CreateBulkMailInput!
    $condition: ModelBulkMailConditionInput
  ) {
    createBulkMail(input: $input, condition: $condition) {
      id
      mailTitle
      batchID
      receiverMail
      senderMail
      deniedUsers
      status
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateBulkMail = /* GraphQL */ `
  mutation UpdateBulkMail(
    $input: UpdateBulkMailInput!
    $condition: ModelBulkMailConditionInput
  ) {
    updateBulkMail(input: $input, condition: $condition) {
      id
      mailTitle
      batchID
      receiverMail
      senderMail
      deniedUsers
      status
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteBulkMail = /* GraphQL */ `
  mutation DeleteBulkMail(
    $input: DeleteBulkMailInput!
    $condition: ModelBulkMailConditionInput
  ) {
    deleteBulkMail(input: $input, condition: $condition) {
      id
      mailTitle
      batchID
      receiverMail
      senderMail
      deniedUsers
      status
      CreatedByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSemester = /* GraphQL */ `
  mutation CreateSemester(
    $input: CreateSemesterInput!
    $condition: ModelSemesterConditionInput
  ) {
    createSemester(input: $input, condition: $condition) {
      id
      semesterTitle
      testString
      batchID
      batchName {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSemester = /* GraphQL */ `
  mutation UpdateSemester(
    $input: UpdateSemesterInput!
    $condition: ModelSemesterConditionInput
  ) {
    updateSemester(input: $input, condition: $condition) {
      id
      semesterTitle
      testString
      batchID
      batchName {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSemester = /* GraphQL */ `
  mutation DeleteSemester(
    $input: DeleteSemesterInput!
    $condition: ModelSemesterConditionInput
  ) {
    deleteSemester(input: $input, condition: $condition) {
      id
      semesterTitle
      testString
      batchID
      batchName {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createReportCard = /* GraphQL */ `
  mutation CreateReportCard(
    $input: CreateReportCardInput!
    $condition: ModelReportCardConditionInput
  ) {
    createReportCard(input: $input, condition: $condition) {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentNameID
      gradesCount
      grades {
        items {
          id
          reportCardID
          searchString
          Mark
          Grade
          status
          subjectID
          Remarks
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateReportCard = /* GraphQL */ `
  mutation UpdateReportCard(
    $input: UpdateReportCardInput!
    $condition: ModelReportCardConditionInput
  ) {
    updateReportCard(input: $input, condition: $condition) {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentNameID
      gradesCount
      grades {
        items {
          id
          reportCardID
          searchString
          Mark
          Grade
          status
          subjectID
          Remarks
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteReportCard = /* GraphQL */ `
  mutation DeleteReportCard(
    $input: DeleteReportCardInput!
    $condition: ModelReportCardConditionInput
  ) {
    deleteReportCard(input: $input, condition: $condition) {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        attendances {
          nextToken
          startedAt
        }
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentNameID
      gradesCount
      grades {
        items {
          id
          reportCardID
          searchString
          Mark
          Grade
          status
          subjectID
          Remarks
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createGrades = /* GraphQL */ `
  mutation CreateGrades(
    $input: CreateGradesInput!
    $condition: ModelGradesConditionInput
  ) {
    createGrades(input: $input, condition: $condition) {
      id
      reportCardID
      reportCard {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      Mark
      Grade
      status
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateGrades = /* GraphQL */ `
  mutation UpdateGrades(
    $input: UpdateGradesInput!
    $condition: ModelGradesConditionInput
  ) {
    updateGrades(input: $input, condition: $condition) {
      id
      reportCardID
      reportCard {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      Mark
      Grade
      status
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteGrades = /* GraphQL */ `
  mutation DeleteGrades(
    $input: DeleteGradesInput!
    $condition: ModelGradesConditionInput
  ) {
    deleteGrades(input: $input, condition: $condition) {
      id
      reportCardID
      reportCard {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      Mark
      Grade
      status
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDegree = /* GraphQL */ `
  mutation CreateDegree(
    $input: CreateDegreeInput!
    $condition: ModelDegreeConditionInput
  ) {
    createDegree(input: $input, condition: $condition) {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      certificateFile {
        bucket
        region
        key
      }
      applicationStatus
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDegree = /* GraphQL */ `
  mutation UpdateDegree(
    $input: UpdateDegreeInput!
    $condition: ModelDegreeConditionInput
  ) {
    updateDegree(input: $input, condition: $condition) {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      certificateFile {
        bucket
        region
        key
      }
      applicationStatus
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDegree = /* GraphQL */ `
  mutation DeleteDegree(
    $input: DeleteDegreeInput!
    $condition: ModelDegreeConditionInput
  ) {
    deleteDegree(input: $input, condition: $condition) {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      certificateFile {
        bucket
        region
        key
      }
      applicationStatus
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      createdDate
      type
      topic
      message
      UserAccountID
      givenByUser {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      batchID
      status
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      createdDate
      type
      topic
      message
      UserAccountID
      givenByUser {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      batchID
      status
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      createdDate
      type
      topic
      message
      UserAccountID
      givenByUser {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      batchID
      status
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createLecturer = /* GraphQL */ `
  mutation CreateLecturer(
    $input: CreateLecturerInput!
    $condition: ModelLecturerConditionInput
  ) {
    createLecturer(input: $input, condition: $condition) {
      id
      name
      image {
        bucket
        region
        key
      }
      profile {
        bucket
        region
        key
      }
      contactInfo {
        email
        phone
        availableTime
        preferredContact
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLecturer = /* GraphQL */ `
  mutation UpdateLecturer(
    $input: UpdateLecturerInput!
    $condition: ModelLecturerConditionInput
  ) {
    updateLecturer(input: $input, condition: $condition) {
      id
      name
      image {
        bucket
        region
        key
      }
      profile {
        bucket
        region
        key
      }
      contactInfo {
        email
        phone
        availableTime
        preferredContact
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLecturer = /* GraphQL */ `
  mutation DeleteLecturer(
    $input: DeleteLecturerInput!
    $condition: ModelLecturerConditionInput
  ) {
    deleteLecturer(input: $input, condition: $condition) {
      id
      name
      image {
        bucket
        region
        key
      }
      profile {
        bucket
        region
        key
      }
      contactInfo {
        email
        phone
        availableTime
        preferredContact
      }
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      id
      title
      createdDate
      type
      batchConnection
      location
      lecturerID
      lecturer {
        id
        name
        image {
          bucket
          region
          key
        }
        profile {
          bucket
          region
          key
        }
        contactInfo {
          email
          phone
          availableTime
          preferredContact
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDate
      endDate
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      id
      title
      createdDate
      type
      batchConnection
      location
      lecturerID
      lecturer {
        id
        name
        image {
          bucket
          region
          key
        }
        profile {
          bucket
          region
          key
        }
        contactInfo {
          email
          phone
          availableTime
          preferredContact
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDate
      endDate
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      id
      title
      createdDate
      type
      batchConnection
      location
      lecturerID
      lecturer {
        id
        name
        image {
          bucket
          region
          key
        }
        profile {
          bucket
          region
          key
        }
        contactInfo {
          email
          phone
          availableTime
          preferredContact
        }
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subjectID
      subject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      startDate
      endDate
      remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStudyNote = /* GraphQL */ `
  mutation CreateStudyNote(
    $input: CreateStudyNoteInput!
    $condition: ModelStudyNoteConditionInput
  ) {
    createStudyNote(input: $input, condition: $condition) {
      id
      noteTitle
      status
      notes {
        items {
          id
          note
          status
          StudyNoteID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateStudyNote = /* GraphQL */ `
  mutation UpdateStudyNote(
    $input: UpdateStudyNoteInput!
    $condition: ModelStudyNoteConditionInput
  ) {
    updateStudyNote(input: $input, condition: $condition) {
      id
      noteTitle
      status
      notes {
        items {
          id
          note
          status
          StudyNoteID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStudyNote = /* GraphQL */ `
  mutation DeleteStudyNote(
    $input: DeleteStudyNoteInput!
    $condition: ModelStudyNoteConditionInput
  ) {
    deleteStudyNote(input: $input, condition: $condition) {
      id
      noteTitle
      status
      notes {
        items {
          id
          note
          status
          StudyNoteID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNotes = /* GraphQL */ `
  mutation CreateNotes(
    $input: CreateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    createNotes(input: $input, condition: $condition) {
      id
      note
      status
      StudyNoteID
      studyNote {
        id
        noteTitle
        status
        notes {
          nextToken
          startedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotes = /* GraphQL */ `
  mutation UpdateNotes(
    $input: UpdateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    updateNotes(input: $input, condition: $condition) {
      id
      note
      status
      StudyNoteID
      studyNote {
        id
        noteTitle
        status
        notes {
          nextToken
          startedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotes = /* GraphQL */ `
  mutation DeleteNotes(
    $input: DeleteNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    deleteNotes(input: $input, condition: $condition) {
      id
      note
      status
      StudyNoteID
      studyNote {
        id
        noteTitle
        status
        notes {
          nextToken
          startedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createFeePayment = /* GraphQL */ `
  mutation CreateFeePayment(
    $input: CreateFeePaymentInput!
    $condition: ModelFeePaymentConditionInput
  ) {
    createFeePayment(input: $input, condition: $condition) {
      id
      feeTitle
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      paymentMode
      paidSubjects
      amount
      dueDate
      dateOfPayment
      status
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateFeePayment = /* GraphQL */ `
  mutation UpdateFeePayment(
    $input: UpdateFeePaymentInput!
    $condition: ModelFeePaymentConditionInput
  ) {
    updateFeePayment(input: $input, condition: $condition) {
      id
      feeTitle
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      paymentMode
      paidSubjects
      amount
      dueDate
      dateOfPayment
      status
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteFeePayment = /* GraphQL */ `
  mutation DeleteFeePayment(
    $input: DeleteFeePaymentInput!
    $condition: ModelFeePaymentConditionInput
  ) {
    deleteFeePayment(input: $input, condition: $condition) {
      id
      feeTitle
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      paymentMode
      paidSubjects
      amount
      dueDate
      dateOfPayment
      status
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      type
      sender
      senderDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      receiver
      receiverDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      text
      status
      parentMessage
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      type
      sender
      senderDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      receiver
      receiverDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      text
      status
      parentMessage
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      type
      sender
      senderDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      receiver
      receiverDetail {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      text
      status
      parentMessage
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDissertationCollection = /* GraphQL */ `
  mutation CreateDissertationCollection(
    $input: CreateDissertationCollectionInput!
    $condition: ModelDissertationCollectionConditionInput
  ) {
    createDissertationCollection(input: $input, condition: $condition) {
      id
      title
      description
      dissertations {
        items {
          id
          title
          collectionID
          studentID
          remarks
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDissertationCollection = /* GraphQL */ `
  mutation UpdateDissertationCollection(
    $input: UpdateDissertationCollectionInput!
    $condition: ModelDissertationCollectionConditionInput
  ) {
    updateDissertationCollection(input: $input, condition: $condition) {
      id
      title
      description
      dissertations {
        items {
          id
          title
          collectionID
          studentID
          remarks
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDissertationCollection = /* GraphQL */ `
  mutation DeleteDissertationCollection(
    $input: DeleteDissertationCollectionInput!
    $condition: ModelDissertationCollectionConditionInput
  ) {
    deleteDissertationCollection(input: $input, condition: $condition) {
      id
      title
      description
      dissertations {
        items {
          id
          title
          collectionID
          studentID
          remarks
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDissertation = /* GraphQL */ `
  mutation CreateDissertation(
    $input: CreateDissertationInput!
    $condition: ModelDissertationConditionInput
  ) {
    createDissertation(input: $input, condition: $condition) {
      id
      title
      collectionID
      collection {
        id
        title
        description
        dissertations {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDissertation = /* GraphQL */ `
  mutation UpdateDissertation(
    $input: UpdateDissertationInput!
    $condition: ModelDissertationConditionInput
  ) {
    updateDissertation(input: $input, condition: $condition) {
      id
      title
      collectionID
      collection {
        id
        title
        description
        dissertations {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDissertation = /* GraphQL */ `
  mutation DeleteDissertation(
    $input: DeleteDissertationInput!
    $condition: ModelDissertationConditionInput
  ) {
    deleteDissertation(input: $input, condition: $condition) {
      id
      title
      collectionID
      collection {
        id
        title
        description
        dissertations {
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      contentFile {
        bucket
        region
        key
      }
      remarks
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
