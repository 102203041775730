/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const listUserAccounts = /* GraphQL */ `
  query ListUserAccounts(
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        officeID
      }
      nextToken
      startedAt
    }
  }
`;

export const listBatchs = /* GraphQL */ `
  query ListBatchs(
    $filter: ModelBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          CreatedByUserID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename
          guidelinesFile {
            bucket
            region
            key
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        studentCount
        createdByUserID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const searchUserAccounts = /* GraphQL */ `
  query SearchUserAccounts(
    $filter: SearchableUserAccountFilterInput
    $sort: SearchableUserAccountSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUserAccounts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        accessExpiry
        typeOfAccount
        studentReport
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      total
    }
  }
`;

export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        createdDate
        type
        batchConnection
        location
        lecturerID
        lecturer {
          id
          name
          image {
            bucket
            region
            key
          }
          profile {
            bucket
            region
            key
          }
          contactInfo {
            email
            phone
            availableTime
            preferredContact
          }
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subjectID
        subject {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        startDate
        endDate
        remarks
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listReportCards = /* GraphQL */ `
  query ListReportCards(
    $filter: ModelReportCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          accessExpiry
          typeOfAccount
          studentReport
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        grades {
          items {
            id
            reportCardID
            searchString
            Mark
            Grade
            status
            subjectID
            subjectName {
              id
              subjectName
            }
            Remarks
            deniedUsers
            createdAt
            createdByUserID
            _version
            _deleted
          }
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listAssignmentSubmissions = /* GraphQL */ `
  query ListAssignmentSubmissions(
    $filter: ModelAssignmentSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssignmentSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assignmentID
        batchID
        assignmentName {
          id
          assignmentTitle
          studentID
          userID
          courseID
          subjectID
          subjectName {
            id
            subjectName
          }
          batchID
          batchCode
          finalSubmissionDate
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentID
        studentName
        submissionDate
        assignmentFile {
          bucket
          region
          key
        }
        remarks
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReportCard = /* GraphQL */ `
  query GetReportCard($id: ID!) {
    getReportCard(id: $id) {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentNameID
      gradesCount
      grades {
        items {
          id
          reportCardID
          searchString
          Mark
          Grade
          status
          subjectID
          subjectName {
            id
            subjectName
          }
          Remarks
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getBatch = /* GraphQL */ `
  query GetBatch($id: ID!) {
    getBatch(id: $id) {
      id
      batchTitle
      batchIconName
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchType
      courseID
      scheduleDocument {
        bucket
        region
        key
      }
      subjectCourse {
        id
        courseName
        courseDescription
        qualifySubjects
        deniedUsers
        textBooks {
          nextToken
          startedAt
        }
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchGuidelinesID
      attendances {
        items {
          id
          title
          batchID
          createdByUserID
          createdAt
          attendanceFile {
            bucket
            region
            key
          }
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      batchGuidelines {
        id
        filename
        deniedUsers
        guidelinesFile {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchDocuments
      studentCount
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getDissertationCollection = /* GraphQL */ `
  query GetDissertationCollection($id: ID!) {
    getDissertationCollection(id: $id) {
      id
      title
      description
      dissertations {
        items {
          id
          title
          collectionID
          studentID
          student {
            id
            accountUserID
            officeID
            firstName
            lastName
          }
          remarks
          contentFile {
            bucket
            region
            key
          }
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const searchBatchs = /* GraphQL */ `
  query SearchBatchs(
    $filter: SearchableBatchFilterInput
    $sort: SearchableBatchSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBatchs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
        }
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      total
    }
  }
`;

export const searchLocations = /* GraphQL */ `
  query SearchLocations(
    $filter: SearchableLocationFilterInput
    $sort: SearchableLocationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchLocations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        locationName
        locationDescription
        deniedUsers
        CreatedByUserID
        createdAt
        updatedAt
        _version
        _deleted
      }
      nextToken
      total
    }
  }
`;
