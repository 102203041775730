import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/Card";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as XLSX from "xlsx";
import { CodeBlockToolbarATMS } from "../../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import CreateAttendance from "./createAttendance";
import { deleteAttendance } from "../../../../graphql/mutations";
import SVG from "react-inlinesvg";

export default class listAttendance extends Component {
  state = {
    errorMessage: null,
    loading: false,
    addEditStatus: true,
    openAddEditAttendance: false,
    openDeleteAttendance: false,
    attendances: this.props.batchDetails.attendances.items,
    selectedAttendance: null,
    editItem: null,
    columns: [],
    data: [],
  };

  // process CSV data
  processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));
    this.setState({
      errorMessage: null,
      columns: columns,
      data: list,
      loading: false,
    });
  };

  handleFileSelect = (e) => {
    var texts = e.target.value.split("----");
    this.setState({
      loading: true,
      selectedAttendance: this.state.attendances.filter(
        (attendance) => attendance.id === texts[1]
      ),
    });
    if (texts[0] !== "Clear") {
      Storage.get(texts[0], {
        download: true,
      })
        .then((res) => {
          const file = res.Body;
          const reader = new FileReader();
          reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsName = wb.SheetNames[0];
            const ws = wb.Sheets[wsName];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            this.processData(data);
          };
          reader.readAsBinaryString(file);
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            errorMessage: err,
            loading: false,
            columns: [],
            data: [],
          });
        });
    } else {
      this.setState({
        loading: false,
        columns: [],
        data: [],
      });
    }
  };

  deleteAttendanceAction() {
    console.log(this.state.selectedAttendance[0]);
    API.graphql(
      graphqlOperation(deleteAttendance, {
        input: {
          id: this.state.selectedAttendance[0].id,
          _version: this.state.selectedAttendance[0]._version,
        },
      })
    ).then((result) => {
      console.log("deleted Attendance", result);
      this.setState({
        attendances: this.props.batchDetails.attendances.items.filter(
          (attendance) => attendance.id !== result.data.deleteAttendance.id
        ),
        openDeleteAttendance: false,
      });
    });
  }

  render() {
    const {
      loading,
      addEditStatus,
      editItem,
      attendances,
      columns,
      data,
      errorMessage,
      openDeleteAttendance,
      selectedAttendance,
      openAddEditAttendance,
    } = this.state;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditAttendance: true,
                addEditStatus: true,
              })
            }
            title={"Add Attendance"}
          />
          <div className="form-group row">
            <label className="col-xl-6 col-lg-6 col-form-label">
              Attendances
            </label>
            <div className="col-lg-9 col-xl-9">
              <div>
                <select
                  type="text"
                  className={`form-control form-control-lg form-control-solid`}
                  name="specializationID"
                  onChange={this.handleFileSelect}
                >
                  <option value={"Clear"}>Select</option>
                  {attendances.map((attendance) => {
                    if (
                      attendance.attendanceFile !== null &&
                      attendance._deleted !== true
                    )
                      return (
                        <option
                          key={attendance.id}
                          value={`${attendance.attendanceFile.key}----${attendance.id}`}
                        >
                          {attendance.title}
                        </option>
                      );
                  })}
                </select>
              </div>
            </div>
            {selectedAttendance === null ? (
              ""
            ) : (
              <div>
                <button
                  className="btn btn-icon btn-light btn-sm mx-1"
                  onClick={() => {
                    console.log(selectedAttendance);
                    this.setState({ openDeleteAttendance: true });
                  }}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                    ></SVG>
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openDeleteAttendance}
          onHide={() => this.setState({ openDeleteAttendance: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Attendance" : "Edit Attendance"}
            </Modal.Title>
          </Modal.Header>
          {selectedAttendance !== null ? (
            selectedAttendance[0] !== undefined ? (
              <Modal.Body>
                Are You sure you want to delete attendance{" "}
                {selectedAttendance[0].title} created on{" "}
                {selectedAttendance[0].createdAt}{" "}
              </Modal.Body>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => this.deleteAttendanceAction()}
            >
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteAttendance: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={openAddEditAttendance}
          onHide={() => this.setState({ openAddEditAttendance: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Attendance" : "Edit Attendance"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateAttendance
              batchDetails={this.props.batchDetails}
              addEditStatus={addEditStatus}
              editingContent={editItem}
              closeForm={() => this.setState({ openAddEditAttendance: false })}
            />
          </Modal.Body>
        </Modal>
        <Card className="example example-compact">
          <CardHeader title="Batch Attendance" toolbar={toolbar} />
          <CardBody>
            <div>
              {errorMessage === null ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      {columns.map((column, i) => (
                        <th key={i}>{column.name} </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="7">
                          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </td>
                      </tr>
                    ) : (
                      data.map((dataValues, i) => (
                        <tr key={i}>
                          <td key={`${i}_new`}>{i + 1}</td>
                          {columns.map((column) => (
                            <td>{dataValues[column.name]}</td>
                          ))}
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              ) : (
                <p>{errorMessage.NoSuchKey}</p>
              )}
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
