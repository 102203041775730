import React, { Component } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { listSubjects, listBatchs } from "../../../../graphql/queries";
import {
  createContentMaterials,
  updateContentMaterials,
} from "../../../../graphql/mutations";
import awsExports from "../../../../aws-exports";
import ReactSelect from "../../../tools/reactMultiSelect";
import UpdateActivity from "../../../tools/updateActivity";
import UploadFile from "./uploadFile";
import notificationActivityMail from "../../../tools/notificationMailActivity";

export class addContent extends Component {
  state = {
    subjects: [],
    subjectNextToken: null,
    uploadedFiles: [],
    contentFile: null,
    relatedBatches: [],
    addedBatches: [
      {
        key: this.props.batchDetails.id,
        label: this.props.batchDetails.batchTitle,
        value: this.props.batchDetails.id,
      },
    ],
    contentName: "",
    editContentID: null,
    subjectID: null,
    userId: "",
    userName: "",
    loading: false,
    version: null,
    contentNameError: false,
    contentBatchError: false,
    contentSubjectError: false,
    contentFileError: false,
    relatedBatchToken: null,
  };

  getSubjects() {
    const { subjectNextToken } = this.state;

    API.graphql(
      graphqlOperation(listSubjects, {
        limit: 100,
        nextToken: subjectNextToken,
      })
    ).then((result) => {
      var subjects = result.data.listSubjects.items.filter(
        (subject) =>
          subject.courseID === this.props.batchDetails.courseID &&
          subject.specializationID === this.props.batchDetails.specializationID
      );
      subjects = subjects.filter((subject) => subject._deleted !== true);
      if (subjects.length === 0) {
        this.setState({
          message: "No Subjects Added yet!",
        });
      }
      var allSubjects = [...this.state.subjects, ...subjects];
      this.setState({
        subjects: allSubjects,
        subjectNextToken: result.data.listSubjects.nextToken,
        loading: false,
      });
      if (result.data.listSubjects.nextToken !== null) {
        this.getSubjects();
      }
    });
  }

  getRelatedBatches() {
    API.graphql(
      graphqlOperation(listBatchs, {
        limit: 100,
        nextToken: this.state.relatedBatchToken,
      })
    ).then((result) => {
      var batches = result.data.listBatchs.items;
      if (batches.length === 0) {
        this.setState({
          batchMessage: "No Similar Batches yet!",
        });
      } else {
        batches = batches.filter((batch) => batch._delete !== true);
        var batchSuggestions = [...this.state.relatedBatches];
        batches.map((batch) => {
          batchSuggestions.push({
            key: batch.id,
            label: batch.batchTitle,
            value: batch.id,
          });
          return null;
        });
        this.setState({
          relatedBatches: batchSuggestions,
          relatedBatchToken: result.data.listBatchs.nextToken,
          loading: false,
        });
        if (result.data.listBatchs.nextToken !== null) {
          this.getRelatedBatches();
        }
      }
    });
  }

  componentDidMount() {
    const { addEditStatus, editingContent } = this.props;
    this.getSubjects();
    this.getRelatedBatches();
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });

    if (!addEditStatus) {
      this.setState({
        contentName: editingContent.contentTitle,
        subjectID: editingContent.subjectID,
        editContentID: editingContent.id,
        version: editingContent._version,
      });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeBulkFiles = (name, id, value) => {
    var uploadedFiles = this.state.uploadedFiles;
    uploadedFiles[id][name] = value;
    this.setState({
      uploadedFiles: uploadedFiles,
    });
  };

  onChangeFile = (e) => {
    var uploadedFiles = this.state.uploadedFiles;
    var uploads = e.target.files;
    for (let i = 0; i < uploads.length; i++) {
      uploadedFiles.push({
        filename: uploads[i].name,
        fileType: "",
        file: uploads[i],
      });
    }
    this.setState({
      [e.target.name]: e.target.files[0],
      uploadedFiles: uploadedFiles,
    });
  };

  onSelectBatch(value) {
    this.setState({
      addedBatches: value,
    });
  }

  onTestSubmit = async () => {
    if (this.state.uploadedFiles === []) {
      this.onSubmit([]);
    } else {
      UploadFile.uploadFile(this.state.uploadedFiles, (values) =>
        this.onSubmit(values)
      );
    }
  };

  onSubmit = (values) => {
    const {
      contentName,
      addedBatches,
      subjectID,
      userId,
      editContentID,
      version,
      userName,
      uploadedFiles,
    } = this.state;
    var valueMissing = false;
    const { batchDetails } = this.props;
    var listOfBatches = [];
    if (addedBatches !== null) {
      addedBatches.map((batch) => {
        listOfBatches.push(batch.value);
        return null;
      });
    }
    var input = {};

    var ContentObjects = [];
    values.map((uploadedFile) => {
      ContentObjects.push({
        fileName: uploadedFile.type,
        bucket: awsExports.aws_user_files_s3_bucket,
        region: awsExports.aws_user_files_s3_bucket_region,
        key: uploadedFile.key,
        status: "New Upload",
      });
      return null;
    });

    if (this.props.addEditStatus) {
      if (
        contentName !== "" &&
        ContentObjects !== [] &&
        subjectID !== null &&
        listOfBatches.length !== 0
      ) {
        input = {
          contentTitle: contentName,
          subjectID: subjectID,
          batchConnection: listOfBatches,
          contentFiles: ContentObjects,
          createdByUserID: userId,
        };
        API.graphql(graphqlOperation(createContentMaterials, { input: input }))
          .then((createdContent) => {
            console.log(createdContent);
            this.setState({
              loading: false,
            });
            var activityTime = new Date();
            /*
            UpdateActivity.updateActivity({
              activityItem: `Course Material Created`,
              tableName: "ContentMaterials",
              referenceID: createdContent.data.createContentMaterials.id,
              remarks: `Course Material ${createdContent.data.createContentMaterials.contentTitle} created by ${userName}`,
              activityDateTime: activityTime.toISOString(),
            });
            */
            notificationActivityMail.notificationActivityMail({
              activityItem: `New Course Material Created`,
              tableName: "Schedule",
              referenceID: createdContent.data.createContentMaterials.id,
              remarks: `Course Material created for ${this.props.batchDetails.batchTitle} created by ${userName}`,
              activityDateTime: activityTime.toISOString(),
              notificationType: "Batch",
              type: "Notification",
              topic: "New Course Material",
              targetID: this.props.batchDetails.id,
              message: `A new Course Material has been uploaded for ${this.props.batchDetails.batchTitle}`,
              givenUserId: userId,
              mailHeading: "New Activity on ATMS Student Gateway",
              mailSubject: "New Activity on ATMS Student Gateway",
            });
            this.props.closeForm();
          })
          .catch((err) => {
            console.log(err);
            var activityTime = new Date();
            UpdateActivity.updateActivity({
              activityItem: `Course Material Failed`,
              tableName: "ContentMaterials",
              referenceID: null,
              remarks: err.message,
              activityDateTime: activityTime.toISOString(),
            });
          });
      } else {
        valueMissing = true;
      }
    } else {
      input = {
        id: editContentID,
        contentName: contentName,
        courseID: batchDetails.courseID,
        specializationID: batchDetails.specializationID,
        createdByUserID: userId,
        _version: version,
      };

      if (values !== []) {
        values.map((uploadedFile) => {
          ContentObjects.push({
            fileName: uploadedFile.type,
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: uploadedFile.key,
            status: "New Upload",
          });
          return null;
        });
        input["contentFiles"] = ContentObjects;
      }
      API.graphql(
        graphqlOperation(updateContentMaterials, { input: input })
      ).then((updatedContent) => {
        console.log("updated Content:", updatedContent);
        this.setState({
          loading: false,
        });
        var activityTime = new Date();
        /*
        UpdateActivity.updateActivity({
          activityItem: `Course Material Updated`,
          tableName: "ContentMaterials",
          referenceID: updatedContent.data.createContentMaterials.id,
          remarks: `Course Material ${updatedContent.data.createContentMaterials.contentTitle} updated by ${userName}`,
          activityDateTime: activityTime.toISOString(),
        });
        */
        notificationActivityMail.notificationActivityMail({
          activityItem: `Course Material updated`,
          tableName: "ContentMaterials",
          referenceID: updatedContent.data.updateContentMaterials.id,
          remarks: `Course Material updated for ${this.props.batchDetails.batchTitle} created by ${userName}`,
          activityDateTime: activityTime.toISOString(),
          notificationType: "Batch",
          type: "Notification",
          topic: "Updated Course Material",
          targetID: this.props.batchDetails.id,
          message: `A new Course Material has been uploaded for ${this.props.batchDetails.batchTitle}`,
          givenUserId: userId,
          mailHeading: "New Activity on ATMS Student Gateway",
          mailSubject: "New Activity on ATMS Student Gateway",
        });

        this.props.closeForm();
      });

      if (valueMissing) {
        console.log("Information Missing!");
        var nameMissing = false,
          batchMissing = false,
          subjectMissing = false;
        var fileMissing = false;
        if (contentName === "") {
          nameMissing = true;
        }
        if (uploadedFiles === []) {
          fileMissing = true;
        }
        if (subjectID === null) {
          subjectMissing = true;
        }
        if (listOfBatches.length === 0) {
          batchMissing = true;
        }
        this.setState({
          loading: false,
          contentNameError: nameMissing,
          contentBatchError: batchMissing,
          contentSubjectError: subjectMissing,
          contentFileError: fileMissing,
        });
      }
    }
  };

  render() {
    const {
      subjects,
      contentName,
      loading,
      relatedBatches,
      addedBatches,
      contentNameError,
      contentBatchError,
      subjectID,
      contentSubjectError,
      contentFileError,
      uploadedFiles,
    } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Content Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Content Name"
              name="contentName"
              value={contentName}
              onChange={this.onChange}
            />
            {contentNameError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">File name missing!</div>
              </div>
            ) : null}
          </Form.Group>
          <ReactSelect
            label="Batches"
            suggestions={relatedBatches}
            addedSuggestions={addedBatches}
            addBatch={(value) => this.onSelectBatch(value)}
          />
          {contentBatchError ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">Select at least One batch</div>
            </div>
          ) : null}
          <br />
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              as="select"
              name="subjectID"
              disabled={!this.props.addEditStatus}
              value={subjectID}
              onChange={this.onChange}
            >
              <option value={null}>Select A Subject</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.subjectName}
                </option>
              ))}
            </Form.Control>
            {contentSubjectError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Please select a Subject</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Content File</Form.Label>
            <Form.Control
              type="file"
              name="contentFile"
              multiple
              onChange={(e) => this.onChangeFile(e)}
            />
            {contentFileError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">File Missing</div>
              </div>
            ) : null}
          </Form.Group>
          {uploadedFiles.length !== 0
            ? uploadedFiles.map((file, i) => {
                return (
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>File Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="File Name"
                        name="filename"
                        value={uploadedFiles[i].filename}
                        onChange={(e) =>
                          this.onChangeBulkFiles("filename", i, e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        value={uploadedFiles[i].fileType}
                        onChange={(e) =>
                          this.onChangeBulkFiles("fileType", i, e.target.value)
                        }
                        as="select"
                      >
                        <option value={null}>Choose...</option>
                        <option value={"Lecture Notes"}>Lecture Notes</option>
                        <option value={"Slides"}>Slides</option>
                        <option value={"Text Books"}>Text Book</option>
                        <option value={"Additional Study Materials "}>
                          Additional Study Materials
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                );
              })
            : ""}
          <Button
            variant="primary"
            onClick={() => {
              this.onTestSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addContent;
