import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class degreeDashboard extends Component {
  static propTypes = {
    userType: PropTypes.string.isRequired,
  };

  render() {
    console.log(this.props.userType);
    const { userType } = this.props;
    if (userType === "Student") {
      return <div>Student Degree Dashboard</div>;
    } else {
      return <div>Degree Dashboard</div>;
    }
  }
}

const mapStateToProps = (state) => ({
  userType: state.reducerAuth.userType,
});
export default connect(mapStateToProps, null)(degreeDashboard);
