import React, { Component } from "react";
import { Toast, Button, OverlayTrigger } from "react-bootstrap";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { updateBatch } from "../../../graphql/mutations";
import {
  listLocations,
  listCourses,
  listBatchDocumentss,
  listSpecializations,
} from "../../../graphql/queries";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import awsExports from "../../../aws-exports";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import UpdateActivity from "../../tools/updateActivity";

export default class batchInfo extends Component {
  state = {
    toastMessage: {},
    openToastMessage: false,
    scheduleDocument: null,
    batch: this.props.batchDetails,
    batchTitle: this.props.batchDetails.batchTitle,
    batchIconName: this.props.batchDetails.batchIconName,
    batchType: this.props.batchDetails.batchType,
    locationID: this.props.batchDetails.locationID,
    batchLocation: this.props.batchDetails.batchLocation,
    courseID: this.props.batchDetails.courseID,
    documentID: "",
    batchDocuments: this.props.batchDetails.batchDocuments,
    subjectCourse: this.props.batchDetails.subjectCourse,
    specializationID: this.props.batchDetails.specializationID,
    batchSpecialization: this.props.batchDetails.batchSpecialization,
    batchGuidelinesID: this.props.batchDetails.batchGuidelinesID,
    batchGuidelines: this.props.batchDetails.batchGuidelines,
    courses: [],
    locations: [],
    documents: [],
    specializations: [],
    loading: false,
  };

  getBatchSpecializations() {
    API.graphql(graphqlOperation(listSpecializations)).then((result) => {
      var specializations = result.data.listSpecializations.items;
      if (specializations.length !== 0) {
        specializations = specializations.filter(
          (specialization) => specialization._deleted !== true
        );
        this.setState({ specializations });
      }
    });
  }
  getBatchDocuments() {
    API.graphql(graphqlOperation(listBatchDocumentss)).then((result) => {
      var documents = result.data.listBatchDocumentss.items;
      if (documents.length !== 0) {
        documents = documents.filter((document) => document._deleted !== true);
        this.setState({ documents });
      }
    });
  }

  getBatchCourses() {
    API.graphql(graphqlOperation(listCourses)).then((result) => {
      var courses = result.data.listCourses.items;
      if (courses.length !== 0) {
        courses = courses.filter((course) => course._deleted !== true);
        this.setState({ courses });
      }
    });
  }

  getBatchLocations() {
    API.graphql(graphqlOperation(listLocations)).then((result) => {
      var locations = result.data.listLocations.items;
      if (locations.length !== 0) {
        locations = locations.filter((location) => location._deleted !== true);
        this.setState({ locations });
      }
    });
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onFileChange = (e) =>
    this.setState({
      [e.target.name]: e.target.files[0],
    });

  submitForm() {
    const {
      batchTitle,
      batchIconName,
      batchType,
      locationID,
      courseID,
      batchDocuments,
      specializationID,
      batchGuidelinesID,
      scheduleDocument,
    } = this.state;

    var input = {
      id: this.props.batchDetails.id,
      batchTitle: batchTitle,
      batchIconName: batchIconName,
      batchType: batchType,
      locationID: locationID,
      courseID: courseID,
      batchDocuments: batchDocuments,
      specializationID: specializationID,
      batchGuidelinesID: batchGuidelinesID,
      _version: this.props.batchDetails._version,
    };
    console.log(input);
    if (scheduleDocument !== null) {
      var file_name = scheduleDocument.name.split(".");
      var unique_filename =
        file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
      Storage.put(unique_filename, scheduleDocument).then((fileDetails) => {
        input["scheduleDocument"] = {
          bucket: awsExports.aws_user_files_s3_bucket,
          region: awsExports.aws_user_files_s3_bucket_region,
          key: fileDetails.key,
        };
        API.graphql(graphqlOperation(updateBatch, { input: input }))
          .then((updatedBatch) => {
            var activityTime = new Date();
            UpdateActivity.updateActivity({
              activityItem: `Batch Updated`,
              tableName: "Batch",
              referenceID: updatedBatch.data.updateBatch.id,
              remarks: `Batch updated ${updatedBatch.data.updateBatch.batchTitle} updated by`,
              activityDateTime: activityTime.toISOString(),
            });

            this.setState({
              toastMessage: {
                heading: "Batch Updated",
                body: "Batch has been updated!",
                status: "success",
              },
              openToastMessage: true,
              loading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              toastMessage: {
                heading: "Batch Update failed!",
                body: "Batch has been failed!",
                status: "danger",
              },
              openToastMessage: true,
              loading: false,
            });
          });
      });
    } else {
      API.graphql(graphqlOperation(updateBatch, { input: input }))
        .then((updatedBatch) => {
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Batch Updated`,
            tableName: "Batch",
            referenceID: updatedBatch.data.updateBatch.id,
            remarks: `Batch updated ${updatedBatch.data.updateBatch.batchTitle} updated by`,
            activityDateTime: activityTime.toISOString(),
          });

          this.setState({
            toastMessage: {
              heading: "Batch Updated",
              body: "Batch has been updated!",
              status: "success",
            },
            openToastMessage: true,
            loading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            toastMessage: {
              heading: "Batch Update failed!",
              body: "Batch has been failed!",
              status: "danger",
            },
            openToastMessage: true,
            loading: false,
          });
        });
    }
  }

  componentDidMount() {
    this.getBatchCourses();
    this.getBatchDocuments();
    this.getBatchLocations();
    this.getBatchSpecializations();
    if (this.props.batchDetails.scheduleDocument !== null) {
      this.setState({
        scheduleDocument: this.props.batchDetails.scheduleDocument,
      });
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  render() {
    const {
      toastMessage,
      openToastMessage,
      batch,
      loading,
      courses,
      specializations,
      locations,
      documents,
      batchTitle,
      batchDocuments,
      batchType,
      locationID,
      courseID,
      documentID,
      specializationID,
      scheduleDocument,
    } = this.state;
    return (
      <>
        <Toast
          bg={toastMessage.status}
          onClose={() => this.setState({ openToastMessage: false })}
          show={openToastMessage}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{toastMessage.heading}</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage.body}</Toast.Body>
        </Toast>
        <div className="card card-custom">
          {loading && <ModalProgressBar />}

          {/* begin::Header */}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                Batch Information
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                {batch.batchTitle}
              </span>
            </div>
            <div className="card-toolbar">
              {"  "}
              <button
                className="btn btn-success mr-2"
                onClick={() => this.submitForm()}
              >
                Save Changes
              </button>
            </div>
          </div>
          <div className="form">
            <div className="card-body">
              {/* begin::Form Group */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Title
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid`}
                      name="batchTitle"
                      value={batchTitle}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Type</label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      type="text"
                      className={`form-control form-control-lg form-control-solid`}
                      name="batchType"
                      value={batchType}
                      onChange={this.onChange}
                    >
                      <option key={"Weekly"} value={"Weekly"}>
                        Weekly
                      </option>
                      <option key={"Monthly"} value={"Monthly"}>
                        Monthly
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Course
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      type="text"
                      className={`form-control form-control-lg form-control-solid`}
                      name="courseID"
                      value={courseID}
                      onChange={this.onChange}
                    >
                      {courses.map((course) => (
                        <option key={course.id} value={course.id}>
                          {course.courseName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Specializations
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      type="text"
                      className={`form-control form-control-lg form-control-solid`}
                      name="specializationID"
                      value={specializationID}
                      onChange={this.onChange}
                    >
                      {specializations.map((specialization) => (
                        <option
                          key={specialization.id}
                          value={specialization.id}
                        >
                          {specialization.specializationName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Schedule Document
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <div className="form-group row">
                      <input
                        type="file"
                        className={`col-lg-10 col-xl-10 form-control form-control-lg form-control-solid`}
                        name="scheduleDocument"
                        onChange={this.onFileChange}
                      />
                      {scheduleDocument !== null ? (
                        <OverlayTrigger
                          placement="right-start"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<div>{scheduleDocument.key}</div>}
                        >
                          <button
                            className="col-lg-2 col-xl-2 btn btn-icon btn-light btn-sm"
                            onClick={() =>
                              Storage.get(scheduleDocument.key, {
                                download: true,
                              }).then((res) => {
                                this.downloadBlob(
                                  res.Body,
                                  scheduleDocument.key
                                );
                              })
                            }
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                style={{ color: "red" }}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Cloud-download.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Location
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <select
                      type="text"
                      className={`form-control form-control-lg form-control-solid`}
                      name="locationID"
                      value={locationID}
                      onChange={this.onChange}
                    >
                      {locations.map((location) => (
                        <option key={location.id} value={location.id}>
                          {location.locationName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Documents
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div className="form-group row">
                    <div className="col-lg-10 col-xl-10">
                      <div>
                        <select
                          type="text"
                          className={`form-control form-control-lg form-control-solid`}
                          name="documentID"
                          value={documentID}
                          onChange={this.onChange}
                        >
                          <option key={"None"} value={null}>
                            None
                          </option>
                          {documents.map((document) => (
                            <option key={document.id} value={document.id}>
                              {document.filename}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <i
                      className="col-lg-2 col-xl-2 fa fa-plus-circle"
                      onClick={() => {
                        var newBatchDocuments = [];
                        if (this.state.batchDocuments === null) {
                          newBatchDocuments = [documentID];
                        } else {
                          newBatchDocuments = [
                            ...this.state.batchDocuments,
                            documentID,
                          ];
                        }
                        this.setState({
                          batchDocuments: newBatchDocuments,
                        });
                      }}
                    ></i>
                  </div>

                  {batchDocuments !== null
                    ? batchDocuments.map((documentID) => {
                        var currentDocuments = documents.filter(
                          (document) => document.id === documentID
                        );
                        return currentDocuments.map((currentDocument, i) => (
                          <div className="form-group row" key={i}>
                            <p>{currentDocument.filename}</p>
                            <div className="col-lg-2 col-xl-2">
                              <Button
                                className="btn btn-danger"
                                onClick={() => console.log("ttes")}
                              >
                                <i className="fa fa-trash-o"></i>
                              </Button>
                            </div>
                          </div>
                        ));
                      })
                    : ""}
                </div>
              </div>

              {/* begin::Form Group */}
              <div className="separator separator-dashed my-5"></div>
            </div>
          </div>
          {/* end::Form */}
        </div>
      </>
    );
  }
}
