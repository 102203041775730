import React, { useState, useEffect } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { countryList } from "./countryLis";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { listUserAccounts } from "../../graphql/queries";
import { createUserAccount } from "../../graphql/mutations";

function StudentEditForm(props) {
  const initialValues = props.initialValues;
  const [loading, setLoading] = useState(false);
  const [existingUser, setExistingUser] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [users, setUsers] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  // Validation schema
  const StudentEditSchema = Yup.object().shape({
    officeID: Yup.string() //
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Office ID is required")
      .test(
        "userId check",
        "User ID already Exists",
        (value) =>
          users.filter((user) => user.officeID.toString() === value.toString())
            .length === 0
      ),
    firstName: Yup.string() //
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("First Name is required"),
    lastName: Yup.string() //
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Last Name is required"),
    DOJ: Yup.date(), //
    birthCountry: Yup.string() //
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
    birthPlace: Yup.string() //
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
    email: Yup.string() //
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    emailPersonal: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
    contactPhone: Yup.string() //
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Contact No. is required"),
    contactPersonal: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
    DOR: Yup.date(), //
    addressLine: Yup.string() //
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
    city: Yup.string() //
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
    state: Yup.string() //
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
    postCode: Yup.string() //
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
    accessExpiry: Yup.date(), //
  });

  const nonStudentSchema = Yup.object().shape({
    officeID: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Office ID is required")
      .test(
        "userId check",
        "User ID already Exists",
        (value) =>
          users.filter((user) => user.officeID.toString() === value).length ===
          0
      ),
    firstName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Last Name is required"),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    contactPhone: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Contact No. is required"),
    designation: Yup.string(),
    qualification: Yup.string(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const checkValue = () => {
    if (
      users.filter(
        (user) => user.email.toUpperCase() === formik.values.email.toUpperCase()
      ).length === 0
    ) {
      formik.handleSubmit();
    } else {
      setOpenConfirmation(true);
    }
  };

  useEffect(() => {
    if (
      (userToken === null && users.length === 0) ||
      (userToken !== null && users.length !== 0)
    ) {
      setLoading(true);
      API.graphql(
        graphqlOperation(listUserAccounts, {
          limit: 100,
          nextToken: userToken,
        })
      ).then((result) => {
        var newUsers = result.data.listUserAccounts.items;
        var newNextToken = result.data.listUserAccounts.nextToken;
        setUsers([...users, ...newUsers]);
        setUserToken(newNextToken);
      });
    } else {
      setLoading(false);
    }
  }, [userToken]);

  var selectedSchema = null;
  if (props.userType === "Student") {
    selectedSchema = StudentEditSchema;
  } else {
    selectedSchema = nonStudentSchema;
  }

  const formik = useFormik({
    initialValues,
    validationSchema: selectedSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      var requiredUsers = users.filter(
        (selectedUser) =>
          selectedUser.officeID.toString() === values.officeID.toString() &&
          selectedUser.email === values.email
      );
      if (!existingUser) {
        console.log("No users");
        Auth.currentAuthenticatedUser()
          .then(async (user) => {
            const apiName = "createUserTrigger";
            const path = "/createUser";
            var user_cred = {
              username: values.email,
              officeID: values.officeID.trim(), //remove whitespace from both ends
              firstName: values.firstName,
              lastName: values.lastName,
              designation: values.designation,
              qualification: values.qualification,
              DOJ: values.DOJ,
              birthCountry: values.birthCountry,
              birthPlace: values.birthPlace,
              email: values.email,
              emailPersonal: values.emailPersonal,
              contactPhone: values.contactPhone,
              contactPersonal: values.contactPersonal,
              DOR: values.DOR,
              address: {
                addressLine: values.addressLine,
                city: values.city,
                state: values.state,
                postCode: values.postCode,
              },
              communication: {
                email: true,
                sms: true,
                phone: true,
              },
              groupNotification: true,
              personalNotification: true,
              emailNotification: true,
              accessExpiry: values.accessExpiry,
              typeOfAccount: props.userType,
              createdByUserID: user.attributes.sub,
              pool_id: Auth.user.pool.userPoolId,
              testFunction: false,
            };
            const myInit = {
              body: { params: user_cred },
              headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                  .getAccessToken()
                  .getJwtToken()}`,
              },
            };
            console.log(myInit);
            API.post(apiName, path, myInit).then((response) => {
              console.log(response);
              disableLoading();
              props.closeForm();
            });
          })
          .catch((err) => {
            setStatus(err.message);
            console.log(err);
            disableLoading();
          });
      } else {
        Auth.currentAuthenticatedUser().then(async (user) => {
          var input = {
            officeID: values.officeID.trim(), //remove whitespace from both ends
            firstName: values.firstName,
            lastName: values.lastName,
            designation: values.designation,
            qualification: values.qualification,
            DOJ: values.DOJ,
            birthCountry: values.birthCountry,
            birthPlace: values.birthPlace,
            email: values.email,
            emailPersonal: values.emailPersonal,
            contactPhone: values.contactPhone,
            contactPersonal: values.contactPersonal,
            DOR: values.DOR,
            address: {
              addressLine: values.addressLine,
              city: values.city,
              state: values.state,
              postCode: values.postCode,
            },
            communication: {
              email: true,
              sms: true,
              phone: true,
            },
            groupNotification: true,
            personalNotification: true,
            emailNotification: true,
            accessExpiry: values.accessExpiry,
            typeOfAccount: props.userType,
            createdByUserID: user.attributes.sub,
          };

          API.graphql(graphqlOperation(createUserAccount, { input: input }))
            .then((createdUserGraph) => {
              console.log("Created user", createdUserGraph);
              props.closeForm();
            })
            .catch((err) => {
              setStatus(err.message);
              console.log(err);
              disableLoading();
            });
        });
      }
    },
  });

  const accountInfo = () => {
    return (
      <>
        <h2>Account Information</h2>
        <div className="form-group row">
          {/* First Name */}
          <Form.Group className="col-lg-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="firstName"
              {...formik.getFieldProps("firstName")}
              placeholder="First Name"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "firstName"
              )}`}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.firstName}</div>
              </div>
            ) : null}
          </Form.Group>
          {/* First Name */}
          <Form.Group className="col-lg-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="lastName"
              placeholder="Last Name"
              {...formik.getFieldProps("lastName")}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "lastName"
              )}`}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.lastName}</div>
              </div>
            ) : null}
          </Form.Group>

          {/* First Name */}
          <Form.Group className="col-lg-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              name="email"
              placeholder="Email ID"
              {...formik.getFieldProps("email")}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </Form.Group>

          {/* Contact No. */}
          <Form.Group className="col-lg-3">
            <Form.Label>Contact No.</Form.Label>
            <Form.Control
              name="contactPhone"
              placeholder="Phone"
              {...formik.getFieldProps("contactPhone")}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "contactPhone"
              )}`}
            />
            {formik.touched.contactPhone && formik.errors.contactPhone ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.contactPhone}
                </div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <hr />
      </>
    );
  };

  const personalInfo = () => {
    return (
      <>
        <h2>Personal Information</h2>
        <div className="form-group row">
          {/* Address  */}
          <Form.Group className="col-lg-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              name="addressLine"
              placeholder="Address"
              {...formik.getFieldProps("addressLine")}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "addressLine"
              )}`}
            />
            {formik.touched.addressLine && formik.errors.addressLine ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.addressLine}</div>
              </div>
            ) : null}
          </Form.Group>

          {/* City */}
          <Form.Group className="col-lg-3">
            <Form.Label>City</Form.Label>
            <Form.Control
              name="city"
              placeholder="City"
              {...formik.getFieldProps("city")}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "city"
              )}`}
            ></Form.Control>
            {formik.touched.city && formik.errors.city ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.city}</div>
              </div>
            ) : null}
          </Form.Group>

          {/* State */}
          <Form.Group className="col-lg-3">
            <Form.Label>State</Form.Label>
            <Form.Control
              name="state"
              placeholder="State"
              {...formik.getFieldProps("state")}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "state"
              )}`}
            />
            {formik.touched.state && formik.errors.state ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.state}</div>
              </div>
            ) : null}
          </Form.Group>

          {/* Postal Code */}
          <Form.Group className="col-lg-3">
            <Form.Label>Post Code</Form.Label>
            <Form.Control
              name="postCode"
              {...formik.getFieldProps("postCode")}
              placeholder="Postal Code"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "postCode"
              )}`}
            />
            {formik.touched.postCode && formik.errors.postCode ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.postCode}</div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <div className="form-group row">
          {/* Birth Place */}
          <Form.Group className="col-lg-3">
            <Form.Label>Birth Place</Form.Label>
            <Form.Control
              name="birthPlace"
              {...formik.getFieldProps("birthPlace")}
              placeholder="Birth Place"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "birthPlace"
              )}`}
            />
            {formik.touched.birthPlace && formik.errors.birthPlace ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.birthPlace}</div>
              </div>
            ) : null}
          </Form.Group>

          {/* Country */}
          <Form.Group className="col-lg-3">
            <Form.Label>Country</Form.Label>
            <Form.Control
              as="select"
              name="birthCountry"
              {...formik.getFieldProps("birthCountry")}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "birthCountry"
              )}`}
            >
              <option value="">Select Country</option>
              {countryList.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
              <option key={"None"} value={null}>
                None
              </option>
            </Form.Control>
            {formik.touched.birthCountry && formik.errors.birthCountry ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.birthCountry}
                </div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-lg-3">
            <Form.Label>Personal Mail</Form.Label>
            <Form.Control
              name="emailPersonal"
              {...formik.getFieldProps("emailPersonal")}
              placeholder="Birth Place"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "emailPersonal"
              )}`}
            />
            {formik.touched.emailPersonal && formik.errors.emailPersonal ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.emailPersonal}
                </div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group className="col-lg-3">
            <Form.Label>Personal Phone</Form.Label>
            <Form.Control
              name="contactPersonal"
              {...formik.getFieldProps("contactPersonal")}
              placeholder="Birth Place"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "contactPersonal"
              )}`}
            />
            {formik.touched.contactPersonal && formik.errors.contactPersonal ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.contactPersonal}
                </div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <hr />
      </>
    );
  };

  const officialInfo = () => {
    return (
      <>
        <h2>Official Information</h2>
        <div className="form-group row">
          {/* Office ID */}
          <Form.Group className="col-lg-3">
            <Form.Label>
              {props.userType === "Student" ? "SBS / ABS ID" : "Office ID"}
            </Form.Label>
            <Form.Control
              name="officeID"
              {...formik.getFieldProps("officeID")}
              placeholder="Office ID"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "officeID"
              )}`}
            />
            {formik.touched.officeID && formik.errors.officeID ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.officeID}</div>
              </div>
            ) : null}
          </Form.Group>
          {/* Date Of Registration */}
          <Form.Group className="col-lg-3">
            <Form.Label>Date Of Registration</Form.Label>
            <Form.Control
              type="date"
              name="DOR"
              {...formik.getFieldProps("DOR")}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "DOR"
              )}`}
            />
            {formik.touched.DOR && formik.errors.DOR ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.DOR}</div>
              </div>
            ) : null}
          </Form.Group>
          {/* Date Of Joining*/}
          <Form.Group className="col-lg-3">
            <Form.Label>Date Of Joining</Form.Label>
            <Form.Control
              type="date"
              name="DOJ"
              {...formik.getFieldProps("DOJ")}
              placeholder="Joining Date"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "DOJ"
              )}`}
            />
            {formik.touched.DOJ && formik.errors.DOJ ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.DOJ}</div>
              </div>
            ) : null}
          </Form.Group>

          {/* Access Expiry */}
          <Form.Group className="col-lg-3">
            <Form.Label>Access Expiry</Form.Label>
            <Form.Control
              type="date"
              name="accessExpiry"
              {...formik.getFieldProps("accessExpiry")}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "accessExpiry"
              )}`}
            />
            {formik.touched.accessExpiry && formik.errors.accessExpiry ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.accessExpiry}
                </div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <hr />
      </>
    );
  };

  const Admin_exam = () => {
    return (
      <>
        <div className="form-group row">
          {/* Office ID */}
          <Form.Group className="col-lg-3">
            <Form.Label>
              {props.userType === "Student" ? "SBS ID" : "Office ID"}
            </Form.Label>
            <Form.Control
              name="officeID"
              {...formik.getFieldProps("officeID")}
              placeholder="Office ID"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "officeID"
              )}`}
            />
            {formik.touched.officeID && formik.errors.officeID ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.officeID}</div>
              </div>
            ) : null}
          </Form.Group>

          {/* First Name */}
          <Form.Group className="col-lg-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="firstName"
              {...formik.getFieldProps("firstName")}
              placeholder="First Name"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "firstName"
              )}`}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.firstName}</div>
              </div>
            ) : null}
          </Form.Group>
          {/* First Name */}
          <Form.Group className="col-lg-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="lastName"
              placeholder="Last Name"
              {...formik.getFieldProps("lastName")}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "lastName"
              )}`}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.lastName}</div>
              </div>
            ) : null}
          </Form.Group>
        </div>

        <div className="form-group row">
          {/* Email */}
          <Form.Group className="col-lg-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              {...formik.getFieldProps("email")}
              placeholder="EMail"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </Form.Group>

          {/* Contact No. */}
          <Form.Group className="col-lg-3">
            <Form.Label>Phone No.</Form.Label>
            <Form.Control
              name="contactPhone"
              {...formik.getFieldProps("contactPhone")}
              placeholder="Contact Phone"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "contactPhone"
              )}`}
            ></Form.Control>
            {formik.touched.contactPhone && formik.errors.contactPhone ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.contactPhone}
                </div>
              </div>
            ) : null}
          </Form.Group>
        </div>

        <div className="form-group row">
          {/* Designation */}
          <Form.Group className="col-lg-3">
            <Form.Label>Designation</Form.Label>
            <Form.Control
              type="text"
              name="designation"
              {...formik.getFieldProps("designation")}
              placeholder="Designation"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "designation"
              )}`}
            />
            {formik.touched.designation && formik.errors.designation ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.designation}</div>
              </div>
            ) : null}
          </Form.Group>

          {/* Qualification */}
          <Form.Group className="col-lg-3">
            <Form.Label>Qualification</Form.Label>
            <Form.Control
              name="qualification"
              {...formik.getFieldProps("qualification")}
              placeholder="Qualification"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "qualification"
              )}`}
            ></Form.Control>
            {formik.touched.qualification && formik.errors.qualification ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.qualification}
                </div>
              </div>
            ) : null}
          </Form.Group>
        </div>
        <hr />
      </>
    );
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  return (
    <>
      <Modal
        size="lg"
        show={openConfirmation}
        onHide={() => setOpenConfirmation(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title style={{ color: "darkred", fontStyle: "bold" }}>
            Warning !
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to create an account with existing Mail ID and different
          userID!
          <br />
          <br />
          The User ID will be the newly created ID!
          <p style={{ color: "red" }}>
            Password will remain the same as the first account created!
          </p>
          <br />
          Do you want to continue?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              setExistingUser(true);
              formik.handleSubmit();
            }}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setOpenConfirmation(false);
              props.closeForm();
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal.Body className="overlay overlay-block cursor-default">
        {loading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}
        <Form className="form form-label-right">
          {props.userType === "Student" ? (
            <>
              {accountInfo()}
              {personalInfo()}
              {officialInfo()}
            </>
          ) : null}
          {props.userType === "Administrator" ||
          props.userType === "Examiner" ||
          props.userType === "Dean" ? (
            <>{Admin_exam()}</>
          ) : null}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {submissionError !== "" ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{submissionError}</div>
          </div>
        ) : (
          ""
        )}
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          ""
        )}
        <Button
          onClick={() => props.closeForm()}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </Button>

        <Button
          disabled={JSON.stringify(formik.errors) !== "{}"}
          onClick={() => checkValue()}
          className="btn btn-primary btn-elevate"
        >
          Save
        </Button>
      </Modal.Footer>
    </>
  );
}

export default injectIntl(StudentEditForm);
