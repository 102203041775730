import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { listDissertationCollections } from "../../../../graphql/queries";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/Card";
import { Table } from "react-bootstrap";
import {
  onCreateDissertationCollection,
  onUpdateDissertationCollection,
} from "../../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class listCollections extends Component {
  state = {
    collections: [],
    loading: true,
    message: null,
  };

  getCollections() {
    API.graphql(graphqlOperation(listDissertationCollections)).then(
      (result) => {
        var collections = result.data.listDissertationCollections.items;
        if (collections.length === 0) {
          this.setState({
            message: "No collections Added yet!",
            loading: false,
          });
        } else {
          collections = collections.filter(
            (collection) => collection._deleted !== true
          );
          this.setState({ collections, loading: false });
        }
      }
    );
  }

  componentDidMount() {
    this.getCollections();

    //Function to listen to post collection
    this.createCollectionListener = API.graphql(
      graphqlOperation(onCreateDissertationCollection)
    ).subscribe({
      next: (collectionData) => {
        const newCollection =
          collectionData.value.data.onCreateDissertationCollection;
        const prevCollections = this.state.collections.filter(
          (collection) => collection.id !== newCollection.id
        );
        this.setState({ collections: [newCollection, ...prevCollections] });
      },
    });

    //Function to listen to update collections
    this.updateCollectionListener = API.graphql(
      graphqlOperation(onUpdateDissertationCollection)
    ).subscribe({
      next: (collectionData) => {
        const updateCollection =
          collectionData.value.data.onUpdateDissertationCollection;
        const index = this.state.collections.findIndex(
          (collection) => collection.id === updateCollection.id
        );
        const updateCollections = [
          ...this.state.collections.slice(0, index),
          updateCollection,
          ...this.state.collections.slice(index + 1),
        ];
        this.setState({ collections: updateCollections });
      },
    });
  }

  componentWillUnmount() {
    if (this.createCollectionListener !== undefined) {
      this.createCollectionListener.unsubscribe();
    }
    if (this.updateCollectionListener !== undefined) {
      this.updateCollectionListener.unsubscribe();
    }
  }

  render() {
    const { collections, loading } = this.state;
    return (
      <>
        <Card className="example example-compact">
          <CardHeader title="Dissertation collection" />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Collection Name </th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : collections.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Collections added Yet!</td>
                    </tr>
                  ) : (
                    collections.map((collection, i) => (
                      <tr key={collection.id}>
                        <td>{i + 1}</td>
                        <td>{collection.title}</td>
                        <td>{collection.description}</td>
                        <td>
                          {" "}
                          <Link
                            to={"/dissertation/" + collection.id}
                            className="btn btn-icon btn-light btn-sm"
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Group-folders.svg"
                                )}
                              ></SVG>
                            </span>
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default listCollections;
