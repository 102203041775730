import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { createCourse, updateCourse } from "../../../graphql/mutations";
import UpdateActivity from "../../tools/updateActivity";

export class addCourse extends Component {
  state = {
    courseName: "",
    courseDescription: "",
    editCourseID: null,
    userId: "",
    userName: "",
    loading: false,
    version: null,
  };

  componentDidMount() {
    const { addEditStatus, editingCourse } = this.props;
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    if (!addEditStatus) {
      this.setState({
        courseName: editingCourse.courseName,
        courseDescription: editingCourse.courseDescription,
        editCourseID: editingCourse.id,
        version: editingCourse._version,
      });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = () => {
    const {
      courseName,
      courseDescription,
      userId,
      userName,
      editCourseID,
      version,
    } = this.state;
    console.log(courseName, courseDescription);
    var input = {};
    if (this.props.addEditStatus) {
      input = {
        courseName: courseName,
        courseDescription: courseDescription,
        CreatedByUserID: userId,
      };
      API.graphql(graphqlOperation(createCourse, { input: input })).then(
        (createdCourse) => {
          console.log(createdCourse);
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Course Updated`,
            tableName: "Course",
            referenceID: createdCourse.data.createCourse.id,
            remarks: `Course created by ${createdCourse.data.createCourse.courseName} created by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        }
      );
    } else {
      input = {
        id: editCourseID,
        courseName: courseName,
        courseDescription: courseDescription,
        CreatedByUserID: userId,
        _version: version,
      };
      API.graphql(graphqlOperation(updateCourse, { input: input })).then(
        (updatedCourse) => {
          console.log(updatedCourse);
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Course Updated`,
            tableName: "Course",
            referenceID: updatedCourse.data.updateCourse.id,
            remarks: `Course Updated ${updatedCourse.data.updateCourse.courseName} created by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          this.setState({
            loading: false,
          });
          this.props.closeForm();
        }
      );
    }
  };

  render() {
    const { courseDescription, courseName, loading } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Course Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="BBA"
              name="courseName"
              value={courseName}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Course Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              name="courseDescription"
              value={courseDescription}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Qualifying Subjects</Form.Label>
            <Form.Control
              as="text"
              name="courseQualify"
              value={courseDescription}
              onChange={this.onChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addCourse;
