/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCourse = /* GraphQL */ `
  subscription OnCreateCourse {
    onCreateCourse {
      id
      courseName
      courseDescription
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCourse = /* GraphQL */ `
  subscription OnUpdateCourse {
    onUpdateCourse {
      id
      courseName
      courseDescription
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCourse = /* GraphQL */ `
  subscription OnDeleteCourse {
    onDeleteCourse {
      id
      courseName
      courseDescription
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation {
    onCreateLocation {
      id
      locationName
      locationDescription
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation {
    onUpdateLocation {
      id
      locationName
      locationDescription
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation {
    onDeleteLocation {
      id
      locationName
      locationDescription
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateSpecialization = /* GraphQL */ `
  subscription OnCreateSpecialization {
    onCreateSpecialization {
      id
      specializationName
      specializationDescription
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSpecialization = /* GraphQL */ `
  subscription OnUpdateSpecialization {
    onUpdateSpecialization {
      id
      specializationName
      specializationDescription
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSpecialization = /* GraphQL */ `
  subscription OnDeleteSpecialization {
    onDeleteSpecialization {
      id
      specializationName
      specializationDescription
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateSubject = /* GraphQL */ `
  subscription OnCreateSubject {
    onCreateSubject {
      id
      subjectName
      subjectDescription
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      specializationID
      subjectSpecialization {
        id
        specializationName
        specializationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSubject = /* GraphQL */ `
  subscription OnUpdateSubject {
    onUpdateSubject {
      id
      subjectName
      subjectDescription
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      specializationID
      subjectSpecialization {
        id
        specializationName
        specializationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSubject = /* GraphQL */ `
  subscription OnDeleteSubject {
    onDeleteSubject {
      id
      subjectName
      subjectDescription
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      specializationID
      subjectSpecialization {
        id
        specializationName
        specializationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateBatchGuidelines = /* GraphQL */ `
  subscription OnCreateBatchGuidelines {
    onCreateBatchGuidelines {
      id
      filename
      guidelinesFile {
        bucket
        region
        key
      }

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBatchGuidelines = /* GraphQL */ `
  subscription OnUpdateBatchGuidelines {
    onUpdateBatchGuidelines {
      id
      filename
      guidelinesFile {
        bucket
        region
        key
      }

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBatchGuidelines = /* GraphQL */ `
  subscription OnDeleteBatchGuidelines {
    onDeleteBatchGuidelines {
      id
      filename
      guidelinesFile {
        bucket
        region
        key
      }

      createdAt
      updatedAt
    }
  }
`;
export const onCreateBatch = /* GraphQL */ `
  subscription OnCreateBatch {
    onCreateBatch {
      id
      batchTitle
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      batchType
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      batchGuidelinesID
      batchGuidelines {
        id
        filename
        guidelinesFile {
          bucket
          region
          key
        }

        createdAt
        updatedAt
      }
      studentCount
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBatch = /* GraphQL */ `
  subscription OnUpdateBatch {
    onUpdateBatch {
      id
      batchTitle
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      batchType
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      batchGuidelinesID
      batchGuidelines {
        id
        filename
        guidelinesFile {
          bucket
          region
          key
        }

        createdAt
        updatedAt
      }
      studentCount
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBatch = /* GraphQL */ `
  subscription OnDeleteBatch {
    onDeleteBatch {
      id
      batchTitle
      locationID
      batchLocation {
        id
        locationName
        locationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      batchType
      courseID
      subjectCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      specializationID
      batchSpecialization {
        id
        specializationName
        specializationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      batchGuidelinesID
      batchGuidelines {
        id
        filename
        guidelinesFile {
          bucket
          region
          key
        }

        createdAt
        updatedAt
      }
      studentCount
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateContentMaterials = /* GraphQL */ `
  subscription OnCreateContentMaterials {
    onCreateContentMaterials {
      id
      contentTitle
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      batchID
      batchName {
        id
        batchTitle
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename

          createdAt
          updatedAt
        }
        studentCount
        createdByUserID

        createdAt
        updatedAt
      }
      Content_file {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContentMaterials = /* GraphQL */ `
  subscription OnUpdateContentMaterials {
    onUpdateContentMaterials {
      id
      contentTitle
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      batchID
      batchName {
        id
        batchTitle
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename

          createdAt
          updatedAt
        }
        studentCount
        createdByUserID

        createdAt
        updatedAt
      }
      Content_file {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContentMaterials = /* GraphQL */ `
  subscription OnDeleteContentMaterials {
    onDeleteContentMaterials {
      id
      contentTitle
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      batchID
      batchName {
        id
        batchTitle
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename

          createdAt
          updatedAt
        }
        studentCount
        createdByUserID

        createdAt
        updatedAt
      }
      Content_file {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateActivityLog = /* GraphQL */ `
  subscription OnCreateActivityLog {
    onCreateActivityLog {
      id
      activityItem
      tableName
      referenceID
      remarks
      activityDateTime
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateActivityLog = /* GraphQL */ `
  subscription OnUpdateActivityLog {
    onUpdateActivityLog {
      id
      activityItem
      tableName
      referenceID
      remarks
      activityDateTime
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteActivityLog = /* GraphQL */ `
  subscription OnDeleteActivityLog {
    onDeleteActivityLog {
      id
      activityItem
      tableName
      referenceID
      remarks
      activityDateTime
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateStudent = /* GraphQL */ `
  subscription OnCreateStudent {
    onCreateStudent {
      id
      firstName
      lastName
      company
      qualification
      designation
      StudentUserId
      DOJ
      birthCountry
      birthPlace
      email
      email2
      contactPhone
      contactPhone2
      DOR
      FbID
      linkedIn
      twitterID
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      icon_image {
        bucket
        region
        key
      }
      Status
      accessExpiry
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStudent = /* GraphQL */ `
  subscription OnUpdateStudent {
    onUpdateStudent {
      id
      firstName
      lastName
      company
      qualification
      designation
      StudentUserId
      DOJ
      birthCountry
      birthPlace
      email
      email2
      contactPhone
      contactPhone2
      DOR
      FbID
      linkedIn
      twitterID
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      icon_image {
        bucket
        region
        key
      }
      Status
      accessExpiry
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStudent = /* GraphQL */ `
  subscription OnDeleteStudent {
    onDeleteStudent {
      id
      firstName
      lastName
      company
      qualification
      designation
      StudentUserId
      DOJ
      birthCountry
      birthPlace
      email
      email2
      contactPhone
      contactPhone2
      DOR
      FbID
      linkedIn
      twitterID
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      icon_image {
        bucket
        region
        key
      }
      Status
      accessExpiry
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateAssignment = /* GraphQL */ `
  subscription OnCreateAssignment {
    onCreateAssignment {
      id
      assignmentTitle
      studentID
      courseID
      assignmentCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      batchID
      finalSubmissionDate
      originalContent {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAssignment = /* GraphQL */ `
  subscription OnUpdateAssignment {
    onUpdateAssignment {
      id
      assignmentTitle
      studentID
      courseID
      assignmentCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      batchID
      finalSubmissionDate
      originalContent {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAssignment = /* GraphQL */ `
  subscription OnDeleteAssignment {
    onDeleteAssignment {
      id
      assignmentTitle
      studentID
      courseID
      assignmentCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      batchID
      finalSubmissionDate
      originalContent {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateAssignmentSubmission = /* GraphQL */ `
  subscription OnCreateAssignmentSubmission {
    onCreateAssignmentSubmission {
      id
      assignmentID
      assignmentName {
        id
        assignmentTitle
        studentID
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          createdByUserID

          createdAt
          updatedAt
        }
        batchID
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        createdByUserID

        createdAt
        updatedAt
      }
      studentID
      studentName
      submissionDate
      assignmentFile {
        bucket
        region
        key
      }
      remarks
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAssignmentSubmission = /* GraphQL */ `
  subscription OnUpdateAssignmentSubmission {
    onUpdateAssignmentSubmission {
      id
      assignmentID
      assignmentName {
        id
        assignmentTitle
        studentID
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          createdByUserID

          createdAt
          updatedAt
        }
        batchID
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        createdByUserID

        createdAt
        updatedAt
      }
      studentID
      studentName
      submissionDate
      assignmentFile {
        bucket
        region
        key
      }
      remarks
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAssignmentSubmission = /* GraphQL */ `
  subscription OnDeleteAssignmentSubmission {
    onDeleteAssignmentSubmission {
      id
      assignmentID
      assignmentName {
        id
        assignmentTitle
        studentID
        courseID
        assignmentCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        subjectID
        subjectName {
          id
          subjectName
          subjectDescription
          courseID
          specializationID
          createdByUserID

          createdAt
          updatedAt
        }
        batchID
        finalSubmissionDate
        originalContent {
          bucket
          region
          key
        }
        createdByUserID

        createdAt
        updatedAt
      }
      studentID
      studentName
      submissionDate
      assignmentFile {
        bucket
        region
        key
      }
      remarks
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateSelfStudentContent = /* GraphQL */ `
  subscription OnCreateSelfStudentContent {
    onCreateSelfStudentContent {
      id
      SelfContent_title
      courseID
      contentCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      specializationID
      contentSpecialization {
        id
        specializationName
        specializationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      subjectID
      contentSubject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      studentID
      contentFile {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSelfStudentContent = /* GraphQL */ `
  subscription OnUpdateSelfStudentContent {
    onUpdateSelfStudentContent {
      id
      SelfContent_title
      courseID
      contentCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      specializationID
      contentSpecialization {
        id
        specializationName
        specializationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      subjectID
      contentSubject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      studentID
      contentFile {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSelfStudentContent = /* GraphQL */ `
  subscription OnDeleteSelfStudentContent {
    onDeleteSelfStudentContent {
      id
      SelfContent_title
      courseID
      contentCourse {
        id
        courseName
        courseDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      specializationID
      contentSpecialization {
        id
        specializationName
        specializationDescription
        CreatedByUserID

        createdAt
        updatedAt
      }
      subjectID
      contentSubject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      studentID
      contentFile {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateBulkMail = /* GraphQL */ `
  subscription OnCreateBulkMail {
    onCreateBulkMail {
      id
      mailTitle
      batchID
      receiverMail
      senderMail
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBulkMail = /* GraphQL */ `
  subscription OnUpdateBulkMail {
    onUpdateBulkMail {
      id
      mailTitle
      batchID
      receiverMail
      senderMail
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBulkMail = /* GraphQL */ `
  subscription OnDeleteBulkMail {
    onDeleteBulkMail {
      id
      mailTitle
      batchID
      receiverMail
      senderMail
      CreatedByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateSemester = /* GraphQL */ `
  subscription OnCreateSemester {
    onCreateSemester {
      id
      semesterTitle
      batchID
      batchName {
        id
        batchTitle
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename

          createdAt
          updatedAt
        }
        studentCount
        createdByUserID

        createdAt
        updatedAt
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSemester = /* GraphQL */ `
  subscription OnUpdateSemester {
    onUpdateSemester {
      id
      semesterTitle
      batchID
      batchName {
        id
        batchTitle
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename

          createdAt
          updatedAt
        }
        studentCount
        createdByUserID

        createdAt
        updatedAt
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSemester = /* GraphQL */ `
  subscription OnDeleteSemester {
    onDeleteSemester {
      id
      semesterTitle
      batchID
      batchName {
        id
        batchTitle
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename

          createdAt
          updatedAt
        }
        studentCount
        createdByUserID

        createdAt
        updatedAt
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;

export const onDeleteGrades = /* GraphQL */ `
  subscription OnDeleteGrades {
    onDeleteGrades {
      id
      StudentID
      SemesterID
      semester {
        id
        semesterTitle
        batchID
        batchName {
          id
          batchTitle
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          studentCount
          createdByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      Mark
      Grade
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      Remarks
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateOfficial = /* GraphQL */ `
  subscription OnCreateOfficial {
    onCreateOfficial {
      id
      authUserID
      officeID
      firstName
      lastName
      email
      contactNo
      designation
      typeOfAccount
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOfficial = /* GraphQL */ `
  subscription OnUpdateOfficial {
    onUpdateOfficial {
      id
      authUserID
      officeID
      firstName
      lastName
      email
      contactNo
      designation
      typeOfAccount
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOfficial = /* GraphQL */ `
  subscription OnDeleteOfficial {
    onDeleteOfficial {
      id
      authUserID
      officeID
      firstName
      lastName
      email
      contactNo
      designation
      typeOfAccount
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      createdDate
      type
      topic
      message
      officialID
      givenByUser {
        id
        authUserID
        officeID
        firstName
        lastName
        email
        contactNo
        designation
        typeOfAccount
        createdByUserID

        createdAt
        updatedAt
      }
      studentID
      batchID
      status
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      createdDate
      type
      topic
      message
      officialID
      givenByUser {
        id
        authUserID
        officeID
        firstName
        lastName
        email
        contactNo
        designation
        typeOfAccount
        createdByUserID

        createdAt
        updatedAt
      }
      studentID
      batchID
      status
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      createdDate
      type
      topic
      message
      officialID
      givenByUser {
        id
        authUserID
        officeID
        firstName
        lastName
        email
        contactNo
        designation
        typeOfAccount
        createdByUserID

        createdAt
        updatedAt
      }
      studentID
      batchID
      status
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateSchedule = /* GraphQL */ `
  subscription OnCreateSchedule {
    onCreateSchedule {
      id
      createdDate
      type
      batchID
      batch {
        id
        batchTitle
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename

          createdAt
          updatedAt
        }
        studentCount
        createdByUserID

        createdAt
        updatedAt
      }
      location
      lecturer
      subjectID
      subject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      startDate
      remarks
      lecturerImage {
        bucket
        region
        key
      }
      lecturerProfile {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSchedule = /* GraphQL */ `
  subscription OnUpdateSchedule {
    onUpdateSchedule {
      id
      createdDate
      type
      batchID
      batch {
        id
        batchTitle
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename

          createdAt
          updatedAt
        }
        studentCount
        createdByUserID

        createdAt
        updatedAt
      }
      location
      lecturer
      subjectID
      subject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      startDate
      remarks
      lecturerImage {
        bucket
        region
        key
      }
      lecturerProfile {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSchedule = /* GraphQL */ `
  subscription OnDeleteSchedule {
    onDeleteSchedule {
      id
      createdDate
      type
      batchID
      batch {
        id
        batchTitle
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename

          createdAt
          updatedAt
        }
        studentCount
        createdByUserID

        createdAt
        updatedAt
      }
      location
      lecturer
      subjectID
      subject {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          CreatedByUserID

          createdAt
          updatedAt
        }
        createdByUserID

        createdAt
        updatedAt
      }
      startDate
      remarks
      lecturerImage {
        bucket
        region
        key
      }
      lecturerProfile {
        bucket
        region
        key
      }
      createdByUserID

      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserAccount = /* GraphQL */ `
  subscription OnCreateUserAccount {
    onCreateUserAccount {
      id
      accountUserID
      officeID
      firstName
      lastName
      company
      qualification
      designation
      occupation
      language
      timeZone
      website
      DOJ
      birthCountry
      birthPlace
      email
      emailPersonal
      contactPhone
      contactPersonal
      DOR
      address {
        addressLine
        city
        state
        postCode
      }
      social {
        linkedIn
        facebook
        twitter
        instagram
      }
      pcAgreement {
        bucket
        region
        key
      }
      graduationForm {
        bucket
        region
        key
      }
      batchId
      activeBatch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchType
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID

          createdAt
          updatedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename
          deniedUsers

          createdAt
          updatedAt
        }
        studentCount
        deniedUsers
        createdByUserID

        createdAt
        updatedAt
      }
      batchInformation {
        batchId
        start_date
        end_date
        status
      }
      icon_image {
        bucket
        region
        key
      }
      communication {
        email
        sms
        phone
      }
      groupNotification
      personalNotification
      emailNotification
      Status
      accessExpiry
      typeOfAccount
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateReportCard = /* GraphQL */ `
  subscription OnUpdateReportCard {
    onUpdateReportCard {
      id
      StudentID
      student {
        id
        accountUserID
        officeID
        firstName
        lastName
        company
        qualification
        designation
        occupation
        language
        timeZone
        website
        DOJ
        birthCountry
        birthPlace
        email
        emailPersonal
        contactPhone
        contactPersonal
        DOR
        address {
          addressLine
          city
          state
          postCode
        }
        social {
          linkedIn
          facebook
          twitter
          instagram
        }
        pcAgreement {
          bucket
          region
          key
        }
        graduationForm {
          bucket
          region
          key
        }
        batchId
        activeBatch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchInformation {
          batchId
          start_date
          end_date
          status
        }
        icon_image {
          bucket
          region
          key
        }
        communication {
          email
          sms
          phone
        }
        groupNotification
        personalNotification
        emailNotification
        Status
        sendInvitationMail
        accessExpiry
        typeOfAccount
        studentReport
        degreeID
        degree {
          id
          StudentID
          batchID
          applicationStatus
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      batchID
      batch {
        id
        batchTitle
        batchIconName
        locationID
        batchLocation {
          id
          locationName
          locationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchType
        courseID
        scheduleDocument {
          bucket
          region
          key
        }
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        batchSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchGuidelinesID
        batchGuidelines {
          id
          filename
          deniedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchDocuments
        studentCount
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentNameID
      gradesCount
      grades {
        items {
          id
          reportCardID
          searchString
          Mark
          Grade
          status
          subjectID
          subjectName {
            id
            subjectName
          }
          Remarks
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const onCreateGrades = /* GraphQL */ `
  subscription OnCreateGrades {
    onCreateGrades {
      id
      reportCardID
      reportCard {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          items {
            id
            reportCardID
            searchString
            Mark
            Grade
            status
            subjectID
            subjectName {
              id
              subjectName
            }
            Remarks
            deniedUsers
            createdByUserID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      Mark
      Grade
      status
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGrades = /* GraphQL */ `
  subscription OnUpdateGrades {
    onUpdateGrades {
      id
      reportCardID
      reportCard {
        id
        StudentID
        student {
          id
          accountUserID
          officeID
          firstName
          lastName
          company
          qualification
          designation
          occupation
          language
          timeZone
          website
          DOJ
          birthCountry
          birthPlace
          email
          emailPersonal
          contactPhone
          contactPersonal
          DOR
          batchId
          groupNotification
          personalNotification
          emailNotification
          Status
          sendInvitationMail
          accessExpiry
          typeOfAccount
          studentReport
          degreeID
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        batchID
        batch {
          id
          batchTitle
          batchIconName
          locationID
          batchType
          courseID
          specializationID
          batchGuidelinesID
          batchDocuments
          studentCount
          deniedUsers
          createdByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentNameID
        gradesCount
        grades {
          items {
            id
            reportCardID
            searchString
            Mark
            Grade
            status
            subjectID
            subjectName {
              id
              subjectName
            }
            Remarks
            deniedUsers
            createdByUserID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        status
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      searchString
      Mark
      Grade
      status
      subjectID
      subjectName {
        id
        subjectName
        subjectDescription
        courseID
        subjectCourse {
          id
          courseName
          courseDescription
          qualifySubjects
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        specializationID
        subjectSpecialization {
          id
          specializationName
          specializationDescription
          deniedUsers
          CreatedByUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        deniedUsers
        createdByUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Remarks
      deniedUsers
      createdByUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
