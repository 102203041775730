import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import MultiSelect from "../tools/reactMultiSelect";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { listUserAccounts } from "../../graphql/queries";
import { createMessage } from "../../graphql/mutations";
import UpdateActivity from "../tools/updateActivity";

export class addMessage extends Component {
  state = {
    allReceivers: [],
    prevSelection: {},
    activeSelection: {},
    type: this.props.messageType,
    sender: "",
    receiver: "",
    selectedReceivers: [],
    text: "",
    status: "",
    parentMessage: "",
    createdByUserID: "",
    loading: false,
    errorMessage: "",
  };

  getUsers(requiredUserType) {
    API.graphql(
      graphqlOperation(listUserAccounts, {
        filter: {
          typeOfAccount: { eq: requiredUserType },
        },
      })
    ).then((result) => {
      var newUsers = result.data.listUserAccounts.items;
      if (newUsers.length === 0) {
        this.setState({
          message: "No Users in Batch Added yet!",
        });
      }
      var userSuggestions = [];
      newUsers.map((user) => {
        userSuggestions.push({
          key: user.id,
          label: `${user.firstName} ${user.lastName} (${user.officeID})`,
          value: user.id,
        });
        return null;
      });
      this.setState({
        allReceivers: userSuggestions,
        loading: false,
      });
    });
  }

  componentDidMount() {
    Auth.currentUserInfo().then((user) => {
      this.setState({
        createdByUserID: user.attributes.sub,
      });
    });
  }

  componentDidUpdate() {
    const { activeSelection, prevSelection } = this.state;
    if (
      activeSelection !== prevSelection &&
      activeSelection.userType !== undefined
    ) {
      this.getUsers(activeSelection.userType);
      this.setState({
        prevSelection: activeSelection,
      });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onSelectUser(value) {
    this.setState({
      selectedReceivers: value,
    });
  }

  onSubmit() {
    const { createdByUserID, selectedReceivers, text } = this.state;
    const { user } = this.props;
    var input = {};
    if (text !== "" && selectedReceivers !== []) {
      selectedReceivers.map((receiver) => {
        input = {
          type: "Message",
          sender: user.id,
          receiver: receiver.value,
          text: text,
          status: "Created",
          parentMessage: null,
          createdByUserID: createdByUserID,
        };
        console.log(input);
        API.graphql(graphqlOperation(createMessage, { input: input })).then(
          (createdMessage) => {
            var message = createdMessage.data.createMessage;
            UpdateActivity.updateActivity({
              activityItem: "Message Sending",
              tableName: "Message",
              referenceID: "message.id",
              remarks: `Message send from ${message.senderDetail.firstName} to ${message.receiverDetail.firstName}`,
              activityDateTime: message.createdAt,
            });

            this.setState({
              loading: false,
            });
            this.props.closeForm();
          }
        );
        return null;
      });
    } else {
      this.setState({
        errorMessage: "Missing Message or receiver",
        loading: false,
      });
      setTimeout(() => {
        this.setState({ errorMessage: "", loading: false });
      }, 3000);
    }
  }

  render() {
    const {
      allReceivers,
      selectedReceivers,
      activeSelection,
      loading,
      text,
      errorMessage,
    } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            {[
              { userTitle: "Administration", userValue: "Administrator" },
              { userTitle: "Exam Department", userValue: "Examiner" },
              { userTitle: "Finance", userValue: "Accounts" },
              { userTitle: "Students", userValue: "Student" },
              { userTitle: "Dean", userValue: "Dean" },
            ].map((user) => {
              if (this.props.userType !== user.userValue) {
                return (
                  <Form.Check
                    type="radio"
                    label={user.userTitle}
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                    checked={activeSelection.userType === user.userValue}
                    value={user.userValue}
                    onChange={(e) =>
                      this.setState({
                        activeSelection: { userType: e.target.value },
                        loading: true,
                      })
                    }
                  />
                );
              }
              return null;
            })}
          </Form.Group>
          <MultiSelect
            label="To"
            suggestions={allReceivers}
            addedSuggestions={selectedReceivers}
            addBatch={(value) => this.onSelectUser(value)}
          />
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Message</Form.Label>
            <Form.Control
              value={text}
              name="text"
              onChange={this.onChange}
              as="textarea"
              rows="3"
            />
          </Form.Group>

          {errorMessage !== "" ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errorMessage}</div>
            </div>
          ) : null}
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              "Send Message"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.reducerAuth.user,
  userType: state.reducerAuth.userType,
});

export default connect(mapStateToProps, null)(addMessage);
