import React, { Component } from "react";
import ReportCard from "./viewReportCard";

export class gradesDashboard extends Component {
  render() {
    return (
      <div>
        <ReportCard />
      </div>
    );
  }
}

export default gradesDashboard;
