import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ListAssignments from "./listBatchAssignments";

export default class assignments extends Component {
  render() {
    return (
      <Tabs defaultActiveKey="batch" id="uncontrolled-tab-example">
        <Tab eventKey="batch" title="Batch">
          <ListAssignments
            key="batch"
            assignmentType={"Batch"}
            batchDetails={this.props.batchDetails}
          />
        </Tab>
        <Tab eventKey="student" title="Student">
          <ListAssignments
            key="student"
            assignmentType={"Student"}
            batchDetails={this.props.batchDetails}
          />
        </Tab>
      </Tabs>
    );
  }
}
