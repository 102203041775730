import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { listDissertationCollections } from "../../../graphql/queries";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditCollections from "./addEditCollections";
import {
  onCreateDissertationCollection,
  onUpdateDissertationCollection,
} from "../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class listCollections extends Component {
  state = {
    collections: [],
    loading: true,
    message: null,
    openAddCollection: false,
    deleteCollectionName: null,
    deleteCollectionID: null,
    openDeleteCollection: false,
    editItem: null,
    addEditStatus: true,
  };

  getCollections() {
    API.graphql(graphqlOperation(listDissertationCollections)).then(
      (result) => {
        var collections = result.data.listDissertationCollections.items;
        if (collections.length === 0) {
          this.setState({
            message: "No collections Added yet!",
            loading: false,
          });
        } else {
          collections = collections.filter(
            (collection) => collection._deleted !== true
          );
          this.setState({ collections, loading: false });
        }
      }
    );
  }

  componentDidMount() {
    this.getCollections();

    //Function to listen to post collection
    this.createCollectionListener = API.graphql(
      graphqlOperation(onCreateDissertationCollection)
    ).subscribe({
      next: (collectionData) => {
        const newCollection =
          collectionData.value.data.onCreateDissertationCollection;
        const prevCollections = this.state.collections.filter(
          (collection) => collection.id !== newCollection.id
        );
        this.setState({ collections: [newCollection, ...prevCollections] });
      },
    });

    //Function to listen to update collections
    this.updateCollectionListener = API.graphql(
      graphqlOperation(onUpdateDissertationCollection)
    ).subscribe({
      next: (collectionData) => {
        const updateCollection =
          collectionData.value.data.onUpdateDissertationCollection;
        const index = this.state.collections.findIndex(
          (collection) => collection.id === updateCollection.id
        );
        const updateCollections = [
          ...this.state.collections.slice(0, index),
          updateCollection,
          ...this.state.collections.slice(index + 1),
        ];
        this.setState({ collections: updateCollections });
      },
    });
  }

  componentWillUnmount() {
    if (this.createCollectionListener !== undefined) {
      this.createCollectionListener.unsubscribe();
    }
    if (this.updateCollectionListener !== undefined) {
      this.updateCollectionListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditCollection: false,
    });
  };

  render() {
    const {
      collections,
      loading,
      openAddEditCollection,
      deleteCollectionName,
      openDeleteCollection,
      addEditStatus,
      editItem,
    } = this.state;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditCollection: true,
                addEditStatus: true,
              })
            }
            title={"Add collection"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditCollection}
          onHide={() => this.setState({ openAddEditCollection: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Collection" : "Edit Collection"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditCollections
              addEditStatus={addEditStatus}
              editingCollection={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteCollection}
          onHide={() => this.setState({ openDeleteCollection: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteCollectionName} collection
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure u want to delete the collection?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.deleteCollection}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteCollection: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="Dissertation collection" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Collection Name </th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : collections.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Collections added Yet!</td>
                    </tr>
                  ) : (
                    collections.map((collection, i) => (
                      <tr key={collection.id}>
                        <td>{i + 1}</td>
                        <td>{collection.title}</td>
                        <td>{collection.description}</td>
                        <td>
                          {" "}
                          <Link
                            to={"/dissertation/" + collection.id}
                            className="btn btn-icon btn-light btn-sm"
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Group-folders.svg"
                                )}
                              ></SVG>
                            </span>
                          </Link>
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: collection,
                                openAddEditCollection: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() => {
                              /*
                              this.setState({
                                deleteCollectionName: collection.collectionName,
                                deleteCollectionID: collection.id,
                                openDeleteCollection: true,
                              });
                              */
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default listCollections;
