import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import { listSubjects, listUserAccounts } from "../../../../graphql/queries";
import {
  createSelfStudentContent,
  updateSelfStudentContent,
} from "../../../../graphql/mutations";
import awsExports from "../../../../aws-exports";
import UpdateActivity from "../../../tools/updateActivity";

export class addStudyMaterial extends Component {
  state = {
    subjects: [],
    students: [],
    studentNextToken: null,
    contentFile: null,
    SelfContent_title: "",
    editContentID: null,
    subjectID: null,
    batchID: null,
    studentID: null,
    userId: "",
    userName: "",
    loading: false,
    version: null,
    SelfContent_titleError: false,
    contentSubjectError: false,
    contentFileError: false,
    subjectNextToken: null,
  };

  getSubjects() {
    const { subjectNextToken } = this.state;
    API.graphql(
      graphqlOperation(listSubjects, {
        limit: 100,
        nextToken: subjectNextToken,
      })
    ).then((result) => {
      var subjects = result.data.listSubjects.items.filter(
        (subject) => subject.courseID === this.props.batchDetails.courseID
      );
      subjects = subjects.filter((subject) => subject._deleted !== true);
      if (subjects.length === 0) {
        this.setState({
          message: "No Subjects Added yet!",
        });
      }
      var allSubjects = [...this.state.subjects, ...subjects];
      this.setState({
        subjects: allSubjects,
        subjectNextToken: result.data.listSubjects.nextToken,
        loading: false,
      });
      if (result.data.listSubjects.nextToken !== null) {
        this.getSubjects();
      }
    });
  }

  getBatchStudents() {
    API.graphql(
      graphqlOperation(listUserAccounts, {
        limit: 100,
        nextToken: this.state.studentNextToken,
      })
    ).then((result) => {
      var students = result.data.listUserAccounts.items.filter(
        (student) =>
          student.batchId === this.props.batchDetails.id &&
          student._deleted !== true
      );
      if (students.length === 0) {
        this.setState({
          message: "No Student in Batch Added yet!",
        });
      }

      this.setState({
        students: [...this.state.students, ...students],
        studentNextToken: result.data.listUserAccounts.nextToken,
      });
      if (result.data.listUserAccounts.nextToken !== null) {
        this.getBatchStudents();
      } else {
        this.setstate({
          loading: false,
        });
      }
    });
  }

  componentDidMount() {
    const { addEditStatus, editingContent } = this.props;
    this.getSubjects();
    this.getBatchStudents();

    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
        batchID: this.props.batchDetails.id,
      });
    });

    if (!addEditStatus) {
      this.setState({
        SelfContent_title: editingContent.contentTitle,
        subjectID: editingContent.subjectID,
        editContentID: editingContent.id,
        studentID: editingContent.studentID,
        version: editingContent._version,
      });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onChangeFile = (e) => this.setState({ [e.target.name]: e.target.files[0] });

  onSubmit = () => {
    const {
      SelfContent_title,
      userName,
      contentFile,
      subjectID,
      studentID,
      batchID,
      userId,
      editContentID,
      version,
    } = this.state;
    var valueMissing = false;
    var input = {};

    if (this.props.addEditStatus) {
      if (
        SelfContent_title !== "" &&
        subjectID !== null &&
        batchID !== null &&
        studentID !== null &&
        contentFile !== null
      ) {
        var file_name = contentFile.name.split(".");
        var unique_filename =
          file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
        Storage.put(unique_filename, contentFile).then((uploadedContent) => {
          input = {
            SelfContent_title: SelfContent_title,
            batchID: batchID,
            subjectID: subjectID,
            studentID: studentID,
            contentFile: {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: uploadedContent.key,
            },
            createdByUserID: userId,
          };
          API.graphql(
            graphqlOperation(createSelfStudentContent, { input: input })
          )
            .then((createdContent) => {
              console.log("Created Content", createdContent);
              this.setState({
                loading: false,
              });
              var activityTime = new Date();
              UpdateActivity.updateActivity({
                activityItem: `Self Study Material created`,
                tableName: "SelfStudentContent",
                referenceID: createdContent.id,
                remarks: `self Study Material ${createdContent.contentName} created by ${userName}`,
                activityDateTime: activityTime.toISOString(),
              });
              this.props.closeForm();
            })
            .catch((err) => {
              console.log(err);
              var activityTime = new Date();
              UpdateActivity.updateActivity({
                activityItem: `SelfStudy Material Failed`,
                tableName: "SelfStudentContent",
                referenceID: null,
                remarks: err.message,
                activityDateTime: activityTime.toISOString(),
              });
              Storage.remove(unique_filename).then(() => {
                console.log("Upload removed due to failed DB insert!");
              });
            });
        });
      } else {
        valueMissing = true;
        console.log(
          SelfContent_title,
          subjectID,
          batchID,
          studentID,
          contentFile
        );
      }
    } else {
      input = {
        id: editContentID,
        SelfContent_title: SelfContent_title,
        batchID: batchID,
        studentID: studentID,
        createdByUserID: userId,
        _version: version,
      };
      var uploadedFileKey = null;
      if (contentFile !== null) {
        Storage.put(unique_filename, contentFile).then((uploadedContent) => {
          uploadedFileKey = uploadedContent.key;
          input["contentFile"] = {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: uploadedFileKey,
          };
          API.graphql(
            graphqlOperation(updateSelfStudentContent, { input: input })
          ).then((updatedContent) => {
            console.log("updated Content:", updatedContent);
            this.setState({
              loading: false,
            });
            var activityTime = new Date();
            UpdateActivity.updateActivity({
              activityItem: `Content Material updated`,
              tableName: "ContentMaterials",
              referenceID: updatedContent.id,
              remarks: `self Study Material ${updatedContent.contentName} created by ${userName}`,
              activityDateTime: activityTime.toISOString(),
            });
            this.props.closeForm();
          });
        });
      }
    }
    if (valueMissing) {
      console.log("Information Missing!");
      var nameMissing = false,
        subjectMissing = false,
        studentMissing = false;
      var fileMissing = false;
      if (SelfContent_title === "") {
        nameMissing = true;
      }
      if (contentFile === null) {
        fileMissing = true;
      }
      if (studentID === null) {
        studentMissing = true;
      }
      if (subjectID === null) {
        subjectMissing = true;
      }
      this.setState({
        loading: false,
        SelfContent_titleError: nameMissing,
        contentSubjectError: subjectMissing,
        studentMissing: studentMissing,
        contentFileError: fileMissing,
      });
    }
  };

  render() {
    const {
      subjects,
      students,
      SelfContent_title,
      loading,
      SelfContent_titleError,
      subjectID,
      studentID,
      contentSubjectError,
      contentFileError,
    } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Content Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Content Name"
              name="SelfContent_title"
              value={SelfContent_title}
              onChange={this.onChange}
            />
            {SelfContent_titleError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">File name missing!</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              as="select"
              name="subjectID"
              disabled={!this.props.addEditStatus}
              value={subjectID}
              onChange={this.onChange}
            >
              <option value={null}>Select A Subject</option>
              {subjects.map((subject) => (
                <option value={subject.id}>{subject.subjectName}</option>
              ))}
            </Form.Control>
            {contentSubjectError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Please select a Subject</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Student</Form.Label>
            <Form.Control
              as="select"
              name="studentID"
              value={studentID}
              onChange={this.onChange}
            >
              <option value={null}>
                {loading ? "Loading" : "Select A Student"}
              </option>
              {students.map((student) => (
                <option value={student.id}>
                  {student.firstName} {student.lastName}
                </option>
              ))}
            </Form.Control>
            {contentSubjectError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">Please select a Student</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Content File</Form.Label>
            <Form.Control
              type="file"
              name="contentFile"
              onChange={this.onChangeFile}
            />
            {contentFileError ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">File Missing</div>
              </div>
            ) : null}
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <>
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span class="sr-only">Loading...</span>
              </>
            ) : this.props.addEditStatus ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}

export default addStudyMaterial;
