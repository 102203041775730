import React from "react";
import CreateUserForm from "./createUserForm";

export default function CreateUser(props) {
  const userType = props.userType;

  const requiredFields = {
    accountUserID: "",
    officeID: "",
    firstName: "",
    lastName: "",
    DOJ: "2020-01-01",
    birthCountry: "None",
    birthPlace: "None",
    email: "",
    emailPersonal: "None@none.com",
    contactPhone: "",
    contactPersonal: "None",
    DOR: "2020-01-01",
    address: {
      addressLine: "None",
      city: "None",
      state: "None",
      postCode: "None",
    },
    communication: {
      email: true,
      sms: true,
      phone: true,
    },
    groupNotification: true,
    personalNotification: true,
    emailNotification: true,
    Status: true,
    accessExpiry: "",
    typeOfAccount: "String",
    createdByUserID: "String",
  };

  return (
    <CreateUserForm
      userType={userType}
      closeForm={() => props.closeForm()}
      initialValues={requiredFields}
    />
  );
}
