import jsPDF from "jspdf";
import "jspdf-autotable";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

// define a generatePDF function that accepts a tickets argument
const generatePDF = (
  tableRows,
  tableColumn,
  textContents,
  headingText,
  footerText
) => {
  // initialize jsPDF
  const doc = new jsPDF();
  var image = new Image();
  image.src = toAbsoluteUrl("/media/logos/Basic.png");
  doc.addImage(image, "PNG", 120, 10, 50, 10); //base64 image, format, x-coordinate, y-coordinate, width, height
  // startY is basically margin-top
  // ticket title. and margin-top + margin-left
  doc.text(headingText, 14, 15);
  doc.text(footerText, 14, 280);
  var yCoordinate = 24;
  if (textContents.length !== 0) {
    textContents.map((text) => {
      doc.text(text, 14, yCoordinate);
      yCoordinate = yCoordinate + 10;
    });
  }
  doc.autoTable(tableColumn, tableRows, { startY: yCoordinate });
  const todayDate = new Date();
  doc.save(`${headingText}_${todayDate.toISOString()}.pdf`);
};

export default generatePDF;
