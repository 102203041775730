import React from "react";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_helpers";

export default function StudentMenu({
  layoutProps,
  getMenuItemActive,
  userType,
}) {
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`}>
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* ---------------------------Academics----------------------------- */}
        <li className="menu-section ">
          <h4 className="menu-text">Academics</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {["Admin", "Administrator", "Examination"].indexOf(userType) !==
        null ? (
          <>
            <li
              className={`menu-item ${getMenuItemActive(
                "/coursematerial",
                false
              )}`}
            >
              <NavLink className="menu-link" to="/coursematerial">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                  />
                </span>
                <span className="menu-text">Course Material</span>
              </NavLink>
            </li>
            <li className={`menu-item ${getMenuItemActive("/library", false)}`}>
              <NavLink className="menu-link" to="/library">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")}
                  />
                </span>
                <span className="menu-text">E Library</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/assignments",
                false
              )}`}
            >
              <NavLink className="menu-link" to="/assignments">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Bookmark.svg")}
                  />
                </span>
                <span className="menu-text">Assignments</span>
              </NavLink>
            </li>
            <li className={`menu-item ${getMenuItemActive("/grades", false)}`}>
              <NavLink className="menu-link" to="/grades">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Folder-star.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Grades</span>
              </NavLink>
            </li>
          </>
        ) : null}
        {/* --------------------------- End Academics----------------------------- */}

        {/* Academics */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Others</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/*begin::2 Level*/}

        {/*begin::2 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/notifications", false)}`}
        >
          <NavLink className="menu-link" to="/notifications">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Notifications</span>
          </NavLink>
        </li>
        {/*end::2 Level*/}

        {/*begin::2 Level*/}
        <li className={`menu-item ${getMenuItemActive("/schedules", false)}`}>
          <NavLink className="menu-link" to="/schedules">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")}
              />
            </span>
            <span className="menu-text">Schedules</span>
          </NavLink>
        </li>
        {/*end::2 Level*/}

        {/*begin::2 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/fee-payments", false)}`}
        >
          <NavLink className="menu-link" to="/fee-payments">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Fee Payment</span>
          </NavLink>
        </li>
        {/*end::2 Level*/}

        {/* Custom */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
