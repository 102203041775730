import React, { useState } from "react";
import { Modal, Button, Form, Toast } from "react-bootstrap";
import { connect } from "react-redux";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { S3Image } from "aws-amplify-react";
import { updateUserAccount } from "../../graphql/mutations";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import awsExports from "../../aws-exports";
import AWS from "aws-sdk";
import UpdateActivity from "../tools/updateActivity";

function UserProfileInfo(props) {
  // Fields
  const [loading, setLoading] = useState(false);
  const [removeImageModal, setRemoveImageModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [errorImage, setErrorImage] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [addImage, setAddImage] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [passwordResetMessage, setPasswordResetMessage] = useState(null);
  const user = props.userInformation;
  AWS.config.update({
    region: awsExports.aws_cognito_region,
    accessKeyId: "AKIAQ23KTFUAHAKFZ3FA",
    secretAccessKey: "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",
  });
  const cognito = new AWS.CognitoIdentityServiceProvider();

  // UI Helpers
  const initialValues = {
    pic: user.icon_image,
    addressLine: user.address.addressLine,
    city: user.address.city,
    state: user.address.state,
    postCode: user.address.postCode,
    firstname: user.firstName,
    lastname: user.lastName,
    officeID: user.officeID,
    companyName: user.company,
    batch: user.batchId,
    phone: user.contactPhone,
    email: user.email,
    website: user.website,
    version: user._version,
  };

  const Schema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    officeID: Yup.string().required("Office ID is required"),
    addressLine: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    postCode: Yup.string().nullable(),
    batch: Yup.string(),
    companyName: Yup.string().nullable(),
    website: Yup.string().nullable(),
  });

  const disableUser = () => {
    setLoading(true);
    var params = {
      UserPoolId: "ap-south-1_fwVjfe8jP" /* required */,
      Username: user.email /* required */,
    };
    cognito.adminDisableUser(params, function(err, data) {
      if (err) console.log("failed", err, err.stack);
      // an error occurred
      else {
        console.log("success", data);
        var input = {
          id: user.id,
          Status: "Disabled",
          _version: user._version,
        };
        API.graphql(graphqlOperation(updateUserAccount, { input: input }))
          .then((updatedUser) => {
            console.log("updated User:", updatedUser);
            setToastMessage({
              heading: "Login Disabled",
              body: "User login has been disabled",
              status: "success",
            });
            setOpenToast(true);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setToastMessage({
              heading: "Failed",
              body: "Action Failed, Please re-try!",
              status: "danger",
            });
            setOpenToast(true);
            setLoading(false);
          });
      } // successful response
    });
  };

  const enableUser = () => {
    var params = {
      UserPoolId: "ap-south-1_fwVjfe8jP" /* required */,
      Username: user.email /* required */,
    };
    cognito.adminEnableUser(params, function(err, data) {
      if (err) console.log(err, err.stack);
      // an error occurred
      else {
        console.log("success", data);
        var input = {
          id: user.id,
          Status: "Enabled",
          _version: user._version,
        };
        API.graphql(graphqlOperation(updateUserAccount, { input: input })).then(
          (updatedUser) => {
            console.log("updated User:", updatedUser);
            setToastMessage({
              heading: "Login Enabled",
              body: "User login has been enabled",
              status: "success",
            });
            setOpenToast(true);
            setLoading(false);
          }
        );
      } // successful response
    });
  };

  const resetUserPassword = () => {
    setLoading(true);
    var params = {
      UserPoolId: "ap-south-1_fwVjfe8jP" /* required */,
      Username: user.email /* required */,
      Password: "NewPassword_1234",
      Permanent: true,
    };

    cognito.adminSetUserPassword(params, function(err, data) {
      if (err) console.log(err, err.stack);
      // an error occurred
      else {
        console.log("success", data);
        var activityTime = new Date();
        UpdateActivity.updateActivity({
          activityItem: `Student Password Reset to Default`,
          tableName: "UserAccount",
          referenceID: user.id,
          remarks: `Student ${user.firstName} password Reset to default Value`,
          activityDateTime: activityTime.toISOString(),
        });
        setToastMessage({
          heading: "User Password Rest",
          body: "Password has been reset to default successfully",
          status: "success",
        });
        setOpenToast(true);
        setLoading(false);
        setPasswordResetMessage("Password Reset Successfull");
        setTimeout(() => {
          setPasswordResetMessage(null);
          setOpenResetPassword(false);
        }, 3000);
      } // successful response
    });
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      var input = {
        id: user.id,
        firstName: values.firstname,
        lastName: values.lastname,
        officeID: values.officeID,
        address: {
          addressLine: values.addressLine,
          city: values.city,
          state: values.state,
          postCode: values.postCode,
        },
        company: values.companyName,
        _version: user._version,
      };
      API.graphql(graphqlOperation(updateUserAccount, { input: input }))
        .then((updatedUser) => {
          console.log("updated User:", updatedUser);
          setAddImage(false);
          setToastMessage({
            heading: "Profile Updated",
            body: "Profile has been updated!",
            status: "success",
          });
          setOpenToast(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setStatus({ error: err.message });
        });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  const onUploadPic = (e) => {
    setUploadImage(e.target.files[0]);
    setErrorImage(null);
  };

  const submitImage = () => {
    console.log(uploadImage);
    if (uploadImage !== null) {
      var file_name = uploadImage.name.split(".");
      var unique_filename =
        file_name[0] + "_" + Date.now().toString() + "." + file_name[1];
      Storage.put(unique_filename, uploadImage).then((uploadedImage) => {
        var input = {
          id: user.id,
          icon_image: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: uploadedImage.key,
          },
          _version: user._version,
        };
        API.graphql(graphqlOperation(updateUserAccount, { input: input }))
          .then((updatedUser) => {
            setAddImage(false);
            setToastMessage({
              heading: "Image Updated",
              body: "Your Image has been updated!",
              status: "success",
            });
            setOpenToast(true);
          })
          .catch((err) => {
            console.log(err);
            Storage.remove(unique_filename).then(() => {
              console.log("Upload removed due to failed DB insert!");
              setAddImage(false);
              setToastMessage({
                heading: "Failed",
                body: "Failed to updated Image!",
                status: "danger",
              });
              setOpenToast(true);
            });
          });
      });
    } else {
      setErrorImage("Add Image file!");
    }
  };

  const removePic = () => {
    var input = {
      id: user.id,
      icon_image: null,
      _version: user._version,
    };
    API.graphql(graphqlOperation(updateUserAccount, { input: input })).then(
      (updatedUser) => {
        console.log("updated User:", updatedUser);
        Storage.remove(user.icon_image.key).then(() => {
          console.log("Delete Image from Storage!");
          setRemoveImageModal(false);
          setToastMessage({
            heading: "Image Removed",
            body: "Your Image Successfully Removed!",
            status: "success",
          });
          setOpenToast(true);
        });
      }
    );
  };

  return (
    <>
      <Toast
        bg={toastMessage.status}
        onClose={() => setOpenToast(false)}
        show={openToast}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
          <strong className="mr-auto">{toastMessage.heading}</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage.body}</Toast.Body>
      </Toast>

      <Modal
        size="lg"
        show={openResetPassword}
        onHide={() => setOpenResetPassword(false)}
        aria-labelledby="remove profile image"
      >
        <Modal.Header closeButton>
          <Modal.Title id="remove profile image">
            Reset Password to default
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {passwordResetMessage !== null ? (
            <>{passwordResetMessage}</>
          ) : (
            <>
              The User's password will be set to "NewPassword_1234".
              <br />
              Are your sure you want to reset password?
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {passwordResetMessage === null ? (
            <Button variant="primary" onClick={resetUserPassword}>
              Yes
            </Button>
          ) : (
            ""
          )}
          <Button variant="danger" onClick={() => setOpenResetPassword(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Remove image Modal */}
      <Modal
        size="sm"
        show={removeImageModal}
        onHide={() => setRemoveImageModal(false)}
        aria-labelledby="remove profile image"
      >
        <Modal.Header closeButton>
          <Modal.Title id="remove profile image">Remove Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are your sure you want to remove image?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={removePic}>
            Yes
          </Button>
          <Button variant="danger" onClick={() => setRemoveImageModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add image Modal */}
      <Modal
        size="sm"
        show={addImage}
        onHide={() => setAddImage(false)}
        aria-labelledby="add profile image"
      >
        <Modal.Header closeButton>
          <Modal.Title id="add profile image">Add New Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Control type="file" name="image" onChange={onUploadPic} />
          </Form>
          {errorImage !== null ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errorImage}</div>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={submitImage}>
            Submit
          </Button>
          <Button variant="danger" onClick={() => setAddImage(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <form
        className="card card-custom card-stretch"
        onSubmit={formik.handleSubmit}
      >
        {loading && <ModalProgressBar />}

        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Personal Information
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Update your personal information
            </span>
          </div>
          <div className="card-toolbar">
            <button
              type="submit"
              className="btn btn-success mr-2"
              disabled={
                formik.isSubmitting || (formik.touched && !formik.isValid)
              }
            >
              Save Changes
            </button>
            <Button
              className="btn btn-secondary"
              onClick={() => props.closeForm()}
            >
              Cancel
            </Button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bold mb-6">User Info</h5>
                {user.Status === "Disabled" ? (
                  <h5 className="font-weight-bold mb-6">Disabled</h5>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
              <div className="col-lg-9 col-xl-6">
                <div
                  className="image-input image-input-outline"
                  id="kt_profile_avatar"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      "/media/users/blank.png"
                    )}`,
                  }}
                >
                  {user.icon_image !== null ? (
                    <S3Image
                      imgKey={user.icon_image.key}
                      className="image-input-wrapper"
                      theme={{
                        photoImg: {
                          height: "120px",
                          width: "120px",
                        },
                      }}
                    />
                  ) : (
                    <div
                      className="image-input-wrapper"
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                          "/media/users/blank.png"
                        )}`,
                      }}
                    />
                  )}
                  <label
                    onClick={() => setAddImage(true)}
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i className="fa fa-pen icon-sm text-muted"></i>
                  </label>
                  <span
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="cancel"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Cancel avatar"
                  >
                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                  <span
                    onClick={() => setRemoveImageModal(true)}
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="remove"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Remove avatar"
                  >
                    <i className="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                </div>
                <span className="form-text text-muted">
                  Allowed file types: png, jpg, jpeg.
                </span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Student ID
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="text"
                  placeholder="Student ID"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "officeID"
                  )}`}
                  name="officeID"
                  {...formik.getFieldProps("officeID")}
                />
                {formik.touched.officeID && formik.errors.officeID ? (
                  <div className="invalid-feedback">
                    {formik.errors.officeID}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                First Name
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="text"
                  placeholder="First name"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "firstname"
                  )}`}
                  name="firstname"
                  {...formik.getFieldProps("firstname")}
                />
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className="invalid-feedback">
                    {formik.errors.firstname}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Last Name
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="text"
                  placeholder="Last name"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "lastname"
                  )}`}
                  name="lastname"
                  {...formik.getFieldProps("lastname")}
                />
                {formik.touched.lastname && formik.errors.lastname ? (
                  <div className="invalid-feedback">
                    {formik.errors.lastname}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Company Name
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="text"
                  placeholder="Company name"
                  className={`form-control form-control-lg form-control-solid`}
                  name="companyName"
                  {...formik.getFieldProps("companyName")}
                />
              </div>
            </div>
            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bold mt-10 mb-6">Contact Info</h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Contact Phone
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="input-group input-group-lg input-group-solid">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-phone"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="+1(123)112-11-11"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "phone"
                    )}`}
                    name="phone"
                    {...formik.getFieldProps("phone")}
                  />
                </div>
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="invalid-feedback display-block">
                    {formik.errors.phone}
                  </div>
                ) : null}
                <span className="form-text text-muted">
                  We'll never share your phone with anyone else.
                </span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Email Address
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="input-group input-group-lg input-group-solid">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-at"></i>
                    </span>
                  </div>
                  <input
                    type="email"
                    disabled
                    placeholder="Email"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "email"
                    )}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback display-block">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Address
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="input-group input-group-lg input-group-solid">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-home"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Address"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "addressLine"
                    )}`}
                    name="addressLine"
                    {...formik.getFieldProps("addressLine")}
                  />
                </div>
                {formik.touched.addressLine && formik.errors.addressLine ? (
                  <div className="invalid-feedback display-block">
                    {formik.errors.addressLine}
                  </div>
                ) : null}

                <div className="input-group input-group-lg input-group-solid">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-city"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="City"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "city"
                    )}`}
                    name="city"
                    {...formik.getFieldProps("city")}
                  />
                </div>
                {formik.touched.city && formik.errors.city ? (
                  <div className="invalid-feedback display-block">
                    {formik.errors.city}
                  </div>
                ) : null}

                <div className="input-group input-group-lg input-group-solid">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-map-pin"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="State"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "state"
                    )}`}
                    name="state"
                    {...formik.getFieldProps("state")}
                  />
                </div>
                {formik.touched.state && formik.errors.state ? (
                  <div className="invalid-feedback display-block">
                    {formik.errors.state}
                  </div>
                ) : null}

                <div className="input-group input-group-lg input-group-solid">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-home"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    placeholder="Postal Code"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "postCode"
                    )}`}
                    name="postCode"
                    {...formik.getFieldProps("postCode")}
                  />
                </div>
                {formik.touched.postCode && formik.errors.postCode ? (
                  <div className="invalid-feedback display-block">
                    {formik.errors.postCode}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Company Site
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="input-group input-group-lg input-group-solid">
                  <input
                    type="text"
                    className={`form-control form-control-lg form-control-solid`}
                    name="website"
                    {...formik.getFieldProps("website")}
                  />
                </div>
                {formik.touched.website && formik.errors.website ? (
                  <div className="invalid-feedback display-block">
                    {formik.errors.website}
                  </div>
                ) : null}
              </div>
            </div>
            {user.Status === "Disabled" ? (
              <Button
                variant="success"
                style={{ margin: "2px" }}
                onClick={() => enableUser()}
              >
                Enable User
              </Button>
            ) : (
              <Button
                variant="danger"
                style={{ margin: "2px" }}
                onClick={() => disableUser()}
              >
                Disable User
              </Button>
            )}
            <Button
              variant="info"
              style={{ margin: "2px" }}
              onClick={() => setOpenResetPassword(true)}
            >
              Reset Password
            </Button>
            <Button
              variant="success"
              style={{ margin: "2px" }}
              onClick={() => props.openBatchHistory()}
            >
              Batch History
            </Button>
          </div>
          {/* end::Body */}
        </div>
        {/* end::Form */}
      </form>
    </>
  );
}

export default connect(null, null)(UserProfileInfo);
