import React from "react";
import { Provider } from "react-redux";
import { Routes } from "./Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";

export default function App({ store }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Add high level  `Suspense` in case if was not handled inside the React tree. */}
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
        <MaterialThemeProvider>
          {/* Provide `react-intl` context synchronized with Redux state.  */}
          <I18nProvider>
            {/* Render routes with provided `Layout`. */}
            <Routes />
          </I18nProvider>
        </MaterialThemeProvider>
      </React.Suspense>
    </Provider>
  );
}
