import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { listCourses } from "../../../graphql/queries";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditCourse from "./addEditCourse";
import { onCreateCourse, onUpdateCourse } from "../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class listCourse extends Component {
  state = {
    courses: [],
    loading: true,
    message: null,
    openAddCourse: false,
    deleteCourseName: null,
    deleteCourseID: null,
    openDeleteCourse: false,
    editItem: null,
    addEditStatus: true,
  };

  getCourses() {
    API.graphql(graphqlOperation(listCourses)).then((result) => {
      var courses = result.data.listCourses.items;
      if (courses.length === 0) {
        this.setState({
          message: "No Courses Added yet!",
          loading: false,
        });
      } else {
        courses = courses.filter((course) => course._deleted !== true);
        this.setState({ courses, loading: false });
      }
    });
  }

  componentDidMount() {
    this.getCourses();

    //Function to listen to post course
    this.createCourseListener = API.graphql(
      graphqlOperation(onCreateCourse)
    ).subscribe({
      next: (courseData) => {
        const newCourse = courseData.value.data.onCreateCourse;
        const prevCourses = this.state.courses.filter(
          (course) => course.id !== newCourse.id
        );
        this.setState({ courses: [newCourse, ...prevCourses] });
      },
    });

    //Function to listen to update courses
    this.updateCourseListener = API.graphql(
      graphqlOperation(onUpdateCourse)
    ).subscribe({
      next: (courseData) => {
        const updateCourse = courseData.value.data.onUpdateCourse;
        const index = this.state.courses.findIndex(
          (course) => course.id === updateCourse.id
        );
        const updateCourses = [
          ...this.state.courses.slice(0, index),
          updateCourse,
          ...this.state.courses.slice(index + 1),
        ];
        this.setState({ courses: updateCourses });
      },
    });
  }

  componentWillUnmount() {
    if (this.createCourseListener !== undefined) {
      this.createCourseListener.unsubscribe();
    }
    if (this.updateCourseListener !== undefined) {
      this.updateCourseListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditCourse: false,
    });
  };

  render() {
    const {
      courses,
      loading,
      openAddEditCourse,
      deleteCourseName,
      openDeleteCourse,
      addEditStatus,
      editItem,
    } = this.state;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditCourse: true,
                addEditStatus: true,
              })
            }
            title={"Add Program"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditCourse}
          onHide={() => this.setState({ openAddEditCourse: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add Course" : "Edit Course"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditCourse
              addEditStatus={addEditStatus}
              editingCourse={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteCourse}
          onHide={() => this.setState({ openDeleteCourse: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteCourseName} Course
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure u want to delete the course?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.deleteCourse}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ openDeleteCourse: false })}
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="Program" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Program </th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : courses.length === 0 ? (
                    <tr>
                      <td colSpan="7">No Courses added Yet!</td>
                    </tr>
                  ) : (
                    courses.map((course, i) => (
                      <tr key={course.id}>
                        <td>{i + 1}</td>
                        <td>{course.courseName}</td>
                        <td>{course.courseDescription}</td>
                        <td>
                          {" "}
                          <Link
                            to={"/library/" + course.id}
                            className="btn btn-icon btn-light btn-sm"
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Group-folders.svg"
                                )}
                              ></SVG>
                            </span>
                          </Link>
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: course,
                                openAddEditCourse: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() => {
                              /*
                              this.setState({
                                deleteCourseName: course.courseName,
                                deleteCourseID: course.id,
                                openDeleteCourse: true,
                              });
                              */
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default listCourse;
