import React, { Component } from "react";
import UserTable from "./listUsers";

export class userDashboard extends Component {
  render() {
    return (
      <UserTable
        key={this.props.match.params.userType}
        userType={this.props.match.params.userType}
      />
    );
  }
}
export default userDashboard;
