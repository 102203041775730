import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import Grader from "./grader";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { listSubjects } from "../../../../graphql/queries";
import {
  createGrades,
  updateGrades,
  updateReportCard,
} from "../../../../graphql/mutations";
import UpdateActivity from "../../../tools/updateActivity";
import UpdateNotification from "../../../tools/createNotification";

export default class addEditGrades extends Component {
  state = {
    reportCardID: "",
    searchString: "",
    Mark: 0,
    Grade: "",
    status: "New Grade",
    subjectID: "",
    subjects: [],
    Remarks: "",
    loading: false,
    userId: "",
    subjectNextToken: null,
    userName: "",
  };

  getSubjects() {
    const { subjectNextToken, subjects } = this.state;
    var tempToken = subjectNextToken;
    API.graphql(
      graphqlOperation(listSubjects, {
        limit: 100,
        nextToken: subjectNextToken,
      })
    ).then((result) => {
      var newSubjects = result.data.listSubjects.items.filter(
        (subject) =>
          subject.courseID === this.props.batchDetails.courseID &&
          subject.specializationID === this.props.batchDetails.specializationID
      );
      if (newSubjects.length === 0) {
        this.setState({
          message: "No Subjects Added yet!",
          loading: false,
        });
      } else {
        newSubjects = newSubjects.filter(
          (subject) => subject._deleted !== true
        );
      }
      this.setState({
        subjects: [...subjects, ...newSubjects],
        subjectNextToken: result.data.listSubjects.nextToken,
        loading: false,
      });
      if (
        result.data.listSubjects.nextToken !== null &&
        result.data.listSubjects.nextToken !== tempToken
      ) {
        this.getSubjects();
      }
    });
  }

  componentDidMount() {
    const { viewGrade, studentInfo, addEditGrade, editingGrade } = this.props;
    Auth.currentUserInfo().then((user) => {
      this.setState({
        userId: user.attributes.sub,
        userName: user.username,
      });
    });
    this.getSubjects();
    this.setState({
      reportCardID: viewGrade.id,
      searchString: `${studentInfo.firstName} ${studentInfo.officeID}`,
      status: "New Grade",
    });
    if (!addEditGrade) {
      this.setState({
        subjectID: editingGrade.subjectID,
        Mark: editingGrade.Mark,
        Grade: editingGrade.Grade,
        Remarks: editingGrade.Remarks,
        status: "Updated Grade",
      });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onChangeMarks = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    var grade = Grader(e.target.value, "graderBBA");
    this.setState({ Grade: grade });
  };

  onSubmit() {
    const {
      reportCardID,
      searchString,
      Mark,
      Grade,
      status,
      subjectID,
      Remarks,
      userName,
      userId,
      subjects,
    } = this.state;
    var subjectName = subjects.filter((subject) => subject.id === subjectID)[0]
      .subjectName;
    const { studentInfo } = this.props;
    var input = {
      reportCardID: reportCardID,
      searchString: searchString,
      Mark: Mark,
      Grade: Grade,
      status: status,
      subjectID: subjectID,
      Remarks: Remarks,
      createdByUserID: userId,
    };
    var gradesCount = 0;
    if (this.props.viewGrade.gradesCount !== null) {
      gradesCount = this.props.viewGrade.gradesCount + 1;
    }
    if (this.props.addEditGrade) {
      API.graphql(graphqlOperation(createGrades, { input: input }))
        .then((createdGrade) => {
          this.setState({
            loading: false,
          });
          API.graphql(
            graphqlOperation(updateReportCard, {
              input: {
                id: reportCardID,
                gradesCount: gradesCount,
                _version: this.props.viewGrade._version,
              },
            })
          ).then((updatedCard) => {
            console.log("Card updaed", updatedCard);
          });
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `New Grade Created`,
            tableName: "Grades",
            referenceID: createdGrade.data.createGrades.id,
            remarks: `Grade created for ${studentInfo.firstName} created by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          UpdateNotification.updateNotification({
            notificationType: "Student",
            type: "Notification",
            topic: "New Grade",
            message: `A new Grade has been uploaded for ${subjectName}`,
            givenUserId: userId,
            targetID: studentInfo.id,
          });
          this.props.closeForm();
        })
        .catch((err) => {
          console.log(err);
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Create Grade Failed`,
            tableName: "Grades",
            referenceID: null,
            remarks: err.message,
            activityDateTime: activityTime.toISOString(),
          });
          this.props.closeForm();
        });
    } else {
      input = {
        id: this.props.editingGrade.id,
        reportCardID: reportCardID,
        searchString: searchString,
        Mark: Mark,
        Grade: Grade,
        status: status,
        Remarks: Remarks,
        _version: this.props.editingGrade._version,
      };

      API.graphql(graphqlOperation(updateGrades, { input: input }))
        .then((updatedGrade) => {
          this.setState({
            loading: false,
          });
          API.graphql(
            graphqlOperation(updateReportCard, {
              input: {
                id: reportCardID,
                gradesCount: gradesCount,
                _version: this.props.viewGrade._version,
              },
            })
          );
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Grade updated`,
            tableName: "Grades",
            referenceID: updatedGrade.data.updateGrades.id,
            remarks: `Grade updated for ${studentInfo.firstName} created by ${userName}`,
            activityDateTime: activityTime.toISOString(),
          });
          UpdateNotification.updateNotification({
            notificationType: "Student",
            type: "Notification",
            topic: "Updated Grade",
            message: `A Grade has been updated for ${subjectName}`,
            givenUserId: userId,
            targetID: studentInfo.id,
          });
          this.props.closeForm();
        })
        .catch((err) => {
          console.log(err);
          var activityTime = new Date();
          UpdateActivity.updateActivity({
            activityItem: `Update Grade Failed`,
            tableName: "Grades",
            referenceID: null,
            remarks: err.message,
            activityDateTime: activityTime.toISOString(),
          });
          this.props.closeForm();
        });
    }
    this.setState({
      loading: false,
    });
  }
  render() {
    const { Mark, Grade, Remarks, loading, subjectID, subjects } = this.state;
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              as="select"
              name="subjectID"
              value={subjectID}
              onChange={this.onChange}
              disabled={!this.props.addEditGrade}
            >
              <option value={null}>Select A Subject</option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.subjectName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Marks</Form.Label>
            <Form.Control
              type="text"
              placeholder="Marks Obtained"
              name="Mark"
              value={Mark}
              onChange={this.onChangeMarks}
            />
            <Form.Label>Grade</Form.Label>
            <Form.Control
              type="text"
              placeholder="Grade"
              name="Grade"
              value={Grade}
              onChange={this.onChange}
            />
          </Form.Group>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Remarks"
              name="Remarks"
              value={Remarks}
              onChange={this.onChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              this.onSubmit();
              this.setState({ loading: true });
            }}
          >
            {loading ? (
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            ) : this.props.addEditGrade ? (
              "Add"
            ) : (
              "Update"
            )}
          </Button>
          {"  "}
          <Button variant="danger" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </Form>
      </div>
    );
  }
}
