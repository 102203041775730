/* eslint-disable import/no-anonymous-default-export */
import { Auth, API, graphqlOperation } from "aws-amplify";
import { createNotification, createActivityLog } from "../../graphql/mutations";
import { listUserAccounts } from "../../graphql/queries";
import sendManualMail from "../sendMail/sendMailFn";
import Template from "../sendMail/generalMail";

export default {
  notificationActivityMail: (actionInfo) => {
    var today = new Date();

    Auth.currentUserInfo().then((user) => {
      var notificationInput = {
        createdDate: today.toISOString(),
        type: actionInfo.type,
        topic: actionInfo.topic,
        message: actionInfo.message,
        UserAccountID: actionInfo.givenUserId,
        status: "New",
        createdByUserID: user.attributes.sub,
      };
      var activityInput = {
        activityItem: actionInfo.activityItem,
        tableName: actionInfo.tableName,
        referenceID: actionInfo.referenceID,
        remarks: actionInfo.remarks,
        activityDateTime: actionInfo.activityDateTime,
        createdByUserID: user.attributes.sub,
      };

      if (actionInfo.notificationType === "Batch") {
        notificationInput["batchID"] = actionInfo.targetID;
      } else {
        notificationInput["studentID"] = actionInfo.targetID;
      }

      console.log("---", activityInput);
      API.graphql(
        graphqlOperation(createNotification, { input: notificationInput })
      ).then((createdNotification) => {
        console.log("Notification Added", createdNotification);
      });

      API.graphql(
        graphqlOperation(createActivityLog, { input: activityInput })
      ).then((createdMessage) => {
        console.log("Activity Recorded", createdMessage);
      });

      if (actionInfo.notificationType === "Batch") {
        console.log("Batch mailing");
        API.graphql(
          graphqlOperation(listUserAccounts, {
            filter: {
              batchId: { eq: actionInfo.targetID },
            },
          })
        ).then((batchStudents) => {
          var students = batchStudents.data.listUserAccounts.items;
          students.map((student) => {
            console.log(
              "sending Batch mail!",
              actionInfo.mailHeading,
              actionInfo.mailSubject,
              student.email,
              `${student.firstName} ${student.lastName}`,
              user.id,
              user.officeID,
              actionInfo.message,
              false
            );
            sendManualMail(
              Template,
              actionInfo.mailHeading,
              actionInfo.mailSubject,
              student.email,
              `${student.firstName} ${student.lastName}`,
              user.id,
              user.officeID,
              actionInfo.message,
              false
            );
            return null;
          });
        });
      } else {
        console.log("Student mailing");
        console.log("sending mail!");
        sendManualMail(
          Template,
          "Test mail",
          "Testing mail sending",
          "dr2633",
          `Aneesh Bharath`,
          user.id,
          user.officeID,
          "this is a test mail",
          false
        );
        activityInput["studentID"] = actionInfo.targetID;
      }
    });
  },
};

/* how to use
import UpdateActivity from "../tools/updateNotification";
 
          UpdateNotification.updateNotification({
            notificationType: "Student",
            type: "Notification",
            topic: "New Grade",
            message: `A new Grade has been uploaded for ${subjectName}`,
            givenUserId: studentInfo.id,
          });
      */
