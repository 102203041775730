/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Menu from "./Menu";

export function AsideMenuList({ layoutProps }) {
  const userType = useSelector(
    (state) => state.reducerAuth.userType,
    shallowEqual
  );
  useEffect(() => {
    return () => {};
  }, [userType]);

  return <Menu layoutProps={layoutProps} userType={userType} />;
}
