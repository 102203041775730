import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Dropdown, Modal, Button } from "react-bootstrap";
import * as dates from "./dates";
import moment from "moment";
import { S3Image } from "aws-amplify-react";
import { DropdownCustomToggler } from "./dropdown_addon";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddCreateSchedule from "./createEvent";
import AddCreateLecturer from "./addNewLecturer";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listSchedules } from "../../customgraphql/queries";
import {
  onCreateSchedule,
  onDeleteSchedule,
  onUpdateSchedule,
} from "../../graphql/subscriptions";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core/";
import ListLecturers from "./listLecturers";
import { updateSchedule, deleteSchedule } from "../../graphql/mutations";

export default function EventLayout({ batchDetails, userType }) {
  const localizer = momentLocalizer(moment);
  const [openEvent, setOpenEvent] = useState(false);
  const [activeEvent, setActiveEvent] = useState(null);
  const [openEventInfo, setOpenEventInfo] = useState(false);
  const [openLecturer, setOpenLecturer] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventNextToken, setEventNextToken] = useState(null);
  const [editingSchedule, setEditingSchedule] = useState(null);
  const [addEditStatus, setAddEditStatus] = useState(true);
  const [viewSchedule, setViewSchedule] = useState(true);
  const [deleteScheduleTitle, setDeleteScheduleTitle] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);

  useEffect(() => {
    if (
      (eventNextToken === null && events.length === 0) ||
      (eventNextToken !== null && events.length !== 0)
    )
      API.graphql(
        graphqlOperation(listSchedules, {
          limit: 100,
          nextToken: eventNextToken,
        })
      ).then((result) => {
        var events = result.data.listSchedules.items.filter((event) =>
          event.batchConnection.includes(batchDetails.id)
        );
        var formattedEvents = [];
        events.map((event) => {
          if (event._deleted !== true) {
            var newEvent = {
              id: event.id,
              title: event.title,
              allDay: false,
              start: event.startDate,
              end: event.endDate,
              backgroundColor: "red",
              createdDate: event.createdDate,
              type: event.type,
              batchConnection: event.batchConnection,
              location: event.location,
              lecturerID: event.lecturerID,
              subjectID: event.subjectID,
              subject: event.subject !== null ? event.subject.subjectName : "",
              startDate: event.startDate,
              endDate: event.endDate,
              remarks: event.remarks,
              _version: event._version,
            };
            if (event.lecturer !== null) {
              newEvent["lecturerName"] = event.lecturer.name;
              if (event.lecturer.profile !== null) {
                newEvent["lecturerProfile"] = event.lecturer.profile.key;
              }
              if (event.lecturer.image !== null) {
                newEvent["lecturerImage"] = event.lecturer.image.key;
              }
              newEvent["lecturerEmail"] = event.lecturer.contactInfo.email;
              newEvent["lecturerPhone"] = event.lecturer.contactInfo.phone;
            }
            formattedEvents.push(newEvent);
            return null;
          }
        });
        setEvents([...events, ...formattedEvents]);
        setEventNextToken(result.data.listSchedules.nextToken);
      });
  }, [eventNextToken, batchDetails.id]);

  useEffect(() => {
    const onCreateSubscription = API.graphql(
      graphqlOperation(onCreateSchedule, {
        filter: { batchConnection: { contains: batchDetails.id } },
      })
    ).subscribe({
      next: (scheduleData) => {
        const newSchedule = scheduleData.value.data.onCreateSchedule;
        if (newSchedule !== null) {
          var newEvent = {
            id: newSchedule.id,
            title: newSchedule.title,
            allDay: false,
            start: newSchedule.startDate,
            end: newSchedule.endDate,
            backgroundColor: "red",
            createdDate: newSchedule.createdDate,
            type: newSchedule.type,
            batchConnection: newSchedule.batchConnection,
            location: newSchedule.location,
            lecturerID: newSchedule.lecturerID,
            subjectID: newSchedule.subjectID,
            subject: newSchedule.subject.subjectName,
            startDate: newSchedule.startDate,
            endDate: newSchedule.endDate,
            remarks: newSchedule.remarks,
            _version: newSchedule._version,
          };
          if (newSchedule.lecturer !== null) {
            newEvent["lecturerName"] = newSchedule.lecturer.name;
            if (newSchedule.lecturer.profile !== null) {
              newEvent["lecturerProfile"] = newSchedule.lecturer.profile.key;
            }
            if (newSchedule.lecturer.image !== null) {
              newEvent["lecturerImage"] = newSchedule.lecturer.image.key;
            }
            newEvent["lecturerEmail"] = newSchedule.lecturer.contactInfo.email;
            newEvent["lecturerPhone"] = newSchedule.lecturer.contactInfo.phone;
          }
          setEvents([newEvent, ...events]);
        }
      },
    });
    return () => onCreateSubscription.unsubscribe();
  }, [batchDetails.id, events]);

  useEffect(() => {
    const onUpdateSubscription = API.graphql(
      graphqlOperation(onUpdateSchedule)
    ).subscribe({
      next: (userData) => {
        console.log("update detected!!!!");
        const updatedSchedule = userData.value.data.onUpdateSchedule;
        if (updatedSchedule.batchConnection.indexOf(batchDetails.id)) {
          const index = events.findIndex(
            (event) => event.id === updatedSchedule.id
          );
          var updatedFormatedEvent = {
            id: updatedSchedule.id,
            title: updatedSchedule.title,
            allDay: false,
            start: updatedSchedule.startDate,
            end: updatedSchedule.endDate,
            backgroundColor: "red",
            createdDate: updatedSchedule.createdDate,
            type: updatedSchedule.type,
            batchConnection: updatedSchedule.batchConnection,
            location: updatedSchedule.location,
            lecturerID: updatedSchedule.lecturerID,
            subjectID: updatedSchedule.subjectID,
            subject: updateSchedule.subject.subjectName,
            startDate: updatedSchedule.startDate,
            endDate: updatedSchedule.endDate,
            remarks: updatedSchedule.remarks,
            _version: updatedSchedule._version,
          };
          if (updatedSchedule.lecturer !== null) {
            updatedFormatedEvent["lecturerName"] =
              updatedSchedule.lecturer.name;
            if (updatedSchedule.lecturer.profile !== null) {
              updatedFormatedEvent["lecturerProfile"] =
                updatedSchedule.lecturer.profile.key;
            }
            if (updatedSchedule.lecturer.image !== null) {
              updatedFormatedEvent["lecturerImage"] =
                updatedSchedule.lecturer.image.key;
            }
            updatedFormatedEvent["lecturerEmail"] =
              updatedSchedule.lecturer.contactInfo.email;
            updatedFormatedEvent["lecturerPhone"] =
              updatedSchedule.lecturer.contactInfo.phone;
          }
          const updatedEvents = [
            ...events.slice(0, index),
            updatedFormatedEvent,
            ...events.slice(index + 1),
          ];
          setEvents(updatedEvents);
        }
      },
    });
    return () => onUpdateSubscription.unsubscribe();
  }, [batchDetails.id, events]);

  useEffect(() => {
    const onDeleteSubscription = API.graphql(
      graphqlOperation(onDeleteSchedule)
    ).subscribe({
      next: (userData) => {
        console.log("deleted detected!!!!");
        const deletedSchedule = userData.value.data.onDeleteSchedule;
        var newEvents = events.filter(
          (event) => event.id !== deletedSchedule.id
        );
        setEvents(newEvents);
      },
    });
    return () => onDeleteSubscription.unsubscribe();
  }, [batchDetails.id, events]);

  const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
      style: {
        backgroundColor: "white",
      },
    });

  const deletingSchedule = () => {
    var input = {
      id: deleteItem.id,
      _version: deleteItem._version,
    };
    API.graphql(graphqlOperation(deleteSchedule, { input: input })).then(
      (deletedSchedule) => {
        console.log("Deleted Schedule:", deletedSchedule);
        setOpenEventInfo(false);
        setOpenDelete(false);
      }
    );
  };

  const EventObject = ({ event }) => {
    return (
      <p
        onClick={() => {
          setActiveEvent(event);
          setOpenEventInfo(true);
        }}
        style={{ color: "white" }}
      >
        {event.title}
        {event.lecturerName}
        {event.subject}
        {event.location}
      </p>
    );
  };
  const AgendaObject = ({ event }) => {
    return (
      <p
        onClick={() => {
          setActiveEvent(event);
          setOpenEventInfo(true);
        }}
        style={{ color: "white" }}
      >
        {event.title}
        {event.lecturerName}
        {event.subject}
        {event.location}
      </p>
    );
  };
  const today = new Date();

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };
  return (
    <>
      <Modal
        size="lg"
        show={openEventInfo}
        onHide={() => setOpenEventInfo(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Event Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeEvent !== null ? (
            <Card>
              {activeEvent.lecturerProfile !== null ? (
                <CardMedia>
                  <S3Image
                    className="img-fluid"
                    imgKey={activeEvent.lecturerProfile}
                  />
                </CardMedia>
              ) : (
                ""
              )}
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {activeEvent.title}{" "}
                  {userType !== "Student" ? (
                    <>
                      <i
                        onClick={() => {
                          setEditingSchedule(activeEvent);
                          setAddEditStatus(false);
                          setOpenEvent(true);
                        }}
                        className="fa fa-pencil"
                        style={{ color: "blue" }}
                        aria-hidden="true"
                      ></i>
                      {"  "}
                      <i
                        onClick={() => {
                          setDeleteItem(activeEvent);
                          setOpenDelete(true);
                          setOpenEvent(false);
                        }}
                        className="fa fa-trash"
                        style={{ color: "red" }}
                        aria-hidden="true"
                      ></i>
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <strong>Lecturer Name :</strong> {activeEvent.lecturerName}
                  <br />
                  <strong>Subject :</strong> {activeEvent.subject}
                  <br />
                  <strong>Location:</strong> {activeEvent.location}
                  <br />
                  <strong>Remarks:</strong> {activeEvent.remarks}
                  <br />
                </Typography>
              </CardContent>
            </Card>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={openEvent}
        onHide={() => setOpenEvent(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {addEditStatus ? "Create Schedule" : "Update Schedule"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCreateSchedule
            batchDetails={batchDetails}
            addEditStatus={addEditStatus}
            editingSchedule={editingSchedule}
            closeForm={() => setOpenEvent(false)}
          />
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={openDelete}
        onHide={() => setOpenDelete(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Delete Schedule
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {deleteScheduleTitle}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => deletingSchedule()}>
            Yes
          </Button>
          <Button variant="danger" onClick={() => setOpenDelete(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={openLecturer}
        onHide={() => setOpenLecturer(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Create Lecturer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCreateLecturer
            addEditStatus={true}
            closeForm={() => setOpenLecturer(false)}
          />
        </Modal.Body>
      </Modal>
      <div className={`card card-custom card-stretch`}>
        {/* begin::Header */}
        <div className="card-header border-0 py-0">
          <h3 className="card-title font-weight-bolder ">Schedule</h3>

          {userType !== "Student" ? (
            <div className="card-toolbar">
              <Dropdown className="dropdown-inline" drop="down" alignRight>
                <Dropdown.Toggle
                  as={DropdownCustomToggler}
                  id="dropdown-toggle-top"
                >
                  <i className="ki ki-bold-more-hor" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  {/*begin::Navigation*/}
                  <ul className="navi navi-hover py-5">
                    <li className="navi-item">
                      <p
                        className="navi-link"
                        onClick={() => setOpenEvent(true)}
                      >
                        <span className="navi-icon">
                          <i className="flaticon2-drop"></i>
                        </span>
                        <span className="navi-text">New Event</span>
                      </p>
                    </li>
                    <li className="navi-item">
                      <p
                        className="navi-link"
                        onClick={() => setOpenLecturer(true)}
                      >
                        <span className="navi-icon">
                          <i className="flaticon2-magnifier-tool"></i>
                        </span>
                        <span className="navi-text">Add Lecturer</span>
                      </p>
                    </li>

                    {viewSchedule ? (
                      <li className="navi-item">
                        <p
                          className="navi-link"
                          onClick={() => setViewSchedule(false)}
                        >
                          <span className="navi-icon">
                            <i className="flaticon2-magnifier-tool"></i>
                          </span>
                          <span className="navi-text">View Lecturers</span>
                        </p>
                      </li>
                    ) : (
                      <li className="navi-item">
                        <p
                          className="navi-link"
                          onClick={() => setViewSchedule(true)}
                        >
                          <span className="navi-icon">
                            <i className="flaticon2-magnifier-tool"></i>
                          </span>
                          <span className="navi-text">View Schedule</span>
                        </p>
                      </li>
                    )}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            " "
          )}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        {viewSchedule ? (
          <>
            <Calendar
              className="pr-2 pb-2"
              style={{ height: "600px", margin: "2px" }}
              events={events}
              popup={true}
              views={["day", "agenda", "month"]}
              step={60}
              max={dates.add(
                dates.endOf(new Date(2022, 12, 1), "day"),
                0,
                "hours"
              )}
              defaultDate={today}
              components={{
                timeSlotWrapper: ColoredDateCellWrapper,
                event: EventObject,
                agenda: AgendaObject,
              }}
              localizer={localizer}
            />
            {batchDetails.scheduleDocument !== null ? (
              <Button
                onClick={() =>
                  Storage.get(batchDetails.scheduleDocument.key, {
                    download: true,
                  }).then((res) => {
                    downloadBlob(res.Body, batchDetails.scheduleDocument.key);
                  })
                }
              >
                Download Schedule
              </Button>
            ) : (
              ""
            )}
          </>
        ) : (
          <ListLecturers />
        )}
      </div>
    </>
  );
}
