import React, { Component } from "react";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { listBatchGuideliness } from "../../../graphql/queries";
import { deleteBatchGuidelines } from "../../../graphql/mutations";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls/Card";
import { CodeBlockToolbarATMS } from "../../../_metronic/_partials/controls/code-examples/CodeBlockToolbarATMS";
import { Table, Modal, Button } from "react-bootstrap";
import AddEditBatchGuidelines from "./addEditBatchGuidelines";
import {
  onCreateBatchGuidelines,
  onDeleteBatchGuidelines,
  onUpdateBatchGuidelines,
} from "../../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class listBatchGuidelines extends Component {
  state = {
    guidelines: [],
    message: null,
    openAddGuidelines: false,
    deleteGuidelinesFilname: null,
    deleteGuidelinesID: null,
    openDeleteGuidelines: false,
    editItem: null,
    loading: true,
    addEditStatus: true,
  };

  getBatchGuidelines() {
    API.graphql(graphqlOperation(listBatchGuideliness)).then((result) => {
      var guidelines = result.data.listBatchGuideliness.items;
      console.log(guidelines);
      if (guidelines.length === 0) {
        this.setState({
          message: "No Batch Guidelines Added yet!",
        });
      }
      this.setState({ guidelines, loading: false });
    });
  }

  componentDidMount() {
    this.getBatchGuidelines();

    //Function to listen to post course
    this.createBatchGuidelinesListener = API.graphql(
      graphqlOperation(onCreateBatchGuidelines)
    ).subscribe({
      next: (guidelineData) => {
        const newBatchGuidelines =
          guidelineData.value.data.onCreateBatchGuidelines;
        const prevBatchGuidelines = this.state.guidelines.filter(
          (guideline) => guideline.id !== newBatchGuidelines.id
        );
        this.setState({
          guidelines: [newBatchGuidelines, ...prevBatchGuidelines],
        });
      },
    });

    //Function to listen to delete courses
    this.deleteBatchGuidelinesListener = API.graphql(
      graphqlOperation(onDeleteBatchGuidelines)
    ).subscribe({
      next: (guidelineData) => {
        var updateBatchGuideliness = this.state.guidelines.filter(
          (guideline) =>
            guideline.id !== guidelineData.value.data.onDeleteBatchGuidelines.id
        );
        this.setState({ guidelines: updateBatchGuideliness });
      },
    });

    //Function to listen to delete guidelines
    this.updateBatchGuidelinesListener = API.graphql(
      graphqlOperation(onUpdateBatchGuidelines)
    ).subscribe({
      next: (guidelineData) => {
        const updateBatchGuidelines =
          guidelineData.value.data.onUpdateBatchGuidelines;
        const index = this.state.guidelines.findIndex(
          (guideline) => guideline.id === updateBatchGuidelines.id
        );
        const updateBatchGuideliness = [
          ...this.state.guidelines.slice(0, index),
          updateBatchGuidelines,
          ...this.state.guidelines.slice(index + 1),
        ];
        this.setState({ guidelines: updateBatchGuideliness });
      },
    });
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  deleteBatchGuidelines(id) {
    API.graphql(graphqlOperation(deleteBatchGuidelines, { id: id })).then(
      (deletedGuidelines) => {
        console.log("Deleted Guidelines:", deletedGuidelines);
      }
    );
  }

  componentWillUnmount() {
    if (this.createBatchGuidelinesListener !== undefined) {
      this.createBatchGuidelinesListener.unsubscribe();
    }
    if (this.updateBatchGuidelinesListener !== undefined) {
      this.updateBatchGuidelinesListener.unsubscribe();
    }
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditBatchGuidelines: false,
    });
  };

  render() {
    const {
      guidelines,
      loading,
      openAddEditBatchGuidelines,
      deleteBatchGuidelinesName,
      openDeleteBatchGuidelines,
      addEditStatus,
      editItem,
    } = this.state;
    const toolbar = (
      <div className="card-toolbar">
        <div className="example-tools">
          <CodeBlockToolbarATMS
            showViewCode={true}
            openAdd={() =>
              this.setState({
                openAddEditBatchGuidelines: true,
                addEditStatus: true,
              })
            }
            title={"Add Guidelines"}
          />
        </div>
      </div>
    );
    return (
      <>
        <Modal
          size="lg"
          show={openAddEditBatchGuidelines}
          onHide={() => this.setState({ openAddEditBatchGuidelines: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {addEditStatus ? "Add BatchGuidelines" : "Edit BatchGuidelines"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddEditBatchGuidelines
              addEditStatus={addEditStatus}
              editingBatchGuidelines={editItem}
              closeForm={this.closeAddEditForm}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={openDeleteBatchGuidelines}
          onHide={() => this.setState({ openDeleteBatchGuidelines: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete {deleteBatchGuidelinesName} BatchGuidelines
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure u want to delete the Guideline Documents?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.deleteBatchGuidelines}>
              Yes{" "}
            </Button>
            <Button
              variant="danger"
              onClick={() =>
                this.setState({ openDeleteBatchGuidelines: false })
              }
            >
              Cancel{" "}
            </Button>
          </Modal.Footer>
        </Modal>

        <Card className="example example-compact">
          <CardHeader title="BatchGuideliness" toolbar={toolbar} />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>File Name </th>
                    <th>File </th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7">
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                      </td>
                    </tr>
                  ) : guidelines.length === 0 ? (
                    <tr>
                      <td colSpan="7">No BatchGuideliness added Yet!</td>
                    </tr>
                  ) : (
                    guidelines.map((guideline, i) => (
                      <tr key={guideline.id}>
                        <td>{i + 1}</td>
                        <td>{guideline.filename}</td>
                        <td>
                          <Button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() =>
                              Storage.get(guideline.guidelinesFile.key, {
                                download: true,
                              }).then((res) => {
                                console.log(res);
                                this.downloadBlob(
                                  res.Body,
                                  guideline.guidelinesFile.key
                                );
                              })
                            }
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                style={{ color: "red" }}
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Files/Cloud-download.svg"
                                )}
                              ></SVG>
                            </span>
                          </Button>
                        </td>
                        <td>
                          {" "}
                          <button className="btn btn-icon btn-light btn-sm">
                            <span className="svg-icon svg-icon-md svg-icon-warning">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Settings-1.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm mx-3"
                            onClick={() => {
                              this.setState({
                                editItem: guideline,
                                openAddEditBatchGuidelines: true,
                                addEditStatus: false,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                          <button
                            className="btn btn-icon btn-light btn-sm"
                            onClick={() => {
                              this.setState({
                                deleteBatchGuidelinesName: guideline.filename,
                                deleteBatchGuidelinesID: guideline.id,
                                openDeleteBatchGuidelines: true,
                              });
                            }}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Trash.svg"
                                )}
                              ></SVG>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default listBatchGuidelines;
