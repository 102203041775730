import React, { Component } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listAssignmentSubmissions } from "../../customgraphql/queries";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../_metronic/_partials/controls/Card";
import { Table, Modal, Button, Badge } from "react-bootstrap";
import {
  onCreateAssignmentSubmission,
  onUpdateAssignmentSubmission,
} from "../../graphql/subscriptions";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export class assignmentSubmissions extends Component {
  state = {
    assignmentSubmissions: [],
    loading: true,
    message: null,
    assignmentRemarks: null,
    openRemarks: false,
  };

  getSelfAssignments() {
    const { assignmentSubmissions, submissionNextToken } = this.state;
    API.graphql(
      graphqlOperation(listAssignmentSubmissions, {
        limit: 100,
        nextToken: submissionNextToken,
      })
    ).then((result) => {
      var newAssignmentSubmissions = result.data.listAssignmentSubmissions.items.filter(
        (submission) => submission.studentID === this.props.studentID
      );
      var allAssignmentSubmissions = [
        ...assignmentSubmissions,
        ...newAssignmentSubmissions,
      ];
      this.setState({
        assignmentSubmissions: allAssignmentSubmissions,
        submissionNextToken: result.data.listAssignmentSubmissions.nextToken,
      });

      if (result.data.listAssignmentSubmissions.nextToken !== null) {
        this.getSelfAssignments();
      } else {
        this.setState({ loading: false });
      }
    });
  }

  componentDidMount() {
    this.getSelfAssignments();
  }

  componentWillUnmount() {
    if (this.createAssignmentListener !== undefined) {
      this.createAssignmentListener.unsubscribe();
    }
    if (this.updateAssignmentListener !== undefined) {
      this.updateAssignmentListener.unsubscribe();
    }
  }

  downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  closeAddEditForm = () => {
    this.setState({
      openAddEditAssignment: false,
    });
  };

  render() {
    const {
      assignmentSubmissions,
      loading,
      message,
      openRemarks,
      assignmentRemarks,
      submissionName,
    } = this.state;
    return (
      <>
        <Modal
          size="lg"
          show={openRemarks}
          onHide={() => this.setState({ openRemarks: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Remarks for {submissionName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{assignmentRemarks}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => this.setState({ openRemarks: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Card className="example example-compact">
          <CardHeader title="Submitted Assignments" />
          <CardBody>
            <div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Assignment Submission </th>
                    <th>Subject</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {message === null ? (
                    loading ? (
                      <tr>
                        <td colSpan="7">
                          <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        </td>
                      </tr>
                    ) : assignmentSubmissions.length === 0 ? (
                      <tr>
                        <td colSpan="7">No Submissions Yet!</td>
                      </tr>
                    ) : (
                      assignmentSubmissions.map((submission, i) => {
                        if (submission.assignmentName !== null) {
                          return (
                            <tr key={submission.id}>
                              <td>{i + 1}</td>
                              <td>
                                {submission.assignmentName.assignmentTitle}
                                {/*
                                <br />
                                {submission.createdAt >
                                submission.assignmentName
                                  .finalSubmissionDate ? (
                                  <Badge pill variant="danger">
                                    Late / Extended
                                  </Badge>
                                ) : (
                                  <Badge pill variant="success">
                                    On Time
                                  </Badge>
                                )} */}
                              </td>
                              <td>
                                {submission.assignmentName.subjectName !== null
                                  ? submission.assignmentName.subjectName
                                      .subjectName
                                  : "Invalid Subject"}
                              </td>
                              <td>
                                {" "}
                                <button
                                  className="btn btn-icon btn-light btn-sm"
                                  onClick={() =>
                                    Storage.get(submission.assignmentFile.key, {
                                      download: true,
                                    }).then((res) => {
                                      console.log(res);
                                      this.downloadBlob(
                                        res.Body,
                                        submission.assignmentFile.key
                                      );
                                    })
                                  }
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-success">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Files/Cloud-download.svg"
                                      )}
                                    ></SVG>
                                  </span>
                                </button>{" "}
                                {submission.remarks !== null ? (
                                  <button
                                    className="btn btn-icon btn-light btn-sm"
                                    onClick={() =>
                                      this.setState({
                                        submissionName:
                                          submission.assignmentName
                                            .assignmentTitle,
                                        assignmentRemarks: submission.remarks,
                                        openRemarks: true,
                                      })
                                    }
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/General/Visible.svg"
                                        )}
                                      ></SVG>
                                    </span>
                                  </button>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        } else console.log(submission);
                      })
                    )
                  ) : (
                    <tr>
                      <td colSpan="7">{message}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default assignmentSubmissions;
